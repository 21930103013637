import React from 'react';
import { useRecordContext, useGetOne } from 'react-admin';
import { DateTime } from 'luxon';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { DateTimeInput } from './date-time-input';

interface DateTimeProviderTZInputProps {
  source: string;
  tenant_id?: string | number;
  label?: string;
  hideIfNoTenant?: boolean;
  defaultValue?: string | null;
  dateTimeFormat?: string;
}

const DateTimeProviderTZInput: React.FC<DateTimeProviderTZInputProps> = ({
  source,
  tenant_id,
  hideIfNoTenant = false,
  label,
  defaultValue = null,
  dateTimeFormat = 'fff',
}) => {
  const record = useRecordContext();
  const effectiveTenantId = tenant_id || record?.user?.tenant_id;

  const { data, isLoading } = useGetOne(
    'providers',
    {
      id: effectiveTenantId,
    },
    { enabled: !!effectiveTenantId },
  );

  if (!effectiveTenantId && hideIfNoTenant) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <LoadingSpinner className="h-8 w-8" />
      </div>
    );
  }

  if (!data) {
    return null;
  }
  const tzAbbreviation = DateTime.now().setZone(data.timezone).toFormat('ZZZZ');
  return (
    <DateTimeInput
      className="w-full"
      source={source}
      label={label}
      defaultValue={defaultValue}
      timezone={data.timezone}
      dateTimeFormat={dateTimeFormat}
      helperText={`Using provider timezone: ${tzAbbreviation}`}
    />
  );
};

export default DateTimeProviderTZInput;
