import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import { ScrollArea } from '@/modules/ui/components/scroll-area';
import { cn } from '@/modules/ui/utils/cn';
import { formatDistanceToNow } from 'date-fns';
import { titleize } from 'inflection';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { createElement } from 'react';

export function EventListView({
  items,
  itemComponent = MeasurementItem,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  allowFetchMore = true,
}) {
  if (!items.length) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="text-md  text-foreground">No events found</div>
      </div>
    );
  }

  return (
    <ScrollArea className="h-[40vh] ">
      <div className="flex flex-col gap-2 p-4 pt-0">
        {items.map((item) =>
          createElement(itemComponent, { key: item.id, item }),
        )}
      </div>
      {hasNextPage && allowFetchMore && (
        <div className="flex justify-center p-4">
          <Button
            variant="outline"
            onClick={fetchNextPage}
            disabled={isFetchingNextPage}
          >
            Load more
          </Button>
        </div>
      )}
    </ScrollArea>
  );
}

const MeasurementItem = ({ item }) => {
  const formatMeasurementValue = (measurement) => {
    if (measurement.name === 'blood_pressure') {
      return `${measurement.value.systolic}/${measurement.value.diastolic} ${measurement.unit.text}`;
    } else {
      return `${measurement.value} ${measurement.unit.text}`;
    }
  };

  const parsedValue = get(item, 'value.0');

  if (!parsedValue) {
    return null;
  }

  return (
    <div
      key={item.id}
      className={cn(
        'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
      )}
    >
      <div className="flex w-full flex-col gap-1">
        <div className="flex items-center">
          <div className="flex items-center gap-2">
            <div className="font-semibold"></div>
            <Badge variant="outline">
              {parsedValue?.name && titleize(parsedValue.name)}
            </Badge>
          </div>
          <div className={cn('ml-auto text-xs', 'text-foreground')}>
            {formatDistanceToNow(
              DateTime.fromISO(item.created_on, {
                zone: 'utc',
              })
                .setZone('local')
                .toJSDate(),
              {
                addSuffix: true,
              },
            )}
          </div>
        </div>
        <div className="text-xs font-medium">{item.subject}</div>
      </div>
      {parsedValue && (
        <div className=" text-mdtext-muted-foreground w-full">
          <div className="flex flex-row justify-between ">
            <div>
              {DateTime.fromISO(parsedValue.timestamp, { zone: 'utc' })
                .setZone('local')
                .toLocaleString(DateTime.DATETIME_MED)}
            </div>

            <div className="text-lg font-semibold">
              {formatMeasurementValue(parsedValue)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
