import {get} from 'lodash';

export function processAPIError(error) {
  const statusCode = error.status;
  const body = error.body;

  if (statusCode === 422) {
    if (typeof body.detail === 'string') {
      return body.detail;
    }
    const loc = get(body, 'detail[0].loc', []).join('.');
    return `Form is missing information: ${loc}`;
  } else {
    return body?.message || body?.detail || null;
  }
}
