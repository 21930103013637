import { ProviderLocationFilter } from '@/modules/data-table/components/ProviderLocationFilter';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import { patientStatus } from '@/modules/patients/constants/patientStatus';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import ProviderField from '@/modules/ra-ui/components/ProviderField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { CardDescription, CardTitle } from '@/modules/ui/components/card';
import { Card, CardContent, CardHeader } from '@/modules/ui/components/card';
import React from 'react';
import {
  Datagrid,
  ListContextProvider,
  Pagination,
  SortPayload,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';

export const UserPatientEnrollment = () => {
  const record = useRecordContext();
  const [sort, setSort] = React.useState<SortPayload>({
    field: 'enrolled_on',
    order: 'DESC',
  });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [filterValues, setFilters] = React.useState({});

  const { data, total, isLoading } = useGetList(`patients`, {
    pagination: { page, perPage },
    filter: {
      'enrolled_by_id[eq]': record.id,
      ...filterValues,
      // 'user.enrolled_on[gte]': filterValues['enrolled_on[gte]'],
      // 'user.enrolled_on[lte]': filterValues['enrolled_on[lte]'],
    },
    sort,
  });

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Enrolled Patients</CardTitle>
        <CardDescription>View patients enrolled by this user.</CardDescription>
      </CardHeader>
      <CardContent className="space-y-2 ">
        <ListContextProvider
          // @ts-ignore
          value={{ filterValues, setFilters, displayedFilters: {} }}
        >
          <DataTableToolbar
            showViewOptions={false}
            search={false}
            className="mx-2"
          >
            <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
            <ProviderLocationFilter label="Onboarding Location" />

            <DataTableDateRangeFilter column="enrolled_on" title="Date range" />
          </DataTableToolbar>
          <Datagrid
            size="small"
            data={data}
            total={total}
            isLoading={isLoading}
            sort={sort}
            bulkActionButtons={false}
            setSort={setSort}
            empty={
              <div className="flex justify-center items-center h-full">
                {filterValues['enrolled_on[gte]'] ||
                filterValues['enrolled_on[lte]'] ? (
                  <p className="text-sm text-muted-foreground text-center">
                    No patients enrolled during the selected date range.
                  </p>
                ) : (
                  <p className="text-sm text-muted-foreground text-center">
                    User has not enrolled any patients.
                  </p>
                )}
              </div>
            }
            rowClick={(id) => `/patients/${id}/show`}
          >
            <BadgeField
              source="patient_status"
              label="Status"
              mapping={patientStatus}
            />
            <TextField
              source="user.full_name"
              label="Name"
              sortBy="user.last_name"
            />
            <ProviderField
              label="Provider"
              source="user.tenant_id"
              sortBy="tenant.name"
            />
            <LuxonDateField source="user.enrolled_on" label="Enrolled On" />
          </Datagrid>
          <Pagination
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </ListContextProvider>
      </CardContent>
    </Card>
  );
};
