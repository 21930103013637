import * as React from 'react';

import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';

export const ResetPatientSuccess = () => (
  <>
    <CardHeader>
      <CardTitle className="text-xl">Reset Password Success</CardTitle>
      <CardDescription>Your password was successfully set!</CardDescription>
    </CardHeader>
    <CardContent>
      <div className="flex flex-col gap-2 items-center">
        <p className="text-base">
          Login to your account through the Vironix RPM mobile apps.
        </p>
        <p className="text-sm italic">
          Vironix only supports patient accounts through the Vironix RPM mobile
          apps.
        </p>
        <p className="text-base">
          <a
            href="https://vironix.notion.site/Help-Center-a26695ee626c4a0895b34f67b14caea4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </p>
      </div>
    </CardContent>
  </>
);
