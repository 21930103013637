import { useStore } from 'ra-core';
import React, { createContext, useContext } from 'react';

interface EnrollmentQueueConfig {
  showEnrolledPatients: boolean;
  appointmentDate: null | { from: Date; to: Date };
}

const defaultConfig: EnrollmentQueueConfig = {
  showEnrolledPatients: false,
  appointmentDate: {
    from: new Date(new Date().setHours(0, 0, 0, 0)),
    to: new Date(new Date().setHours(23, 59, 59, 999)),
  },
};

const EnrollmentQueueContext = createContext<{
  config: EnrollmentQueueConfig;
  setConfig: React.Dispatch<React.SetStateAction<EnrollmentQueueConfig>>;
}>({
  config: defaultConfig,
  setConfig: () => {},
});

export const EnrollmentQueueProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [config, setConfig] = useStore<EnrollmentQueueConfig>(
    'enrollmentQueue.enrollmentConfig',
    defaultConfig,
  );

  return (
    <EnrollmentQueueContext.Provider value={{ config, setConfig }}>
      {children}
    </EnrollmentQueueContext.Provider>
  );
};

export const useEnrollmentQueue = () => useContext(EnrollmentQueueContext);
