import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { FileDown, Loader2 } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import {
  AutocompleteInput,
  Form,
  NumberInput,
  ReferenceInput,
  SelectInput,
  maxValue,
  minValue,
  required,
  useDataProvider,
  useGetIdentity,
  useNotify,
  usePermissions,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { get } from 'lodash';
import { filterTenantName } from '@/utils/filterTenantName';

// MUI dialog for export
export const ExportClaimItemsButton = () => {
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const notify = useNotify();

  const dataprovider = useDataProvider();

  const handleClickOpen = () => {
    setIsLoading(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onExport = (values) => {
    setIsLoading(true);

    dataprovider
      .getFile(`claim-items/export`, {
        meta: {
          export_type: values.export_type,
          tenant_id: values.tenant_id || identity?.tenantId,
          month: values.month,
          year: values.year,
        },
        download: values.export_type === 'csv' ? 'export.csv' : 'export.pdf',
        downloadType:
          values.export_type === 'csv' ? 'text/csv' : 'application/pdf',
      })
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => {
        setIsLoading(false);
        setOpen(false);
      })
      .catch(() => {
        notify('Error exporting claims', { type: 'error' });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        size="sm"
        className="h-8"
        variant="outline"
      >
        <FileDown className="mr-2 h-4 w-4" />
        Export
      </Button>

      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogContent onClose={handleClose}>
          <DialogHeader>
            <DialogTitle>Export Claims</DialogTitle>
          </DialogHeader>
          <Form onSubmit={onExport}>
            <div className="grid grid-col">
              {get(permissions, 'is_internal') === true ? (
                <ReferenceInput
                  source="tenant_id"
                  reference="providers"
                  sort={{
                    field: 'name',
                    order: 'ASC',
                  }}
                >
                  <AutocompleteInput
                    filterToQuery={filterTenantName}
                    label="Provider"
                    optionText="name"
                    helperText={false}
                    validate={required()}
                  />
                </ReferenceInput>
              ) : null}
              <SelectInput
                source="export_type"
                label="Export Type"
                choices={[
                  { name: 'CSV', id: 'csv' },
                  { name: 'PDF', id: 'pdf' },
                ]}
                validate={required()}
                helperText={false}
                defaultValue={'csv'}
              />
              <div className="flex space-x-4">
                <SelectInput
                  source="month"
                  label="Month"
                  choices={[
                    { id: 1, name: 'January' },
                    { id: 2, name: 'February' },
                    { id: 3, name: 'March' },
                    { id: 4, name: 'April' },
                    { id: 5, name: 'May' },
                    { id: 6, name: 'June' },
                    { id: 7, name: 'July' },
                    { id: 8, name: 'August' },
                    { id: 9, name: 'September' },
                    { id: 10, name: 'October' },
                    { id: 11, name: 'November' },
                    { id: 12, name: 'December' },
                  ]}
                  validate={required()}
                  helperText={false}
                  defaultValue={DateTime.now().month}
                />
                <NumberInput
                  source="year"
                  label="Year"
                  validate={[required(), minValue(2022), maxValue(2100)]}
                  helperText={false}
                  defaultValue={DateTime.now().year}
                />
              </div>
            </div>
            <DialogFooter className="items-center pt-4">
              <p className="text-xs text-justify p-2">
                Nothing in these exports should be construed as a guarantee that
                specific services will be reimbursed. Reimbursement rates vary
                by geography and are based on the location of the billing
                practitioner when delivering the services. Billing practitioners
                should check with their local Medicare Administrative Contractor
                (“MAC”) and/or specific commercial payers for confirmation of
                reimbursement rates for these services.
              </p>
              <SubmitButton isLoading={isLoading} />
            </DialogFooter>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const SubmitButton = ({ isLoading }) => {
  const { isValid, isValidating, isSubmitting } = useFormState();

  const disabled = isValidating || isSubmitting || !isValid || isLoading;
  return (
    <Button disabled={disabled} type="submit" className="mt-2 min-w-32">
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Please wait
        </>
      ) : (
        <>
          <FileDown className="mr-2 h-4 w-4 " />
          Export
        </>
      )}
    </Button>
  );
};
