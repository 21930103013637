import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { Separator } from '@/modules/ui/components/separator';
import { NumberInput } from '@/modules/ui/inputs/number-input';
import { get } from 'lodash';
import { CheckIcon, PlusCircle } from 'lucide-react';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useListContext } from 'react-admin';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

interface DataTableDaysFilterProps {
  column?: string;
  title?: string;
  onSubmit?: (data: any) => void;
  submittedDays?: number;
}

// replace `[` `]`with _ to avoid react-hook-form error
const sanitizeName = (name) => {
  return name.replace(/\[/g, '_').replace(/\]/g, '_');
};

const calculateDateFromDays = (days: number): string => {
  if (!days && days !== 0) return null;
  const date = DateTime.now().minus({ days }).startOf('day');
  return date.toISO();
};

const dateToDays = (date: string): number => {
  if (!date) return null;
  const days = DateTime.now()
    .startOf('day')
    .diff(DateTime.fromISO(date).startOf('day'), 'days').days;
  return Math.round(days);
};

export function DataTableDaysFilter({
  column,
  title,
}: DataTableDaysFilterProps) {
  const { filterValues, setFilters } = useListContext();
  const form = useForm<Object>({
    defaultValues: {
      [sanitizeName(column)]: get(filterValues, column),
    },
  });
  const [submittedDays, setSubmittedDays] = React.useState<number | null>(
    dateToDays(get(filterValues, column)),
  );

  React.useEffect(() => {
    const newValue = get(filterValues, column, null);
    const newValueDays = dateToDays(newValue);

    if (newValueDays !== submittedDays) {
      setSubmittedDays(newValueDays);
      form.setValue(sanitizeName(column), newValueDays);
    }
  }, [filterValues, column, form, submittedDays]);

  const onSubmit = React.useCallback(
    (data) => {
      const days = data[sanitizeName(column)];
      setSubmittedDays(days);
      setFilters(
        {
          ...filterValues,
          [column]: calculateDateFromDays(days),
        },
        null,
      );
    },
    [setFilters, filterValues, column],
  );

  return (
    <FormProvider {...form}>
      <DataTableDaysFilterInput
        column={sanitizeName(column)}
        title={title}
        onSubmit={onSubmit}
        submittedDays={submittedDays}
      />
    </FormProvider>
  );
}

function DataTableDaysFilterInput({
  column,
  title,
  onSubmit,
  submittedDays,
}: DataTableDaysFilterProps) {
  const form = useFormContext();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircle className="mr-2 h-4 w-4" />
          {title}
          {submittedDays && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal"
              >
                {submittedDays} days
              </Badge>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="m-2 flex flex-col gap-2">
            <NumberInput source={column} label="Days since Measurement" />

            <Button size="sm" type="submit">
              <CheckIcon className="mr-2 h-4 w-4" />
              Apply
            </Button>
          </div>
        </form>
      </PopoverContent>
    </Popover>
  );
}
