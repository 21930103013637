import React from 'react';
import { appIcon, iphoneScreenhosts } from '../../assets';
import { iphoneInforamtion, iphoneDescription } from '../../constants';

export const AppStorePreview = () => {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: 880,
        padding: 16,
        boxSizing: 'border-box',
      }}
    >
      <header
        style={{
          paddingTop: 16,
          paddingBottom: 16,
          borderBottomWidth: 0.5,
          borderBottomStyle: 'solid',
          borderBottomColor: '#80808063',
          width: '100%',
          marginBottom: 0,
        }}
      >
        <span
          style={{
            fontSize: 18,
            fontWeight: '600',
            marginRight: 5,
          }}
        >
          App Store
        </span>
        <span
          style={{
            fontSize: 18,
          }}
        >
          Preview
        </span>
      </header>

      <div
        style={{
          display: 'flex',
          width: '100%',
          paddingTop: 32,
          paddingBottom: 32,
          borderBottomWidth: 0.5,
          borderBottomStyle: 'solid',
          borderBottomColor: '#80808063',
        }}
      >
        <img
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#80808063',
            borderRadius: 30,
            padding: 20,
            width: 140,
            height: 140,
            marginRight: 15,
          }}
          src={appIcon}
          alt="App Icon"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                fontSize: 20,
                fontWeight: '600',
                marginBottom: 3,
              }}
            >
              Vironix RPM
            </span>
            <span
              style={{
                fontSize: 14,
                marginBottom: 3,
                color: '#58585B',
              }}
            >
              Vironix Health
            </span>
            <span
              style={{
                fontSize: 14,
                marginBottom: 3,
                color: '#58585B',
              }}
            >
              Design for iPhone
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                fontSize: 14,
                marginBottom: 3,
                color: '#58585B',
              }}
            >
              Free
            </span>
            <a
              style={{
                fontSize: 16,
                color: '#0066BD',
                padding: 7,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#0066BD',
                borderRadius: 5,
                textDecoration: 'none',
              }}
              href="https://apps.apple.com/us/app/vironix-rpm/id1553104293?uo=4&at=11l6hc&app=itunes&ct=fnd"
            >
              View in App Store ↗
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          minWidth: '100%',
          borderBottomWidth: 0.5,
          borderBottomStyle: 'solid',
          borderBottomColor: '#80808063',
          paddingTop: 32,
          paddingBottom: 32,
        }}
      >
        <span
          style={{
            fontSize: 20,
            fontWeight: '600',
          }}
        >
          iPhone Screenshots
        </span>
        <div
          style={{
            overflow: 'auto',
            whiteSpace: 'nowrap',
            marginTop: 20,
          }}
        >
          {iphoneScreenhosts.map((screenshot, index) => (
            <img
              key={index}
              style={{
                width: 200,
                borderRadius: 20,
                marginRight: 10,
              }}
              src={screenshot}
              alt="Screenshot"
            />
          ))}
        </div>
      </div>

      <div
        style={{
          maxWidth: 500,
          marginTop: 16,
        }}
      >
        <span
          style={{
            fontSize: 20,
            fontWeight: '600',
          }}
        >
          Description
        </span>
        {iphoneDescription.map((description) => (
          <p
            style={{
              maxWidth: 500,
            }}
            key={description}
          >
            {description}
          </p>
        ))}
      </div>

      <div
        style={{
          minWidth: '100%',
          paddingTop: 32,
          paddingBottom: 32,
        }}
      >
        <span
          style={{
            fontSize: 20,
            fontWeight: '600',
          }}
        >
          Information
        </span>
        {iphoneInforamtion.map((info) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
                marginBottom: 10,
              }}
              key={info.title}
            >
              <span
                style={{
                  fontSize: 14,
                  color: '#58585B',
                  marginBottom: 3,
                }}
              >
                {info.title}
              </span>
              <span
                style={{
                  fontSize: 14,
                }}
              >
                {info.text}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
