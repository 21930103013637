import {
  get,
  isEmpty,
  isNull,
  has,
  forOwn,
  isPlainObject,
  isArray,
  forEach,
} from 'lodash';

function isEmptyValues(value) {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export const sanitizeEmptyValuesCustom = (values, record = {}) => {
  const sanitizedValues = {};
  forOwn(values, (value, key) => {
    if (isNull(value) || isEmptyValues(value)) {
      if (has(record, key)) {
        sanitizedValues[key] = null;
      }
    } else if (isPlainObject(value)) {
      const sanitizedValue = sanitizeEmptyValuesCustom(value, get(record, key));
      if (!isEmptyValues(sanitizedValue)) {
        sanitizedValues[key] = sanitizedValue;
      }
    } else if (isArray(value)) {
      const sanitizedArray = [];
      forEach(value, (item) => {
        if (isPlainObject(item)) {
          const sanitizedItem = sanitizeEmptyValuesCustom(item, {});
          if (!isEmpty(sanitizedItem)) {
            sanitizedArray.push(sanitizedItem);
          }
        } else if (!isNull(item) && !isEmpty(item)) {
          sanitizedArray.push(item);
        }
      });
      if (!isEmpty(sanitizedArray)) {
        sanitizedValues[key] = sanitizedArray;
      }
    } else {
      sanitizedValues[key] = value;
    }
  });
  return sanitizedValues;
};
