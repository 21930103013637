import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import inflection from 'inflection';
import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { AssessmentType } from '../types/assessmentTypes';
import { renderAvailableNotesText } from '../utils/renderAvailableNotesText';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';

type ModalSelectAssessmentContentProps = {
  onCloseClick: () => void;
  onNextClick: (workflow: AssessmentType) => void;
  dataAssessments: AssessmentType[];
  isLoading: boolean;
};

export function ModalSelectAssessmentContent({
  onCloseClick,
  onNextClick,
  dataAssessments,
  isLoading,
}: ModalSelectAssessmentContentProps) {
  const [selectedAssessment, setSelectedAssessment] =
    useState<AssessmentType>();
  const patientRecord = useRecordContext();

  const onChangeAssessments = (event) => {
    setSelectedAssessment({
      ...dataAssessments.find(
        (assessment) => assessment.name === event.target.value,
      ),
    });
  };

  if (!patientRecord?.id) return;
  return (
    <Box p="32px">
      <Box mb="12px">
        <Typography variant="body1">
          This process will allow you to take an assessment on behalf of a
          patient. Please make sure you clearly read the question and options to
          the patient. Depending on the questionnaire, you might want to ask the
          patient to log their physiological measurements before starting
        </Typography>
      </Box>

      {isLoading ? (
        <div className="flex items-center justify-center w-full">
          <LoadingSpinner />
        </div>
      ) : (
        <Box mb={1} mt={4}>
          <Typography variant="subtitle2">Select an assessment:</Typography>
          <FormControl>
            <RadioGroup onChange={onChangeAssessments}>
              {dataAssessments.map((userAssessment) => {
                return (
                  <FormControlLabel
                    key={userAssessment.name}
                    value={userAssessment.name}
                    control={<Radio />}
                    label={
                      <Tooltip
                        title={
                          userAssessment.available === false ? (
                            <div style={{ whiteSpace: 'pre-line' }}>
                              {renderAvailableNotesText(
                                userAssessment.available_notes,
                              )}
                            </div>
                          ) : (
                            ''
                          )
                        }
                      >
                        <Typography variant="body1">
                          {userAssessment.title ||
                            inflection.titleize(userAssessment.name)}{' '}
                          {userAssessment.available === false
                            ? '(Unavailable)'
                            : ''}
                        </Typography>
                      </Tooltip>
                    }
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Box mr="13px">
          <Button onClick={onCloseClick} size="large">
            Cancel
          </Button>
        </Box>

        <Button
          onClick={() => {
            onNextClick(selectedAssessment);
          }}
          size="large"
          variant="contained"
          disabled={!selectedAssessment?.name}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
