import { useOrganization } from '@/modules/organizations/components/OrganizationProvider';
import { AddressInput } from '@/modules/ra-ui/components/address-input';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { filterTenantName } from '@/utils/filterTenantName';
import { get } from 'lodash';
import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  required,
  usePermissions,
} from 'react-admin';

export const LocationCreateButton = () => {
  const { organization } = useOrganization();
  const { permissions } = usePermissions();

  const transform = (data) => {
    return {
      ...data,
      tenant_id: data.tenant_id || get(organization, 'id'),
    };
  };

  return (
    <CreateInDialogButton
      title="Create location"
      resource="locations"
      variant="default"
      transform={transform}
      label="Location"
      notifyMessage="Location created."
    >
      <div className="flex flex-col gap-1">
        <TextInput
          source="name"
          helperText={false}
          inputProps={{
            autoComplete: 'nope',
          }}
          validate={required()}
        />
        <TextInput
          source="description"
          minRows={3}
          multiline
          helperText={false}
        />
        {get(permissions, 'is_internal') === true && (
          <ReferenceInput
            label="Provider"
            source="tenant_id"
            reference="providers"
          >
            <AutocompleteInput
              label="Provider"
              optionText="name"
              parse={(v) => (v === '' ? null : v)}
              helperText="Keep empty for internal-use location."
              filterToQuery={filterTenantName}
            />
          </ReferenceInput>
        )}
        <AddressInput source="address" isRequired />
      </div>
    </CreateInDialogButton>
  );
};
