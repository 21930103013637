import { LoaderIcon } from 'lucide-react';
import {
  Form,
  required,
  useAuthProvider,
  useNotify,
  useSafeSetState,
} from 'ra-core';
import * as React from 'react';

import { Button } from '@/modules/ui/components/button';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { TextInput } from '@/modules/ui/inputs/text-input';

export const ForgotPasswordForm = (props) => {
  const { setView } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const authProvider = useAuthProvider();
  const notify = useNotify();

  const submit = (values: FormData) => {
    setLoading(true);
    authProvider
      .resetPassword(values)
      .then(() => {
        setLoading(false);
        notify('Password reset link sent.', { type: 'success' });
        setView('login');
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          {
            type: 'warning',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                    ? error.message
                    : undefined,
            },
          },
        );
      });
  };

  return (
    <>
      <CardHeader>
        <CardTitle className="text-2xl">Forgot your Password</CardTitle>
        <CardDescription>
          Enter your email below to reset your password
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form onSubmit={submit} mode="onChange" noValidate>
          <div className="flex flex-col gap-4">
            <TextInput
              autoFocus
              source="email"
              label="Email"
              validate={required()}
            />

            <Button
              variant="default"
              type="submit"
              color="primary"
              disabled={loading}
              className="w-full"
            >
              {loading ? <LoaderIcon className="animate-spin" /> : 'Reset'}
            </Button>
            <Button
              variant="ghost"
              onClick={() => setView('login')}
              className="w-full"
            >
              Back to login
            </Button>
          </div>
        </Form>
      </CardContent>
    </>
  );
};
