import { Button } from '@/modules/ui/components/button';
import clsx from 'clsx';
import { Loader2 } from 'lucide-react';
import PropTypes from 'prop-types';
import {
  setSubmissionErrors,
  useSaveContext,
  useTranslate,
  warning,
} from 'ra-core';
import React, { useState, useRef } from 'react';
import { MouseEventHandler, useCallback } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

interface SaveButtonProps {
  icon?: React.ReactNode;
  invalid?: boolean;
  label?: string;
  onClick?: (event) => void;
  mutationOptions?: any; // You may want to provide a more specific type here
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset'; // Assuming these are the possible values
  transform?: any; // You may want to provide a more specific type here
  variant?: any; // Assuming these are the possible values
  alwaysEnable?: boolean;
  className?: string;
  [key: string]: any; // This allows for additional, unspecified props
}

export const SaveButton = (props: SaveButtonProps) => {
  const {
    icon = null,
    invalid,
    label = 'ra.action.save',
    onClick,
    mutationOptions,
    disabled: disabledProp,
    type = 'submit',
    transform,
    variant = 'contained',
    alwaysEnable = false,
    className = null,
    ...rest
  } = props;
  const translate = useTranslate();
  const form = useFormContext();
  const saveContext = useSaveContext();
  const { dirtyFields, isValidating, isSubmitting } = useFormState();
  const [isThisButtonClicked, setIsThisButtonClicked] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  // useFormState().isDirty might differ from useFormState().dirtyFields (https://github.com/react-hook-form/react-hook-form/issues/4740)
  const isDirty = Object.keys(dirtyFields).length > 0;
  // Use form isDirty, isValidating and form context saving to enable or disable the save button
  // if alwaysEnable is undefined
  const disabled = valueOrDefault(
    alwaysEnable === false || alwaysEnable === undefined
      ? undefined
      : !alwaysEnable,
    disabledProp || !isDirty || isValidating || isSubmitting,
  );

  warning(
    type === 'submit' &&
      ((mutationOptions &&
        (mutationOptions.onSuccess || mutationOptions.onError)) ||
        transform),
    'Cannot use <SaveButton mutationOptions> props on a button of type "submit". To override the default mutation options on a particular save button, set the <SaveButton type="button"> prop, or set mutationOptions in the main view component (<Create> or <Edit>).',
  );

  const handleSubmit = useCallback(
    async (values) => {
      let errors;
      if (saveContext?.save) {
        errors = await saveContext.save(values, {
          ...mutationOptions,
          transform,
        });
      }
      if (errors != null) {
        setSubmissionErrors(errors, form.setError);
      }
      setIsThisButtonClicked(false);
    },
    [form.setError, saveContext, mutationOptions, transform],
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      setIsThisButtonClicked(true);
      if (onClick) {
        onClick(event);
      }
      if (event.defaultPrevented) {
        return;
      }
      if (type === 'button') {
        // this button doesn't submit the form, so it doesn't trigger useIsFormInvalid in <FormContent>
        // therefore we need to check for errors manually
        event.stopPropagation();
        await form.handleSubmit(handleSubmit)(event);
      }
    },
    [onClick, type, form, handleSubmit],
  );

  const displayedLabel = label && translate(label, { _: label });

  const isLoading = isSubmitting && isThisButtonClicked;
  return (
    <Button
      ref={buttonRef}
      variant={variant}
      className={clsx(className)}
      disabled={disabled || isLoading}
      onClick={handleClick}
      {...rest}
    >
      {isLoading ? (
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
      ) : icon ? (
        icon
      ) : null}
      {displayedLabel}
    </Button>
  );
};

SaveButton.propTypes = {
  className: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.element,
  alwaysEnable: PropTypes.bool,
};

const valueOrDefault = (value, defaultValue) =>
  typeof value === 'undefined' ? defaultValue : value;