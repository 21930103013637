import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { Button } from '@/modules/ui/components/button';
import { AlertCircle } from 'lucide-react';
import React, { ErrorInfo } from 'react';
import { useResetErrorBoundaryOnLocationChange } from 'react-admin';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/modules/ui/components/accordion';

function goBack() {
  window.history.go(-1);
}

export const ErrorCapture = ({
  error,
  errorInfo,
  resetErrorBoundary,
}: {
  error: Error;
  errorInfo: ErrorInfo;
  resetErrorBoundary: () => void;
}) => {
  useResetErrorBoundaryOnLocationChange(resetErrorBoundary);

  return (
    <div className="flex flex-col items-center justify-center h-screen px-4 ">
      <Alert variant="destructive" className="bg-white w-8/12 ">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          <p>{error.toString()}</p>
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger>Details</AccordionTrigger>
              <AccordionContent className="overflow-y-auto h-[80vh]">
                <p>{errorInfo?.componentStack}</p>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </AlertDescription>
      </Alert>
      <Button onClick={goBack} className="mt-4 text-center mx-auto">
        Go Back
      </Button>
    </div>
  );
};
