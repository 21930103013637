import { SimpleTable } from '@/modules/ra-ui/components/simple-table';
import { Badge } from '@/modules/ui/components/badge';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { createColumnHelper } from '@tanstack/react-table';
import inflection from 'inflection';
import { DateTime } from 'luxon';
import React from 'react';

type OneTimeFeeColumns = {
  id: number;
  fee_type: string;
  amount: number;
  timestamp: string;
  device_id?: number;
  stripe_charge_id?: string;
};

const columnHelper = createColumnHelper<OneTimeFeeColumns>();
const columns = [
  columnHelper.accessor('timestamp', {
    header: () => 'Timestamp',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATETIME_SHORT),
    enableSorting: true,
  }),
  columnHelper.accessor('amount', {
    header: () => 'Amount',
    cell: (info) => `$${info.getValue().toFixed(2)}`,
    enableSorting: true,
  }),
  columnHelper.accessor('fee_type', {
    header: () => 'Fee Type',
    cell: (info) => (
      <Badge variant="outline">{inflection.humanize(info.getValue())}</Badge>
    ),
    enableSorting: true,
  }),
];

export const DeviceOneTimeFeeTable = ({ data, isLoading, total }) => {
  if (isLoading) {
    return (
      <div className="w-full h-32 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (total === 0) {
    return (
      <div>
        <p className="text-center">None found</p>
      </div>
    );
  }

  return (
    <SimpleTable
      data={data}
      columns={columns}
      sorting={[{ id: 'timestamp', desc: true }]}
    />
  );
};
