import { Container, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import {
  ArrayInput,
  FormDataConsumer,
  NumberInput,
  RecordContextProvider,
  SaveContextProvider,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useGetIdentity,
  useGetOne,
  useNotify,
  useUpdate,
} from 'react-admin';
import { BaseForm } from '../../modules/ra-ui/components/BaseForm';
import { FormCard } from '../../modules/ra-ui/components/FormCard';

import AppTitle from '@/modules/layout/components/app-title';
export const OrganizationSetting: FC = (props) => {
  const { identity, isLoading: identityLoading } = useGetIdentity();

  const { data: organization } = useGetOne(
    'providers',
    { id: get(identity, 'tenantId') },
    { enabled: !identityLoading },
  );

  const [update, { isLoading: isUpdating }] = useUpdate();
  const notify = useNotify();

  const transformData = (data) => {
    const sanitizedLimits = get(data, 'settings.monitoring_limits', []).map(
      (item) => {
        const sanitizedValues = {};
        Object.keys(item).forEach((key) => {
          if (item[key] === '') {
            sanitizedValues[key] = null;
          } else {
            // this is a non-empty value, so we include it in the sanitized item
            sanitizedValues[key] = item[key];
          }
        });
        return sanitizedValues;
      },
    );

    return {
      ...data,
      settings: {
        ...data.settings,
        monitoring_limits: sanitizedLimits,
      },
    };
  };

  const save = (data) => {
    update(
      'providers',
      {
        id: get(identity, 'tenantId'),
        data: transformData(data),
        previousData: organization,
      },
      { onSuccess: (data) => notify('Changes saved') },
    );
  };

  return (
    <div className="m-4 mb-2">
      <AppTitle title="Organization" />
      <RecordContextProvider value={organization}>
        <SaveContextProvider
          value={{ save, saving: isUpdating, mutationMode: 'pessimistic' }}
        >
          <Container>
            <BaseForm
              title="Organization"
              warnWhenUnsavedChanges
              sanitizeEmptyValues
            >
              <FormCard title="Settings">
                <Stack spacing={1} alignItems="flex-start">
                  <Typography variant="h6">
                    {get(organization, 'name')}
                  </Typography>
                  <TextInput source="email" label="Primary Email" />
                  <Typography variant="h6">
                    Global device monitoring configuration
                  </Typography>
                  <Typography variant="body1">
                    The following rules will apply to all patients within your
                    organization, unless you add patient-specific rules.
                  </Typography>
                  <Typography variant="body1">
                    An alert will be triggered when a parameter is outside the
                    ranges specified. An empty value means no limit on that
                    bound.
                  </Typography>
                  <ArrayInput source={`settings.monitoring_limits`}>
                    <SimpleFormIterator inline>
                      <SelectInput
                        source="parameter"
                        choices={[
                          {
                            id: 'oxygen_saturation',
                            name: 'Oxygen Saturation',
                          },
                          { id: 'heart_rate', name: 'Heart Rate' },
                          { id: 'blood_pressure', name: 'Blood Pressure' },
                          { id: 'body_temperature', name: 'Temperature' },
                        ]}
                      />

                      <FormDataConsumer>
                        {({ scopedFormData, getSource, ...rest }) =>
                          scopedFormData.parameter &&
                          scopedFormData.parameter === 'blood_pressure' && (
                            <SelectInput
                              required
                              source={getSource('sub_parameter')}
                              parse={(v) => (v === '' ? null : v)}
                              choices={[
                                { id: 'systolic', name: 'Systolic' },
                                { id: 'diastolic', name: 'Diastolic' },
                              ]}
                            />
                          )
                        }
                      </FormDataConsumer>

                      <NumberInput source="min" />
                      <NumberInput source="max" />
                    </SimpleFormIterator>
                  </ArrayInput>
                  {/* <Typography variant="h6">Monitoring frequency</Typography>
                  <Typography variant="body1">
                    How often a patient will be instructed to take measurements
                    using their monitoring devices.
                  </Typography>
                  <Stack direction="row" gap={1}>
                    {deviceTypes.map((deviceType) => (
                      <SelectInput
                        source={`settings.monitoring_frequency.${deviceType.id}`}
                        label={inflection.titleize(deviceType.id)}
                        choices={[
                          { id: 'daily', name: 'Daily' },
                          { id: 'twice-daily', name: 'Twice daily' },
                          { id: 'weekly', name: 'Weekly' },
                        ]}
                      />
                    ))}
                  </Stack> */}
                </Stack>
              </FormCard>
            </BaseForm>
          </Container>
        </SaveContextProvider>
      </RecordContextProvider>
    </div>
  );
};
