import { Button } from '@/modules/ui/components/button';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { LoaderIcon } from 'lucide-react';
import { useAuthProvider, useLogin, useNotify } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { useMFAToken } from './MFATokenProvider';
import {
  startAuthentication,
  browserSupportsWebAuthn,
} from '@simplewebauthn/browser';
import { Separator } from '@/modules/ui/components/separator';

export function WebAuthnLogin({ setView }) {
  const [loading, setLoading] = useState(false);
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const { token, availableMethods } = useMFAToken();
  const login = useLogin();

  const checkBrowserSupport = () => {
    if (!browserSupportsWebAuthn()) {
      notify(
        'Your browser does not support WebAuthn. Please use a modern browser that supports this feature.',
        { type: 'error' },
      );
      setTimeout(() => setView('totp'), 1000);
      return false;
    }
    return true;
  };

  const startAuthenticationProcess = async () => {
    setLoading(true);

    try {
      const data = await authProvider.webAuthnMFAStart({ mfaToken: token });

      if (!checkBrowserSupport()) return;

      let asseResp = await startAuthentication(data);

      if (asseResp) {
        await handleWebAuthnMFA(asseResp);
      }
    } catch (error) {
      console.error('WebAuthn authentication failed:', error, error.message);
      if (error.status === 401 && error.message === 'Token has expired') {
        notify(
          "Oops! Your session timed out. No worries, let's try that again!",
          { type: 'warning' },
        );
        setTimeout(() => setView('login'), 3000);
      } else {
        notify('WebAuthn authentication failed. Please try again.', {
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    startAuthenticationProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only on first render

  const handleWebAuthnMFA = async (credential) => {
    try {
      await login({
        credential: credential,
        mfaToken: token,
        mfaType: 'webauthn',
      });
      notify('Login successful', { type: 'success' });
    } catch (error) {
      console.error('Login failed:', error);
      if (error.status === 401 && error.message === 'Token has expired') {
        notify('Request timed out. Please try again.', { type: 'warning' });
        setTimeout(() => setView('login'), 3000);
      } else {
        notify('Login failed. Please try again.', { type: 'error' });
      }
    }
  };

  return (
    <>
      <CardHeader className=" text-center">
        <CardTitle className="text-2xl text-center">
          Verify Your Identity
        </CardTitle>
        <CardDescription>
          Use your security key or biometric authentication for verification.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-4">
          <Button
            variant="default"
            className="w-full"
            onClick={startAuthenticationProcess}
            disabled={loading}
          >
            {loading ? <LoaderIcon className="animate-spin mr-2" /> : null}
            Verify
          </Button>
          {availableMethods?.includes('totp') && (
            <Button
              variant="outline"
              className="w-full"
              onClick={() => setView('totp')}
              type="button"
            >
              Use TOTP Instead
            </Button>
          )}
          <Separator className="w-full" />
          <Button
            variant="ghost"
            className="w-full"
            onClick={() => setView('login')}
            type="button"
          >
            Back
          </Button>
        </div>
      </CardContent>
    </>
  );
}
