import React from 'react';
import {
  ReferenceInput,
  useCreate,
  useGetIdentity,
  useRecordContext,
} from 'react-admin';
import { DateTime } from 'luxon';
import { Button } from '@/modules/ui/components/button';
import inflection from 'inflection';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { TextInput } from '@/modules/ui/inputs/text-input';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import DateTimeProviderTZInput from '@/modules/ui/inputs/date-time-provider-tz-input';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { AutocompleteInput } from '@/modules/ui/inputs/autocomplete-input';
import { get } from 'lodash';
const filterUserName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

export function MarkPendingButton() {
  const record = useRecordContext();
  const { identity } = useGetIdentity();
  const [create] = useCreate();
  const transform = (data) => {
    return {
      patient_status: 'pending_enrollment',
      eligible_devices: data.eligible_devices,
      insurances: data.insurances,
      insurance_coverage_type: data.insurance_coverage_type,
      onboarding_appointment_datetime: data.onboarding_appointment_datetime,
      onboarding_appointment_location_id:
        data.onboarding_appointment_location_id,
      expected_care_program: data.expected_care_program,
      expected_onboarding_user_id: data.expected_onboarding_user_id,
      virtual_enrollment: data.virtual_enrollment,
    };
  };

  if (!record) return null;

  if (record.patient_status === 'disabled') return null;

  let filters: any = {
    'role[ne]': `patient`,
    'tenant_id[in]': [get(record, 'user.tenant_id'), null],
  };

  const logMonitoringNote = (data) => {
    const patient_id = record?.id;

    create(`monitoring-activities`, {
      data: {
        patient_id: patient_id,
        practitioner_id: identity?.id,
        reviewed_on: DateTime.utc().toISO({ includeOffset: false }),
        type: 'activity',
        description:
          'Patient status updated to ' +
          inflection.humanize(data.patient_status, true),
      },
    });
  };

  return (
    <CreateInDialogButton
      title="Mark patient as pending"
      description="Please review and update the following information."
      resource={`patients/${record.id}/status`}
      transform={transform}
      notifyMessage="Patient set as pending"
      refreshOnSuccess={true}
      dialogVariant="shadcn"
      createButton={
        <Button className="w-fit mx-auto" variant="outline">
          Mark Pending
        </Button>
      }
      initialData={record}
      saveButtonAlwaysEnable
      onSuccess={(data) => {
        logMonitoringNote(data);
      }}
    >
      <div className="flex flex-col gap1">
        <TextInput
          source="eligible_devices"
          label="Eligible Devices"
          helperText={false}
        />
        <TextInput source="insurances" label="Insurances" helperText={false} />
        <SelectInput
          source="insurance_coverage_type"
          label="Insurance Coverage Type"
          choices={[
            { id: 'partial', name: 'Partial' },
            { id: 'full', name: 'Full' },
            { id: 'other', name: 'Other' },
          ]}
          helperText={false}
        />
        <DateTimeProviderTZInput
          source="onboarding_appointment_datetime"
          tenant_id={record?.user?.tenant_id}
        />
        <ReferenceInput
          source="onboarding_appointment_location_id"
          reference="locations"
          label="Appointment Location"
          filter={{
            'tenant_id[eq]': record?.user?.tenant_id,
          }}
        >
          <AutocompleteInput
            label="Appointment Location"
            optionText="name"
            parse={(v) => (v === '' ? null : v)}
            helperText={false}
          />
        </ReferenceInput>
        <TextInput
          source="expected_care_program"
          label="Expected Care Program"
          helperText={false}
        />

        <ReferenceInput
          source="expected_onboarding_user_id"
          reference="users"
          label="Enrollment Clinician"
          filter={filters}
        >
          <AutocompleteInput
            label="Enrollment Clinician"
            optionText={(choice) => {
              if (choice.is_internal) {
                return `${choice.first_name} ${choice.last_name} (Vironix) ${
                  choice.credentials ? `(${choice.credentials})` : ``
                }`;
              }
              return `${choice.full_name} ${
                choice.credentials ? `(${choice.credentials})` : ``
              }`;
            }}
            parse={(v) => (v === '' ? null : v)}
            helperText={false}
            filterToQuery={filterUserName}
          />
        </ReferenceInput>
        <SwitchInput
          source="virtual_enrollment"
          label="Virtual Enrollment"
          helperText={false}
        />
      </div>
    </CreateInDialogButton>
  );
}
