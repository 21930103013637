import React from 'react';
import { DatagridConfigurable, DatagridConfigurableProps } from 'react-admin';

interface DataGridScrollProps extends DatagridConfigurableProps {
  minWidth?: string | number;
}

export const DataGridScroll: React.FC<DataGridScrollProps> = ({
  sx,
  ...props
}) => {
  const defaultSx = {
    '& .RaDatagrid-root': {
      width: '100%',
    },
    '& .RaDatagrid-tableWrapper': {
      overflowX: 'scroll',
    },
    '& .RaDatagrid-table': {
      minWidth: 'fit-content',
    },
    '& .RaDatagrid-headerCell': {
      whiteSpace: 'nowrap',
    },
    '& .RaDatagrid-rowCell': {
      whiteSpace: 'nowrap',
    },
  };

  const combinedSx = { ...defaultSx, ...(sx as object) };

  return <DatagridConfigurable sx={combinedSx} {...props} />;
};
