import React, { ReactEventHandler } from 'react';
import { Button } from '@/modules/ui/components/button';
import { Trash2 } from 'lucide-react';
import {
  RedirectionSideEffect,
  useDeleteWithUndoController,
  useRecordContext,
} from 'react-admin';
import { cn } from '@/modules/ui/utils/cn';

export interface MiniDeleteButtonProps {
  onClick?: ReactEventHandler<any>;
  mutationOptions?: any;
  redirect?: RedirectionSideEffect;
  resource: string;
  patientId?: any;
  className?: string;
}

export const MiniDeleteButton = (props: MiniDeleteButtonProps) => {
  const {
    onClick,
    mutationOptions,
    redirect = false,
    resource,
    patientId = null,
    className,
  } = props;

  const record = useRecordContext(props);

  const deleteResource = patientId
    ? `patients/${patientId}/${resource}`
    : resource;

  const { isLoading, handleDelete } = useDeleteWithUndoController({
    record,
    resource: deleteResource,
    redirect,
    onClick,
    mutationOptions,
  });

  if (!record || record.id == null) {
    return null;
  }

  return (
    <Button
      size="sm"
      className={cn('h-8 ', className)}
      variant="ghost"
      onClick={handleDelete}
      disabled={isLoading}
    >
      <Trash2 className=" h-4 w-4 fill-red-500 stroke-red-500" />
    </Button>
  );
};
