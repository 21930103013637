import { SaveButton } from '@/modules/ra-ui/components/save-button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';

import {
  Dialog as ShadcnDialog,
  DialogContent as ShadcnDialogContent,
  DialogTitle as ShadcnDialogTitle,
  DialogDescription as ShadcnDialogDescription,
  DialogFooter as ShadcnDialogFooter,
  DialogHeader as ShadcnDialogHeader,
} from '@/modules/ui/components/dialog';

import { cn } from '@/modules/ui/utils/cn';

import inflection from 'inflection';
import { get, set } from 'lodash';
import { DateTime } from 'luxon';
import React, { ReactNode } from 'react';
import {
  CreateBase,
  Form,
  useCreate,
  useGetIdentity,
  useNotify,
  useRefresh,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

export type CreateDialogProps = {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  notifyMessage?: string | ((data: any) => string);
  deleteButton?: ReactNode;
  transform?: any;
  refreshOnSuccess?: boolean;
  dialogProps?: any;
  description?: string | null;
  dialogContentClassName?: string;
  closeOnClickOutside?: boolean;
  [key: string]: any;
  resource?: string;
  initialData?: any;
  logActionLabel?: string | ((data: any) => string) | null;
  enableLogAction?: boolean;
  logActionPatientIdField?: string;
  saveAndAddAnother?: boolean;
  saveAndAddAnotherClearFields?: string[];
  dialogVariant?: 'mui' | 'shadcn';
  onSuccess?: (data: any) => void;
};

const CreateDialogFooter = ({
  saveAndAddAnother,
  saveButtonAlwaysEnable,
  onSuccess,
  enableLogAction,
  logAction,
  notify,
  notifySuccessMessage,
  refreshOnSuccess,
  refresh,
  translate,
  resource,
  handleClose,
  saveButtonProps,
  saveAndAddAnotherClearFields,
  component: Component,
}) => {
  const form = useFormContext();

  return (
    <Component className="mt-4">
      {saveAndAddAnother && (
        <SaveButton
          variant="outline"
          type="button"
          size="sm"
          alwaysEnable={saveButtonAlwaysEnable}
          mutationOptions={{
            onSuccess: (data) => {
              const notifyMessage =
                typeof notifySuccessMessage === 'function'
                  ? notifySuccessMessage(data)
                  : notifySuccessMessage;

              onSuccess && onSuccess(data);
              enableLogAction && logAction(data);
              notify(notifyMessage, {
                type: 'success',
                multiLine: notifyMessage.length > 50,
              });
              if (refreshOnSuccess) refresh();
              if (saveAndAddAnotherClearFields) {
                saveAndAddAnotherClearFields.forEach((field) => {
                  form.setValue(field, null);
                });
              }
            },
            onError: (error) => {
              if (get(error, 'message', '').includes('Key')) {
                notify(
                  `${translate(`resources.${resource}.name`, {
                    _: inflection.transform(resource, [
                      'singularize',
                      'capitalize',
                    ]),
                  })} already exists.`,
                  { type: 'warning' },
                );
              } else {
                notify(
                  typeof error === 'string'
                    ? error
                    : get(error, 'message') || 'ra.notification.http_error',
                  { type: 'warning' },
                );
              }
            },
          }}
          label="Save & Add Another"
          {...saveButtonProps}
        />
      )}
      <SaveButton
        variant="default"
        type="button"
        size="sm"
        alwaysEnable={saveButtonAlwaysEnable}
        mutationOptions={{
          onSuccess: (data) => {
            onSuccess && onSuccess(data);
            enableLogAction && logAction(data);
            const notifyMessage =
              typeof notifySuccessMessage === 'function'
                ? notifySuccessMessage(data)
                : notifySuccessMessage;
            notify(notifyMessage, {
              type: 'success',
              multiLine: notifyMessage.length > 50,
            });
            if (refreshOnSuccess) {
              refresh();
            }
            handleClose();
          },
          onError: (error) => {
            if (get(error, 'message', '').includes('Key')) {
              notify(
                `${translate(`resources.${resource}.name`, {
                  _: inflection.transform(resource, [
                    'singularize',
                    'capitalize',
                  ]),
                })} already exists.`,
                { type: 'warning' },
              );
            } else {
              notify(
                typeof error === 'string'
                  ? error
                  : get(error, 'message') || 'ra.notification.http_error',
                { type: 'warning' },
              );
            }
          },
        }}
        {...saveButtonProps}
      />
    </Component>
  );
};

const CreateDialog: React.FC<CreateDialogProps> = (props) => {
  const {
    children,
    open,
    setOpen,
    title,
    transform = null,
    saveButtonAlwaysEnable = false,
    notifyMessage = null,
    dialogProps = {},
    refreshOnSuccess = false,
    description = null,
    dialogContentClassName = null,
    closeOnClickOutside = true,
    initialData = null,
    onSuccess,
    saveButtonProps = {},
    enableLogAction = false,
    logActionLabel = null,
    logActionPatientIdField = 'patient_id',
    saveAndAddAnother = false,
    saveAndAddAnotherClearFields = null,
    dialogVariant = 'mui',
    ...rest
  } = props;

  const { resource: resourceFromProps } = rest;
  const resourceFromContext = useResourceContext(props);
  const resource = resourceFromProps ? resourceFromProps : resourceFromContext;
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [create] = useCreate();
  const { identity } = useGetIdentity();

  const handleClose = (e) => {
    setOpen(false);
    e && e.stopPropagation();
  };

  const customTransform = (data) => {
    if (get(data, 'phone_number') === '+1') {
      set(data, 'phone_number', null);
    }
    if (transform) return transform(data);
    return data;
  };

  const notifySuccessMessage = notifyMessage
    ? notifyMessage
    : `${translate(`resources.${resource}.name`, {
        _: inflection.transform(resource, ['singularize', 'capitalize']),
      })} created`;

  const logAction = (data) => {
    const patient_id = get(data, logActionPatientIdField);
    if (!logActionLabel || !patient_id) return;

    create(`monitoring-activities`, {
      data: {
        patient_id: patient_id,
        practitioner_id: identity.id,
        reviewed_on: DateTime.utc().toISO({ includeOffset: false }),
        type: 'activity',
        description:
          typeof logActionLabel === 'function'
            ? logActionLabel(data)
            : logActionLabel,
      },
    });
  };

  const renderShadcnDialog = () => (
    <ShadcnDialog open={open} onOpenChange={setOpen} {...dialogProps}>
      <ShadcnDialogContent
        className={cn(
          'min-w-[400px] overflow-y-auto max-h-[80vh]',
          dialogContentClassName,
        )}
        onInteractOutside={
          !closeOnClickOutside
            ? (e) => {
                e.preventDefault();
              }
            : undefined
        }
      >
        <ShadcnDialogHeader>
          <ShadcnDialogTitle>{title}</ShadcnDialogTitle>
          {description && (
            <ShadcnDialogDescription>{description}</ShadcnDialogDescription>
          )}
        </ShadcnDialogHeader>

        <CreateBase resource={resource} {...rest} transform={customTransform}>
          <Form defaultValues={initialData}>
            {children}
            <CreateDialogFooter
              saveAndAddAnother={saveAndAddAnother}
              saveButtonAlwaysEnable={saveButtonAlwaysEnable}
              onSuccess={onSuccess}
              enableLogAction={enableLogAction}
              logAction={logAction}
              notify={notify}
              notifySuccessMessage={notifySuccessMessage}
              refreshOnSuccess={refreshOnSuccess}
              refresh={refresh}
              translate={translate}
              resource={resource}
              handleClose={handleClose}
              saveButtonProps={saveButtonProps}
              saveAndAddAnotherClearFields={saveAndAddAnotherClearFields}
              component={ShadcnDialogFooter}
            />
          </Form>
        </CreateBase>
      </ShadcnDialogContent>
    </ShadcnDialog>
  );

  const renderMuiDialog = () => (
    <Dialog
      open={open}
      onOpenChange={closeOnClickOutside ? setOpen : undefined}
      {...dialogProps}
    >
      <DialogContent
        onClose={handleClose}
        className={cn(
          'min-w-[400px] overflow-y-auto max-h-[80vh]',
          dialogContentClassName,
        )}
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {description && <DialogDescription>{description}</DialogDescription>}
        </DialogHeader>

        <CreateBase resource={resource} {...rest} transform={customTransform}>
          <Form defaultValues={initialData}>
            {children}
            <CreateDialogFooter
              saveAndAddAnother={saveAndAddAnother}
              saveButtonAlwaysEnable={saveButtonAlwaysEnable}
              onSuccess={onSuccess}
              enableLogAction={enableLogAction}
              logAction={logAction}
              notify={notify}
              notifySuccessMessage={notifySuccessMessage}
              refreshOnSuccess={refreshOnSuccess}
              refresh={refresh}
              translate={translate}
              resource={resource}
              handleClose={handleClose}
              saveButtonProps={saveButtonProps}
              saveAndAddAnotherClearFields={saveAndAddAnotherClearFields}
              component={DialogFooter}
            />
          </Form>
        </CreateBase>
      </DialogContent>
    </Dialog>
  );

  return dialogVariant === 'shadcn' ? renderShadcnDialog() : renderMuiDialog();
};

export default CreateDialog;
