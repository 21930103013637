import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, memo } from 'react';
import {
  sanitizeFieldRestProps,
  useRecordContext,
  useTranslate,
  useGetOne,
} from 'react-admin';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { Badge } from '@/modules/ui/components/badge';
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/modules/ui/components/tooltip';

export interface DateTimeProviderTZFieldProps {
  className?: string;
  emptyText?: string;
  source: string;
  label?: string;
  locales?: string | string[];
  options?: object;
  showTime?: boolean;
  showDate?: boolean;
  showTZ?: boolean;
  tenant_id?: string | number;
  onTenantIdFail?: 'ignore' | 'fail';
}

export const DateTimeProviderTZField: FC<DateTimeProviderTZFieldProps> = memo(
  (props) => {
    const {
      className,
      emptyText,
      locales,
      options,
      showTime = true,
      showDate = true,
      showTZ = false,
      source,
      tenant_id,
      onTenantIdFail = 'ignore',
      ...rest
    } = props;
    const translate = useTranslate();
    const record = useRecordContext(props);

    if (!showTime && !showDate) {
      throw new Error(
        '<DateTimeProviderTZField> cannot have showTime and showDate false at the same time',
      );
    }

    const effectiveTenantId = tenant_id || record?.user?.tenant_id;

    const { data: providerData, isLoading } = useGetOne(
      'providers',
      { id: effectiveTenantId },
      {
        enabled: !!effectiveTenantId,
        staleTime: 2 * 60 * 1000,
        refetchOnWindowFocus: false,
      },
    );

    if (!record) {
      return null;
    }

    const value = get(record, source);
    if (value === null || value === '') {
      return emptyText ? (
        <Badge
          variant="default"
          className={className}
          {...sanitizeFieldRestProps(rest)}
        >
          {emptyText && translate(emptyText, { _: emptyText })}
        </Badge>
      ) : null;
    }

    if (isLoading) {
      return (
        <div className="flex justify-center items-center">
          <LoadingSpinner className="h-8 w-8" />
        </div>
      );
    }

    if (onTenantIdFail === 'fail' && !providerData) {
      return null;
    } else {
      // fall back to local
      providerData.timezone =
        providerData?.timezone || DateTime.local().zoneName;
    }

    const date = DateTime.fromISO(value, { zone: 'utc' }).setZone(
      providerData.timezone,
    );

    let dateString = '';
    if (showTime && showDate) {
      dateString = date.toLocaleString(DateTime.DATETIME_MED);
    } else if (showDate) {
      dateString = date.toLocaleString(DateTime.DATE_MED);
    } else if (showTime) {
      dateString = date.toLocaleString(DateTime.TIME_SIMPLE);
    }

    let tzAbbreviation = null;
    let localTime = null;
    if (showTime && showTZ) {
      tzAbbreviation = date.toFormat('ZZZZ');
      localTime = DateTime.fromISO(value, { zone: 'utc' })
        .setZone('local')
        .toLocaleString(DateTime.DATETIME_MED);
    }

    return (
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div>
              {dateString}
              {tzAbbreviation && (
                <Badge variant="outline" className={`${className} ml-1 px-1.5`}>
                  {tzAbbreviation}
                </Badge>
              )}
            </div>
          </TooltipTrigger>
          <TooltipContent className="w-fit  p-2" align="start" side="bottom">
            <div className="flex flex-row items-center gap-1">
              <span className="text-xs font-bold">Your Time:</span> {localTime}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  },
);
