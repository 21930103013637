import { CheckIcon, PlusCircle } from 'lucide-react';
import * as React from 'react';

import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/modules/ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { Separator } from '@/modules/ui/components/separator';
import { cn } from '@/modules/ui/utils/cn';
import { get, isEqual } from 'lodash';
import { useListContext } from 'react-admin';
import { FormProvider, useController, useForm } from 'react-hook-form';

interface DataTableFilterProps {
  column?: string;
  title?: string;
  options: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
}

// replace `[` `]`with _ to avoid react-hook-form error
const sanitizeName = (name: string) => {
  return name.replace(/\[/g, '_').replace(/\]/g, '_');
};

interface FormValues {
  [key: string]: any;
  previousColumn: string | null;
}

export function DataTableFilter({
  column,
  title,
  options,
}: DataTableFilterProps) {
  const { filterValues, setFilters } = useListContext();
  const form = useForm<FormValues>({
    defaultValues: {
      [sanitizeName(column)]: get(filterValues, column),
      previousColumn: null,
    },
  });

  React.useEffect(() => {
    const newValue = get(filterValues, column, null);
    const previousValue = get(form.getValues(), sanitizeName(column));
    const previousColumn = form.getValues().previousColumn;

    if (previousColumn && previousColumn !== column) {
      // If the column has changed, unset the old filter
      setFilters(
        {
          ...filterValues,
          [previousColumn]: undefined,
        },
        null,
      );
      form.setValue(sanitizeName(previousColumn), undefined);
    }

    if (!isEqual(newValue, previousValue) || previousColumn !== column) {
      form.reset({
        [sanitizeName(column)]: newValue,
        previousColumn: column,
      });
    }
    // The reference to the filterValues object is not updated when it changes,
    // so we must stringify it to compare it by value and also compare the reference.
    // This makes it work for both input values and filters applied directly through
    // the ListContext.setFilter (e.g. QuickFilter in the simple example)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, form, column, setFilters]);

  React.useEffect(() => {
    const subscription = form.watch(async (values, { name }) => {
      const isFormValid = await form.trigger();
      if (name && isFormValid) {
        setFilters(
          {
            ...filterValues,
            [column]: get(values, sanitizeName(column)),
          },
          null,
        );
        // }
      }
    });
    return () => subscription.unsubscribe();
  }, [form, setFilters, filterValues, column]);

  return (
    <FormProvider {...form}>
      <DataTableFilterInput
        column={sanitizeName(column)}
        title={title}
        options={options}
      />
    </FormProvider>
  );
}

function DataTableFilterInput({
  column,
  title,
  options,
}: DataTableFilterProps) {
  const { field } = useController({ name: column });

  const selectedValues = new Set(
    field.value?.split(',').filter((i) => i !== '') ?? [],
  );

  const onChange = (newFilterValue) => {
    const newVal =
      newFilterValue && newFilterValue.length
        ? newFilterValue.join(',')
        : undefined;
    field.onChange(newVal);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircle className="mr-2 h-4 w-4" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-sm px-1 font-normal"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option.value}
                        className="rounded-sm px-1 font-normal"
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }
                      const newFilterValues = Array.from(selectedValues);
                      onChange(newFilterValues);
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => onChange(undefined)}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
