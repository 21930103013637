import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import { Separator } from '@/modules/ui/components/separator';
import inflection from 'inflection';
import { Flag, Plus, StickyNote } from 'lucide-react';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import {
  DeleteButton,
  RecordContextProvider,
  ReferenceField,
  TextField,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { AnnotateMetricCard } from './AnnotateMetricCard';
import { MetricsDialogContext } from './MetricsDialog';

export function MetricTableSidebar({ data }) {
  const { selectedRow, measureName } = useContext(MetricsDialogContext);
  const [showAnnotate, setShowAnnotate] = useState(false);
  const record = useRecordContext();
  const refresh = useRefresh();
  const selectedRowData = data?.pages
    ?.flatMap((page) => page.data.points)
    .find((point) => point.id === selectedRow);

  const formatValue = (value) => {
    if (measureName === 'blood_pressure') {
      return `${value.systolic} / ${value.diastolic}`;
    }
    return value;
  };

  return (
    <div className="p-4 border-l border-gray-200 sticky top-0">
      {!selectedRow && (
        <p className="text-center   text-gray-400">
          Select a measurement to view its details
        </p>
      )}
      {selectedRow && selectedRowData && !showAnnotate && (
        <div className="flex flex-col gap-2 ">
          <div className="flex flex-row justify-between">
            <p className="text-sm">
              {DateTime.fromISO(selectedRowData.x, {
                zone: 'utc',
              })
                .toLocal()
                .toLocaleString(DateTime.DATETIME_MED)}
            </p>
            <p className="text-right text-xl ">{selectedRowData.y}</p>
          </div>
          <Separator />
          <p className="text-sm font-semibold text-center">Annotations</p>
          {selectedRowData.annotations ? (
            selectedRowData.annotations.map((annotation) => (
              <RecordContextProvider value={annotation}>
                <div
                  key={annotation.id}
                  className="flex flex-col gap-1 border border-gray-200 rounded-md p-2"
                >
                  <div className="flex flex-row gap-1 justify-between	">
                    <div className="flex flex-row gap-1 items-center">
                      {annotation.annotation_type === 'flag' && (
                        <Flag className="w-4 h-4 text-red-500 fill-red-500" />
                      )}
                      {annotation.annotation_type === 'note' && (
                        <StickyNote className="w-4 h-4 " />
                      )}
                      <Badge variant="outline">
                        {inflection.humanize(annotation?.annotation_reason)}
                      </Badge>
                    </div>
                    <DeleteButton
                      redirect={false}
                      resource={`patients/${record?.id}/observation-annotations`}
                      mutationOptions={{
                        onSettled: () => {
                          refresh();
                        },
                      }}
                    />
                  </div>
                  <p className="text-sm">
                    <span className="font-semibold">Notes:</span>{' '}
                    {annotation.note}
                  </p>
                  {annotation.new_value && (
                    <p className="text-sm">
                      <span className="font-semibold">New value:</span>{' '}
                      {formatValue(annotation.new_value)}
                    </p>
                  )}
                  <ReferenceField
                    source="author_id"
                    reference="users"
                    link={false}
                  >
                    <TextField source="full_name" />
                  </ReferenceField>
                </div>
              </RecordContextProvider>
            ))
          ) : (
            <p className="text-center text-gray-400">No annotations</p>
          )}
          {/* Limit to one annotation per metric */}
          {selectedRowData?.annotations === null && (
            <Button
              onClick={() => setShowAnnotate(true)}
              className="w-fit m-auto"
            >
              <Plus className="w-4 h-4 mr-2" />
              Annotation
            </Button>
          )}
        </div>
      )}

      {selectedRowData && showAnnotate && (
        <AnnotateMetricCard clickCancel={() => setShowAnnotate(false)} />
      )}
    </div>
  );
}
