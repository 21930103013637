import React, { createElement } from 'react';
import { get } from 'lodash';
import { useRecordContext } from 'react-admin';
import { phoneNumberTypeMapping } from '../constants/phoneNumberTypeMapping';
import { formatDisplayPhoneNumber } from '@/utils/formatPhoneNumber';

export const PatientPhoneNumberList = () => {
  const record = useRecordContext();
  if (!record) return null;

  const phoneTypes = Object.keys(phoneNumberTypeMapping);

  const allPhoneNumbers = phoneTypes
    .map((type) => {
      const number = get(record, `user.${type}_phone_number`);
      return {
        type: phoneNumberTypeMapping[type].title,
        number,
        icon: phoneNumberTypeMapping[type].icon,
      };
    })
    .filter((phone) => phone.number); // Filter out phone numbers without a value

  return (
    <div className="flex flex-row gap-4 p-4">
      {allPhoneNumbers.length === 0 ? (
        <p>No phone numbers found</p>
      ) : (
        allPhoneNumbers.map((phone, index) => (
          <div key={index} className="flex items-center gap-2">
            {createElement(phone.icon, { size: 18 })}
            <div>
              <p className="text-sm ">{phone.type}</p>
              <p className="text-sm font-medium">
                {formatDisplayPhoneNumber(phone.number)}
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
