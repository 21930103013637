import { Button } from '@/modules/ui/components/button';
import { Plus } from 'lucide-react';

import React, { cloneElement } from 'react';
import CreateDialog, { CreateDialogProps } from './create-dialog';
import { MenuItem } from '@mui/material';

type OmitCreateDialogProps = Omit<CreateDialogProps, 'open' | 'setOpen'>;

type CreateInDialogButtonProps = OmitCreateDialogProps & {
  label?: string;
  icon?: React.ReactNode;
  variant?: string;
  createButton?: React.ReactElement;
};

const CreateInDialogDefaultButton = (props) => {
  const { label, icon, variant, ...rest } = props;

  if (variant === 'menu-item') {
    return <MenuItem {...rest}>{label}</MenuItem>;
  }
  return (
    <Button variant={variant} size="sm" className="h-8" type="button" {...rest}>
      {icon && cloneElement(icon, { className: 'mr-2 h-4 w-4' })}
      {label ? label : null}
    </Button>
  );
};

export default function CreateInDialogButton(props: CreateInDialogButtonProps) {
  const {
    label = null,
    icon = <Plus />,
    createButton = null,
    variant = 'default',
    title,
    children,
    ...rest
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {createButton ? (
        cloneElement(createButton, { onClick: handleClickOpen })
      ) : (
        <CreateInDialogDefaultButton
          variant={variant}
          label={label}
          icon={icon}
          onClick={handleClickOpen}
        />
      )}
      <CreateDialog
        title={title}
        children={children}
        open={open}
        setOpen={setOpen}
        {...rest}
      />
    </>
  );
}
