import React, { useMemo } from 'react';
import { useGetIdentity, usePermissions } from 'ra-core';
import { menuConfig } from '../constants/menu-config';
import { get, every } from 'lodash';
import { SidebarSection } from './SidebarSection';
import { UserNav } from '../../components/UserNav';
import { cn } from '@/modules/ui/utils/cn';
import { SidebarLogo } from './SidebarLogo';
import { useSidebarState } from '../hooks/useSidebarState';
import { ShowUpdate } from '@/modules/version/Version';
import { Button } from '@/modules/ui/components/button';
import { MessageSquare } from 'lucide-react';

export function Sidebar() {
  const { permissions } = usePermissions();
  const [sidebarOpen] = useSidebarState();

  const filteredMenuConfig = useMemo(() => {
    return menuConfig
      .map((section) => {
        const items = section.resources.reduce((res, item) => {
          if (
            (item.roles && !item.roles.includes(get(permissions, 'role'))) ||
            (item.internal_user_only && !get(permissions, 'is_internal'))
          ) {
            return res;
          }

          if (item.dev_only && window.config.env === 'production') {
            return res;
          }

          if (
            item.permissions &&
            !every(
              item.permissions,
              (permission) => !!get(permissions, permission),
            )
          ) {
            return res;
          }

          res.push(item);
          return res;
        }, []);

        return items.length ? { name: section.name, items } : null;
      })
      .filter((section) => section !== null);
  }, [permissions]);

  return (
    <div
      data-open={sidebarOpen}
      className={cn(
        'h-screen flex flex-col justify-between bg-white shadow-sm pb-4 transition-width ease-in-out duration-400 overflow-y-auto',
        sidebarOpen ? 'min-w-52' : 'min-w-16',
      )}
    >
      <div>
        <div
          className={cn(
            'flex items-center justify-center mt-1 mb-2',
            sidebarOpen ? 'px-2' : '',
          )}
        >
          <SidebarLogo />
        </div>

        <div
          className={cn(
            'flex items-center justify-center mt-1 mb-2',
            sidebarOpen ? 'px-2' : '',
          )}
        >
          <ShowUpdate />
        </div>

        {filteredMenuConfig.map((section, index) => (
          <SidebarSection
            key={index}
            links={section.items.map((resource) => ({
              title: resource?.name,
              icon: resource.icon,
              to: resource.link,
            }))}
            title={section.name}
          />
        ))}
      </div>
      <div className="flex flex-col justify-center">
        <FeedbackButton />
        <div
          className={cn(
            'flex h-[52px] items-center justify-center',
            sidebarOpen ? 'px-2' : 'h-[52px]',
          )}
        >
          <UserNav />
        </div>
      </div>
    </div>
  );
}

// Inside the Sidebar component, before the return statement
const FeedbackButton = () => {
  const [sidebarOpen] = useSidebarState();
  const { identity } = useGetIdentity();

  if (!identity?.is_internal) return null;

  return (
    <Button
      variant="ghost"
      size={sidebarOpen ? 'default' : 'icon'}
      data-featurebase-feedback
      className={cn(
        'w-full  flex items-center justify-center ',
        sidebarOpen ? 'px-2' : 'justify-center',
      )}
    >
      <MessageSquare className={cn('h-4 w-4', sidebarOpen ? 'mr-2' : '')} />
      {sidebarOpen && 'Feedback'}
    </Button>
  );
};

// In the return statement, replace the existing feedback button with:
