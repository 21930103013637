export const measurementDeviceTypes = [
  {
    id: 'blood_pressure',
    name: 'Blood Pressure',
  },
  {
    id: 'pulse_oximeter',
    name: 'Pulse Oximeter',
  },
  {
    id: 'weight_scale',
    name: 'Weight Scale',
  },
  {
    id: 'peak_flow_meter',
    name: 'Peak Flow Meter',
  },
  {
    id: 'glucometer',
    name: 'Glucometer',
  },
];
