import React from 'react';
import { useRecordContext, useDataProvider } from 'react-admin';
import { useInfiniteQuery } from 'react-query';
import { SimpleTable } from '@/modules/ra-ui/components/simple-table';
import { Badge } from '@/modules/ui/components/badge';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { titleize } from 'inflection';
import { useNavigate } from 'react-router-dom';
import { deviceTypes } from '@/modules/devices/constants/deviceTypes';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';

type DevicePatientActivityColumns = {
  id: number;
  activity_type: string;
  created_on: string;
  actor_name: string;
  value: any;
  device_id: number;
  device_type: string;
};

const columnHelper = createColumnHelper<DevicePatientActivityColumns>();
const columns = [
  columnHelper.accessor('created_on', {
    header: () => 'Timestamp',
    cell: (info) => {
      const date = DateTime.fromISO(info.getValue(), { zone: 'utc' }).setZone(
        'local',
      );
      const dateString = date.toLocaleString(DateTime.DATETIME_MED);
      const tzAbbreviation = date.toFormat('ZZZZ');
      return (
        <span>
          {dateString}
          <Badge variant="outline" className="ml-1 px-1.5">
            {tzAbbreviation}
          </Badge>
        </span>
      );
    },
    enableSorting: true,
  }),
  columnHelper.accessor('activity_type', {
    header: () => 'Activity Type',
    cell: (info) => (
      <Badge variant="outline">{titleize(info.getValue())}</Badge>
    ),
    enableSorting: true,
  }),
  columnHelper.accessor('actor_name', {
    header: () => 'Performed By',
    cell: (info) => info.getValue(),
    enableSorting: true,
  }),
  columnHelper.accessor('device_type', {
    header: () => 'Device Type',
    cell: (info) => {
      const deviceType = deviceTypes.find(
        (device) => device.id === info.getValue(),
      );
      return <Badge variant="outline">{deviceType?.name}</Badge>;
    },
    enableSorting: true,
  }),
  columnHelper.accessor('value', {
    header: () => 'Details',
    cell: (info) => {
      const value = info.getValue();
      if (value?.message) {
        return value.message;
      }
      if (value?.issue_type) {
        return `${titleize(value.issue_type)}: ${value.description}`;
      }
      return JSON.stringify(value);
    },
    enableSorting: false,
  }),
];

export const DevicePatientActivities = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();

  const pagination = {
    page: 1,
    perPage: 20,
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['device-activities', { patient_id: record?.id }],
      ({ pageParam = pagination.page }) =>
        dataProvider
          .getList(`device-activities`, {
            pagination: {
              page: pageParam,
              perPage: pagination.perPage,
            },
            sort: { field: 'created_on', order: 'DESC' },
            filter: {
              'activity_type[notin]': ['measurement', 'device_ping'],
              'patient_id[eq]': record?.id,
            },
          })
          .then(({ data, total, pageInfo }) => ({
            data,
            total,
            pageInfo,
            pageParam,
          })),
      {
        getNextPageParam: (lastPage) =>
          lastPage.pageInfo?.hasNextPage ? lastPage.pageParam + 1 : undefined,
      },
    );

  if (isLoading) {
    return (
      <div className="w-full h-32 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const flatData = data?.pages.flatMap((page) => page.data) || [];

  if (flatData.length === 0) {
    return (
      <EmptyDatagrid
        resourceName="Device Activities"
        textFormatString="None found for patient"
        icon={false}
      />
    );
  }

  return (
    <div>
      <SimpleTable
        data={flatData}
        columns={columns}
        sorting={[{ id: 'created_on', desc: true }]}
        rowClick={(row) => navigate(`/devices/${row.original.device_id}/show`)}
      />
      {hasNextPage && (
        <div className="mt-4 text-center">
          <button
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {isFetchingNextPage ? 'Loading more...' : 'Load More'}
          </button>
        </div>
      )}
    </div>
  );
};
