import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { get } from 'lodash';
import React, { createElement } from 'react';
import { useRecordContext } from 'react-admin';
import { phoneNumberTypeMapping } from '../constants/phoneNumberTypeMapping';
import { InfoBlock } from './InfoBlock';
import { formatDisplayPhoneNumber } from '@/utils/formatPhoneNumber';

const PatientPhoneNumberAdditional = ({
  additionalNumbers,
}: {
  additionalNumbers: { type: string; number: string; icon: any }[];
}) => {
  return (
    <div className="py-1">
      <p className="text-sm font-medium">Additional Phone Numbers:</p>
      {additionalNumbers.map((phone, index) => (
        <div key={index} className="text-sm flex flex-row items-center gap-2">
          {createElement(phone.icon, { size: 16 })} <span>{phone.type}:</span>
          {phone.number}
        </div>
      ))}
    </div>
  );
};

export const PatientPhoneNumber = () => {
  const record = useRecordContext();
  const primaryPhoneNumberType = get(record, 'user.primary_phone_number_type');
  let value = get(record, `user.${primaryPhoneNumberType}_phone_number`);
  let primaryPhoneNumberTypeMapping =
    phoneNumberTypeMapping[primaryPhoneNumberType];

  const additionalPhoneTypes = Object.keys(phoneNumberTypeMapping).filter(
    (name) => name !== primaryPhoneNumberType,
  );

  const additionalNumbers = additionalPhoneTypes
    .map((type) => {
      const number = get(record, `user.${type}_phone_number`);
      return {
        type: phoneNumberTypeMapping[type].title,
        number,
        icon: phoneNumberTypeMapping[type].icon,
      };
    })
    .filter((phone) => phone.number);

  if (!value) {
    value = 'Not set';
  }

  return (
    <>
      {additionalNumbers.length > 0 ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <InfoBlock
                  icon={createElement(primaryPhoneNumberTypeMapping.icon, {
                    size: 18,
                  })}
                  title={primaryPhoneNumberTypeMapping.title}
                  text={formatDisplayPhoneNumber(value)}
                />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <PatientPhoneNumberAdditional
                additionalNumbers={additionalNumbers}
              />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <InfoBlock
          icon={createElement(primaryPhoneNumberTypeMapping.icon, { size: 18 })}
          title={primaryPhoneNumberTypeMapping.title}
          text={formatDisplayPhoneNumber(value)}
        />
      )}
    </>
  );
};
