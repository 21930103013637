import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { Separator } from '@/modules/ui/components/separator';
import { cn } from '@/modules/ui/utils/cn';
import inflection from 'inflection';
import { get } from 'lodash';
import React from 'react';
import {
  DeleteButton,
  useDataProvider,
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { TenantUserRoleAdd } from './TenantUserRoleAdd';
import { useQuery } from 'react-query';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';

const RolesTab: React.FC = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  if (record.is_internal) {
    return <InternalRolesTab />;
  } else {
    return null;
  }
};

const InternalRolesTab = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <InternalRoleSection />
      <Separator />
      {!!!['superadmin', 'internal_admin'].includes(get(record, 'role')) ? (
        <Card>
          <CardHeader>
            <div className="flex justify-between items-center">
              <div>
                <CardTitle>Organization Access</CardTitle>
                <CardDescription>
                  This user has access to the following organizations
                </CardDescription>
              </div>

              <TenantUserRoleAdd />
            </div>
          </CardHeader>
          <CardContent>
            <InternalTenantUserRolesTable />
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};

const InternalRolesTable = () => {
  const record = useRecordContext();

  const notify = useNotify();
  const refresh = useRefresh();

  const { data: roles, isLoading } = useQuery(`roles.internal.true`, {
    queryFn: () =>
      dataProvider
        .getCustom('roles', {
          meta: { is_internal: true },
        })
        .then(({ data }) => {
          return data;
        }),
  });

  const dataProvider = useDataProvider();

  if (!record) {
    return null;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isRoleInData = (roleIn) => {
    return roleIn === record.role;
  };

  const handleRoleClick = (roleIn) => {
    if (roleIn === 'superadmin') {
      return notify("You can't set a user as a superadmin.", {
        type: 'warning',
      });
    }

    dataProvider
      .update(`users`, {
        id: record.id,
        data: {
          role: roleIn,
          is_internal: true,
        },
      } as any)
      .then(({ data }) => {
        notify('Role updated to ' + inflection.titleize(roleIn));
        refresh();
      })
      .catch((error) => {
        notify(`${get(error, 'message')}`, {
          type: 'warning',
        });
      });
  };

  return (
    <div className="grid gap-4">
      {roles.map((item) => (
        <div
          className={cn(
            '-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-accent hover:text-accent-foreground  cursor-pointer',
            {
              'bg-accent text-accent-foreground': isRoleInData(item.role),
            },
          )}
          onClick={
            isRoleInData(item.id) ? null : () => handleRoleClick(item.role)
          }
          key={item.id}
        >
          {/* <BellIcon className="mt-px h-5 w-5" /> */}
          <div className="space-y-1">
            <p className="text-medium font-medium leading-none">
              {inflection.titleize(item.role)}
            </p>
            <p className="text-sm text-muted-foreground">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const InternalRoleSection = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Internal Role</CardTitle>
        <CardDescription>Roles specific to internal users</CardDescription>
      </CardHeader>
      <CardContent>
        <InternalRolesTable />
      </CardContent>
    </Card>
  );
};

const InternalTenantUserRolesTable = () => {
  const record = useRecordContext();
  const sort = { field: null, order: null };

  const { data, isLoading } = useGetList(
    `users/${record.id}/tenant-user-roles`,
    {
      pagination: { page: 1, perPage: 20 },
      filter: {},
      sort,
    },
  );

  if (!record) {
    return null;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid gap-4">
      {!isLoading &&
        data.map((item) => {
          //   const role = tenantUserRoles.find((role) => role.id === item.role_id);
          return (
            <div
              className={
                '-mx-2 flex items-center space-x-4 rounded-md p-2 transition-all border-gray-200 border '
              }
              key={item.id}
            >
              {/* <BellIcon className="mt-px h-5 w-5" /> */}
              <div className="space-y-1 w-full">
                <p className="text-medium font-medium leading-none">
                  {item.tenant_name}
                </p>
              </div>

              <div className="justify-self-end	">
                <DeleteButton
                  record={item}
                  resource={`users/${record.id}/tenant-user-roles`}
                  redirect={false}
                  label=""
                  sx={{
                    minWidth: 'unset',
                    '& .MuiButton-startIcon': {
                      mr: 0,
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export { RolesTab };
