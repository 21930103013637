import { DataProvider, fetchUtils } from 'ra-core';
import inMemoryJWT from './inMemoryJWT';
import queryString from 'query-string';

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const dataProvider = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  countHeader = 'content-range',
): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      ...params.meta,
      sort: field ? field : undefined,
      order: order ? order : undefined,

      skip: JSON.stringify(perPage * (page - 1)),
      limit: JSON.stringify(perPage),
      filter: JSON.stringify(formatFilters(params.filter)),
    };

    const url = `${apiUrl}/${formatResource(resource)}?${queryString.stringify(
      query,
    )}`;
    const options =
      countHeader === 'content-range'
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${formatResource(resource)}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {};

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        console.warn(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`,
        );
      }
      return {
        data: json,
        total: headers.get('content-range')
          ? countHeader === 'content-range'
            ? parseInt(headers.get('content-range').split('/').pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase()))
          : null,
      };
    });
  },

  getOne: (resource, params) => {
    const meta = params.meta;
    return httpClient(
      `${apiUrl}/${formatResource(resource)}/${
        params.id
      }?${queryString.stringify(meta)}`,
    ).then(({ json }) => ({
      data: json,
    }));
  },

  getCustom: (resource, params) => {
    const meta = params.meta;
    return httpClient(
      addQueryParams(`${apiUrl}/${formatResource(resource)}`, meta),
    ).then(({ json }) => ({
      data: json,
    }));
  },

  getExternal: (resource, params) => {
    return httpClient(addQueryParams(`${resource}`, params)).then(
      ({ json }) => ({
        data: json,
      }),
    );
  },

  getFile: async (resource, params) => {
    const meta = params.meta;
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = params.download;

    const headers = new Headers({ 'Content-Type': params.downloadType });
    const token = await inMemoryJWT.getToken('getFile')

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    } else {
      inMemoryJWT.getToken('getFile').then((token) => {
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      });
    }

    return fetch(
      addQueryParams(`${apiUrl}/${formatResource(resource)}`, meta),
      { headers: headers },
    )
      .then((response) => {
        const filename = response.headers
          .get('content-disposition')
          .split('=')[1];
        link.download = filename;
        return response.blob();
      })
      .then((blob) => {
        link.href = URL.createObjectURL(blob);
        link.click();
      });
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ 'id[in]': params.ids }),
    };
    const url = `${apiUrl}/${formatResource(resource)}?${queryString.stringify(
      query,
    )}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${formatResource(resource)}?${queryString.stringify(
      query,
    )}`;
    const options =
      countHeader === 'Content-Range'
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${formatResource(resource)}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {};

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`,
        );
      }
      return {
        data: json,
        total:
          countHeader === 'Content-Range'
            ? parseInt(headers.get('content-range').split('/').pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase())),
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${formatResource(resource)}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updatePatch: (resource, params) =>
    httpClient(`${apiUrl}/${formatResource(resource)}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${formatResource(resource)}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${formatResource(resource)}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${formatResource(resource)}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${formatResource(resource)}/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
        }),
      ),
    ).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    })),

  getPatientResource: (resource, params) => {
    let url = `${apiUrl}/patients/${params.patient_id}/${formatResource(
      resource,
    )}`;
    if (params.id) {
      url = url + '/' + params.id;
    }
    if (params.params) {
      url =
        url + '?' + queryString.stringify(params.params, { skipNull: true });
    }

    const rangeStart = params.skip * params.limit;
    const rangeEnd = (params.skip + 1) * params.limit;

    const options =
      countHeader === 'content-range' && params.limit && params.skip
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${formatResource(resource)}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {};

    return httpClient(url, options).then(({ json, headers }) => ({
      data: json,
      total: headers.get('content-range')
        ? countHeader === 'content-range'
          ? parseInt(headers.get('content-range').split('/').pop(), 10)
          : parseInt(headers.get(countHeader.toLowerCase()))
        : null,
    }));
  },
  // file is File object
  uploadFile: (resource, params) => {
    const formData = new FormData();

    formData.append('file', params.file);

    params.meta &&
      Object.keys(params.meta).forEach((key) => {
        formData.append(key, params.meta[key]);
      });

    return httpClient(`${apiUrl}/${formatResource(resource)}`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
});

const formatResource = (resource) => {
  if (resource === 'patient-queue') {
    return 'patients';
  } else {
    return resource;
  }
};

const addQueryParams = (url, queryParams) => {
  if (queryParams) {
    return url + '?' + queryString.stringify(queryParams, { skipNull: true });
  } else {
    return url;
  }
};

export const formatFilters = (filters) => {
  // if filter key name has a : then split it, and concatenate the last part to the value
  // and the first part to the key name
  if (typeof filters !== 'object') {
    return filters;
  }

  Object.keys(filters).forEach((key) => {
    if (key.includes(':')) {
      const splitKey = key.split(':');
      const value = filters[key];
      delete filters[key];
      filters[splitKey[0]] = splitKey[1] + ':' + value;
    }
  });
  return filters;
};

export default dataProvider;
