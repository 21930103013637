import { AddressInput } from '@/modules/ra-ui/components/address-input';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import React from 'react';
import {
  TextInput,
  useRecordContext,
  DeleteButton,
  BooleanInput,
} from 'react-admin';

const LocationEditContent = () => {
  return (
    <div className="flex flex-col gap-1">
      <TextInput source="name" helperText={false} />
      <TextInput
        source="description"
        label="Description"
        helperText={false}
        multiline
        minRows={2}
      />
      <AddressInput source="address" />
      <BooleanInput source="is_active" />
    </div>
  );
};

export const EditLocationButton = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  const transform = (data) => {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      address: data.address,
    };
  };

  return (
    <EditInDialogButton
      title="Edit location"
      resource="locations"
      variant="outlined"
      deleteButton={
        <DeleteButton
          resource="locations"
          redirect={false}
          confirmTitle="Delete location?"
        />
      }
      refreshOnSuccess={true}
      label="Edit"
      editButtonVariant="outline"
      transform={transform}
    >
      <LocationEditContent />
    </EditInDialogButton>
  );
};
