import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { Card } from '@/modules/ui/components/card';
import { DateTime } from 'luxon';
import React from 'react';
import {
  Datagrid,
  Pagination,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { AISummariesEmpty } from './AISummariesEmpty';
import { AddAISummaryButton } from './AddAISummaryButton';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';

const AISummaryDateRangeField = ({
  label = 'Date Range',
}: { label?: string }) => {
  const record = useRecordContext();
  const startDateString =
    record.additional_info?.patient_summary_metadata?.start_date;
  const endDateString =
    record.additional_info?.patient_summary_metadata?.end_date;

  const startDate = startDateString ? DateTime.fromISO(startDateString) : null;
  const endDate = endDateString ? DateTime.fromISO(endDateString) : null;

  if (!startDate || !endDate || !startDate.isValid || !endDate.isValid) {
    return null;
  }

  return (
    <>
      {startDate.toLocaleString(DateTime.DATE_MED)} to{' '}
      {endDate.toLocaleString(DateTime.DATE_MED)}
    </>
  );
};
function ExpandedRow() {
  const record = useRecordContext();
  return (
    <div className="py-2">
      <p className="whitespace-pre-wrap">{record.content}</p>
      <div className="bg-amber-50 rounded-lg p-1 w-fit mt-1">
        <span className="text-xs font-semibold text-amber-800">
          AI Generated
        </span>
      </div>
      <footer className="border-t border-gray-150 mt-4 pt-2 text-sm text-gray-500 flex flex-col gap-1 justify-between items-start">
        <div className="flex flex-row gap-1">
          <p>Date Range:</p>
          <AISummaryDateRangeField />
        </div>
        <br />
        Summary generated on:{' '}
        {DateTime.fromISO(record.created_at).toLocaleString(DateTime.DATE_MED)}
        <br />
      </footer>
    </div>
  );
}

export function AISummariesSection() {
  const patientRecord = useRecordContext();
  const [sort, setSort] = React.useState({
    field: 'created_at',
    order: 'DESC',
  } as any);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList(
    'foundation-model-responses',
    {
      pagination: { page, perPage },
      sort,
      filter: { 'patient_id[eq]': patientRecord?.user.id },
    },
    {
      enabled: !!patientRecord,
    },
  );

  if (!patientRecord) return null;

  if (!data || data.length === 0) {
    return (
      <div className="relative pt-10 mt-5">
        <div className="absolute right-4 top-4">
          <AddAISummaryButton />
        </div>
        <Card className="p-4 mt-5">
          <AISummariesEmpty />
        </Card>
      </div>
    );
  }

  return (
    <Card className="mb-4 sm:mb-6 md:mb-8">
      <div className="space-y-2 p-2 sm:p-3 md:p-4">
        <div className="relative mt-5">
          <div className="absolute right-4 top-4">
            <AddAISummaryButton />
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <h3 className="text-xl font-semibold">AI Summaries</h3>
              <p className="text-sm text-gray-500">
                Patient summaries generated using large language models.
              </p>
            </div>
            <Datagrid
              data={data}
              total={total}
              isLoading={isLoading}
              bulkActionButtons={false}
              expand={<ExpandedRow />}
              expandSingle
              empty={
                <EmptyDatagrid
                  resourceName="AI Summaries"
                  textFormatString="No AI summaries found for patient"
                />
              }
              sort={sort}
              setSort={setSort}
              rowClick="expand"
            >
              <LuxonDateField
                source="created_at"
                label="Generated On"
                showTime
              />
              <AISummaryDateRangeField label="Data Date Range" />
            </Datagrid>
            {data && data.length > 0 && (
              <Pagination
                page={page}
                perPage={perPage}
                total={total}
                setPage={setPage}
                setPerPage={setPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
