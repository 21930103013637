import { CardDescription, CardTitle } from '@/modules/ui/components/card';
import { Card, CardContent, CardHeader } from '@/modules/ui/components/card';
import { ColoredChipField } from '@/modules/ra-ui/components/ColoredChipField';
import { monitoringConditionColor } from '@/utils/color';
import { conditionNames } from '@/modules/patients/constants/conditionNames';
import { get } from 'lodash';
import React from 'react';
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Identifier,
  ListContextProvider,
  Pagination,
  ReferenceField,
  ResourceContextProvider,
  TextField,
  useGetList,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { DeleteCareMemberButton } from '@/pages/Users/UserShowEdit';

export const PatientsUnderCareTab = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const sort = { field: null, order: null };
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [selectedIds, setSelectedIds] = React.useState<Identifier[]>([]);

  const { data, total, isLoading } = useGetList(
    `users/${record.id}/care-teams`,
    {
      pagination: { page, perPage },
      filter: {},
      sort,
    },
  );

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Patients</CardTitle>
        <CardDescription>
          View patients under care for this particular user. You can add
          clinicians to a patient's care team from the patient's profile.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-2">
        <>
          <ListContextProvider
            // @ts-ignore
            value={{ selectedIds, onUnselectItems: () => setSelectedIds([]) }}
          >
            <Datagrid
              size="small"
              data={data}
              total={total}
              isLoading={isLoading}
              sort={sort}
              bulkActionButtons={
                <ResourceContextProvider value={`care-teams`}>
                  <BulkDeleteWithConfirmButton
                    mutationMode="pessimistic"
                    confirmTitle="Remove patients from care of this user?"
                    confirmContent="This will remove the patients from the care of this user."
                    selectedIds={selectedIds}
                  />
                </ResourceContextProvider>
              }
              setSort={() => {}}
              selectedIds={selectedIds}
              onSelect={(ids) => setSelectedIds(ids as Identifier[])}
              onToggleItem={(id) => {
                if (selectedIds.includes(id)) {
                  setSelectedIds(
                    selectedIds.filter((selectedId) => selectedId !== id),
                  );
                } else {
                  setSelectedIds([...selectedIds, id]);
                }
              }}
              empty={
                <p className="text-sm text-muted-foreground">
                  User has no patients under care.
                </p>
              }
            >
              <TextField source="patient_name" label="Name" />
              <ColoredChipField
                source="primary_condition"
                colorScheme={monitoringConditionColor}
                mapping={conditionNames}
              />
              {get(permissions, 'is_internal') ? (
                <ReferenceField
                  label="Provider"
                  source="patient_tenant_id"
                  reference="providers"
                  link="show"
                  emptyText="None"
                >
                  <TextField source="name" />
                </ReferenceField>
              ) : null}
              <DeleteCareMemberButton />
            </Datagrid>
          </ListContextProvider>
          <Pagination
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </>
      </CardContent>
    </Card>
  );
};
