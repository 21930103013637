import Typography from '@mui/material/Typography';
import React from 'react';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';

export const SectionTitle = (props) => {
  const { text, icon, helpText = '' } = props;

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="flex-start"
      wrap="nowrap"
    >
      {icon ? <Grid item>{icon}</Grid> : null}
      <Grid item sx={{ marginRight: 1 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        {helpText ? (
          <Tooltip title={helpText}>
            <InfoIcon
              fontSize="small"
              sx={{ color: grey[700], fontSize: 12 }}
            />
          </Tooltip>
        ) : null}
      </Grid>
    </Grid>
  );
};
