import React from 'react';
import { useRecordContext } from 'react-admin';

const PatientStatusContext = React.createContext(undefined);

export function usePatientStatus() {
  const patientStatus = React.useContext(PatientStatusContext);

  return patientStatus;
}

export function PatientStatusProvider({ children }) {
  const record = useRecordContext();
  return (
    <PatientStatusContext.Provider value={record?.patient_status}>
      {children}
    </PatientStatusContext.Provider>
  );
}
