import React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';

export const NameField = (props) => {
  const record = useRecordContext(props);

  if (!record) {
    return null;
  }

  const age = get(record, 'patient.profile.demographics.age');
  const gender = get(record, 'gender');

  return (
    <>
      <span className="text-base font-medium">
        {get(record, 'user.full_name')}
      </span>
      {age && gender ? (
        <span className="text-sm mx-2">{`(${age}, ${gender})`} </span>
      ) : null}
    </>
  );
};
