import { DateRangePicker } from '@/modules/ui/components/date-picker-range';
import { get, isEqual } from 'lodash';
import React from 'react';
import { useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { format } from 'date-fns'; // Import format from date-fns

const sanitizeName = (name: string): string => {
  return name.replace(/\[/g, '_').replace(/\]/g, '_');
};

export default function DataTableDateRangeFilter({
  column,
  title,
  dateOnly = false,
  presets = undefined,
  align = 'start',
}: {
  column: string;
  title: string;
  dateOnly?: boolean;
  presets?: any;
  align?: 'start' | 'center' | 'end';
}) {
  const { filterValues, setFilters } = useListContext();

  const getDefaultValues = () => {
    const from = get(filterValues, `${column}[gte]`);
    const to = get(filterValues, `${column}[lte]`);
    if (from && to) {
      return { from, to };
    }
    return null;
  };

  const form = useForm<Object>({
    defaultValues: {
      [sanitizeName(column)]: getDefaultValues(),
    },
  });

  React.useEffect(() => {
    const newValue = getDefaultValues();
    const previousValue = get(form.getValues(), sanitizeName(column));
    if (!isEqual(newValue, previousValue)) {
      form.reset({
        [sanitizeName(column)]: newValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues), filterValues, form]);

  React.useEffect(() => {
    const subscription = form.watch(async (values, { name }) => {
      const isFormValid = await form.trigger();
      if (name && isFormValid) {
        const range = get(values, sanitizeName(column));

        if (range) {
          let fromDate = new Date(range.from);
          let toDate = new Date(range.to);

          if (!toDate || isNaN(toDate.getTime())) {
            toDate = new Date(fromDate);
          }

          if (dateOnly) {
            // Set time to start and end of the day
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(23, 59, 59, 999);
          }

          let formattedFromDate: string;
          let formattedToDate: string;

          if (dateOnly) {
            // Format dates as 'yyyy-MM-dd' without time
            formattedFromDate = format(fromDate, 'yyyy-MM-dd');
            formattedToDate = format(toDate, 'yyyy-MM-dd');
          } else {
            // Include ISO string with time
            formattedFromDate = fromDate.toISOString();
            formattedToDate = toDate.toISOString();
          }

          setFilters(
            {
              ...filterValues,
              [`${column}[gte]`]: formattedFromDate,
              [`${column}[lte]`]: formattedToDate,
            },
            null,
          );
        } else {
          setFilters(
            {
              ...filterValues,
            },
            null,
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [form, setFilters, filterValues, column, dateOnly]);

  return (
    <FormProvider {...form}>
      <DateRangePicker
        column={sanitizeName(column)}
        align={align}
        locale="en-GB"
        placeHolderText={title}
        presets={presets}
      />
    </FormProvider>
  );
}
