import React from 'react';
import { useController } from 'react-hook-form';
import { Input } from '../../ui/components/input';
import { Label } from '../../ui/components/label';
import { cn } from '../../ui/utils/cn';

import { FormLabel } from '@/modules/ui/components/form';
import { FieldTitle } from 'ra-core';

export interface ElapsedTimeProps {
  className?: string;
  title?: string;
}

export const ElapsedTime: React.FC<ElapsedTimeProps> = ({
  className,
  title = 'Activity duration',
}) => {
  const { field, fieldState } = useController({
    name: 'duration',
    defaultValue: 0,
    rules: { min: 30, max: 3600 }, // Max 60 minutes (3600 seconds)
  });

  const getMinutes = () => Math.floor((field.value / 60) % 60);
  const getSeconds = () => Math.floor(field.value % 60);

  const onChangeMinute = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (isNaN(value)) return;
    field.onChange(Math.min(3600, Math.max(0, value * 60 + getSeconds())));
  };

  const onChangeSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (isNaN(value)) return;
    field.onChange(Math.min(3600, Math.max(0, getMinutes() * 60 + value)));
  };

  return (
    <div className={cn('flex flex-col gap-2 py-2', className)}>
      {title && (
        <FormLabel>
          <FieldTitle label={title} source="duration" isRequired={true} />
        </FormLabel>
      )}
      <div className="flex items-end gap-2">
        <div className="grid gap-1 text-center">
          <Label htmlFor="minutes" className="text-xs">
            Minutes
          </Label>
          <Input
            id="minutes"
            value={(field.value === 0 ? '' : '0' + getMinutes()).slice(-2)}
            onChange={onChangeMinute}
            className={cn(
              'w-[48px] text-center font-mono text-base tabular-nums caret-transparent focus:bg-accent focus:text-accent-foreground [&::-webkit-inner-spin-button]:appearance-none',
              fieldState.invalid && 'border-red-500 focus:border-red-500',
            )}
            type="tel"
            inputMode="numeric"
          />
        </div>
        <div className="grid gap-1 text-center">
          <Label htmlFor="seconds" className="text-xs">
            Seconds
          </Label>
          <Input
            id="seconds"
            value={(field.value === 0 ? '' : '0' + getSeconds()).slice(-2)}
            onChange={onChangeSecond}
            className={cn(
              'w-[48px] text-center font-mono text-base tabular-nums caret-transparent focus:bg-accent focus:text-accent-foreground [&::-webkit-inner-spin-button]:appearance-none',
              fieldState.invalid && 'border-red-500 focus:border-red-500',
            )}
            type="tel"
            inputMode="numeric"
          />
        </div>
      </div>
      {fieldState.invalid && (
        <p className="text-xs text-red-500 mt-1">
          Duration must be between 30 seconds and 60 minutes.
        </p>
      )}
    </div>
  );
};
