import { createColumnHelper } from '@tanstack/react-table';
import { SimpleTable } from '@/modules/ra-ui/components/simple-table';
import { useRecordContext } from 'react-admin';

import React from 'react';
import { humanize } from 'inflection';
import { Badge } from '@/modules/ui/components/badge';
import { Card } from '@/modules/ui/components/card';

type DeviceCountTable = {
  device_type: string;
  count: number;
  device_manufacturer: string;
  device_model: string;
};

const columnHelper = createColumnHelper<DeviceCountTable>();

const columns = [
  columnHelper.accessor('device_type', {
    header: () => 'Device Type',
    cell: (info) =>
      info.getValue() && (
        <Badge variant="secondary">{humanize(info.getValue())}</Badge>
      ),
  }),
  columnHelper.accessor('device_manufacturer', {
    header: () => 'Device Manufacturer',
    cell: (info) => info.getValue() && humanize(info.getValue()),
  }),
  columnHelper.accessor('device_model', {
    header: () => 'Device Model',
    cell: (info) => info.getValue() && humanize(info.getValue()),
  }),
  columnHelper.accessor('count', {
    header: () => 'Count',
    cell: (info) => info.getValue(),
  }),
];

export const LocationDetailDeviceCountTable = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <Card>
      <SimpleTable data={record?.device_count_table} columns={columns} />
    </Card>
  );
};
