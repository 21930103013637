import inflection from 'inflection';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { triageColors } from 'src/utils/color';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  value,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {value}
    </text>
  );
};
export default function AssessmentTypeChart({ data }) {
  if (isEmpty(data)) return null;

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          // cx={200}
          // cy={200}
          labelLine={false}
          label={renderCustomizedLabel}
          // outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={get(triageColors, [entry.name, 'secondary'])}
            />
          ))}
        </Pie>
        <Legend
          verticalAlign="bottom"
          height={36}
          formatter={(v) => inflection.humanize(v)}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
