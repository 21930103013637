import { Grid } from '@mui/material';
import { find, get } from 'lodash';
import React, { FC } from 'react';
import { useRecordContext } from 'react-admin';
import { VitalCardBase, VitalConfig } from './VitalCardBase';
import { MetricsDialog } from '../metrics-dialog/components/MetricsDialog';

const vitalsConfig: Array<VitalConfig> = [
  {
    measureReportName: 'oxygen_saturation_7_day_baseline',
    chartMeasureName: 'oxygen_saturation',
    nameKey: 'Oxygen Saturation',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'lungs',
  },
  {
    measureReportName: 'heart_rate_7_day_baseline',
    chartMeasureName: 'heart_rate',
    nameKey: 'Heart Rate',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'heartbeat',
  },
  {
    measureReportName: 'body_temperature_7_day_baseline',
    chartMeasureName: 'body_temperature',
    nameKey: 'Temperature',
    roundDigits: 1,
    alwaysShow: false,
    faIcon: 'thermometer-half',
  },
  {
    measureReportName: 'peak_expiratory_flow_rate_7_day_baseline',
    chartMeasureName: 'peak_expiratory_flow_rate',
    nameKey: 'Peak Flow',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'wind',
  },
  {
    measureReportName: 'forced_expiratory_volume_1_7_day_baseline',
    chartMeasureName: 'forced_expiratory_volume_1',
    nameKey: 'FEV1',
    roundDigits: 1,
    alwaysShow: false,
    faIcon: 'wind',
  },
  {
    measureReportName: 'blood_pressure_7_day_baseline',
    chartMeasureName: 'blood_pressure',
    nameKey: 'Blood Pressure',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'wind',
  },
  {
    measureReportName: 'body_mass_7_day_baseline',
    chartMeasureName: 'body_mass',
    nameKey: 'Weight',
    roundDigits: 1,
    alwaysShow: false,
    faIcon: 'wind',
  },
  {
    measureReportName: `blood_glucose_7_day_baseline`,
    chartMeasureName: 'blood_glucose',
    nameKey: 'Blood Glucose',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'droplet',
  },
];

export const PatientVitals: FC = () => {
  const patientRecord = useRecordContext();
  const [open, setOpen] = React.useState(false);
  const [measureName, setMeasureName] = React.useState('');

  if (!patientRecord) return null;

  const observations = patientRecord.observations;
  const measureReports = patientRecord.measure_reports;
  const monitoringLimits = get(patientRecord, 'care_plan.monitoring_limits');

  const handleClickIndicator = (chartMeasureName: string) => {
    setMeasureName(chartMeasureName);
    setOpen(true);
  };

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <h3 className="text-xl font-semibold">Physiological Measurements</h3>
        <p className="text-sm text-gray-500">
          Measurements taken by patient devices.
        </p>
      </div>

      <Grid container direction="row" spacing={2}>
        <MetricsDialog
          open={open}
          setOpen={setOpen}
          measureName={measureName}
          setMeasureName={setMeasureName}
        />
        {vitalsConfig
          .map((element, index) => {
            const vitalData = find(
              observations,
              (o) => o.name === element.chartMeasureName,
            );
            const baseline = find(
              measureReports,
              (o) => o.name === element.measureReportName,
            );

            if (vitalData) {
              return (
                <Grid item key={index} xs={12} sm="auto">
                  <VitalCardBase
                    value={vitalData}
                    text={element.nameKey}
                    lastUpdated={vitalData.timestamp}
                    baseline={baseline}
                    unit={vitalData.unit && vitalData.unit.text}
                    chartName={element.chartMeasureName}
                    faIcon={element.faIcon}
                    limits={monitoringLimits}
                    roundDigits={element.roundDigits}
                    onClick={() =>
                      handleClickIndicator(element.chartMeasureName)
                    }
                  />
                </Grid>
              );
            } else if (element.alwaysShow) {
              return (
                <Grid item key={index} xs={12} sm="auto">
                  <VitalCardBase
                    text={element.nameKey}
                    chartName={element.chartMeasureName}
                    value="-"
                    // faIcon={faIcon}
                    handleIndicatorClick={() => {
                      return null;
                    }}
                  />
                </Grid>
              );
            }
            return null;
          })
          .filter((element) => {
            return element !== null;
          })}
      </Grid>
    </div>
  );
};
