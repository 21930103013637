export const ethnicity = [
  {
    id: 'hispanic',
    name: 'Hispanic or Latino',
  },
  {
    id: 'not_hispanic',
    name: 'Not Hispanic or Latino',
  },
  {
    id: 'no_disclosure',
    name: 'Choose not to disclose',
  },
];
