export const patientConditions = [
  {
    id: 'Pulmonary Hypertension',
    name: 'Pulmonary Hypertension',
  },
  {
    id: 'High Blood Pressure',
    name: 'High Blood Pressure',
  },
  { id: 'Diabetes', name: 'Diabetes' },
  {
    id: 'Coronary Artery Disease',
    name: 'Coronary Artery Disease',
  },
  {
    id: 'Congestive Heart Failure',
    name: 'Congestive Heart Failure',
  },
  {
    id: 'Chronic Kidney Disease',
    name: 'Chronic Kidney Disease',
  },
  { id: 'Asthma', name: 'Asthma' },
  { id: 'Anemia', name: 'Anemia' },
  {
    id: 'Acid Reflux',
    name: 'Acid Reflux',
  },
  { id: 'COPD', name: 'COPD' },
  {
    id: 'Atrial Fibrillation',
    name: 'Atrial Fibrillation',
  },
  {
    id: 'Sleep Disordered Breathing',
    name: 'Sleep Disordered Breathing',
  },
  { id: 'Cancer', name: 'Cancer' },
];
