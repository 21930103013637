import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const translations = {
  en: {
    ...englishMessages,
  },
};

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  'en', // default locale
  [{ locale: 'en', name: 'English' }],
);
