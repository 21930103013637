import React from 'react';
import { Badge } from '@/modules/ui/components/badge';
import { useRecordContext } from 'ra-core';

interface PatientCareProgramArrayProps {
  label?: string;
}
export const PatientCareProgramArray: React.FC<
  PatientCareProgramArrayProps
> = () => {
  const record = useRecordContext();
  const activePrograms = record?.service_instances.filter(
    (program: any) => program.is_active,
  );

  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-1">
      {activePrograms.map((program, index) => (
        <Badge key={index} variant="outline" className="m-0.5">
          {program.type.toUpperCase()}
        </Badge>
      ))}
    </div>
  );
};
