export const careTeamRoles = [
  {
    id: 'rpm_clinician',
    value: 'RPM Clinician',
  },
  {
    id: 'rtm_clinician',
    value: 'RTM Clinician',
  },

  {
    id: 'ccm_clinician',
    value: 'CCM Clinician',
  },
  {
    id: 'pcm_clinician',
    value: 'PCM Clinician',
  },
  {
    id: 'provider_success',
    value: 'Provider Success',
  },
  {
    id: 'billing_provider',
    value: 'Billing Provider',
    canBeSelected: false,
  },
  {
    id: 'other',
    value: 'Other',
  },
];
