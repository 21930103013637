import AppTitle from '@/modules/layout/components/app-title';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';
import React, { FC } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  useStore,
} from 'react-admin';
import BadgeField from '@/modules/ra-ui/components/badge-field';

const Toolbar = ({ selectedTab, setSelectedTab }) => (
  <div className="w-full">
    <TabbedWrapper
      options={[
        {
          title: 'Open',
          value: 'open',
        },
        {
          title: 'Closed',
          value: 'closed',
        },
        {
          title: 'All',
          value: '',
        },
      ]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    >
      <DataTableToolbar
        showViewOptions={false}
        search={
          <MultiSearch
            searchFields={[
              { value: 'user.full_name', label: 'Name' },
              { value: 'patient_id', label: 'ID' },
            ]}
          />
        }
      ></DataTableToolbar>
    </TabbedWrapper>
  </div>
);

export const SupportTicketList: FC = () => {
  const [selectedTab, setSelectedTab] = useStore(
    'support-tickets.listParams.status',
    'open',
  );

  return (
    <>
      <AppTitle title="Support Tickets" />
      <List
        exporter={false}
        filter={{
          ...(selectedTab === 'open' && { 'status[eq]': 'open' }),
          ...(selectedTab === 'closed' && { 'status[eq]': 'closed' }),
        }}
        actions={
          <Toolbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        }
        storeKey="support-tickets.listParams"
        empty={false}
      >
        <Datagrid rowClick="show">
          <BadgeField source="category" />
          <ReferenceField
            label="By"
            source="user_id"
            reference="patients"
            link="show"
          >
            <TextField source="user.full_name" />
          </ReferenceField>
          <DateField source="created_on" />
          <BadgeField source="status" />
        </Datagrid>
      </List>
    </>
  );
};
