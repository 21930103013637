import AppTitle from '@/modules/layout/components/app-title';
import React, { FC } from 'react';
import {
  Button,
  ChipField,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';

const CloseButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  // const redirect = useRedirect();
  const data = { ...record, status: 'closed', closed: true };
  delete data['reviewed_on'];
  const [approve, { isLoading }] = useUpdate(
    'support-tickets',
    { id: record.id, data: data },
    {
      onSuccess: (data) => {
        refresh();
        notify('Support ticket closed');
      },
      onError: () => {
        notify(`Support ticket closing error`, {
          type: 'warning',
        });
      },
    },
  );

  return (
    <Button
      label="Mark Closed"
      onClick={() => approve()}
      disabled={isLoading}
      color="primary"
      variant="contained"
    />
  );
};

export const SupportTicketShow: FC = (props) => (
  <>
    <AppTitle title="Support Ticket" />
    <Show {...props}>
      <SupportTicketShowLayout />
    </Show>
  </>
);

const SupportTicketShowLayout: FC = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <SimpleShowLayout>
      <DateField source="created_on" />
      <ChipField source="category" />
      <ReferenceField
        source="user_id"
        label="By"
        reference="patients"
        link="show"
      >
        <TextField source="user.full_name" />
      </ReferenceField>
      {record.device_id ? (
        <ReferenceField
          source="device_id"
          label="Related to device"
          reference="devices"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      ) : null}
      <TextField source="description" />
      {record.reviewed_on && <DateField source="reviewed_on" />}
      {record.reviewed_on && <DateField source="reviewed_on" />}
      <CloseButton record={record} />
    </SimpleShowLayout>
  );
};
