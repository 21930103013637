import EditDialog from '@/modules/ra-ui/components/edit-dialog';
import React from 'react';
import { BooleanInput } from 'react-admin';

const DeviceToggleSharedStatus = ({ open, setOpen }) => {
  return (
    <EditDialog
      title="Toggle shared device status"
      resource="devices"
      variant="default"
      notifyMessage="Device configuration updated."
      transform={(data) => {
        return {
          ...data,
          update_action: 'update_shared_device',
        };
      }}
      open={open}
      setOpen={setOpen}
      refreshOnSuccess={true}
    >
      <BooleanInput source="is_shared_device" helperText={false} />
    </EditDialog>
  );
};

export default DeviceToggleSharedStatus;
