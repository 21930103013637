import { Separator } from '@/modules/ui/components/separator';
import { formatDistance } from 'date-fns';
import { Dot } from 'lucide-react';
import { DateTime } from 'luxon';
import { useGetList, useRecordContext } from 'ra-core';
import React from 'react';

export const TaskActivities = ({
  showSeperator = false,
  items = 3,
}: { showSeperator?: boolean; items?: number }) => {
  const record = useRecordContext();
  const { data, isLoading } = useGetList(
    'task-activities',
    {
      pagination: { page: 1, perPage: items },
      filter: {
        'task_id[eq]': record?.id,
      },
      sort: { field: 'performed_at', order: 'DESC' },
    },
    {
      enabled: !!record?.id,
    },
  );

  if (isLoading || !data || data.length === 0) {
    return null;
  }

  return (
    <div>
      {showSeperator && <Separator />}

      {data.map((activity) => {
        let text = '';
        let actor = activity.is_system_generated
          ? 'Vironix'
          : activity.performed_by_name;
        let action = activity.activity_type;
        let description = activity.description || '';
        const distance = formatDistance(
          DateTime.fromISO(activity.performed_at, { zone: 'utc' })
            .toLocal()
            .toJSDate(),
          new Date(),
        );

        if (
          action === 'created' ||
          action === 'completed' ||
          action === 'uncompleted'
        ) {
          description = 'this task';
        }
        if (action === 'assigned') {
          action = 'assigned this to';
        }

        text = `${action} ${description}`;
        return (
          <p key={activity.id} className="text-sm flex flex-wrap">
            {' '}
            <span className="font-semibold mr-1"> {actor}</span> {text} <Dot />{' '}
            {distance} ago
          </p>
        );
      })}
    </div>
  );
};
