import { Box, Card, CardContent } from '@mui/material';
import React from 'react';
import { SectionTitle } from './SectionTitle';

export const CardSectionLayout = (props) => {
  const { title, icon, helpText, children } = props;
  return (
    <Box>
      <SectionTitle text={title} icon={icon} helpText={helpText} />
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};
