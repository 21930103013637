import { useDataProvider, useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';

export function useAISummaries() {
  const patientRecord = useRecordContext();
  const dataProvider = useDataProvider();

  const query = useQuery(
    ['llm-summaries', patientRecord?.id],
    () =>
      dataProvider
        .getCustom('foundation-model-responses/summary-enabled', {
          meta: {
            patient_id: patientRecord?.id,
          },
        })
        .then((data) => data.data),
    {
      enabled: !!patientRecord,
    },
  );

  return query;
}
