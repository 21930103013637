import React from 'react';
import { useRecordContext, useGetOne, DateTimeInput } from 'react-admin';
import { DateTime } from 'luxon';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';

interface DateTimeProviderTZInputProps {
  source: string;
  tenant_id?: string | number;
  label?: string;
  hideIfNoTenant?: boolean;
  defaultValue?: string | null;
}

const DateTimeProviderTZInput: React.FC<DateTimeProviderTZInputProps> = ({
  source,
  tenant_id,
  hideIfNoTenant = false,
  label,
  defaultValue = null,
}) => {
  const record = useRecordContext();
  const effectiveTenantId = tenant_id || record?.user?.tenant_id;

  const { data, isLoading } = useGetOne(
    'providers',
    {
      id: effectiveTenantId,
    },
    { enabled: !!effectiveTenantId },
  );

  if (!effectiveTenantId && hideIfNoTenant) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <LoadingSpinner className="h-8 w-8" />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  const tzAbbreviation = DateTime.now().setZone(data.timezone).toFormat('ZZZZ');

  return (
    <DateTimeInput
      className="w-full"
      source={source}
      label={label}
      helperText={`Using provider timezone: ${tzAbbreviation}`}
      format={(v) =>
        DateTime.fromISO(v, {
          zone: 'utc',
        })
          .startOf('minute')
          .setZone(data.timezone)
          .toISO({ includeOffset: false, suppressMilliseconds: true })
      }
      parse={(v) =>
        DateTime.fromISO(v, { zone: data.timezone })
          .startOf('minute')
          .toUTC()
          .toISO({ includeOffset: false, suppressMilliseconds: true })
      }
      defaultValue={defaultValue}
    />
  );
};

export default DateTimeProviderTZInput;
