import React from 'react';

import Symptom from 'healthicons-react/dist/outline/Symptom';

export const SymptomsEmpty = () => {
  return (
    <div className="flex flex-col gap-4 items-center">
      <p className="text-xl">None reported</p>
      <Symptom className="w-16 h-16" />
    </div>
  );
};
