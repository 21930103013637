import React from 'react';
import { Button } from '@/modules/ui/components/button';
import { Package } from 'lucide-react';
import {
  useRecordContext,
  useUpdate,
  useRefresh,
  useNotify,
} from 'react-admin';
import { DropdownMenuItem } from '@/modules/ui/components/dropdown-menu';

const DeviceMarkReceived = ({
  label = 'Mark as Received',
  asDropdownMenuItem = false,
  className = null,
}) => {
  const record = useRecordContext();
  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  if (!record) {
    return null;
  }

  const handleMarkReceived = () => {
    update(
      'devices',
      {
        id: record.id,
        data: { update_action: 'mark_received' },
      },
      {
        onSuccess: () => {
          refresh();
          notify('Device successfully marked as received', {
            type: 'success',
          });
        },
        onError: (error) => {
          console.error('Error marking device as received:', error);
          notify('Error marking device as received. Please try again.', {
            type: 'error',
          });
        },
      },
    );
  };

  if (asDropdownMenuItem) {
    return (
      <DropdownMenuItem onSelect={handleMarkReceived} disabled={isLoading}>
        <Package className="w-4 h-4 mr-2" />
        {label}
      </DropdownMenuItem>
    );
  }

  return (
    <Button
      onClick={handleMarkReceived}
      disabled={isLoading}
      variant="outline"
      size="sm"
      className={className}
    >
      <Package className="w-4 h-4 mr-2" />
      {label}
    </Button>
  );
};

export default DeviceMarkReceived;
