import { DateTime } from 'luxon';
import { formatPastDay } from './formatPastDay';

export function formatPastDateTime(timestamp: string) {
  const dateTime = DateTime.fromISO(timestamp, {
    zone: 'utc',
  }).setZone('local');

  let date = formatPastDay(timestamp);
  date = date + ' at ' + dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  return date;
}
