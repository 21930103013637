import { Button } from '@/modules/ui/components/button';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { get } from 'lodash';
import { Building2 } from 'lucide-react';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  Form,
  ReferenceInput,
  SaveButton,
  useListContext,
  usePermissions,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';

export const BulkAssignToProviderButton = (props) => {
  const { resource = null } = props;
  const { selectedIds, resource: contextResource } = useListContext();
  const { permissions } = usePermissions();
  const unselectAll = useUnselectAll(contextResource);

  const [updateMany, { isLoading }] = useUpdateMany();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (value) => {
    updateMany(
      resource || contextResource,
      {
        ids: selectedIds,
        data: value,
      },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
          unselectAll();
        },
      },
    );
  };

  if (get(permissions, 'is_internal') !== true) {
    return null;
  }

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        disabled={isLoading}
        variant="outline"
        size="sm"
        className="text-black"
      >
        <Building2 className="w-4 h-4 mr-2" />
        Assign to Provider
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Form onSubmit={onSubmit}>
          <DialogTitle>
            <div>Select a provider</div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ReferenceInput source="tenant_id" reference="providers">
              <AutocompleteInput label="Provider" optionText="name" />
            </ReferenceInput>
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};
