import React from 'react';

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/modules/ui/components/tabs';

import INoteAction from 'healthicons-react/dist/filled/INoteAction';
import { ListTodo } from 'lucide-react';
import { TaskList } from '@/modules/tasks/components/TaskList';
import { TaskCreateButton } from '@/modules/tasks/components/TaskCreateButton';
import { Badge } from '@/modules/ui/components/badge';
import {
  useGetList,
  useRecordContext,
  usePermissions,
  useGetIdentity,
} from 'ra-core';
import { cn } from '@/modules/ui/utils/cn';
import { get } from 'lodash';
import { MonitoringActivityAside } from '../../monitoring-activity/components/MonitoringActivityAside';

const TasksBadge = ({ onClickBadge, isCollapsed }) => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();
  const { total, isLoading } = useGetList('tasks', {
    pagination: { page: 1, perPage: 1 },
    filter: {
      'patient_id[eq]': record?.id,
      'complete[eq]': false,
      'assigned_to_id[eq]': identity?.id,
    },
    sort: { field: 'created_at', order: 'DESC' },
  });

  const displayTotal = total > 99 ? '99+' : total;
  const text = !isCollapsed ? displayTotal : `${displayTotal} Tasks`;
  if (isLoading) {
    return null;
  }
  if (total === 0 && isCollapsed) {
    return (
      <Badge
        variant="outline"
        className="ml-1 text-sm rounded-full cursor-pointer bg-white border-gray-300"
        onClick={onClickBadge}
      >
        <ListTodo className=" mr-1" />
        Tasks
      </Badge>
    );
  }

  if (total === 0 && !isCollapsed) {
    return null;
  }

  return (
    <Badge
      variant="outline"
      className={cn(
        'ml-1 border-yellow-500 bg-yellow-50 text-sm rounded-full',
        { 'cursor-pointer': isCollapsed },
      )}
      onClick={isCollapsed ? onClickBadge : null}
    >
      {text}
    </Badge>
  );
};

export function PatientAside(props) {
  const { isCollapsed, panelRef } = props;

  const { permissions } = usePermissions();

  const showMonitoringActivity = get(
    permissions,
    'monitoring_activities.list',
    false,
  );

  const openPanel = () => {
    if (panelRef.current) {
      panelRef.current.resize(25);
    }
  };

  if (isCollapsed) {
    return (
      <div className="absolute right-10 top-2">
        <TasksBadge onClickBadge={openPanel} isCollapsed={isCollapsed} />
      </div>
    );
  }

  return (
    <Tabs defaultValue={showMonitoringActivity ? 'activity' : 'tasks'}>
      <TabsList className="flex h-12 overflow-x-auto">
        {showMonitoringActivity && (
          <TabsTrigger asChild value="activity" className="grow text-sm">
            <div>
              <INoteAction className="opacity-70 mr-1" />
              Monitoring
            </div>
          </TabsTrigger>
        )}
        <TabsTrigger asChild value="tasks" className="grow text-sm">
          <div>
            <ListTodo className="opacity-70 mr-1" />
            Tasks
            <TasksBadge onClickBadge={openPanel} isCollapsed={isCollapsed} />
          </div>
        </TabsTrigger>
      </TabsList>
      {showMonitoringActivity && (
        <TabsContent className="px-2" value="activity">
          <MonitoringActivityAside />
        </TabsContent>
      )}
      <TabsContent className="px-1" value="tasks">
        <div className="flex flex-col gap-1  h-[90vh] ">
          <div className="flex justify-end px-2  items-center   border-b border-gray-200 pb-2">
            <TaskCreateButton />
          </div>
          <TaskList />
        </div>
      </TabsContent>
    </Tabs>
  );
}
