import { HomeIcon, Smartphone, Hospital } from 'lucide-react';

export const phoneNumberTypeMapping = {
  mobile: {
    icon: Smartphone,
    title: 'Mobile Number',
  },
  home: {
    icon: HomeIcon,
    title: 'Home Number',
  },
  facility: {
    icon: Hospital,
    title: 'Facility Number',
  },
};

export const phoneNumberTypes = [
  { id: 'mobile', name: 'Mobile' },
  { id: 'home', name: 'Home' },
  { id: 'facility', name: 'Facility' },
];
