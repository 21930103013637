import { Input } from '@/modules/ui/components/input';
import { useInput } from 'ra-core';
import {
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
} from '@/modules/ui/components/form';
import { InputMessage } from './input-message';
import inflection from 'inflection';
import React, { forwardRef } from 'react';

export const NumberInput = forwardRef((props: any, ref) => {
  const {
    className,
    defaultValue,
    label,
    format,
    onBlur,
    onChange,
    helperText,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error, isTouched },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse: (value) => {
      if (value === null || value === undefined || value === '') return null;
      const parsed = Number(value);
      return Number.isInteger(parsed) ? parseInt(value, 10) : parseFloat(value);
    },
    resource,
    source,
    type: 'number',
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  let showLabel: string | boolean = label;
  if (label === undefined || label === null) {
    showLabel = inflection.humanize(source);
  }
  return (
    <FormItem id={id}>
      {showLabel && (
        <FormLabel>
          {showLabel}
          {isRequired && <span className="">*</span>}
        </FormLabel>
      )}
      <FormControl>
        <Input
          {...field}
          className={className}
          ref={ref}
          {...rest}
          type="number"
        />
      </FormControl>
      {helperText && <FormDescription>{helperText}</FormDescription>}
      <InputMessage touched={isTouched} error={error?.message} />
    </FormItem>
  );
});

NumberInput.displayName = 'NumberInput';
