import React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { Badge } from '@/modules/ui/components/badge';

export default function DeviceLocationState(props) {
  const record = useRecordContext();
  const patient_id = get(record, 'patient_id');
  const location = get(record, 'location_id');
  const value = patient_id
    ? 'With Patient'
    : location
      ? 'At location'
      : 'Unknown';

  return <Badge variant="secondary">{value}</Badge>;
}
