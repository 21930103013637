import { Button } from '@/modules/ui/components/button';
import { UserCheck2 } from 'lucide-react';
import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  useRecordContext,
} from 'react-admin';

import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { filterPatientName } from '@/utils/filterPatientName';

const DeviceAssignToPatient = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <EditInDialogButton
      resource="devices"
      variant="default"
      title="Assign to Patient"
      notifyMessage="Device assigned to patient."
      refreshOnSuccess={true}
      transform={(data) => {
        return {
          update_action: 'assign_to_patient',
          patient_id: data.patient_id,
        };
      }}
      editButton={
        <Button variant="outline" size="sm">
          <UserCheck2 className="w-4 h-4 mr-2" />
          Assign to Patient
        </Button>
      }
    >
      <ReferenceInput
        source="patient_id"
        reference="patients"
        filter={{
          'tenant_id[eq]': record?.tenant_id,
          'patient_status[in]': ['active', 'enrolled'],
        }}
      >
        <AutocompleteInput
          filterToQuery={filterPatientName}
          label="Patient"
          optionText="user.full_name"
          parse={(v) => (v === '' ? null : v)}
          helperText={false}
          validate={required()}
        />
      </ReferenceInput>
    </EditInDialogButton>
  );
};

export default DeviceAssignToPatient;
