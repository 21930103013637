import { QuestionnaireDialog } from '@/modules/questionnaire/components/QuestionnaireDialog';
import { Button } from '@/modules/ui/components/button';
import { Plus } from 'lucide-react';
import React, { useState } from 'react';

export const AddAssessmentButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onTakeAssessmentsClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button onClick={onTakeAssessmentsClick} size="sm" className="h-8">
        <Plus className="mr-2 h-4 w-4" />
        Assessment
      </Button>
      <QuestionnaireDialog open={isModalOpen} setOpen={setIsModalOpen} />
    </>
  );
};
