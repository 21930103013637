import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@/modules/ui/components/dialog';

import { MetricsPrimaryView } from './MetricsPrimaryView';
import { MetricsTableView } from './MetricsTableView';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
export const MetricsDialogContext = React.createContext<any>(null);

interface MetricsDialogProps {
  measureName: string;
  setMeasureName: (measureName: string) => void;
  onClick?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const MetricsDialog = ({
  measureName,
  setMeasureName,
  open,
  setOpen,
}: MetricsDialogProps) => {
  const [selectedView, setSelectedView] = React.useState('chart');
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClose = (e) => {
    setOpen(false);
    setSelectedView('chart');
    setSelectedRow(null);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <MetricsDialogContext.Provider
        value={{
          measureName,
          selectedView,
          setSelectedView,
          selectedRow,
          setSelectedRow,
        }}
      >
        <DialogContent className="min-w-[70vw] overflow-y-auto max-h-[90vh] max-w-[95vw]">
          <VisuallyHidden asChild>
            <DialogTitle>{measureName}</DialogTitle>
          </VisuallyHidden>
          {selectedView === 'chart' ? <MetricsPrimaryView open={open} /> : null}
          {selectedView === 'table' ? <MetricsTableView /> : null}
        </DialogContent>
      </MetricsDialogContext.Provider>
    </Dialog>
  );
};
