import React from 'react';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { RecordContextProvider, useGetList } from 'ra-core';
import { createColumnHelper } from '@tanstack/react-table';
import { SimpleTable } from '@/modules/ra-ui/components/simple-table';
import { Card } from '@/modules/ui/components/card';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { ReferenceField, TextField } from 'react-admin';
import { Progress } from '@/modules/ui/components/progress';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { get } from 'lodash';
import { Button } from '@/modules/ui/components/button';

type MonitoringProgressColumns = {
  id: number;
  patient_id: number;
  last_updated: string;
  progress_rpm?: number;
  progress_ccm?: number;
  progress_pcm?: number;
  data?: any;
};

const columnHelper = createColumnHelper<MonitoringProgressColumns>();
const columns = [
  columnHelper.accessor('patient_id', {
    header: () => 'Patient',
    cell: (info) => (
      <RecordContextProvider
        value={{
          patient_id: info.getValue(),
        }}
      >
        <ReferenceField
          reference="patients"
          source="patient_id"
          label="Patient"
          link={false}
        >
          <TextField source="user.full_name" />
        </ReferenceField>
      </RecordContextProvider>
    ),
  }),
  columnHelper.accessor('progress_rpm', {
    header: () => 'RPM Progress (%)',
    cell: (info) => {
      const value = info.getValue();
      const data = info.row.original.data;
      return (
        <MonitoringProgressIndicator value={value} data={data} type="rpm" />
      );
    },
  }),
  columnHelper.accessor('progress_ccm', {
    header: () => 'CCM Progress (%)',
    cell: (info) => {
      const value = info.getValue();
      const data = info.row.original.data;
      return (
        <MonitoringProgressIndicator value={value} data={data} type="ccm" />
      );
    },
  }),
  columnHelper.accessor('progress_pcm', {
    header: () => 'PCM Progress (%)',
    cell: (info) => {
      const value = info.getValue();
      const data = info.row.original.data;
      return (
        <MonitoringProgressIndicator value={value} data={data} type="pcm" />
      );
    },
  }),
  columnHelper.accessor('last_updated', {
    header: () => 'Last Updated',
    cell: (info) =>
      DateTime.fromISO(info.getValue(), { zone: 'UTC' }).toLocaleString(
        DateTime.DATETIME_SHORT,
      ),
  }),
];

export const MonitoringProgressIndicator: React.FC<{
  value: number;
  data: any;
  type: string;
}> = ({ value, data, type }) => {
  if (value === null) {
    return <span className="text-sm">N/A</span>;
  }

  const today = DateTime.now();
  const dayOfMonth = today.day;
  const daysInMonth = today.daysInMonth;

  // Calculate number of business days so far this month
  let businessDaysSoFar = 0;
  for (let i = 1; i <= dayOfMonth; i++) {
    const day = DateTime.fromObject({
      year: today.year,
      month: today.month,
      day: i,
    });
    if (day.weekday <= 5) {
      // Monday - Friday are business days
      businessDaysSoFar++;
    }
  }

  // Calculate total business days in the month
  let totalBusinessDays = 0;
  for (let i = 1; i <= daysInMonth; i++) {
    const day = DateTime.fromObject({
      year: today.year,
      month: today.month,
      day: i,
    });
    if (day.weekday <= 5) {
      totalBusinessDays++;
    }
  }

  const expectedProgress = (businessDaysSoFar / totalBusinessDays) * 100;

  let statusColor = 'bg-red-500';
  if (value >= expectedProgress) {
    statusColor = 'bg-green-500';
  } else if (value >= expectedProgress - 0.1) {
    statusColor = 'bg-yellow-500';
  }

  let tooltipContent;

  if (type === 'rpm') {
    const totalTime =
      get(data, 'rpm.99457_and_99458_remaining_time', 0) +
      get(data, 'rpm.99457_and_99458_time', 0);
    tooltipContent = (
      <div className="text-sm">
        <p>
          99457 / 99458 Time: {get(data, 'rpm.99457_and_99458_time', 0) / 60} /{' '}
          {totalTime / 60} minutes
        </p>
        <p>
          99454 Readings: {get(data, 'rpm.99454_qualified_count', 0)} /{' '}
          {get(data, 'rpm.99454_optimal_count', 0)}
        </p>
      </div>
    );
  } else if (type === 'ccm') {
    const totalTime =
      get(data, 'ccm.99490_and_99439_remaining_time', 0) +
      get(data, 'ccm.99490_and_99439_time', 0);
    tooltipContent = (
      <div className="text-sm">
        <p>
          99490 / 99439 Time: {get(data, 'ccm.99490_and_99439_time', 0) / 60} /{' '}
          {totalTime / 60} minutes
        </p>
      </div>
    );
  } else if (type === 'pcm') {
    const totalTime =
      get(data, 'pcm.99426_and_99427_remaining_time', 0) +
      get(data, 'pcm.99426_and_99427_time', 0);
    tooltipContent = (
      <div className="text-sm">
        <p>
          99426 / 99427 Time: {get(data, 'pcm.99426_and_99427_time', 0) / 60} /{' '}
          {totalTime / 60} minutes
        </p>
      </div>
    );
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className="flex items-center gap-2">
            <Progress
              value={value}
              className="w-[100px] bg-gray-300"
              max={100}
              classNameIndicator={statusColor}
            />
            <span className="text-sm">{Math.round(value)}%</span>
          </div>
        </TooltipTrigger>
        <TooltipContent>{tooltipContent}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const PatientMonitoringProgressList: React.FC = () => {
  const navigate = useNavigate();

  const { data, isLoading, total } = useGetList('monitoring-progress', {
    pagination: { page: 1, perPage: 5 },
    sort: { field: 'last_updated', order: 'DESC' },
    meta: {
      under_care: true,
    },
  });

  if (isLoading) {
    return (
      <div className="w-full h-32 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (total === 0) {
    return null;
  }

  const onRowClick = (row: any) => {
    navigate(`/patients/${row.getValue('patient_id')}/show`);
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <h1 className="text-lg font-bold">Monitoring Progress</h1>
      <div className="my-2 w-fit">
        <Card>
          <p className="text-md font-semibold px-3 py-1">
            Patient Monitoring Progress
          </p>

          <SimpleTable data={data} columns={columns} rowClick={onRowClick} />
        </Card>
        <div className="flex justify-center px-3">
          <Button variant="link" onClick={() => navigate('/monitoring')}>
            View More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PatientMonitoringProgressList;
