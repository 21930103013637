import { useCheckAuth } from 'ra-core';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Copyright from '@/modules/login/components/Copyright';
import { LoginForm } from '@/modules/login/components/LoginForm';
import { PatientSuccess } from '@/modules/login/components/PatientSuccess';
import { ResetPatientSuccess } from '@/modules/login/components/ResetPatientSuccess';
import { SetPasswordForm } from '@/modules/login/components/SetPasswordForm';
import { useSearchParams } from 'react-router-dom';

import { ForgotPasswordForm } from '@/modules/login/components/ForgotPassword';

import { Button } from '@/modules/ui/components/button';
import { Card } from '@/modules/ui/components/card';
import { WebAuthnLogin } from '@/modules/login/components/WebAuthnLogin';
import { MFATokenProvider } from '@/modules/login/components/MFATokenProvider';
import { TOTPLogin } from '@/modules/login/components/TOTPLogin';

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
export const Login = (props) => {
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  const [view, setView] = React.useState('login');

  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams.get('resetCode') && view !== 'set-password') {
      setView('set-password');
    } else if (searchParams.get('page') === 'reset-success-patient') {
      setView('reset-success-patient');
    } else if (searchParams.get('page') === 'patient-success') {
      setView('patient-success');
    }
  }, [searchParams, view]);

  React.useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        if (searchParams.get('page') !== 'reset-success-patient') {
          navigate('/');
        }
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, navigate, searchParams]);

  const handleSetView = ({ view, searchParams }) => {
    setView(view);
    setSearchParams(searchParams);
  };

  return (
    <MFATokenProvider>
      <div className="flex min-h-screen w-full flex-col">
        <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
          <div className="flex flex-row justify-end gap-2 w-full">
            <Button
              asChild
              //  href="https://vironix.ai"
              variant="ghost"
            >
              <a href="https://vironix.ai">Back to vironix.ai</a>
            </Button>
            <Button variant="default" asChild>
              <a href="https://www.vironix.ai/contact">Request Demo</a>
            </Button>
          </div>
        </header>
        <main className="flex flex-col gap-4 items-center justify-between mx-auto mt-10 h-full">
          <img
            src="/vironix.png"
            alt="Vironix logo"
            style={{ width: 50, height: 50 }}
          />
          <Card className="mx-auto max-w-sm">
            {view === 'login' ? <LoginForm setView={setView} /> : null}
            {view === 'reset-password' ? (
              <ForgotPasswordForm setView={setView} />
            ) : null}
            {view === 'set-password' ? <SetPasswordForm /> : null}
            {view === 'reset-success-patient' ? <ResetPatientSuccess /> : null}
            {view === 'patient-success' ? (
              <PatientSuccess setView={handleSetView} />
            ) : null}
            {view === 'webauthn' ? <WebAuthnLogin setView={setView} /> : null}
            {view === 'totp' ? <TOTPLogin setView={setView} /> : null}
          </Card>
          <Copyright />
        </main>
      </div>
    </MFATokenProvider>
  );
};
