import React from 'react';
import {
  BooleanInput,
  DeleteButton,
  SelectInput,
  TextInput,
  email,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { PhoneNumberInput } from '../../../ra-ui/components/PhoneNumberInput';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';

const contactChoices = [
  { id: 'caregiver', name: 'Caregiver' },
  { id: 'child', name: 'Child' },
  { id: 'dependent', name: 'Dependent' },
  { id: 'family_member', name: 'Family member' },
  { id: 'legal_guardian', name: 'Legal guardian' },
  { id: 'parent', name: 'Parent' },
  { id: 'spouse', name: 'Spouse' },
  { id: 'other', name: 'Other' },
];

const PatientContactFields = () => (
  <div className="flex flex-col gap-0">
    <div className="flex flex-row gap-1">
      <TextInput
        source="full_name"
        label="Contact full name"
        validate={[required()]}
        helperText={false}
        fullWidth
      />
      <SelectInput
        choices={contactChoices}
        source="relationship_to_patient"
        label="Relationship to patient"
        helperText={false}
        validate={[required()]}
        fullWidth
      />
    </div>
    <div className="flex flex-row gap-1">
      <PhoneNumberInput
        source="cell_phone_number"
        label="Cell phone number"
        helperText={false}
      />

      <PhoneNumberInput
        source="home_phone_number"
        label="Home phone number"
        helperText={false}
      />
    </div>
    <div className="flex flex-row gap-1">
      <TextInput
        source="email"
        validate={[email()]}
        helperText={false}
        sx={{
          minWidth: 250,
        }}
      />
      <BooleanInput source="is_emergency_contact" helperText={false} />
    </div>
    <TextInput source="notes" multiline minRows={2} helperText={false} />
  </div>
);

export const PatientContactCreateButton = ({ variant = 'default' }) => {
  const record = useRecordContext();

  const transform = (data) => {
    return { ...data, patient_id: record.id, tenant_id: record.user.tenant_id };
  };
  return (
    <CreateInDialogButton
      title="Add new contact"
      resource="contacts"
      transform={transform}
      variant={variant}
      label="Contact"
    >
      <PatientContactFields />
    </CreateInDialogButton>
  );
};

export const PatientContactEditButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const transform = (data) => {
    return data;
  };
  return (
    <EditInDialogButton
      title="Edit contact"
      resource="contacts"
      transform={transform}
      refreshOnSuccess={true}
      deleteButton={
        <DeleteButton
          confirmTitle="Delete this contact?"
          resource="contacts"
          redirect={false}
          mutationOptions={{
            onSuccess: () => {
              notify('Contact deleted.');
              refresh();
            },
          }}
        />
      }
    >
      <PatientContactFields />
    </EditInDialogButton>
  );
};
