import { web, email, security } from '../assets';

export const iphoneInforamtion = [
  {
    title: 'Seller',
    text: 'Vironix Health, Inc',
  },
  {
    title: 'Category',
    text: 'Medical',
  },
  {
    title: 'Compatibility',
    text: 'iPhone(Requires iOS 12.1 or later)',
  },
  {
    title: 'Languages',
    text: 'English',
  },
  {
    title: 'Age Rating',
    text: '17+',
  },
  {
    title: 'Copyright',
    text: '© Vironix Health 2022',
  },
  {
    title: 'Price',
    text: 'Free',
  },
];

export const iphoneDescription = [
  'Vironix RPM is a full physiological monitoring solution that save lives and raise quality-of-life for those afflicted with Heart Failure, COPD, Asthma, and Acute Respiratory Illness.',
  'Vironix RPM allows for patients to track physiological signs (vital signs, symptoms, & medical events) using FDA-approved devices, and quick self-reported questionnaires.',
  'Vironix RPM uniquely learns about your condition, and provides you with personalized decision support on your current state, identifies risk of exacerbations & flare-ups and provides you with an easy score to track your relative health.',
  'Vironix RPM requires that your healthcare provider is subscripted to the Vironix RPM solution and has registered you on the platform. Please contact your healthcare provider to see if they support Vironix RPM, and/or have them contact support@vironix.ai.',
  'Vironix is not a substitute for professional medical consultation. If you feel that you are experiencing a life-threatening emergency, please call 911. If you continue to feel anxious or are experiencing other symptoms that concern you, please call your doctor. Please make sure to seek a doctor’s advice in addition to using this app.',
];

export const androidContacts = [
  {
    icon: web,
    title: 'Website',
    text: 'https://www.vironix.ai',
  },
  {
    icon: email,
    title: 'Email',
    text: 'mailto:support@vironix.ai',
  },
  {
    icon: security,
    title: 'Privacy policy',
    text: 'https://www.vironix.ai/privacy-policy',
  },
];
