import React from 'react';
import { Button } from '@/modules/ui/components/button';
import { Ban } from 'lucide-react';
import {
  useCreate,
  useRecordContext,
  useNotify,
  useRefresh,
} from 'react-admin';

export const MarkAsIncorrect = () => {
  const record = useRecordContext();
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleMarkAsIncorrect = async () => {
    create(
      `patients/${record.patient_id}/runs/${record.id}/entered-in-error`,
      {
        data: {},
      },
      {
        onSuccess: () => {
          notify('Assessment marked as entered in error', { type: 'success' });
          refresh();
        },
        onError: () => {
          notify('Error marking assessment as incorrect', { type: 'error' });
        },
      },
    );
  };

  return (
    <Button
      onClick={handleMarkAsIncorrect}
      variant="ghost"
      className="text-destructive hover:text-destructive/90"
    >
      <Ban className="mr-2 h-4 w-4" />
      Mark as Entered in Error
    </Button>
  );
};
