// const unitText = get(data, 'unit.text', '');
// const chartName = get(data, 'chart_name', '');
import {get} from 'lodash';

export const formatYAxisLabel = (props) => {
  const { unitText, chartName, chartProperties, position } = props;
  let label = get(chartProperties, [chartName, 'name']);

  if (unitText) label = `${label} (${unitText})`;

  return {
    value: label,
    angle: -90,
    position: position,
  };
};
