import React from 'react';
import { Inbox } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';

export const PatientQueueEmpty = (props) => {
  const { className, underCare } = props;

  return (
    <Card
      className={`flex flex-col items-center justify-center h-full w-full ${className}`}
    >
      <CardContent className="text-center">
        <Inbox className="w-24 h-24 mx-auto text-gray-400" />
        <CardHeader>
          <CardTitle className="mt-4 text-2xl font-semibold text-gray-900">
            No patients in your queue
          </CardTitle>
        </CardHeader>
        <p className="mt-2 text-sm text-gray-500">
          The patient queue is currently empty. Enrolled patients will appear
          here when they are added to the queue.
        </p>
        {underCare && (
          <p className="mt-4 text-base font-medium text-blue-600 bg-blue-100 p-3 rounded-md">
            You are currently viewing only patients under your care.
            <span className="block mt-2 font-bold">
              Try toggling to all patients to see others.
            </span>
          </p>
        )}
      </CardContent>
    </Card>
  );
};
