import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { AlertCircle } from 'lucide-react';
import React from 'react';

export const PatientNoDevice = () => {
  return (
    <Alert variant="warning" className="mt-5">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>No Device Assigned</AlertTitle>
      <AlertDescription className="flex justify-between items-center">
        <div>
          This patient currently has no devices assigned to them. A device needs
          to be assigned to collect metrics and measurements.
        </div>
      </AlertDescription>
    </Alert>
  );
};
