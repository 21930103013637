import EditDialog from '@/modules/ra-ui/components/edit-dialog'; // Changed from EditInDialogButton
import React from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  TextInput,
  required,
} from 'react-admin';

const DeviceSetCustomerInvoice = ({ open, setOpen }) => {
  return (
    <EditDialog // Changed from EditInDialogButton
      open={open}
      setOpen={setOpen}
      title="Set device paid status"
      resource="devices"
      variant="default"
      notifyMessage="Device status updated."
      refreshOnSuccess={true}
      transform={(data) => {
        return {
          ...data,
          update_action: 'set_invoice_item_id',
          customer_invoice_item_id: data.has_invoice
            ? data.customer_invoice_item_id
            : null,
        };
      }}
    >
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const defaultHasInvoice = formData.customer_invoice_item_id
            ? true
            : false;
          return (
            <>
              <BooleanInput
                source="has_invoice"
                label="Has customer paid for this device?"
                defaultValue={defaultHasInvoice}
                helperText={false}
              />
              {formData.has_invoice ||
              (defaultHasInvoice && formData.has_invoice === undefined) ? (
                <TextInput
                  source="customer_invoice_item_id"
                  label="Invoice Item ID"
                  validate={required()}
                  helperText="The Invoice Item ID from stripe"
                />
              ) : null}
            </>
          );
        }}
      </FormDataConsumer>
    </EditDialog>
  );
};

export default DeviceSetCustomerInvoice;
