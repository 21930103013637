import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/modules/ui/components/select';
import { cn } from '@/modules/ui/utils/cn';
import { FieldTitle, useChoices, useInput } from 'ra-core';
import * as React from 'react';
import { useCallback } from 'react';
import { InputMessage } from './input-message';
import { FormLabel } from '../components/form';
import { Button } from '../components/button';

export interface SelectInputProps {
  choices?: any[];
  className?: string;
  label?: string | React.ReactElement;
  helperText?: string | React.ReactElement | false;
  source: string;
  optionText?: string | ((choice: any) => React.ReactNode);
  optionValue?: string;
  translateChoice?: boolean;
  validate?: any;
  [key: string]: any;
}

export const SelectInput = (props: SelectInputProps) => {
  const {
    choices = [],
    className,
    label,
    helperText,
    source,
    optionText = 'name',
    optionValue = 'id',
    translateChoice = true,
    validate,
    ...rest
  } = props;

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const [, setKey] = React.useState(+new Date());
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    source,
    validate,
    ...rest,
  });

  const renderHelperText =
    helperText !== false || ((isTouched || isSubmitted) && invalid);

  const handleChange = useCallback(
    (value: string) => {
      field.onChange(value);
    },
    [field],
  );

  return (
    <div className={cn('space-y-2', className)}>
      <FormLabel>
        <FieldTitle label={label} source={source} isRequired={isRequired} />
      </FormLabel>
      <Select
        onValueChange={handleChange}
        value={field.value}
        required={isRequired}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select an option" />
        </SelectTrigger>
        <SelectContent>
          {choices.map((choice: any) => (
            <SelectItem
              key={getChoiceValue(choice)}
              value={getChoiceValue(choice)}
            >
              {getChoiceText(choice)}
            </SelectItem>
          ))}
          {!isRequired && (
            <Button
              className="w-full px-2 text-left"
              variant="ghost"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                field.onChange('');
                setKey(+new Date());
              }}
            >
              Clear
            </Button>
          )}
        </SelectContent>
      </Select>
      {renderHelperText && (
        <InputMessage
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      )}
    </div>
  );
};
