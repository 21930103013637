import ProviderSelector from '@/modules/organizations/components/ProviderSelector';
import { StatCard } from '@/modules/ui/components/stat-card';
import { formatDuration } from '@/utils/formatDuration';
import INoteAction from 'healthicons-react/dist/filled/INoteAction';
import { get } from 'lodash';
import {
  Calendar,
  Check,
  Clock,
  ListTodo,
  Ticket,
  User,
  UserPlus,
} from 'lucide-react';
import { useGetIdentity, useGetOne, usePermissions } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { EnrollmentMetricPlot } from '@/modules/home/components/EnrollmentMetricPlot';
import { OpenTasksTable } from '@/modules/home/components/OpenTasksTable';
import PatientMonitoringProgressList from '@/modules/home/components/PatientMonitoringProgressList';

export const Home = () => {
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();

  const [selectedProvider, setSelectedProvider] = useState<string | undefined>(
    '',
  );

  const {
    data: metricsData,
    isLoading: metricsIsLoading,
    isFetched: metricsIsFetched,
  } = useGetOne(
    'metrics/user',
    { id: identity?.id },
    { enabled: identity?.id !== undefined },
  );
  const {
    data: providersData,
    isLoading: providersIsLoading,
    isFetched: providersIsFetched,
  } = useGetOne(
    'metrics',
    { id: 'provider', meta: { tenant_id: selectedProvider } },
    {
      queryKey: ['metrics-provider', selectedProvider],
      enabled: selectedProvider !== '',
    },
  );

  useEffect(() => {
    if (identity) {
      setSelectedProvider(identity.tenantId ? identity.tenantId : undefined);
    }
  }, [identity]);

  return (
    <div className="container mx-auto w-fit mb-4">
      <div className="flex flex-col items-center py-2 gap-4 ">
        <div className="flex flex-col gap-1 w-full">
          <h1 className="text-2xl font-bold">Home</h1>
          <p className="text-base ">An overview of your app usage</p>
        </div>
        <div className="flex flex-col gap-1 w-full">
          <h1 className="text-lg font-bold">Your metrics</h1>
          <div className="flex flex-row gap-1 ">
            <StatCard
              statName="Patients under care"
              statValue={get(metricsData, 'patients_under_care', '-')}
              isLoading={metricsIsLoading || !metricsIsFetched}
              icon={User}
            />
            <StatCard
              statName="Patients enrolled by you"
              statValue={get(metricsData, 'patients_enrolled', '-')}
              isLoading={metricsIsLoading || !metricsIsFetched}
              icon={User}
            />
            <StatCard
              statName="Notes created"
              statValue={get(metricsData, 'monitoring_notes_count', '-')}
              isLoading={metricsIsLoading || !metricsIsFetched}
              icon={INoteAction}
            />
            <StatCard
              statName="Monitoring duration"
              statValue={
                get(metricsData, 'monitoring_minutes_sum')
                  ? formatDuration(
                      get(metricsData, 'monitoring_minutes_sum'),
                      true,
                      true,
                    )
                  : '-'
              }
              isLoading={metricsIsLoading || !metricsIsFetched}
              icon={Clock}
            />
          </div>
        </div>
        {get(permissions, 'tasks.list') ? (
          <>
            <div className="flex flex-col gap-1 w-full">
              <h1 className="text-lg font-bold">Your Tasks</h1>
              <div className="flex flex-row gap-1 ">
                <StatCard
                  statName="Open Tasks"
                  statValue={get(metricsData, 'open_tasks', '-')}
                  isLoading={metricsIsLoading || !metricsIsFetched}
                  icon={ListTodo}
                />
                <StatCard
                  statName="Completed Tasks"
                  statValue={get(metricsData, 'completed_tasks', '-')}
                  isLoading={metricsIsLoading || !metricsIsFetched}
                  icon={Check}
                />
              </div>
              <OpenTasksTable />
            </div>{' '}
          </>
        ) : null}

        {get(permissions, 'monitoring_progress.list') ? (
          <PatientMonitoringProgressList />
        ) : null}
        <div className="flex flex-col gap-1 w-full">
          <h1 className="text-lg font-bold">Your organization</h1>
          <div className="flex flex-row gap-1">
            <ProviderSelector
              value={selectedProvider}
              onChange={setSelectedProvider}
            />
          </div>
          <div className="flex flex-col gap-1 ">
            <div className="flex flex-row gap-1">
              <StatCard
                statName="Active patients"
                statValue={get(providersData, 'active_patients', '-')}
                icon={User}
                isLoading={providersIsLoading || !providersIsFetched}
              />
              <StatCard
                statName="Enrolled patients (Today)"
                statValue={get(providersData, 'today_enrolled_count', '-')}
                icon={UserPlus}
                isLoading={providersIsLoading || !providersIsFetched}
              />
              <StatCard
                statName="Pending enrollments (Today)"
                statValue={get(
                  providersData,
                  'today_pending_enrollment_count',
                  '-',
                )}
                icon={Calendar}
                isLoading={providersIsLoading || !providersIsFetched}
              />
            </div>
            <div className="flex flex-row gap-1">
              <StatCard
                statName="Notes created"
                statValue={get(providersData, 'monitoring_notes_count', '-')}
                icon={INoteAction}
                isLoading={providersIsLoading || !providersIsFetched}
              />
              <StatCard
                statName="Monitoring duration"
                statValue={
                  get(providersData, 'monitoring_minutes_sum')
                    ? formatDuration(
                        get(providersData, 'monitoring_minutes_sum'),
                        true,
                        true,
                      )
                    : '-'
                }
                icon={Clock}
                isLoading={providersIsLoading || !providersIsFetched}
              />
              <StatCard
                statName="Claims generated"
                statValue={get(providersData, 'claims_count', '-')}
                icon={Ticket}
                isLoading={providersIsLoading || !providersIsFetched}
              />
            </div>
          </div>
        </div>
        <EnrollmentMetricPlot providerId={selectedProvider} />
      </div>
    </div>
  );
};
