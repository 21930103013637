import React from 'react';
import { cn } from '@/modules/ui/utils/cn';
import { buttonVariants } from '@/modules/ui/components/button';
import { useLocation, Link } from 'react-router-dom';

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
    default?: boolean;
  }[];
  horizontal?: boolean;
}

export function SidebarNav({
  className,
  items,
  horizontal = false,
  ...props
}: SidebarNavProps) {
  const location = useLocation();
  const defaultItem = items.find((item) => item.default);
  const isPathMatched = items.some((item) =>
    location.pathname.endsWith(item.href),
  );

  return (
    <nav
      className={cn(
        horizontal
          ? 'flex space-x-2'
          : 'flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1',
        className,
      )}
      {...props}
    >
      {items.map((item) => (
        <Link
          key={item.href}
          to={item.href}
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            location.pathname.endsWith(item.href)
              ? 'bg-muted hover:bg-muted'
              : !isPathMatched && defaultItem && item.href === defaultItem.href
                ? 'bg-muted hover:bg-muted'
                : 'hover:bg-transparent hover:underline',
            'justify-start',
          )}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  );
}
