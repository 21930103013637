export const conditionNames = {
  HEARTFAILURE: 'Heart Failure',
  HeartFailure: 'Heart Failure',
  heartfailure: 'Heart Failure',
  'Congestive Heart Failure': 'Heart Failure',
  chf: 'Heart Failure',
  copd: 'COPD',
  Copd: 'COPD',
  asthma: 'Asthma',
  covid19: 'Covid-19',
  viral: 'Viral',
  general: 'General',
  diabetes: 'Diabetes',
  chronic_kidney_disease: 'Chronic Kidney Disease',
  ckd: 'Chronic Kidney Disease',
};
