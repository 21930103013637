import React from 'react';
import { useRecordContext } from 'react-admin';

import { MonitoringActivitiesListDataGrid } from '../../../monitoring-activity/components/MonitoringActivitiesListDataGrid';

export default function MonitoringActivitiesSection(props) {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <h3 className="text-xl font-semibold">Monitoring</h3>
        <p className="text-sm text-gray-500">
          All monitoring activities for this patient related to providing
          virtual care services.
        </p>
      </div>
      {/* <ServiceListActions record={record} /> */}
      <MonitoringActivitiesListDataGrid />
    </div>
  );
}
