import React, { useState } from 'react';
import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { cn } from '@/modules/ui/utils/cn';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import {
  Form,
  useListContext,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
  useNotify,
} from 'react-admin';
import { Package } from 'lucide-react';

export const BulkMarkDeviceReceivedButton = (props) => {
  const { resource = null } = props;
  const { selectedIds, resource: contextResource } = useListContext();
  const unselectAll = useUnselectAll(contextResource);

  const [updateMany, { isLoading }] = useUpdateMany();
  const refresh = useRefresh();
  const notify = useNotify();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    updateMany(
      resource || contextResource,
      {
        ids: selectedIds,
        data: { update_action: 'mark_received' },
      },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
          unselectAll();
          notify('Devices successfully marked as received', {
            type: 'success',
          });
        },
        onError: (error) => {
          console.error('Error marking devices as received:', error);
          notify('Error marking devices as received. Please try again.', {
            type: 'error',
          });
        },
      },
    );
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        disabled={isLoading}
        variant="outline"
        size="sm"
        className="text-black"
        type="button"
      >
        <Package className="w-4 h-4 mr-2" />
        Mark as Received
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          onClose={handleClose}
          className={cn('min-w-[400px] overflow-y-auto max-h-[90vh]')}
        >
          <DialogHeader>
            <DialogTitle>Mark Devices as Received</DialogTitle>
          </DialogHeader>
          <Form onSubmit={onSubmit}>
            <p className="text-muted-foreground">
              This action will mark the selected devices as received and change
              their status from 'pending' to 'inactive'.
            </p>
            <DialogFooter className="mt-4">
              <SaveButton label="Confirm" variant="default" alwaysEnable />
            </DialogFooter>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
