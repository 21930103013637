import { Button } from '@/modules/ui/components/button';

import { get } from 'lodash';
import { User2 } from 'lucide-react';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  Form,
  ReferenceInput,
  useGetIdentity,
  useListContext,
  useNotify,
  useRefresh,
  useUpdateMany,
} from 'react-admin';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';

export const AssignTasksButton = (props) => {
  const { identity } = useGetIdentity();

  const { selectedIds } = useListContext();

  const refresh = useRefresh();
  const notify = useNotify();

  const [updateMany, { isLoading }] = useUpdateMany();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (value) => {
    updateMany(
      'tasks',
      {
        ids: selectedIds,
        data: { assigned_to_id: value.assigned_to_id },
      },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
          notify('Successfully updated tasks', {
            type: 'info',
          });
        },
        onError: (error) => {
          notify(
            get(error, 'message') ||
              'An error occurred while updating the tasks',
            {
              type: 'error',
            },
          );
        },
      },
    );
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        disabled={isLoading}
        variant="outline"
        size="sm"
        className="text-black"
        type="button"
      >
        <User2 className="w-4 h-4 mr-2" />
        Assign tasks to
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent onClose={handleClose} className="min-w-72">
          <DialogHeader>
            <DialogTitle>Assign tasks</DialogTitle>
            <DialogDescription>
              Assign tasks to the selected user
            </DialogDescription>
          </DialogHeader>
          <Form onSubmit={onSubmit}>
            <ReferenceInput
              source="assigned_to_id"
              reference="users"
              filter={{
                'tenant_id[in]': [identity?.tenant_id],
                'role[ne]': `patient`,
              }}
            >
              <AutocompleteInput
                label="Assigned to"
                optionText={(choice) => {
                  if (choice.is_internal) {
                    return `${choice.first_name} ${choice.last_name} (Vironix) ${
                      choice.credentials ? `(${choice.credentials})` : ``
                    }`;
                  }
                  return `${choice.full_name} ${
                    choice.credentials ? `(${choice.credentials})` : ``
                  }`;
                }}
              />
            </ReferenceInput>
            <DialogFooter>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? <LoadingSpinner className="w-4 h-4 " /> : 'Assign'}
              </Button>
            </DialogFooter>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
