import React from 'react';
import { useRecordContext } from 'react-admin';
import { MetricsEmpty } from './MetricsEmpty';
import { PatientVitals } from './PatientVitals';
import { AddObservationButton } from '@/modules/shared-device-observation/components/AddObservationButton';
import { Card } from '@/modules/ui/components/card';
import { PatientScores } from '../patient-scores/PatientScores';
import { useIsDemo } from '@/modules/demo/components/DemoProvider';
import DemoMeasurement from '@/modules/demo/components/DemoMeasurement';
import { PatientNoDevice } from './PatientNoDevice';

export function MetricsSection() {
  const patientRecord = useRecordContext();
  const { isDemo } = useIsDemo();

  if (!patientRecord) return null;

  const observations = patientRecord.observations;
  const measureReports = patientRecord.measure_reports;
  const hasDevice = patientRecord.has_device;

  if (
    !observations ||
    !measureReports ||
    !observations.length ||
    !measureReports.length
  )
    return (
      <div>
        {!hasDevice && <PatientNoDevice />}
        <div className="relative pt-10 mt-5">
          <div className="absolute right-4 top-4">
            <div className="flex flex-row gap-2">
              <AddObservationButton />
              {isDemo ? <DemoMeasurement /> : null}
            </div>
          </div>
          <Card className="p-4 mt-5">
            <MetricsEmpty />
          </Card>
        </div>
      </div>
    );

  return (
    <div>
      {!hasDevice && <PatientNoDevice />}
      <div className="relative mt-5">
        <div className="absolute right-4 top-4">
          <div className="flex flex-row gap-2">
            <AddObservationButton />
            {isDemo ? <DemoMeasurement /> : null}
          </div>
        </div>
        <div className="flex flex-col gap-2 pt-14">
          <div>
            <PatientVitals />
          </div>
          <div>
            <PatientScores />
          </div>
        </div>
      </div>
    </div>
  );
}
