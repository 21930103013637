import { serviceInstanceTypesAbbreviated } from '@/modules/patients/constants/serviceInstanceTypesAbbreviated';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { formatDurationAbbreviated } from '@/utils/formatDurationAbbreviated';
import INoteAction from 'healthicons-react/dist/filled/INoteAction';
import { get } from 'lodash';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Pagination,
  ReferenceField,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { MonitoringActivityEditDialog } from './MonitoringActivityEditDialog';
import React from 'react';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import { map } from 'lodash';
import { Badge } from '@/modules/ui/components/badge';
import { monitoringActivityTypesExtended } from '../constants/monitoringActivityTypeExtended';
import { DateTimeProviderTZField } from '@/modules/ra-ui/components/DateTimeProviderTZField';

const monitoringActivityTypeChoices = map(
  monitoringActivityTypesExtended,
  (v, k) => ({ label: v, value: k }),
);

export function MonitoringActivitiesListDataGrid() {
  const record = useRecordContext();

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [filterValues, setFilters] = React.useState({});

  const { data, total, isLoading } = useGetList('monitoring-activities', {
    pagination: { page, perPage },
    filter: {
      'patient_id[eq]': record?.id,
      ...filterValues,
    },
    sort,
  });
  if (!record) {
    return null;
  }

  return (
    <>
      <ListContextProvider
        // @ts-ignore
        value={{
          filterValues,
          setFilters,
          displayedFilters: {},
        }}
      >
        <DataTableToolbar showViewOptions={false} search={false} className="">
          <DataTableDateRangeFilter column="reviewed_on" title="Date range" />
          <DataTableFilter
            column="type[in]"
            title="Type"
            options={monitoringActivityTypeChoices}
          />
          <DataTableFilter
            column="service_instance_id[in]"
            title="Care Program"
            options={record.service_instances.map((serviceInstance) => ({
              label:
                serviceInstance.type &&
                serviceInstanceTypesAbbreviated[serviceInstance.type],
              value: serviceInstance.id.toString(),
            }))}
          />
        </DataTableToolbar>
        <Datagrid
          data={data}
          total={total}
          isLoading={isLoading}
          sort={sort}
          bulkActionButtons={false}
          setSort={setSort}
          expand={<ExpandedRow />}
          rowClick="expand"
          empty={
            <EmptyDatagrid
              resourceName="Monitoring Activities"
              textFormatString="No monitoring activities found for this patient"
              icon={
                <INoteAction height="5em" width="5em" className="opacity-70" />
              }
            />
          }
        >
          <TextField label="Performed by" source="practitioner_name" />
          <FunctionField
            label="Type"
            source="type"
            render={(record) => (
              <p className="text-sm font-medium">{`${get(monitoringActivityTypesExtended, record.type, record.type || '')}`}</p>
            )}
          />{' '}
          <DateTimeProviderTZField
            source="reviewed_on"
            label="Date"
            showTime
            showTZ
            tenant_id={record?.user?.tenant_id}
          />
          <FunctionField
            source="duration"
            label="Duration"
            render={(record) => formatDurationAbbreviated(record.duration)}
          />
          <ReferenceField
            source="service_instance_id"
            reference="service-instances"
            label="Care Program"
          >
            <FunctionField
              render={(record) => (
                <Badge variant="outline">{`${get(
                  serviceInstanceTypesAbbreviated,
                  record.type,
                  record.type,
                )}`}</Badge>
              )}
            />
          </ReferenceField>
          <MonitoringActivityEditDialog tenant_id={record?.user?.tenant_id} />
        </Datagrid>
        <Pagination
          total={total}
          perPage={perPage}
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      </ListContextProvider>
    </>
  );
}
export function ExpandedRow() {
  const record = useRecordContext();

  return (
    <div>
      <p className="whitespace-pre-wrap">{record.description}</p>
    </div>
  );
}
