import { titleize } from 'inflection';
import React from 'react';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useListController,
  useRecordContext,
} from 'react-admin';
import { PatientContactEditButton } from './ContactsCreateEditButton';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { cropText } from '@/modules/patients/utils/cropText';

export const PatientContactsGrid = (props) => {
  const record = useRecordContext();
  const listContext = useListController({
    debounce: 500,
    exporter: false,
    filter: { 'patient_id[eq]': record.id },
    perPage: 10,
    resource: 'contacts',
    storeKey: `patient-view-${record.id}-contacts`,
  });

  if (!record) return null;

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        bulkActionButtons={false}
        empty={
          <EmptyDatagrid
            resourceName="Contact"
            textFormatString="No contacts found"
          />
        }
        {...props}
      >
        <TextField source="full_name" label="Contact name" />
        <FunctionField
          source="relationship_to_patient"
          label="Relationship"
          render={(record) => titleize(record.relationship_to_patient)}
        />
        <TextField source="cell_phone_number" label="Cell Phone" />
        <TextField source="Home_phone_number" label="Home Phone" />
        <TextField source="email" />
        <FunctionField
          render={(record) => cropText(record.notes, 120)}
          label="Notes"
        />
        <BooleanField source="is_emergency_contact" label="Emergency contact" />
        <PatientContactEditButton />
      </Datagrid>
    </ListContextProvider>
  );
};
