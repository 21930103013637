// This component should let users set tenant and location of devices.
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { cn } from '@/modules/ui/utils/cn';

import { get } from 'lodash';
import { Building2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Form,
  ReferenceInput,
  useGetIdentity,
  useListContext,
  usePermissions,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

const DeviceLocationInput = () => {
  const { identity } = useGetIdentity();
  const tenantId = useWatch({ name: 'tenant_id' });
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('location_id', null);
  }, [tenantId, setValue]);

  return (
    <ReferenceInput
      source="location_id"
      reference="locations"
      filter={{
        'is_active[eq]': true,
        ...(identity.tenant_id !== null && {
          'tenant_id[eq]': tenantId ? tenantId : null,
        }),
      }}
    >
      <AutocompleteInput
        label="Location"
        optionText="name"
        parse={(v) => (v === '' ? null : v)}
        helperText={false}
      />
    </ReferenceInput>
  );
};

export const BulkEditDevices = (props) => {
  const { resource = null } = props;
  const { selectedIds, resource: contextResource } = useListContext();
  const { permissions } = usePermissions();
  const unselectAll = useUnselectAll(contextResource);

  const [updateMany, { isLoading }] = useUpdateMany();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (value) => {
    updateMany(
      resource || contextResource,
      {
        ids: selectedIds,
        data: { update_action: 'bulk_update', ...value },
      },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
          unselectAll();
        },
      },
    );
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        disabled={isLoading}
        variant="outline"
        size="sm"
        className="text-black"
        type="button"
      >
        <Building2 className="w-4 h-4 mr-2" />
        Edit devices
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          onClose={handleClose}
          className={cn('min-w-[400px] overflow-y-auto max-h-[90vh]')}
        >
          <DialogHeader>
            <DialogTitle>Bulk edit devices</DialogTitle>
          </DialogHeader>
          <Form onSubmit={onSubmit}>
            <p className="text-muted-foreground">
              Only devices not assigned to patients can be edited
            </p>
            {get(permissions, 'is_internal') && (
              <ReferenceInput source="tenant_id" reference="providers">
                <AutocompleteInput
                  label="Provider"
                  optionText="name"
                  helperText={false}
                />
              </ReferenceInput>
            )}
            <DeviceLocationInput />
            <DialogFooter className="mt-4">
              <SaveButton variant="default" />
            </DialogFooter>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
