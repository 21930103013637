import React from 'react';
import { Card } from '@/modules/ui/components/card';

export const PatientProfileSection = ({
  title,
  description = null,
  action = null,
  children,
}) => {
  return (
    <div>
      <div className="mt-4 mb-2">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-medium leading-6 text-gray-900">
            {title}
          </h3>
          {action}
        </div>
        {description && (
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        )}
      </div>
      <Card>{children}</Card>
    </div>
  );
};
