import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { AlertCircle } from 'lucide-react';

function ErrorCard(props) {
  const { details } = props;
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="center" mt={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">An error has occurred!</Typography>
              <Typography variant="body1">{details && details}</Typography>
            </Grid>
            <Grid item xs={12}>
              <AlertCircle />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ErrorCard;
