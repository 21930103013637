export function renderAvailableNotesText(data) {
  let result = '';

  for (const item of data) {
    result += `Type: ${item.type}\n`;
    result += `Description: ${item.description}\n`;
    result += 'Items:\n';

    for (const subItem of item.items) {
      result += `- ${subItem}\n`;
    }

    result += '\n';
  }

  return result;
}
