import React, { useState } from 'react';
import { UpdateDialog } from './UpdateDialog';
declare const __VERSION_CHECK_INTERVAL__: number;

const CURRENT_VERSION = `${import.meta.env.VITE_GIT_SHA}`;

export const ShowUpdate = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const dialogTrigger = () => {
    setOpen(!open);
  };

  const checkVersion = async () => {
    try {
      const response = await fetch('/signature.json', {
        headers: { 'Cache-Control': 'no-cache' },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch version info');
      }
      const text = await response.text();
      const data = JSON.parse(text.trim());
      const latestVersion = data.version;

      if (CURRENT_VERSION && latestVersion !== CURRENT_VERSION) {
        setUpdateAvailable(true);
      }
    } catch (error) {
      console.error('Error checking version:', error);
    }
  };

  React.useEffect(() => {
    // Initial check
    checkVersion();

    // Only set up interval if no update was found
    let interval: NodeJS.Timeout;
    if (!updateAvailable) {
      interval = setInterval(() => {
        checkVersion();
      }, __VERSION_CHECK_INTERVAL__);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [updateAvailable]); // Re-run effect when updateAvailable changes

  const message = `A new version of Vironix is available.`;

  const handleUpdate = () => {
    window.location.reload();
  };

  return updateAvailable ? (
    <UpdateDialog
      state={open}
      message={message}
      onSubmit={handleUpdate}
      dialogTrigger={dialogTrigger}
    />
  ) : null;
};
