import React from 'react';
import { SelectInput, required } from 'react-admin';

import EditInDialog from '@/modules/ra-ui/components/edit-dialog';

const DeviceDecommissionDialog = ({ open, setOpen }) => {
  const transform = (data) => {
    return {
      patient_id: null,
      update_action: 'decommission_device',
    };
  };

  return (
    <EditInDialog
      title="Decommission device"
      resource="devices"
      variant="default"
      notifyMessage="Device decommissioned."
      transform={transform}
      open={open}
      setOpen={setOpen}
    >
      <div className="flex flex-col">
        <SelectInput
          source="decommission_type"
          label="Reason"
          choices={[
            { id: 'decommission', name: 'Decommission device' },
            {
              id: 'pending_return_to_manufacturer',
              name: 'Pending return to manufacturer',
            },
            {
              id: 'returned_to_manufacturer',
              name: 'Returned to manufacturer',
            },
          ]}
          helperText={false}
          validate={required()}
        />
      </div>
    </EditInDialog>
  );
};

export default DeviceDecommissionDialog;
