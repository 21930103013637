import React from 'react';
import Heartbeat from 'healthicons-react/dist/outline/Heartbeat';

export const MetricsEmpty = () => {
  return (
    <div className="flex flex-col space-y-4 items-center justify-center">
      <p className="text-2xl font-bold">None reported</p>
      <p className="text-base text-center">
        Measurements and metrics will appear here once a patient uses their
        devices for the first time.
      </p>
      <Heartbeat className="w-16 h-16" />
    </div>
  );
};
