import RangeField from '@/modules/ra-ui/components/RangeField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import inflection from 'inflection';
import React from 'react';
import {
  Datagrid,
  DateInput,
  DeleteButton,
  FunctionField,
  ListContextProvider,
  Pagination,
  SelectInput,
  TextInput,
  required,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { ICD10Input } from '../../../icd10/components/ICD10Input';
import { Badge } from '@/modules/ui/components/badge';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { Button } from '@/modules/ui/components/button';
import { Pencil } from 'lucide-react';
import { formatICD10Code } from '@/modules/icd10/utils/formatICD10Code';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { get } from 'lodash';

const problemStatus = [
  { id: 'active', name: 'Active' },
  { id: 'recurrence', name: 'Recurrence' },
  { id: 'relapse', name: 'Relapse' },
  { id: 'inactive', name: 'Inactive' },
  { id: 'remission', name: 'Remission' },
  { id: 'resolved', name: 'Resolved' },
  { id: 'entered-in-error', name: 'Entered in Error' },
  { id: 'event', name: 'Event' },
];

export function ConditionEdit() {
  const record = useRecordContext();

  if (record.source !== 'vironix') {
    return (
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger asChild className="z-50">
            <span tabIndex={0}>
              <Button
                disabled
                size="sm"
                className="h-8 pointer-events-none"
                variant="link"
              >
                <Pencil className="mr-2 h-4 w-4 fill-primary " />
              </Button>
            </span>
          </TooltipTrigger>
          <TooltipContent>
            <p>Can only edit encounters sourced from Vironix</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  const transform = (data) => {
    return {
      ...data,
    };
  };
  return (
    <EditInDialogButton
      title="Edit patient condition"
      resource={`patients/${record?.patient_id}/conditions`}
      transform={transform}
      fullWidth
      label="Condition"
      notifyMessage="Condition updated"
      refreshOnSuccess
      editButton={
        <Button
          variant="ghost"
          size="sm"
          className="text-sm h-8  hover:bg-gray-300"
        >
          <Pencil className="w-4 h-4 fill-blue-600 stroke-blue-600" />
        </Button>
      }
      deleteButton={
        <DeleteButton redirect={false} confirmTitle="Delete condition?" />
      }
    >
      <div className="grid grid-col-4 gap-0">
        <div className="flex flex-row gap-2">
          <DateInput
            source="start_date"
            parse={(v) => new Date(v).toISOString().split('T', 1)[0]}
            validate={required()}
            helperText={false}
          />
          <DateInput
            source="end_date"
            helperText={false}
            parse={(v) => new Date(v).toISOString().split('T', 1)[0]}
          />
        </div>
        <SelectInput
          source="status"
          choices={problemStatus}
          validate={required()}
          helperText={false}
          defaultValue="active"
        />
        <ICD10Input validate={[required()]} source="additional_info.icd10_id" />
        <TextInput
          source="additional_info.notes"
          label="Notes"
          helperText={false}
        />
      </div>
    </EditInDialogButton>
  );
}

export function ConditionCreate() {
  const record = useRecordContext();

  const transform = (data) => {
    return {
      ...data,
      patient_id: record.id,
      source: 'vironix',
      category: 'diagnosis',
    };
  };
  return (
    <CreateInDialogButton
      title="Add patient condition"
      resource={`patients/${record?.id}/conditions`}
      transform={transform}
      fullWidth
      label="Condition"
      notifyMessage="Condition created"
      refreshOnSuccess
      saveAndAddAnother
      saveAndAddAnotherClearFields={['additional_info.icd10_id', 'notes']}
    >
      <div className="grid grid-col-4 gap-0">
        <SelectInput
          source="status"
          choices={problemStatus}
          validate={required()}
          helperText={false}
          defaultValue="active"
        />
        <ICD10Input validate={[required()]} source="additional_info.icd10_id" />
        <TextInput
          source="additional_info.notes"
          label="Notes"
          helperText={false}
        />
        <div className="flex flex-row gap-2">
          <DateInput
            source="start_date"
            parse={(v) => new Date(v).toISOString().split('T', 1)[0]}
            helperText={false}
          />
          <DateInput
            source="end_date"
            helperText={false}
            parse={(v) => new Date(v).toISOString().split('T', 1)[0]}
          />
        </div>
      </div>
    </CreateInDialogButton>
  );
}

export function ConditionsDataGrid() {
  const record = useRecordContext();

  const [sort, setSort] = React.useState({
    field: 'start_date',
    order: 'DESC' as any,
  });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [filterValues, setFilters] = React.useState({});

  const { data, total, isLoading } = useGetList(
    `patients/${record?.id}/conditions`,
    {
      pagination: { page, perPage },
      filter: filterValues,
      sort: sort,
    },
  );
  if (!record) {
    return null;
  }

  return (
    <div>
      <ListContextProvider
        // @ts-ignore
        value={{ filterValues, setFilters, displayedFilters: {} }}
      >
        <Datagrid
          data={data}
          total={total}
          isLoading={isLoading}
          sort={sort}
          bulkActionButtons={false}
          setSort={setSort}
          empty={
            <EmptyDatagrid
              resourceName="Conditions"
              textFormatString="No conditions found for patient"
            />
          }
          // expand={<ExpandedRow />}
          expandSingle
          rowClick="expand"
        >
          <RangeField
            source_start="start_date"
            source_end="end_date"
            sortBy="start_date"
            label="Date"
          />
          <FunctionField
            render={(record) =>
              get(record, 'additional_info.icd10_id') ? (
                <div className="flex flex-row gap-2 items-center">
                  <Badge variant="outline">
                    {formatICD10Code(get(record, 'additional_info.icd10_id'))}
                  </Badge>
                  <span className=" overflow-hidden overflow-ellipsis">
                    {record.name}
                  </span>
                </div>
              ) : (
                <span>{record.name && inflection.humanize(record.name)}</span>
              )
            }
            label="Description"
          />
          <BadgeField source="category" />
          <BadgeField source="source" />
          <ConditionEdit />
        </Datagrid>
        {data && total > perPage ? (
          <Pagination
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        ) : null}
      </ListContextProvider>
    </div>
  );
}

export default function ConditionsSection() {
  return (
    <div className="flex flex-col space-y-2 mt-4">
      <div className="flex flex-row space-x-1 justify-between items-center ">
        <div>
          <h3 className="text-xl font-semibold">Conditions</h3>
          <p className="text-sm text-gray-500">
            Conditions describes a clinical condition, problem, diagnosis, or
            other event, situation, issue, or clinical concept that has risen to
            a level of concern.
          </p>
        </div>
        <ConditionCreate />
      </div>
      <ConditionsDataGrid />
    </div>
  );
}
