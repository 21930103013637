import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/modules/ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { cn } from '@/modules/ui/utils/cn';
import { debounce } from 'lodash';
import { CheckIcon, Search } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { useGetList, usePermissions } from 'react-admin';

export default function ProviderSelector({ value, onChange }) {
  const { permissions } = usePermissions();

  if (permissions && !!!permissions.is_internal) return null;

  return <ProviderSelectorMain value={value} onChange={onChange} />;
}

function ProviderSelectorMain({ value, onChange }) {
  const perPage = 10;
  const [page] = useState(1);
  const [providerNameSearch, setProviderNameSearch] = useState(null);

  const delayedUpdate = useRef(
    debounce((q) => setProviderNameSearch(q), 500),
  ).current;

  const { data, total } = useGetList(`providers`, {
    pagination: { page, perPage },
    filter: {
      ...(providerNameSearch &&
        providerNameSearch.length > 1 && {
          q: { field: 'name', value: providerNameSearch },
        }),
    },
    sort: { field: 'name', order: 'DESC' },
  });

  const options = data?.map((provider) => ({
    label: provider.name,
    value: provider.id,
  }));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          {value ? (
            <>
              <div className="hidden space-x-1 lg:flex">
                {options
                  ? options
                      .filter((option) => value === option.value)
                      .map((option) => (
                        <Badge
                          variant="secondary"
                          key={option.value}
                          className="rounded-sm px-1 font-normal"
                        >
                          {option.label}
                        </Badge>
                      ))
                  : null}
              </div>
            </>
          ) : (
            <>All providers</>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <div className="flex items-center border-b px-3">
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
              className={cn(
                'flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
              )}
              placeholder="Search Providers"
              onChange={(e) => {
                delayedUpdate(e.target.value);
              }}
              defaultValue={providerNameSearch}
            />
          </div>
          <CommandList>
            {total === 0 ? (
              <CommandEmpty>No results found.</CommandEmpty>
            ) : null}
            <CommandGroup>
              {options &&
                options.map((option) => {
                  const isSelected = value === option.value;
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => {
                        onChange(isSelected ? undefined : option.value);
                      }}
                      value={option.value}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-lg border border-primary',
                          isSelected
                            ? 'bg-primary text-primary-foreground'
                            : 'opacity-50 [&_svg]:invisible',
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <span>{option.label}</span>
                      <span className="invisible">{option.value}</span>
                    </CommandItem>
                  );
                })}
            </CommandGroup>
            {value && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => onChange(undefined)}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
