import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useController, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';

export const MedicationSearchInput = () => {
  const dataProvider = useDataProvider();
  const [medicationSearch, setMedicationSearch] = useState('');
  const field = useController({
    name: 'medication',
    rules: { required: true },
  });

  const watchSelf = useWatch({
    name: 'medication',
  });

  // Preload search with watchSelf.name if it exists and medicationSearch is empty
  useEffect(() => {
    if (!medicationSearch && watchSelf) {
      setMedicationSearch(watchSelf);
    }
  }, [watchSelf, medicationSearch]);

  const { data: medicationResult, isLoading } = useQuery(
    ['medicationsNames', medicationSearch],
    () =>
      dataProvider.getCustom(`medications/search`, {
        meta: { q: medicationSearch },
      }),
    {
      enabled: !!medicationSearch,
    },
  );
  const searchMedications = (searchText) => {
    if (searchText && searchText.length >= 3) {
      setMedicationSearch(searchText);
    }
  };

  const choices = medicationResult
    ? medicationResult.data.map((foundMedication) => {
        return {
          name: foundMedication.name,
          codes: [
            {
              code: foundMedication.rxcui,
              codeSystem: '2.16.840.1.113883.6.88',
              codeSystemName: 'RxNorm',
            },
          ],
        };
      })
    : [];

  return (
    <div className="mb-2">
      <Autocomplete
        {...field.field}
        onChange={(e, value) => {
          field.field.onChange(value);
        }}
        options={choices}
        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          searchMedications(e?.target?.value)
        }
        loading={isLoading}
        renderInput={(params) => <TextField {...params} label="Medication" />}
        getOptionLabel={(option: any) => option.name}
      />
    </div>
  );
};
