import React from 'react';
import { useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { LuxonDateField } from './LuxonDateField';

function RangeField({
  source_start,
  source_end,
  showTime = false,
  sortBy = null,
  label = '',
}) {
  const record = useRecordContext();

  return (
    <span>
      <LuxonDateField source={source_start} showTime={showTime} />
      {get(record, source_end) && ' - '}
      <LuxonDateField source={source_end} showTime={showTime} />
    </span>
  );
}

export default RangeField;
