import { Chip } from '@mui/material';
import { get, find } from 'lodash';
import React from 'react';
import { useRecordContext } from 'react-admin';

const MappedChipField = ({ source, mapping, ...rest }) => {
  const record = useRecordContext();

  const value = get(record, source);
  const mappedValue =
    find(mapping, { id: value }).value ||
    find(mapping, { id: value }).name ||
    value;

  return <Chip {...rest} label={mappedValue} />;
};

export default MappedChipField;
