import React from 'react';
import { useOrganization } from '../../../organizations/components/OrganizationProvider';
import { cn } from '@/modules/ui/utils/cn';
import { ChevronsLeft, ChevronsRight } from 'lucide-react';
import { Button } from '@/modules/ui/components/button';
import { useSidebarState } from '../hooks/useSidebarState';
import { ReactComponent as Logo } from '@/modules/layout/components/Logo.svg';
import { useIsDemo } from '@/modules/demo/components/DemoProvider';
import { Badge } from '@/modules/ui/components/badge';

export function SidebarLogo() {
  const { organization } = useOrganization();
  const [sidebarOpen, setSidebarOpen] = useSidebarState();
  const { isDemo } = useIsDemo();

  if (sidebarOpen) {
    return (
      <div className="flex flex-col w-full">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="ml-auto"
        >
          <ChevronsLeft className="w-4 h-4" />
        </Button>

        <div className={cn('flex flex-row justify-start items-center gap-1 ')}>
          <Logo className="object-cover h-8 w-8 p-1" />
          <p className="text-sm">
            {organization ? organization.name : 'Vironix Health'}
          </p>
        </div>
        {isDemo && (
          <Badge className="bg-yellow-500 text-center w-fit mx-auto hover:bg-yellow-500">
            Demo Mode
          </Badge>
        )}
      </div>
    );
  }

  if (!sidebarOpen) {
    return (
      <div className="flex flex-col ">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className={cn({ 'mx-auto': sidebarOpen })}
        >
          <ChevronsRight className="w-4 h-4" />
        </Button>
        <Logo className="object-cover h-8 w-8 p-1 mx-auto" />
      </div>
    );
  }
}
