import { DateTime } from 'luxon';
import React from 'react';
import {
  Form,
  SelectInput,
  required,
  useDataProvider,
  useRecordContext,
  useNotify,
} from 'react-admin';
import { Button } from '@/modules/ui/components/button';
import { Loader2, FileDown } from 'lucide-react';

export const ExportPatientDataButton = () => {
  const record = useRecordContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onExport = (values) => {
    setIsLoading(true);
    dataProvider
      .getFile(`patients/${record.id}/export`, {
        meta: {
          start_date: values.start_date,
        },
        download: 'export.pdf',
        downloadType: 'application/pdf',
      })
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => {
        setIsLoading(false);
        notify('Export successful', { type: 'success' });
      })
      .catch(() => {
        notify('Error exporting patient data', { type: 'error' });
        setIsLoading(false);
      });
  };

  // Create entries for the first of each month since registration date
  const generateMonthChoices = () => {
    const registrationDate = DateTime.fromISO(record.user.registered_on);

    const today = DateTime.now().startOf('month');

    let choices = [];
    let diffInMonths = today.diff(registrationDate, 'months').months;

    for (let i = 0; i < diffInMonths; i++) {
      const month = registrationDate.startOf('month').plus({ months: i });
      choices.push({
        id: month.toISODate(),
        // show as month - year
        name: month.toLocaleString({ month: 'long', year: 'numeric' }),
      });
    }

    choices.push({
      id: today.toISODate(),
      name: today.toLocaleString({ month: 'long', year: 'numeric' }),
    });
    return choices;
  };

  if (!record) {
    return null;
  }

  return (
    <Form
      onSubmit={onExport}
      //   defaultValues={{ tenant_id: get(filterValues, 'tenant_id[eq]') }}
    >
      <div className="flex flex-row space-x-2 items-center">
        <SelectInput
          source="start_date"
          label="Select month"
          choices={generateMonthChoices()}
          helperText={false}
          validate={required()}
        />
        <Button disabled={isLoading} className="h-8" type="submit">
          {isLoading ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <>
              <FileDown className="mr-2 h-4 w-4" />
              Export
            </>
          )}
        </Button>
      </div>
    </Form>
  );
};
