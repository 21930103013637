import appIcon from './app_icon.png';
import googleIcon from './google_icon.png';
import web from './web.png';
import email from './email.png';
import security from './security.svg';

import screenshot_iphone_0 from './screenshot_iphone_0.png';
import screenshot_iphone_1 from './screenshot_iphone_1.png';
import screenshot_iphone_2 from './screenshot_iphone_2.png';
import screenshot_iphone_3 from './screenshot_iphone_3.png';

import screenshot_android_0 from './screenshot_android_0.png';
import screenshot_android_1 from './screenshot_android_1.png';
import screenshot_android_2 from './screenshot_android_2.png';
import screenshot_android_3 from './screenshot_android_3.png';

const iphoneScreenhosts = [
  screenshot_iphone_0,
  screenshot_iphone_1,
  screenshot_iphone_2,
  screenshot_iphone_3,
];

const androidScreenshots = [
  screenshot_android_0,
  screenshot_android_1,
  screenshot_android_2,
  screenshot_android_3,
];

export {
  appIcon,
  iphoneScreenhosts,
  googleIcon,
  androidScreenshots,
  web,
  email,
  security,
};
