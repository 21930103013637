import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    config: any;
    Featurebase: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const config = window.config; // ok now

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Print app version
console.log(
  'Vironix Front',
  import.meta.env.PACKAGE_VERSION,
  import.meta.env.VITE_GIT_SHA,
);
