import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { cptCodeInfo } from '@/modules/patients/constants/cptCodeInfo';
import { Progress } from '@/modules/ui/components/progress';
import { AlertTriangle } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import { Identifier, useGetList } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { get } from 'lodash';
import { Separator } from '@/modules/ui/components/separator';
import { checkDeviceMeasurements } from '../util/checkDeviceMeasurements';

function ProgressBar(props) {
  const { service } = props;
  const codeInfo = get(cptCodeInfo, service.cpt_code);

  if (!service) {
    return null;
  }

  const primaryText = () => {
    let primaryTextVar = '';
    if (codeInfo.type === 'days') {
      primaryTextVar = `${Math.min(
        Math.round(service.value),
        service.max_value,
      )} / ${Math.round(service.max_value)} ${
        codeInfo.units ? `(${codeInfo.units})` : ''
      }`;
    } else if (codeInfo.type === 'time') {
      primaryTextVar = `${Math.round(service.value / 60)} / ${Math.round(
        service.max_value / 60,
      )} ${codeInfo.units ? `(${codeInfo.units})` : ''}`;
    }
    return primaryTextVar;
  };

  return (
    <div>
      <div className="flex flex-col gap-1">
        <div>{primaryText()}</div>
        <div>
          <Progress value={service.progress * 100} className="h-2.5" />
        </div>
      </div>
    </div>
  );
}

const incompatibleCodes = {
  rpm_99091: '99457',
  rpm_99457_99458: '99091',
  ccm_99490_99439: '99491',
  ccm_99491_99437: '99490',
  pcm_99424_99425: '99426',
  pcm_99426_99427: '99424',
};

interface IncompatibleBillingWarningProps {
  patientId: Identifier | null;
  hasDevice: boolean | null;
  lastDeviceMeasurement: string | null;
  className?: string;
}

function IncompatibleBillingWarning(props: IncompatibleBillingWarningProps) {
  const { patientId, hasDevice, lastDeviceMeasurement, className = '' } = props;
  const startDate = DateTime.now().minus({ days: 30 }).toISODate();

  const monitoringType = useWatch({ name: 'type' });
  const description = useWatch({ name: 'description' });

  const { data } = useGetList('services', {
    pagination: { page: 1, perPage: 10 },
    filter: {
      'patient_id[eq]': patientId,
      'start_date[gte]': startDate,
    },
  });

  if (monitoringType) {
    const incompatibleCode = incompatibleCodes[monitoringType];
    const incompatibleService = data?.find(
      (service: any) =>
        service.cpt_code === incompatibleCode && service.progress > 0,
    );

    const warnings = [];

    if (
      patientId &&
      hasDevice !== null &&
      !hasDevice &&
      monitoringType.startsWith('rpm')
    ) {
      warnings.push(
        <Alert variant="warning" key="no-device">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>No Device Assigned</AlertTitle>
          <AlertDescription>
            This patient currently has no devices assigned.
          </AlertDescription>
        </Alert>,
      );
    }

    const deviceCheck = checkDeviceMeasurements(
      hasDevice,
      lastDeviceMeasurement,
    );

    if (
      deviceCheck.hasWarning &&
      deviceCheck.warningType === 'no-measurements' &&
      monitoringType.startsWith('rpm')
    ) {
      warnings.push(
        <Alert variant="warning" key="no-measurements">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>No Device Measurements</AlertTitle>
          <AlertDescription>
            No device measurements have been received yet.
          </AlertDescription>
        </Alert>,
      );
    } else if (
      deviceCheck.hasWarning &&
      deviceCheck.warningType === 'stale-measurements' &&
      monitoringType.startsWith('rpm')
    ) {
      warnings.push(
        <Alert variant="warning" key="stale-measurements">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Stale Device Measurements</AlertTitle>
          <AlertDescription>
            No device measurements have been received in over 7 business days.
          </AlertDescription>
        </Alert>,
      );
    }

    if (description && description.length > 0 && monitoringType === 'note') {
      warnings.push(
        <Alert variant="warning" key="note-only">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Note only mode</AlertTitle>
          <AlertDescription>
            This note will not count as billable time.
          </AlertDescription>
        </Alert>,
      );
    }

    if (monitoringType && incompatibleService) {
      warnings.push(
        <Alert variant="warning" key="incompatible">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Potential incompatible work</AlertTitle>
          <AlertDescription>
            The type of work potentially conflicts with an existing service.
            Both may not be billed in the same calendar month.
          </AlertDescription>
          <Separator />
          <AlertDescription className="mt-1 text-gray-500 flex flex-col gap-1">
            <div>
              {incompatibleService.start_date} - {incompatibleService.end_date}
            </div>
            <div>{incompatibleService.cpt_code}</div>
            <div>
              <ProgressBar service={incompatibleService} />
            </div>
          </AlertDescription>
        </Alert>,
      );
    }

    if (warnings.length > 0) {
      return (
        <div className={`${className} flex flex-col gap-2`}>{warnings}</div>
      );
    }
  }

  return null;
}

export default IncompatibleBillingWarning;
