import { Button } from '@/modules/ui/components/button';
import React from 'react';
import { Loader2, RefreshCw } from 'lucide-react';
import { useCreate, useRefresh, useRecordContext } from 'ra-core';
import { useNotify } from 'ra-core';
import { get } from 'lodash';

const RefreshSubscriptionButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();

  const handleRefresh = () => {
    create(
      `billing/${record?.id}/refresh-subscription/`,
      {
        data: {},
      },
      {
        onSuccess: () => {
          refresh();
        },
        onError: (error) => {
          notify(`${get(error, 'message')}`, { type: 'error' });
        },
      },
    );
  };

  return (
    <Button onClick={handleRefresh} className="h-8" disabled={isLoading}>
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Please wait
        </>
      ) : (
        <>
          <RefreshCw className="h-4 w-4 mr-2" />
          Refresh
        </>
      )}
    </Button>
  );
};

export default RefreshSubscriptionButton;
