import { Button } from '@/modules/ui/components/button';

import { Stethoscope } from 'lucide-react';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  required,
  useCreate,
  useListContext,
  useRefresh,
  useUnselectAll,
  useNotify,
} from 'react-admin';

import { useOrganization } from '@/modules/organizations/components/OrganizationProvider';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { FormProvider, useForm } from 'react-hook-form';
import { careTeamRoles } from '../constants/careTeamRoles';

const filterUserName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

export function BulkAddToCareTeam() {
  const { selectedIds, resource: contextResource } = useListContext();
  const unselectAll = useUnselectAll(contextResource);
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const [create, { isLoading }] = useCreate();
  const refresh = useRefresh();

  const { organization } = useOrganization();

  const form = useForm<Object>({
    defaultValues: {},
  });

  let filters: any = {
    'role[ne]': `patient`,
    'is_provider[eq]': true,
  };

  if (organization) {
    filters = {
      ...filters,
      'tenant_id[eq]': organization.id,
    };
  }

  const onSubmit = (value) => {
    const data = {
      ...value,
      start_date: new Date().toISOString(),
    };

    selectedIds.forEach((id) => {
      create(`patients/${id}/care-teams`, {
        data: data,
      });
    });

    refresh();
    unselectAll();
    notify('Successfully added clinician to care team.');
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outline"
        size="sm"
        className="text-black"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Stethoscope className="w-4 h-4 mr-2" />
        Add Clinician
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogHeader>
            <DialogTitle>Add Clinician to Care Teams</DialogTitle>
            <DialogDescription>
              This will add the following clinician to the care team of all
              selected patients.
            </DialogDescription>
          </DialogHeader>

          <FormProvider {...form}>
            <ReferenceInput
              source="practitioner_id"
              reference="users"
              filter={filters}
            >
              <AutocompleteInput
                label="Clinician"
                optionText={(choice) => {
                  if (choice.is_internal) {
                    return `${choice.first_name} ${choice.last_name} (Vironix) ${
                      choice.credentials ? `(${choice.credentials})` : ``
                    }`;
                  }
                  return `${choice.full_name} ${
                    choice.credentials ? `(${choice.credentials})` : ``
                  }`;
                }}
                parse={(v) => (v === '' ? null : v)}
                helperText={false}
                validate={required()}
                filterToQuery={filterUserName}
              />
            </ReferenceInput>
            <SelectInput
              source="role"
              choices={careTeamRoles.filter(
                (role) => role.canBeSelected !== false,
              )}
              optionText="value"
              helperText={false}
              validate={required()}
            />
            <BooleanInput
              source="clear_existing"
              label="Clear existing clinician"
              helperText="If checked, the existing clinician will be removed from the care team before adding the new clinician."
            />

            <DialogFooter>
              <Button
                disabled={isLoading}
                onClick={form.handleSubmit(onSubmit)}
              >
                Add
              </Button>
            </DialogFooter>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
