import { Button } from '@/modules/ui/components/button';
import { DateTime } from 'luxon';
import React from 'react';
import {
    useNotify,
    useRecordContext,
    useRefresh,
    useUpdate,
} from 'react-admin';
import { InfoBlock } from '../../components/InfoBlock';

export const MobileAppRejectedSection = () => {
  const record = useRecordContext();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleToggleRejection = () => {
    const isCurrentlyRejected = !!record.rejected_mobile_app_on;
    const newData = isCurrentlyRejected
      ? { rejected_mobile_app_on: null }
      : { rejected_mobile_app_on: DateTime.utc().toISO() };

    update(
      'patients',
      { id: record.id, data: newData },
      {
        onSuccess: () => {
          refresh();
          notify(
            isCurrentlyRejected
              ? 'Mobile app rejection cleared'
              : 'Patient marked as rejected for mobile app',
            { type: 'success' },
          );
        },
        onError: (error: any) => {
          notify(`Error: ${error?.message}`, { type: 'error' });
        },
      },
    );
  };

  const isRejected = !!record.rejected_mobile_app_on;

  return (
    <InfoBlock
      title="Mobile app rejected"
      component={
        <div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex flex-row items-center ">
              {isRejected ? (
                <>
                  <span className="font-semibold">Yes</span>
                  <span className="ml-2 text-gray-500 text-nowrap">
                    (
                    {DateTime.fromISO(record.rejected_mobile_app_on, {
                      zone: 'utc',
                    })
                      .toLocal()
                      .toLocaleString(DateTime.DATETIME_SHORT)}
                    )
                  </span>
                </>
              ) : (
                <>
                  <span className="font-semibold">No</span>
                </>
              )}
            </div>
            <Button onClick={handleToggleRejection} variant="outline" size="sm">
              {isRejected ? 'Clear' : 'Mark Rejected'}
            </Button>
          </div>
        </div>
      }
    />
  );
};
