import {
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  Pagination,
  ReferenceField,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { ColoredChipField } from '../../../ra-ui/components/ColoredChipField';
import { LuxonDateField } from '../../../ra-ui/components/LuxonDateField';
import { cptCodeInfo } from '../../constants/cptCodeInfo';
import { serviceInstanceTypesAbbreviated } from '../../constants/serviceInstanceTypesAbbreviated';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CPTCodeField } from '@/modules/ra-ui/components/CPTCodeField';
import { ExportPatientDataButton } from '@/modules/patients/components/ExportPatientDataButton';
import { ServiceInstanceDataGrid } from '@/modules/patients/components/ServiceInstanceCreate';
import { EditClaimItemButton } from '@/modules/claims/components/EditClaimItem';

export function ServiceProgressField(props) {
  const record = useRecordContext();
  const codeInfo = get(cptCodeInfo, record.cpt_code);

  if (!record) {
    return null;
  }

  const primaryText = () => {
    let primaryTextVar = '';
    if (codeInfo.type === 'days') {
      primaryTextVar = `${Math.min(
        Math.round(record.value),
        record.max_value,
      )} / ${Math.round(record.max_value)} ${
        codeInfo.units ? `(${codeInfo.units})` : ''
      }`;
    } else if (codeInfo.type === 'time') {
      primaryTextVar = `${Math.round(record.value / 60)} / ${Math.round(
        record.max_value / 60,
      )} ${codeInfo.units ? `(${codeInfo.units})` : ''}`;
    }
    return primaryTextVar;
  };

  return (
    <ListItem sx={{}}>
      <Grid container justifyContent="flex-start" direction="column">
        <Grid item>
          <ListItemText primary={primaryText()} />
        </Grid>
        <Grid item>
          <Box>
            <LinearProgress
              variant="determinate"
              value={record.progress * 100}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
}

const servicerRangeFilterOptions = ['Show last 30 days', 'Show all'];

const ServiceRangeFilter = ({ filterValues, setFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const ThirtyDaysAgo = DateTime.now().minus({ days: 30 }).toISODate();

  const [selectedIndex, setSelectedIndex] = React.useState(
    get(filterValues, 'start_date:gte') === ThirtyDaysAgo ? 0 : 1,
  );
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    if (index === 0) {
      setFilters({
        ...filterValues,
        'start_date:gte': ThirtyDaysAgo,
      });
    } else if (index === 1) {
      setFilters({
        ...filterValues,
        'start_date:gte': null,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" aria-label="Services filter">
        <ListItemButton
          id="service-filter"
          aria-haspopup="listbox"
          aria-controls="services-filter"
          aria-label="filter range of services"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          dense
        >
          <ListItemText secondary={servicerRangeFilterOptions[selectedIndex]} />
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItemButton>
      </List>
      <Menu
        id="services-filter"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {servicerRangeFilterOptions.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

function ServiceListDataGrid(props) {
  const record = useRecordContext();
  const startDate = DateTime.now().minus({ days: 30 }).toISODate();

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(25);
  const [filterValues, setFilters] = React.useState({
    'start_date:gte': startDate,
  });

  const { data, total, isLoading } = useGetList('services', {
    pagination: { page, perPage },
    filter: {
      'patient_id[eq]': record?.id,
      ...Object.fromEntries(
        Object.entries(filterValues).filter(([_, v]) => v != null),
      ),
    },
    sort,
  });

  return (
    <>
      <Datagrid
        bulkActionButtons={false}
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        setSort={setSort}
      >
        <LuxonDateField source="start_date" zone="utc" />
        <LuxonDateField source="end_date" zone="utc" />
        <LuxonDateField source="date" label="Date completed" zone="utc" />
        <ColoredChipField source="status" />
        <CPTCodeField source="cpt_code" label="CPT code" />
        <ServiceProgressField source="progress" />
        <BooleanField source="claimable" />
        <BooleanField
          source="patient_communication_performed"
          emptyText="N/A"
        />
        <ReferenceField
          source="service_instance_id"
          reference="service-instances"
          label="Care Program Type"
        >
          <FunctionField
            render={(record) =>
              `${get(
                serviceInstanceTypesAbbreviated,
                record.type,
                record.type,
              )}`
            }
          />
        </ReferenceField>
      </Datagrid>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ServiceRangeFilter
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <Pagination
          total={total}
          perPage={perPage}
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      </Stack>
    </>
  );
}

function ClaimItemsListDataGrid(props) {
  const { record } = props;

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList('claim-items', {
    pagination: { page, perPage },
    filter: { 'patient_id[eq]': record?.id },
    sort,
  });

  if (!record) {
    return null;
  }

  return (
    <>
      <Datagrid
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        bulkActionButtons={false}
        setSort={setSort}
      >
        <DateField source="date_of_service" />
        <CPTCodeField label="CPT Code" source="cpt_code" />
        <NumberField source="units" label="Units" />
        <BooleanField source="submitted" label="Submitted" />
        <EditClaimItemButton />
      </Datagrid>
      <Pagination
        total={total}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
      />
    </>
  );
}

export default function ServicesSection(props) {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 my-4">
      <div className="flex flex-col space-y-2">
        <div>
          <h3 className="text-xl font-semibold">Services</h3>
          <p className="text-sm text-gray-500">Services rendered to patient.</p>
        </div>
        {/* <ServiceListActions record={record} /> */}
        <ServiceListDataGrid record={record} />
      </div>
      <div className="flex flex-col space-y-2">
        <div>
          <h3 className="text-xl font-semibold">Claims</h3>
          <p className="text-sm text-gray-500">
            Qualified claims for services rendered to patient.
          </p>
        </div>
        <ClaimItemsListDataGrid record={record} />
      </div>

      <div className="flex flex-col space-y-2">
        <div>
          <h3 className="text-xl font-semibold">Care Programs</h3>
          <p className="text-sm text-gray-500">
            Care programs are the virtual care services that are provided to the
            patient.
          </p>
        </div>
        <ServiceInstanceDataGrid />
      </div>
      <div className="flex flex-col space-y-2 pb-8">
        <div>
          <h3 className="text-xl font-semibold">Export patient data</h3>
          <p className="text-sm text-gray-500">
            Need to download a detailed report of patient services?
          </p>
        </div>
        <ExportPatientDataButton />
      </div>
    </div>
  );
}
