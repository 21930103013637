import { deviceTypes } from '@/modules/devices/constants/deviceTypes';
import { get } from 'lodash';
import React, { FC } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  EditBase,
  Form,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  email,
  maxLength,
  minLength,
  required,
  useCreate,
  useNotify,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import AppTitle from '@/modules/layout/components/app-title';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import stateCodes from '@/modules/ra-ui/constants/states.json';
import { Card, CardContent, CardFooter } from '@/modules/ui/components/card';
import ConfirmActionButton from '@/modules/ui/components/confirm-action-button';
import { Separator } from '@/modules/ui/components/separator';
import { SidebarNav } from '@/modules/ui/components/sidebar-nav';
import inflection from 'inflection';
import { Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { BillingSection } from '../../modules/organization-settings/components/BillingSection';

import { getTimeZones } from '@vvo/tzdb';
import { Alert, AlertDescription } from '@/modules/ui/components/alert';

const timeZones = getTimeZones();

const filterUserName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

const OrganizationName = (props) => {
  const record = useRecordContext();

  if (!record) return null;
  return <p className="text-xl font-semibold mb-2">{record.name}</p>;
};

const sidebarNavItems = [
  {
    title: 'General',
    href: 'general',
    default: true,
  },
  {
    title: 'Billing',
    href: 'billing',
  },
  {
    title: 'Device Monitoring',
    href: 'device-monitoring',
  },
  {
    title: 'AI Summary',
    href: 'ai-summary',
  },
];

const GeneralSection = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [create, { isLoading }] = useCreate();
  const notify = useNotify();

  const offboardCustomer = () => {
    create(
      `providers/${record?.id}/offboard`,
      { data: {} },
      {
        onSuccess: (data) => {
          notify(`${data.message}`, { type: 'success' });
          navigate('/providers');
        },
        onError: (error) => {
          notify(`Failed to offboard provider: ${get(error, 'message')}`, {
            type: 'error',
          });
        },
      },
    );
  };

  if (!record) return null;

  return (
    <div className="flex flex-col gap-4 mb-6">
      <Card>
        <Form warnWhenUnsavedChanges sanitizeEmptyValues>
          <CardContent className="mt-4">
            <div className="flex flex-row gap-1 items-center">
              <p className="text-sm ">Client since</p>
              <LuxonDateField
                source="registered_on"
                label="Registered on"
                className="!font-semibold"
              />
            </div>
            <div className="flex flex-1 space-x-4 items-center">
              <TextInput source="name" helperText={false} />
              <TextInput
                source="email"
                label="Primary Email"
                helperText={false}
                validate={[required(), email()]}
              />
              <NumberInput
                source="customer_size"
                label="Size (Estimated patient count)"
                helperText={false}
                validate={required()}
              />
              <TextInput
                source="npi_number"
                label="Organization NPI Number"
                validate={[
                  required(),
                  minLength(10, 'NPI number must be 10 digits'),
                  maxLength(10, 'NPI number must be 10 digits'),
                ]}
                helperText={false}
              />
            </div>

            <div className="flex flex-col mt-6">
              <div>
                <p className="text-md font-medium">Address </p>
              </div>
              <TextInput
                source="address.address_line1"
                label="Street Address"
                fullWidth
                helperText={false}
              />
              <TextInput
                source="address.address_line2"
                label="Apt, Suite, etc. (Optional)"
                fullWidth
                helperText={false}
              />
              <div className="flex flex-row gap-1">
                <TextInput
                  source="address.city"
                  label="City"
                  fullWidth
                  helperText={false}
                />
                <SelectInput
                  choices={stateCodes}
                  source="address.state"
                  label="State"
                  fullWidth
                  helperText={false}
                />
                <TextInput
                  source="address.zip_code"
                  label="Zip Code"
                  fullWidth
                  helperText={false}
                />
              </div>
              <SelectInput
                source="address.country"
                label="Country"
                fullWidth
                helperText={false}
                choices={[{ id: 'United States', name: 'United States' }]}
                defaultValue="United States"
              />
            </div>
            <div className="flex flex-col mt-6">
              <AutocompleteInput
                source="timezone"
                label="Timezone"
                helperText={false}
                validate={required()}
                choices={timeZones}
                optionValue="name"
                optionText="name"
              />
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>
      <Card>
        <Form warnWhenUnsavedChanges sanitizeEmptyValues>
          <CardContent className="mt-4">
            <div className="flex flex-col mt-6">
              <div>
                <p className="text-md font-medium">
                  Default clinicians assigned to patients
                </p>
                <p className="text-sm">
                  These clinicians will be assigned to new patient's care teams
                  by default.
                </p>
              </div>
              <div className="container m-auto grid grid-cols-2   ">
                <ReferenceInput
                  source="default_rpm_clinician_id"
                  reference="users"
                  filter={{
                    'role[ne]': `patient`,
                    'is_provider[eq]': true,
                    'tenant_id[in]': [get(record, 'id'), null],
                  }}
                >
                  <AutocompleteInput
                    label="Default RPM Clinician"
                    optionText={(choice) => {
                      if (choice.is_internal) {
                        return `${choice.first_name} ${choice.last_name} (Vironix) ${
                          choice.credentials ? `(${choice.credentials})` : ``
                        }`;
                      }
                      return `${choice.full_name} ${
                        choice.credentials ? `(${choice.credentials})` : ``
                      }`;
                    }}
                    parse={(v) => (v === '' ? null : v)}
                    sx={{ width: '250px' }}
                    helperText={false}
                    filterToQuery={filterUserName}
                  />
                </ReferenceInput>
                <ReferenceInput
                  source="default_rtm_clinician_id"
                  reference="users"
                  filter={{
                    'role[ne]': `patient`,
                    'is_provider[eq]': true,
                    'tenant_id[in]': [get(record, 'id'), null],
                  }}
                >
                  <AutocompleteInput
                    label="Default RTM Clinician"
                    optionText={(choice) => {
                      if (choice.is_internal) {
                        return `${choice.first_name} ${choice.last_name} (Vironix) ${
                          choice.credentials ? `(${choice.credentials})` : ``
                        }`;
                      }
                      return `${choice.full_name} ${
                        choice.credentials ? `(${choice.credentials})` : ``
                      }`;
                    }}
                    parse={(v) => (v === '' ? null : v)}
                    sx={{ width: '250px' }}
                    helperText={false}
                    filterToQuery={filterUserName}
                  />
                </ReferenceInput>
                <ReferenceInput
                  source="default_ccm_clinician_id"
                  reference="users"
                  filter={{
                    'role[ne]': `patient`,
                    'is_provider[eq]': true,
                    'tenant_id[in]': [get(record, 'id'), null],
                  }}
                >
                  <AutocompleteInput
                    label="Default CCM Clinician"
                    optionText={(choice) => {
                      if (choice.is_internal) {
                        return `${choice.first_name} ${choice.last_name} (Vironix) ${
                          choice.credentials ? `(${choice.credentials})` : ``
                        }`;
                      }
                      return `${choice.full_name} ${
                        choice.credentials ? `(${choice.credentials})` : ``
                      }`;
                    }}
                    parse={(v) => (v === '' ? null : v)}
                    sx={{ width: '250px' }}
                    helperText={false}
                    filterToQuery={filterUserName}
                  />
                </ReferenceInput>
                <ReferenceInput
                  source="default_pcm_clinician_id"
                  reference="users"
                  filter={{
                    'role[ne]': `patient`,
                    'is_provider[eq]': true,
                    'tenant_id[in]': [get(record, 'id'), null],
                  }}
                >
                  <AutocompleteInput
                    label="Default PCM Clinician"
                    optionText={(choice) => {
                      if (choice.is_internal) {
                        return `${choice.first_name} ${choice.last_name} (Vironix) ${
                          choice.credentials ? `(${choice.credentials})` : ``
                        }`;
                      }
                      return `${choice.full_name} ${
                        choice.credentials ? `(${choice.credentials})` : ``
                      }`;
                    }}
                    parse={(v) => (v === '' ? null : v)}
                    sx={{ width: '250px' }}
                    helperText={false}
                    filterToQuery={filterUserName}
                  />
                </ReferenceInput>
                <ReferenceInput
                  source="default_provider_success_id"
                  reference="users"
                  filter={{
                    'role[ne]': `patient`,
                    'tenant_id[in]': [get(record, 'id'), null],
                  }}
                >
                  <AutocompleteInput
                    label="Default Provider Success"
                    optionText="full_name"
                    parse={(v) => (v === '' ? null : v)}
                    sx={{ width: '250px' }}
                    helperText={false}
                    filterToQuery={filterUserName}
                  />
                </ReferenceInput>
              </div>

              <div className="flex flex-col mt-6">
                <div>
                  <p className="text-md font-medium">Internal Management</p>
                  <p className="text-sm">
                    Internal assignment of personnel to customer accounts.
                  </p>
                </div>
                <div className="container m-auto grid grid-cols-2   ">
                  <ReferenceInput
                    source="provider_success_id"
                    reference="users"
                    filter={{
                      'role[ne]': `patient`,
                      'tenant_id[eq]': null,
                    }}
                  >
                    <AutocompleteInput
                      label="Provider Success"
                      optionText="full_name"
                      parse={(v) => (v === '' ? null : v)}
                      sx={{ width: '250px' }}
                      helperText={false}
                      filterToQuery={filterUserName}
                    />
                  </ReferenceInput>
                  <ReferenceInput
                    source="sales_manager_id"
                    reference="users"
                    filter={{
                      'role[ne]': `patient`,
                      'tenant_id[eq]': null,
                    }}
                  >
                    <AutocompleteInput
                      label="Sales Manager"
                      optionText="full_name"
                      parse={(v) => (v === '' ? null : v)}
                      sx={{ width: '250px' }}
                      helperText={false}
                      filterToQuery={filterUserName}
                    />
                  </ReferenceInput>
                </div>
              </div>
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>
      {get(permissions, 'tenants.offboard') && (
        <Card>
          <Form warnWhenUnsavedChanges sanitizeEmptyValues>
            <CardContent className="mt-4">
              <div className="flex flex-col mt-6 gap-2">
                <div>
                  <p className="text-md font-medium">Offboard Customer</p>
                  <p className="text-sm">
                    Remove customer from the system. This action is
                    irreversible.
                  </p>
                </div>
                <div className="flex flex-row ">
                  <ConfirmActionButton
                    action={offboardCustomer}
                    actionText="Offboard"
                    confirmText="Offboard"
                    Icon={Trash2}
                    cancelText="Cancel"
                    dialogTitle="Are you sure you want to offboard this provider?"
                    dialogDescription="This will remove all data associated with this provider. This action cannot be undone."
                    isLoading={isLoading}
                    buttonVariant="destructive"
                    buttonType="button"
                  />
                </div>
              </div>
            </CardContent>
          </Form>
        </Card>
      )}
    </div>
  );
};

const AISummarySection = () => {
  return (
    <div className="flex flex-col gap-4 mb-6">
      <Card>
        <Form warnWhenUnsavedChanges sanitizeEmptyValues>
          <CardContent className="mt-4">
            <div>
              <p className="text-md font-medium">AI Summary</p>
              <p className="text-sm">
                Settings related to enabling AI summaries
              </p>
            </div>
            <div className="flex flex-1 space-x-4 items-center mt-6">
              <BooleanInput
                source="llm_summary_enabled"
                label="Enabled"
                helperText={false}
              />
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>
    </div>
  );
};

const DeviceMonitoringSection = () => {
  return (
    <div className="flex flex-col gap-4 mb-6">
      <Card>
        <Form warnWhenUnsavedChanges sanitizeEmptyValues>
          <CardContent className="mt-4">
            <Alert variant="warning" className="mb-2">
              <AlertDescription>
                This will change client set configurations. Do not alter without
                speaking to client.
              </AlertDescription>
            </Alert>
            <div className="flex flex-col gap-2">
              <p className="text-sm">
                The following rules will apply to all patients within your
                organization, unless you add patient-specific rules.
              </p>
              <p className="text-md font-semibold">
                Global device monitoring configuration
              </p>
              <p className="text-sm">
                The following rules will apply to all patients within your
                organization, unless you add patient-specific rules.
              </p>
              <p className="text-sm">
                An alert will be triggered when a parameter is outside the
                ranges specified. An empty value means no limit on that bound.
              </p>
              <ArrayInput source={`settings.monitoring_limits`}>
                <SimpleFormIterator inline>
                  <SelectInput
                    source="parameter"
                    choices={[
                      {
                        id: 'oxygen_saturation',
                        name: 'Oxygen Saturation',
                      },
                      { id: 'heart_rate', name: 'Heart Rate' },
                      { id: 'blood_pressure', name: 'Blood Pressure' },
                      { id: 'body_temperature', name: 'Temperature' },
                    ]}
                  />

                  <FormDataConsumer>
                    {({ scopedFormData, getSource, ...rest }) =>
                      scopedFormData.parameter &&
                      scopedFormData.parameter === 'blood_pressure' && (
                        <SelectInput
                          required
                          source={getSource('sub_parameter')}
                          parse={(v) => (v === '' ? null : v)}
                          choices={[
                            { id: 'systolic', name: 'Systolic' },
                            { id: 'diastolic', name: 'Diastolic' },
                          ]}
                        />
                      )
                    }
                  </FormDataConsumer>

                  <NumberInput source="min" />
                  <NumberInput source="max" />
                </SimpleFormIterator>
              </ArrayInput>
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>
      <Card>
        <Form warnWhenUnsavedChanges sanitizeEmptyValues>
          <CardContent className="mt-4">
            <p className="text-md font-semibold">Monitoring frequency</p>
            <p className="text-sm">
              How often a patient will be instructed to take measurements using
              their monitoring devices.
            </p>
            <div className="flex flex-row gap-2">
              {deviceTypes.map((deviceType) => (
                <SelectInput
                  source={`settings.monitoring_frequency.${deviceType.id}`}
                  label={inflection.titleize(deviceType.id)}
                  choices={[
                    { id: 'daily', name: 'Daily' },
                    { id: 'twice-daily', name: 'Twice daily' },
                    { id: 'weekly', name: 'Weekly' },
                  ]}
                />
              ))}
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>
    </div>
  );
};

const OrganizationShowEditBody = (props) => {
  const record = useRecordContext();

  const location = useLocation();
  const pathSegments = location.pathname.split('/'); // Split the URL path into segments
  // Assuming the URL pattern is /organizations/:id/sectionName
  const section =
    pathSegments.length > 3 ? pathSegments[pathSegments.length - 1] : 'general';

  if (!record) return null;

  return (
    <>
      <div className="mx-4 my-2">
        <OrganizationName />
      </div>
      <Separator className="w-full" />
      <div className="flex flex-row items-center gap-1 justify-between">
        <p className="text-xl font-semibold">Settings</p>

        {/* <SaveButton variant='default' className='h-8' /> */}
      </div>
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/8 min-w-fit">
          <SidebarNav items={sidebarNavItems} />
        </aside>
        <div className="flex-1 ">
          {section === 'general' && <GeneralSection />}
          {section === 'billing' && <BillingSection />}
          {section === 'ai-summary' && <AISummarySection />}
          {section === 'device-monitoring' && <DeviceMonitoringSection />}
        </div>
      </div>
    </>
  );
};

export const OrganizationShowEdit: FC = (props) => {
  const transformData = (data) => {
    const sanitizedLimits = get(data, 'settings.monitoring_limits', []).map(
      (item) => {
        const sanitizedValues = {};
        Object.keys(item).forEach((key) => {
          if (item[key] === '') {
            sanitizedValues[key] = null;
          } else {
            // this is a non-empty value, so we include it in the sanitized item
            sanitizedValues[key] = item[key];
          }
        });
        return sanitizedValues;
      },
    );

    return {
      ...data,
      settings: {
        ...data.settings,
        monitoring_limits: sanitizedLimits,
      },
    };
  };

  return (
    <div className="flex flex-col gap-2  container mx-auto">
      <AppTitle title="Organization" onlyHeader />
      <EditBase redirect={null} transform={transformData}>
        <OrganizationShowEditBody />
      </EditBase>
    </div>
  );
};
