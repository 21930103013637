import React from 'react';
import { RadioButtonGroupInput, required, useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import inflection from 'inflection';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { useWatch } from 'react-hook-form';

export const RoleRadioSelect = ({ internal = true }) => {
  const dataProvider = useDataProvider();

  const { data: roles, isLoading } = useQuery(
    `roles.internal.${internal ? 'true' : 'false'}`,
    {
      queryFn: () =>
        dataProvider
          .getCustom('roles', {
            meta: { is_internal: internal },
          })
          .then(({ data }) => {
            return data.filter((role) => role.role !== 'superadmin');
          }),
    },
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <RadioButtonGroupInput
      source="role"
      choices={roles.map((role) => ({
        id: role.role,
        name: inflection.titleize(role.role),
      }))}
      validate={[required()]}
      helperText={<RoleDescription internal={internal} />}
    />
  );
};

const RoleDescription = ({ internal = true }) => {
  const selectedRoleId = useWatch({ name: 'role' });
  const dataProvider = useDataProvider();

  const { data: roles, isLoading } = useQuery(
    `roles.internal.${internal ? 'true' : 'false'}`,
    {
      queryFn: () =>
        dataProvider
          .getCustom('roles', {
            meta: { is_internal: internal },
          })
          .then(({ data }) => {
            return data.filter((role) => role.role !== 'superadmin');
          }),
    },
  );
  const selectedRole = roles?.find((role) => role.role === selectedRoleId);

  if (!selectedRole || isLoading) {
    return null;
  }

  return (
    <p className="text-base  text-black mb-1">{selectedRole.description}</p>
  );
};
