import { QuestionnaireForm } from '@vironix-health/questionnaire-react-sdk';
import { get } from 'lodash';
import React from 'react';
import { useDataProvider, useNotify, useRecordContext } from 'react-admin';
import { useMutation, useQuery } from 'react-query';
import QuestionnaireCard from './QuestionnaireCard';
import ErrorCard from './QuestionnaireError';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';

interface QuestionnaireHandlerProps {
  workflowName: string | any;
  onComplete?: any;
}

function QuestionnaireHandler({
  workflowName,
  onComplete = false,
}: QuestionnaireHandlerProps) {
  const dataProvider = useDataProvider();
  const patientRecord = useRecordContext();
  const notify = useNotify();

  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: false,
  };

  const { data, isLoading, error } = useQuery(
    [`patients/${patientRecord.id}/tasks/${workflowName}`, 'getCustom'],
    () =>
      dataProvider.getCustom(
        `patients/${patientRecord.id}/tasks/${workflowName}`,
        {
          id: workflowName,
          meta: { skip_vitals: true },
        },
      ),
    queryOptions,
  );

  const { mutate, isLoading: isLoadingCreateQuestionnaire } = useMutation(
    (response: any) =>
      dataProvider.create(`patients/${patientRecord.id}/assessments`, {
        data: {
          questionnaire_response: {
            ...response,
            questionnaire_id: response.questionnaireId,
          },
          workflow_name: workflowName,
        },
      }),
    {
      onSuccess(data: any, variables, context) {
        if (onComplete) onComplete(data.id);
        notify('Assessment successfully submitted');
      },
    },
  );

  const onQuestionnaireCompleted = (response) => {
    mutate(response);
  };

  if (!patientRecord) return null;

  if (isLoading || isLoadingCreateQuestionnaire) {
    return (
      <div className="flex items-center justify-center w-full">
        <LoadingSpinner />
      </div>
    );
  } else if (error) {
    if (get(error, 'status') === 404)
      return <ErrorCard details="Workflow not found for patient." />;
  } else if (get(data, 'data')) {
    return (
      <QuestionnaireForm
        form={get(data, 'data')}
        onQuestionnaireCompleted={onQuestionnaireCompleted}
      />
    );
  } else {
    return <ErrorCard />;
  }
}

function Questionnaire({ workflowName, onComplete }) {
  if (workflowName) {
    return (
      <QuestionnaireCard>
        <QuestionnaireHandler
          workflowName={workflowName}
          onComplete={onComplete}
        />
      </QuestionnaireCard>
    );
  }

  return (
    <QuestionnaireCard>
      <LoadingSpinner />
    </QuestionnaireCard>
  );
}

export { Questionnaire, QuestionnaireHandler };
