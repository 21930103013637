import React from 'react';
import { useRecordContext } from 'react-admin';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import {
  BooleanInput,
  DateInput,
  SelectInput,
  TextInput,
  required,
} from 'react-admin';
import { MedicationDosageInput } from './MedicationDosageInput';
import { MedicationSearchInput } from './MedicationSearchInput';
import { DateTime } from 'luxon';
import { set } from 'lodash';

export const MedicationEditButton = ({
  variant = 'default',
  label = 'Edit Medication',
}) => {
  const record = useRecordContext();

  const transform = (data) => {
    set(data, 'dosage[0].timing.repeat.period', 1);

    return {
      ...data,
      update_reason: 'incorrect-details',
      start_date: data.start_date
        ? DateTime.fromISO(data.start_date).startOf('day').toUTC().toISO({
            includeOffset: false,
          })
        : undefined,
      end_date: data.end_date
        ? DateTime.fromISO(data.end_date).startOf('day').toUTC().toISO({
            includeOffset: false,
          })
        : undefined,
    };
  };

  return (
    <EditInDialogButton
      title="Edit medication"
      resource={`patients/${record?.patient_id}/medication-statements`}
      transform={transform}
      variant={variant}
      label={label}
      notifyMessage="Medication updated"
      refreshOnSuccess={true}
    >
      <div className="flex flex-col gap-1">
        <MedicationSearchInput />
        <div className="flex flex-col">
          <p>Dosing</p>
          <MedicationDosageInput />
          <div className="flex flex-row gap-1 items-center">
            <TextInput
              source="dosage[0].timing.repeat.frequency"
              label="Frequency"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              validate={required()}
            />
            <SelectInput
              source="dosage[0].timing.repeat.periodUnit"
              choices={[
                { id: 'd', name: 'Daily' },
                { id: 'wk', name: 'Weekly' },
                { id: 'mo', name: 'Monthly' },
                { id: 'a', name: 'Yearly' },
              ]}
              label="Period"
              validate={required()}
            />
          </div>
          <BooleanInput source="dosage[0].as_needed" label="As Needed" />
        </div>
        <div className="flex flex-col">
          <p>Dosage Period</p>
          <div className="flex flex-row gap-1">
            <DateInput
              source="start_date"
              label="Start Date"
              validate={required()}
            />
            <DateInput source="end_date" label="End Date" />
          </div>
        </div>
        <TextInput
          source="additional_info"
          label="Notes"
          multiline
          fullWidth
          minRows={3}
        />
      </div>
    </EditInDialogButton>
  );
};
