import React from 'react';
import { AssessmentSummary } from '@/modules/patients/assessment-summary/components/AssessmentSummary';
import { SymptomSeverity } from '@/modules/patients/symptom-severity/components/SymptomSeverity';

export default function AssessmentsSection(props) {
  return (
    <div className="flex flex-col gap-2">
      <SymptomSeverity />
      <AssessmentSummary />
    </div>
  );
}
