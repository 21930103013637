import React from 'react';
import { BooleanInput, useGetRecordId, useStore } from 'react-admin';
import CreateInDialogButton from '../../../ra-ui/components/create-dialog-button';

const PatientCreateInviteCodeButton = () => {
  const recordId = useGetRecordId();
  const [, setOtp] = useStore('otp', '');

  const onSuccess = (data) => {
    setOtp(data.token);
  };
  return (
    <CreateInDialogButton
      label="Invite Code"
      title="Create Invite Code"
      resource={`patients/${recordId}/invite-code`}
      saveButtonAlwaysEnable
      onSuccess={onSuccess}
      notifyMessage="Successfully generated a new code"
    >
      <p>
        Please note that creating a new invite code will revoke previous invite
        codes
      </p>
      <BooleanInput source="send_email" />
    </CreateInDialogButton>
  );
};

export default PatientCreateInviteCodeButton;
