import AppTitle from '@/modules/layout/components/app-title';

import React, { FC } from 'react';
import { Show, useRecordContext } from 'react-admin';
import {
  MonitoringReportContent,
  MonitoringReportHeader,
} from '../../modules/reports/components/MonitoringReport';
import { Alert } from '@/modules/ui/components/alert';
import {
  CarePlanReportContent,
  CarePlanReportHeader,
} from '../../modules/reports/components/CarePlanReport';

const ReportShowRoot = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  const renderReportContent = () => {
    switch (record.type) {
      case 'monitoring_report':
      case 'clinician_monitoring_report':
        return (
          <>
            <MonitoringReportHeader />
            <MonitoringReportContent />
          </>
        );
      case 'care_plan_report':
      case 'care_plan_clinician_report':
        return (
          <>
            <CarePlanReportHeader />
            <CarePlanReportContent />
          </>
        );
      default:
        return (
          <div>
            <Alert variant="destructive">
              <p>Unsupported report type</p>
            </Alert>
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col gap-4 pb-6">{renderReportContent()}</div>
  );
};

export const ReportShow: FC = (props) => {
  return (
    <>
      <AppTitle title="Report" />
      <Show {...props} component="div">
        <ReportShowRoot />
      </Show>
    </>
  );
};
