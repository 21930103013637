import * as React from 'react';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { useTimeout } from 'ra-core';

export const RootLoadingPage = ({
  loadingPrimary = 'ra.page.loading',
  loadingSecondary = 'ra.message.loading',
  ...props
}) => {
  const oneSecondHasPassed = useTimeout(1000);
  return oneSecondHasPassed ? (
    <div className="flex items-center justify-center h-screen flex-col gap-2">
      <LoadingSpinner />
      <p>Loading Vironix...</p>
    </div>
  ) : null;
};
