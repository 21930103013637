import React, { createContext, useContext, useState } from 'react';

interface MFATokenContextType {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  availableMethods: string[];
  setAvailableMethods: React.Dispatch<React.SetStateAction<string[]>>;
}

const MFATokenContext = createContext<MFATokenContextType | undefined>(
  undefined,
);

export const MFATokenProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [availableMethods, setAvailableMethods] = useState<string[]>([]);

  return (
    <MFATokenContext.Provider
      value={{ token, setToken, availableMethods, setAvailableMethods }}
    >
      {children}
    </MFATokenContext.Provider>
  );
};

export const useMFAToken = () => {
  const context = useContext(MFATokenContext);
  if (context === undefined) {
    throw new Error('useMFAToken must be used within a MFATokenProvider');
  }
  return context;
};
