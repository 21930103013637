export const assessmentsNames = {
  oxygen_saturation: 'Oxygen saturation',
  body_temperature: 'Temperature',
  blood_glucose: 'Blood glucose',
  blood_pressure: 'Blood pressure',
  heart_rate: 'Heart rate',
  body_mass: 'Weight',
  peak_expiratory_flow_rate: 'Peak flow',
  forced_expiratory_volume_1: 'FEV1',
  weight: 'Weight',
  covid19_assessment: 'Respiratory Assessment',
  copd_assessment: 'COPD Assessment',
  asthma_assessment: 'Asthma Assessment',
  chf_assessment: 'Heart Failure Assessment',
  asthma_control: 'Asthma Control Assessment',
  covid19_vitals: 'Respiratory Vitals',
  covid19_baseline: 'Respiratory Baseline',
  covid_19_primary: 'Respiratory Score',
  covid19_symptoms: 'Respiratory Symptoms',
  copd_vitals: 'COPD Vitals',
  copd_primary: 'COPD Score',
  copd_baseline: 'COPD Baseline',
  copd_exacerbation: 'COPD Exacerbation',
  copd_primary_score: 'COPD Score',
  copd_symptoms: 'COPD Symptoms',
  heart_failure_primary: 'Heart Failure Score',
  heart_failure_baseline: 'Heart Failure Baseline',
  heart_failure_symptoms: 'Heart Failure Symptoms',
  heart_failure_vitals: 'Heart Failure Vitals',
  asthma_primary: 'Asthma Score',
  asthma_baseline: 'Asthma Baseline',
  asthma_symptoms: 'Asthma Symptoms',
  asthma_vitals: 'Asthma Vitals',
  chf_exacerbation: 'Heart Failure Exacerbation',
  chf_primary: 'Heart Failure Score',
  chf_baseline: 'Heart Failure Baseline',
  chf_symptoms: 'Heart Failure Symptoms',
  chf_vitals: 'Heart Failure Vitals',
  living_with_chf: 'Living with CHF',
  diabetes_assessment: 'Diabetes Assessment',
  ckd_assessment: 'Chronic Kidney Disease Assessment',
  hads: 'HADS',
  dsi: 'Diabetes Symptom Index',
  phq9: 'PHQ-9',
};
