import AppTitle from '@/modules/layout/components/app-title';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import { CreateButton } from '@/modules/ra-ui/components/create-button';
import React, { FC } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  List,
  TextField,
} from 'react-admin';

const Toolbar = () => (
  <DataTableToolbar
    showViewOptions={false}
    createButton={<CreateButton variant="default" />}
    searchProps={{
      source: 'name',
      placeholder: 'Search...',
    }}
  ></DataTableToolbar>
);

export const TenantList: FC = (props) => (
  <>
    <AppTitle title="Providers" />
    <List
      {...props}
      exporter={false}
      sort={{ field: 'registered_on', order: 'DESC' }}
      actions={<Toolbar />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <BooleanField source="is_active" />
        <DateField source="registered_on" />
        <EmailField source="email" />
        <EditButton />
      </Datagrid>
    </List>
  </>
);
