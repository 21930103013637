import { required } from 'ra-core';


const validPasswordLength =
  (message = 'Password should be between 8-64 characters') =>
  (value) =>
    value.length >= 8 && value.length <= 64 ? undefined : message;

export const passwordsMatch =
  (message = 'Passwords must match') =>
  (value, allValues) =>
    value === allValues.new_password ? undefined : message;

export const passwordValidation = [
  required(),
  validPasswordLength(),
];
