import React from 'react';
import {
  FunctionField,
  ReferenceField,
  TextField,
  useCreate,
  useGetIdentity,
  useNotify,
  useRefresh,
} from 'react-admin';
import { get, find } from 'lodash';
import { useRecordContext } from 'react-admin';
import { InfoBlock } from '../../patients/components/InfoBlock';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { unenrollReasons } from '../../patients/constants/unenrollReasons';
import { dateFormatter } from '@/utils/formatedData';
import { usePatientStatus } from '../../patients/hooks/patient-status';
import { Button } from '@/modules/ui/components/button';
import { DateTime } from 'luxon';
import inflection from 'inflection';
import { EnrollmentEditButton } from './EnrollmentEditButton';
import { DateTimeProviderTZField } from '@/modules/ra-ui/components/DateTimeProviderTZField';
import { MarkDeclinedButton } from './MarkDeclinedButton';
import { declinedReasons } from '../constants/declinedReasons';
import { MarkUnseenButton } from './MarkUnseenButton';
import { unseenReasons } from '@/modules/patients/constants/unseenReasons';
import { MarkPendingButton } from './MarkPendingButton';

export const EnrollmentInfo = () => {
  const record = useRecordContext();
  const patientStatus = usePatientStatus();

  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { identity } = useGetIdentity();

  if (!record) {
    return null;
  }

  const onUpdateStatus = (newStatus) => {
    create(
      `patients/${record.id}/status`,
      {
        data: {
          patient_status: newStatus,
        },
      },
      {
        onSuccess: (data) => {
          refresh();
          logMonitoringNote(data);
        },
        onError: (error) => {
          refresh();
          notify(`Unable to save changes: ${get(error, 'message')}`, {
            type: 'warning',
          });
        },
      },
    );
  };

  const logMonitoringNote = (data) => {
    const patient_id = get(record, 'id');

    create(`monitoring-activities`, {
      data: {
        patient_id: patient_id,
        practitioner_id: identity?.id,
        reviewed_on: DateTime.utc().toISO({ includeOffset: false }),
        type: 'activity',
        description:
          'Patient status updated to ' +
          inflection.humanize(data.patient_status, true),
      },
    });
  };

  return (
    <div className="flex flex-col gap-1 relative">
      {patientStatus === 'pending_enrollment' && (
        <div className="absolute top-1 right-1">
          <EnrollmentEditButton />
        </div>
      )}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-4 items-center">
        <InfoBlock
          title="Registered on"
          text={dateFormatter(get(record, 'user.registered_on'))}
        />
        <InfoBlock
          title="Enrolled on"
          text={dateFormatter(get(record, 'user.enrolled_on')) || 'N/A'}
        />
        <InfoBlock
          title="Enrolled by"
          component={
            <ReferenceField
              source="enrolled_by_id"
              reference="users"
              emptyText="N/A"
            >
              <TextField source="full_name" className="!font-semibold" />
            </ReferenceField>
          }
        />
        <InfoBlock
          title="Insurances"
          text={get(record, 'insurances') || 'N/A'}
        />
        <InfoBlock
          title="Insurance Coverage Type"
          text={
            get(record, 'insurance_coverage_type')
              ? inflection.titleize(get(record, 'insurance_coverage_type'))
              : 'N/A'
          }
        />
        {patientStatus === 'pending_enrollment' && (
          <>
            <InfoBlock
              title="Eligible Devices"
              text={get(record, 'eligible_devices') || 'N/A'}
            />
            <InfoBlock
              title="Onboarding Appointment"
              component={
                <DateTimeProviderTZField
                  source="onboarding_appointment_datetime"
                  label="Onboarding Appointment"
                  showTime
                  showTZ
                  className="!font-semibold"
                />
              }
            />
            <InfoBlock
              title="Expected Care Program"
              text={get(record, 'expected_care_program') || 'N/A'}
            />
            <InfoBlock
              title="Expected Onboarding Clinician"
              component={
                <ReferenceField
                  source="expected_onboarding_user_id"
                  reference="users"
                  emptyText="N/A"
                >
                  <TextField source="full_name" className="!font-semibold" />
                </ReferenceField>
              }
            />
            <InfoBlock
              title="Virtual Enrollment"
              text={get(record, 'virtual_enrollment') ? 'Yes' : 'No'}
            />
          </>
        )}
        {patientStatus === 'disabled' ? (
          <>
            <InfoBlock
              title="Unenrollment Date"
              component={
                <LuxonDateField
                  source="unenrolled_date"
                  className="!font-semibold"
                />
              }
            />
            <InfoBlock
              title="Unenrolled by"
              component={
                <ReferenceField
                  source="unenrolled_by_id"
                  reference="users"
                  emptyText="N/A"
                >
                  <TextField source="full_name" className="!font-semibold" />
                </ReferenceField>
              }
            />
            {get(record, 'unenrolled_reason') === 'other' ? (
              <InfoBlock
                title="Unenrollment Reason"
                component={
                  <TextField
                    source="unenrolled_reason_other"
                    className="!font-semibold"
                  />
                }
              />
            ) : (
              <InfoBlock
                title="Unenrollment Reason"
                component={
                  <FunctionField
                    className="!font-semibold"
                    render={(record) =>
                      find(unenrollReasons, [
                        'id',
                        get(record, 'unenrolled_reason'),
                      ])?.value
                    }
                  />
                }
              />
            )}
          </>
        ) : null}
        {patientStatus === 'declined' ? (
          <>
            <InfoBlock
              title="Declined on"
              component={
                <LuxonDateField
                  source="declined_on"
                  className="!font-semibold"
                />
              }
            />
            <InfoBlock
              title="Declined by"
              component={
                <ReferenceField
                  source="declined_by_id"
                  reference="users"
                  emptyText="N/A"
                >
                  <TextField source="full_name" className="!font-semibold" />
                </ReferenceField>
              }
            />
            {get(record, 'declined_reason') && (
              <InfoBlock
                title="Declined Reason"
                component={
                  <FunctionField
                    className="!font-semibold"
                    render={(record) =>
                      find(declinedReasons, ['id', record.declined_reason])
                        ?.name || record.declined_reason
                    }
                  />
                }
              />
            )}
          </>
        ) : null}
        {patientStatus === 'unseen' ? (
          <>
            <InfoBlock
              title="Unseen on"
              component={
                <LuxonDateField source="unseen_on" className="!font-semibold" />
              }
            />
            <InfoBlock
              title="Marked unseen by"
              component={
                <ReferenceField
                  source="unseen_by_id"
                  reference="users"
                  emptyText="N/A"
                >
                  <TextField source="full_name" className="!font-semibold" />
                </ReferenceField>
              }
            />
            {get(record, 'unseen_reason') && (
              <InfoBlock
                title="Unseen Reason"
                component={
                  <FunctionField
                    className="!font-semibold"
                    render={(record) =>
                      find(unseenReasons, ['id', record.unseen_reason])?.name ||
                      record.unseen_reason
                    }
                  />
                }
              />
            )}
          </>
        ) : null}
      </div>
      {patientStatus === 'pending_enrollment' && (
        <div className="flex  flex-col gap-2 w-fit mx-auto mb-1">
          <div className="div flex flex-row justify-center  mx-auto w-fit gap-1">
            <Button
              onClick={() => onUpdateStatus('active')}
              className="bg-green-500 hover:bg-green-700 w-fit mx-auto"
            >
              Mark Active
            </Button>
            <MarkUnseenButton />
            <MarkDeclinedButton />
          </div>

          <p className="text-sm text-gray-500">
            Set patient to active once they are consented and ready to begin
            enrollment.
          </p>
        </div>
      )}
      {patientStatus === 'unseen' && (
        <div className="flex flex-col gap-2 w-fit mx-auto mb-2">
          <MarkPendingButton />
        </div>
      )}
    </div>
  );
};