import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import AppTitle from '@/modules/layout/components/app-title';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import React, { FC } from 'react';
import { BooleanField, Datagrid, List, useStore } from 'react-admin';
import { NameAvatarField } from '@/modules/users/components/NameAvatarField';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';
import { InternalUserCreate } from '../../modules/users/components/InternalUserCreate';

const Toolbar = ({ selectedTab, setSelectedTab }) => (
  <div className="w-full">
    <TabbedWrapper
      options={[
        {
          title: 'Active',
          value: 'active',
        },
        {
          title: 'Inactive',
          value: 'inactive',
        },
        {
          title: 'All',
          value: '',
        },
      ]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    >
      <DataTableToolbar
        showViewOptions={false}
        createButton={<InternalUserCreate />}
        search={
          <MultiSearch
            searchFields={[
              { value: 'full_name', label: 'Name' },
              { value: 'id', label: 'ID' },
            ]}
          />
        }
      ></DataTableToolbar>
    </TabbedWrapper>
  </div>
);

export const InternalUserList: FC = () => {
  const [selectedTab, setSelectedTab] = useStore(
    'internal-users.listParams.status',
    'active',
  );

  return (
    <>
      <AppTitle title="Internal Users" />
      <List
        resource="users"
        filter={{
          'is_internal[eq]': true,
          ...(selectedTab &&
            selectedTab === 'active' && { 'is_active[eq]': true }),
          ...(selectedTab &&
            selectedTab === 'inactive' && { 'is_active[ne]': true }),
        }}
        empty={false}
        exporter={false}
        actions={
          <Toolbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        }
        storeKey="internal-users.listParams"
      >
        <Datagrid
          rowClick={(id, _resource, _record) => `/internal-users/${id}/`}
        >
          <NameAvatarField sortBy="last_name" label="Name" />
          <LuxonDateField source="registered_on" label="Registered On" />
          <LuxonDateField source="last_login" label="Last Login" />
          <BooleanField source="mfa_enabled" label="2FA" />
          <BooleanField source="is_active" label="Active" />
          <BadgeField source="role" label="Role" />
        </Datagrid>
      </List>
    </>
  );
};
