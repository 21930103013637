import { roundTo } from './roundTo';
import {get} from 'lodash';

export const determineCellValue = ({ row, chartName, chartProperties }) => {
  const roundDigits = get(chartProperties, [chartName, 'round'], 0);
  if (chartName === 'blood_pressure') {
    return `${roundTo(row.y, roundDigits)}/${roundTo(row.y2, roundDigits)}`;
  }
  return roundTo(row.y, roundDigits);
};
