import React, { useMemo } from 'react';

import { get } from 'lodash';
import { DateTime } from 'luxon';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { roundTo } from '@/modules/metrics/utils/roundTo';
import { chartProperties } from './chartProperties';
import { formatYAxisLabel } from '../utils/formartYAxisLabel';
import { Card, CardContent } from '@/modules/ui/components/card';
import { useRecordContext } from 'react-admin';

function CustomTooltip(props) {
  const { active, payload, timescale, dataPointsTrends, dataPointsTrendY2 } =
    props;

  const determinePercentChangeFromBaseline = (timestamp, value, is_y2) => {
    let baseline;
    const data = is_y2 ? dataPointsTrendY2 : dataPointsTrends;

    if (!data || data.length === 0) {
      return {
        baseline: null,
        percentChange: null,
      };
    }

    if (timescale === 'all') {
      baseline = data.reduce((acc, obj) =>
        Math.abs(timestamp - obj.x) < Math.abs(timestamp - acc.x) ? obj : acc,
      );
    } else {
      baseline = data.find((point) => point.x === timestamp);
    }
    if (!baseline) {
      return {
        baseline: null,
        percentChange: null,
      };
    }
    return {
      baseline: baseline.y,
      percentChange: roundTo(((value - baseline.y) / baseline.y) * 100, 1),
    };
  };
  if (active && payload && payload.length) {
    return (
      <Card>
        <CardContent className="p-2">
          <div className="flex flex-col gap-1">
            <span>
              {DateTime.fromSeconds(Number(get(payload, '0.payload.x')), {
                zone: 'local',
              }).toLocaleString(
                timescale === 'all' ? DateTime.DATETIME_MED : DateTime.DATE_MED,
              )}
            </span>
            {payload.map((pld) => {
              if (
                pld.name.includes('Trend') ||
                pld.name.includes('FEV1/FVC') ||
                pld.name.includes('PEF/Predicted')
              ) {
                return null;
              }
              const { baseline, percentChange } =
                determinePercentChangeFromBaseline(
                  pld.payload.x,
                  pld.value,
                  pld.payload.is_y2,
                );
              return (
                <div key={pld.dataKey + pld.name} className="flex flex-col">
                  {pld.name.includes('stolic') ? (
                    <p className="underline">{pld.name}</p>
                  ) : null}
                  <span>
                    <p className="font-light inline">Value:</p> {pld.value}
                  </span>
                  {baseline != null && (
                    <span>
                      <p className="font-light inline">Baseline:</p> {baseline}
                    </span>
                  )}
                  {percentChange != null && (
                    <span>
                      <p className="font-light inline">Change:</p>{' '}
                      {percentChange} %
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
    );
  }

  return null;
}

export function MetricsScatterChart({
  data,
  chartName,
  unitText,
  showTrend,
  timescale,
}) {
  //Determine if its CGM by checking if there are 24+ data points in one day
  const isCGM = useMemo(() => {
    if (!data?.dataPoints?.length) {
      return false;
    }

    const sortedData = [...data.dataPoints].sort((a, b) => a.x - b.x);
    let count = 1; // Start counting from the first point
    let start = sortedData[0].x;

    for (let i = 1; i < sortedData.length; i++) {
      if (sortedData[i].x - start < 86400) {
        // 86400 seconds in a day
        count++;
        if (count >= 24) {
          return true; // Early exit if 24 or more points are found within one day
        }
      } else {
        // Reset count and start time when moving to a new day
        start = sortedData[i].x;
        count = 1;
      }
    }

    return false; // Return false if no day with 24 or more points is found
  }, [data]);

  const patientRecord = useRecordContext();

  let percentPredictedDataPoints;
  let percentPredictedLabel;

  const referenceValues = {
    forced_expiratory_volume_1: 'fvc_GLI2012',
    peak_expiratory_flow_rate: 'pef_NHANES',
  };

  const chartReferenceKey = referenceValues[chartName];
  if (chartReferenceKey) {
    const referenceValuePath = `patient.profile.reference_values.${chartReferenceKey}.value`;
    const referenceValue = get(patientRecord, referenceValuePath);

    if (referenceValue) {
      percentPredictedDataPoints = data.dataPoints.map((point) => {
        const fvcValue = (point.y / referenceValue) * 100;
        return { ...point, y: fvcValue };
      });
      if (chartName === 'forced_expiratory_volume_1') {
        percentPredictedLabel = {
          unitText: '%',
          chartName: 'forced_expiratory_volume_1',
          chartProperties: {
            forced_expiratory_volume_1: {
              name: 'FEV1/FVC',
            },
          },
          position: 'right',
        };
      } else if (chartName === 'peak_expiratory_flow_rate') {
        percentPredictedLabel = {
          unitText: '%',
          chartName: 'peak_expiratory_flow_rate',
          chartProperties: {
            peak_expiratory_flow_rate: {
              name: 'PEF/Predicted',
            },
          },
          position: 'right',
        };
      }
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={800}
        height={400}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="x"
          name="Date"
          label={{ value: 'Date', position: 'bottom', offset: 0 }}
          padding={{ left: 20, right: 20 }}
          domain={['dataMin', 'dataMax']}
          scale="time"
          tickFormatter={(time) =>
            DateTime.fromSeconds(time).toLocaleString(DateTime.DATE_MED)
          }
        />
        <YAxis
          yAxisId="left"
          padding={{ top: 20, bottom: 20 }}
          type="number"
          domain={['dataMin', 'dataMax']}
          dataKey="y"
          label={formatYAxisLabel({
            unitText,
            chartName,
            chartProperties,
            position: 'left',
          })}
          tickFormatter={(value) =>
            `${roundTo(value, get(chartProperties, [chartName, 'round'], 0))}`
          }
        />
        {percentPredictedLabel && (
          <YAxis
            yAxisId="right"
            orientation="right"
            padding={{ top: 20, bottom: 20 }}
            type="number"
            domain={['auto', 'auto']}
            dataKey="y"
            label={formatYAxisLabel(percentPredictedLabel)}
            tickFormatter={(value) =>
              `${roundTo(value, get(chartProperties, [chartName, 'round'], 0))}`
            }
          />
        )}
        ;
        <Tooltip
          content={
            <CustomTooltip
              timescale={timescale}
              dataPointsTrends={get(data, 'dataPointsTrend')}
              dataPointsTrendY2={get(data, 'dataPointsTrendY2')}
            />
          }
        />
        <Legend verticalAlign="top" height={36} />
        {chartName === 'blood_pressure' ? (
          <>
            <Line
              yAxisId="left"
              type="monotone"
              name="Systolic"
              data={get(data, 'dataPoints')}
              stroke="#8884d8"
              strokeWidth={2}
              dataKey="y"
              id="y1"
              activeDot={{ r: 8 }}
            />
            <Line
              yAxisId="left"
              type="monotone"
              name="Diastolic"
              data={get(data, 'dataPointsY2')}
              stroke="#bc5090"
              strokeWidth={2}
              dataKey="y"
              id="y2"
              activeDot={{ r: 8 }}
            />
          </>
        ) : (
          <Line
            yAxisId="left"
            id="points"
            name="Points"
            type="monotone"
            dataKey="y"
            data={get(data, 'dataPoints')}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            strokeWidth={2}
            dot={isCGM ? false : true}
          />
        )}
        {percentPredictedDataPoints && (
          <Line
            yAxisId="right"
            type="monotone"
            name={percentPredictedLabel.chartProperties[chartName].name}
            data={percentPredictedDataPoints}
            stroke="#bc5090"
            strokeWidth={2}
            dataKey="y"
            id="y1"
            activeDot={{ r: 8 }}
          />
        )}
        {showTrend && chartName === 'blood_pressure' ? (
          <>
            <Line
              yAxisId="left"
              name="Systolic - Trend"
              type="monotone"
              dataKey="y"
              data={get(data, 'dataPointsTrend')}
              stroke="#82ca9d"
              strokeWidth={2}
              strokeDasharray="4 2"
              id="y1-trend"
            />
            <Line
              yAxisId="left"
              name="Diastolic - Trend"
              type="monotone"
              dataKey="y"
              data={get(data, 'dataPointsTrendY2')}
              stroke="#539177"
              strokeWidth={2}
              strokeDasharray="4 2"
              id="y2-trend"
            />
          </>
        ) : null}
        {showTrend && chartName !== 'blood_pressure' ? (
          <Line
            yAxisId="left"
            id="trend"
            name="Trend"
            type="monotone"
            dataKey="y"
            data={get(data, 'dataPointsTrend')}
            stroke="#82ca9d"
            strokeWidth={2}
            strokeDasharray={'4 2'}
            dot={isCGM ? false : true}
            activeDot={false}
          />
        ) : null}
      </LineChart>
    </ResponsiveContainer>
  );
}
