import { Button } from '@/modules/ui/components/button';
import { Building } from 'lucide-react';
import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  usePermissions,
  useRecordContext,
} from 'react-admin';

import { DropdownMenuItem } from '@/modules/ui/components/dropdown-menu';
import { get } from 'lodash';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';

const DeviceSetLocation = ({
  label = 'Set Location',
  asDropdownMenuItem = false,
  className = null,
}) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  if (!record) {
    return null;
  }

  if (get(permissions, 'is_internal') !== true) {
    return null;
  }

  return (
    <EditInDialogButton
      resource="devices"
      variant="default"
      title="Set Location"
      notifyMessage="Device location set."
      refreshOnSuccess={true}
      transform={(data) => {
        return {
          ...data,
          update_action: 'set_location',
        };
      }}
      editButton={
        asDropdownMenuItem ? (
          <DropdownMenuItem>
            <Building className="w-4 h-4 mr-2" />
            {label}
          </DropdownMenuItem>
        ) : (
          <Button variant="outline" size="sm" className={className}>
            <Building className="w-4 h-4 mr-2" />
            {label}
          </Button>
        )
      }
    >
      <ReferenceInput
        source="location_id"
        reference="locations"
        filter={{
          'is_active[eq]': true,
          'tenant_id[eq]': record.tenant_id,
        }}
      >
        <AutocompleteInput
          label="Location"
          optionText="name"
          parse={(v) => (v === '' ? null : v)}
          helperText={false}
        />
      </ReferenceInput>
    </EditInDialogButton>
  );
};

export default DeviceSetLocation;
