import React from 'react';
import { RadioTower } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { DeviceCreateButton } from './DeviceCreateButton';
import {
  useGetResourceLabel,
  useResourceContext,
  useTranslate,
} from 'react-admin';

export const DeviceEmpty = (props) => {
  const { className } = props;
  const resource = useResourceContext(props);
  const translate = useTranslate();
  const getResourceLabel = useGetResourceLabel();

  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    smart_count: 0,
    _: getResourceLabel(resource, 0),
  });
  const emptyMessage = translate('ra.page.empty', { name: resourceName });
  const inviteMessage = translate('ra.page.invite');

  return (
    <Card
      className={`flex flex-col items-center justify-center h-full w-full ${className}`}
    >
      <CardContent className="text-center">
        <CardHeader>
          <CardTitle className="mt-4 text-2xl font-semibold text-gray-900">
            {translate(`resources.${resource}.empty`, {
              _: emptyMessage,
            })}
          </CardTitle>
        </CardHeader>
        <RadioTower className="w-24 h-24 mx-auto text-gray-400" />
        <p className="mt-2 text-sm text-gray-500">
          {translate(`resources.${resource}.invite`, {
            _: inviteMessage,
          })}
        </p>
        <div className="mt-6">
          <DeviceCreateButton variant="default" />
        </div>
      </CardContent>
    </Card>
  );
};
