import { Button } from '@/modules/ui/components/button';
import { UserX2 } from 'lucide-react';
import React from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';

import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { get } from 'lodash';
const DeviceUnassignFromPatient = () => {
  const record = useRecordContext();

  const transform = (data) => {
    return {
      patient_id: null,
      update_action: 'unassign_patient',
      unassign_device_reason: data.unassign_device_reason,
    };
  };

  return (
    <EditInDialogButton
      title="Unassign device"
      resource="devices"
      variant="default"
      notifyMessage="Device unassigned from patient."
      transform={transform}
      refreshOnSuccess={true}
      editButton={
        <Button variant="outline" size="sm">
          <UserX2 className="w-4 h-4 mr-2" />
          Unassign patient
        </Button>
      }
    >
      <div className="flex flex-col">
        <SelectInput
          source="unassign_device_reason.reason"
          label="Reason"
          choices={[
            { id: 'patient_unenrolled', name: 'Patient unenrolled' },
            { id: 'in_error', name: 'Assigned in error' },
            { id: 'replace_device', name: 'Replace due to issue' },
            { id: 'other', name: 'Other' },
          ]}
          helperText={false}
          validate={required()}
        />
        <TextInput
          source="unassign_device_reason.notes"
          label="Notes"
          helperText={false}
          multiline
          minRows={2}
        />
        <BooleanInput
          source="unassign_device_reason.is_device_returned"
          label="Has the device been returned?"
          helperText={false}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (get(formData, 'unassign_device_reason.is_device_returned'))
              return (
                <ReferenceInput
                  source="unassign_device_reason.returned_to_location_id"
                  reference="locations"
                  filter={{ 'tenant_id[eq]': record.tenant_id }}
                  {...rest}
                >
                  {/* // TODO change this to required in the future */}
                  <SelectInput optionText="name" label="Returned to" />
                </ReferenceInput>
              );
            return null;
          }}
        </FormDataConsumer>
        <BooleanInput
          source="unassign"
          helperText={false}
          validate={[
            (value, allValues) => {
              if (value === true) {
                return undefined;
              }
              return 'Please confirm unassigning the device.';
            },
          ]}
        />
      </div>
    </EditInDialogButton>
  );
};

export default DeviceUnassignFromPatient;
