import React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';

export function addDashToFourChars(str) {
  if (!str) return str;
  const regex = /(.{4})(?!$)/g;
  const result = str.replace(regex, '$1-');
  return result;
}

interface DeviceExternalIdFieldProps {
  crop?: number | false;
  source?: string;
  label?: string;
}

export default function DeviceExternalIdField({
  crop = false,
  source = 'external_id',
  ...rest
}: DeviceExternalIdFieldProps) {
  const record = useRecordContext();

  const value = get(record, source);

  var formattedId =
    record?.device_type === 'tenovi_gateway'
      ? addDashToFourChars(value)
      : value;

  if (crop && value && formattedId?.length > crop) {
    formattedId = formattedId?.substring(0, crop) + '...';
  }

  return <span>{formattedId}</span>;
}
