import * as React from 'react';

import {
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { Button } from '@/modules/ui/components/button';

export const PatientSuccess = ({ setView }) => (
  <>
    <CardHeader>
      <CardTitle className="text-2xl">Patient Account</CardTitle>
    </CardHeader>
    <CardContent className="text-center">
      <div className="flex flex-col gap-8 items-center">
        <div>
          <h3 className="text-xl font-semibold">
            It looks like you are using a patient account.
          </h3>
          <p className="text-base">
            Vironix only supports patient accounts through the Vironix RPM
            mobile apps.
          </p>
        </div>
        <Button
          onClick={() => {
            setView({
              view: 'login',
              searchParams: {},
            });
          }}
          variant="default"
        >
          Sign in with another account
        </Button>
        <p className="text-sm">
          <a
            href="https://vironix.notion.site/Help-Center-a26695ee626c4a0895b34f67b14caea4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </p>
      </div>
    </CardContent>
  </>
);
