import CircleIcon from '@mui/icons-material/Circle';
import { Box, Button, Typography } from '@mui/material';
import { find } from 'lodash';
import React from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';
import { assessmentsNames } from '../../users/constants/assessmentsNames';
import { CustomPallete } from '../../../utils/color';
import { formatPastDay } from '@/utils/formatPastDay';
import { useQuestionnaireContext } from '../hooks/useQuestionnaireContext';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';

export function ModalVitalsToUpdateContent() {
  const patientRecord = useRecordContext();
  const dataProvider = useDataProvider();

  const {
    workFlow,
    setWorkFlow,
    refetchAssessments,
    isLoadingAssessments,
    setInProgress,
  } = useQuestionnaireContext();

  const formatObservations = () => {
    const observations = {};
    for (let i = 0; i < data.data.length; i++) {
      observations[data.data[i].name] = data.data[i].timestamp;
    }
    return observations;
  };

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchObservations,
  } = useQuery(['userObservations', patientRecord?.id], () =>
    dataProvider.getCustom(`patients/${patientRecord?.id}/observations`, {}),
  );

  const onClickRecheck = () => {
    refetchObservations({ cancelRefetch: true });
    refetchAssessments({ cancelRefetch: true });
  };

  const onSkipClick = () => {
    setWorkFlow({
      ...workFlow,
      available: true,
    });
    setInProgress(true);
  };

  const vitalNotes = find(workFlow.available_notes, {
    type: 'observations',
  });
  const profileNotes = find(workFlow.available_notes, {
    type: 'profile',
  });

  if (!patientRecord?.id) return;

  if (isLoading || isFetching || isLoadingAssessments) {
    return (
      <Box p="32px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <LoadingSpinner />
        </Box>
      </Box>
    );
  }

  return (
    <Box p="32px">
      {vitalNotes ? (
        <>
          <Typography variant="subtitle1">
            To start this assessment please ask a Patient to update the
            following vital signs in the mobile application:
          </Typography>
          {vitalNotes.items.map((note) => {
            return (
              <Box
                key={note}
                display="flex"
                alignItems="center"
                ml="12px"
                mb="5px"
                mt="5px"
              >
                <CircleIcon
                  sx={{
                    width: '5px',
                    height: '5px',
                    color: CustomPallete.neutral10,
                    marginRight: '10px',
                  }}
                />
                <Typography variant="subtitle1">
                  {`${assessmentsNames[note]} (last updated ${formatPastDay(
                    formatObservations()[note],
                  )})`}
                </Typography>
              </Box>
            );
          })}{' '}
        </>
      ) : null}
      {profileNotes ? (
        <>
          <Typography variant="subtitle1">
            To start this assessment please complete the patient's profile.
          </Typography>

          {profileNotes.items.map((note) => {
            return (
              <Box
                key={note}
                display="flex"
                alignItems="center"
                ml="12px"
                mb="5px"
                mt="5px"
              >
                <CircleIcon
                  sx={{
                    width: '5px',
                    height: '5px',
                    color: CustomPallete.neutral10,
                    marginRight: '10px',
                  }}
                />
                <Typography variant="subtitle1">{note}</Typography>
              </Box>
            );
          })}
          <Typography variant="subtitle1">
            This is required and can't be skipped.
          </Typography>
        </>
      ) : null}
      <Box display="flex" justifyContent="flex-end" mt="20px">
        <Box mr="13px">
          <Button onClick={onSkipClick} size="large" disabled={!!profileNotes}>
            Skip
          </Button>
        </Box>
        {vitalNotes ? (
          <Button onClick={onClickRecheck} size="large" variant="contained">
            Recheck Vitals Status
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}
