import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/modules/ui/components/accordion';
import { Badge } from '@/modules/ui/components/badge';
import React from 'react';

export const FormCard = (props) => {
  const { title, optional, collapsible = false, children } = props;

  if (collapsible) {
    return (
      <Accordion type="single" collapsible>
        <AccordionItem value={`${title}`} className="border-t border-b-0">
          <AccordionTrigger className="no-underline">
            <div className="flex flex-row gap-4 items-center">
              {title}

              {optional ? <Badge variant="outline">Optional </Badge> : null}
            </div>
          </AccordionTrigger>
          <AccordionContent>{children}</AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  } else {
    return (
      <div className="border-t">
        <div className="flex flex-1 items-center justify-between py-4 font-medium">
          {title}
        </div>
        <div
          className="
              overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
        >
          <div className="pb-4 pt-0">{children}</div>
        </div>
      </div>
    );
  }
};
