import { PhoneNumberInput } from '@/modules/ra-ui/components/PhoneNumberInput';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { get, set } from 'lodash';
import React from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  SelectArrayInput,
  SelectInput,
  TextInput,
  maxLength,
  minLength,
  required,
} from 'react-admin';
import { RoleRadioSelect } from '../../ra-ui/components/RoleRadioSelect';
import { clinicianTypes } from '../constants/clinicanTypes';
import { serviceInstanceTypesChoices } from '@/modules/patients/constants/serviceInstanceTypesChoices';

export const InternalUserCreate = () => {
  const transform = (data) => {
    if (
      get(data, 'phone_number') === '+1' ||
      get(data, 'phone_number') === ''
    ) {
      set(data, 'phone_number', null);
    }

    return {
      ...data,
      is_internal: true,
      email_invite: true,
    };
  };

  return (
    <CreateInDialogButton
      title="Create internal user"
      transform={transform}
      redirect="internal-users"
      label="User"
    >
      <div className="flex-1">
        <div className="flex flex-row gap-1">
          <TextInput source="first_name" validate={[required()]} />
          <TextInput source="middle_name" />
          <TextInput source="last_name" validate={[required()]} />
        </div>
        <div className="flex flex-row gap-1 items-center">
          <TextInput
            source="email"
            type="email"
            validate={[required()]}
            helperText={false}
          />
          <PhoneNumberInput label={false} source="phone_number" />
        </div>
        <div className="flex gap-2 items-center">
          <TextInput
            source="npi_number"
            label="NPI Number"
            helperText="10-digit NPI Number"
            validate={[
              minLength(10, 'NPI number must be 10 digits'),
              maxLength(10, 'NPI number must be 10 digits'),
            ]}
          />
          <TextInput source="credentials" helperText="MD, RN, PhD" />
        </div>
        <div className="flex gap-2 items-center">
          <BooleanInput
            source="is_provider"
            label="Is clinician"
            helperText={false}
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.is_provider ? (
                <>
                  <SelectInput
                    validate={required()}
                    source="clinician_type"
                    choices={clinicianTypes}
                    helperText={false}
                  />
                  <SelectArrayInput
                    validate={required()}
                    source="enabled_care_program_types"
                    choices={serviceInstanceTypesChoices.filter(
                      (c) => c.enabled !== false,
                    )}
                    helperText={false}
                  />
                </>
              ) : null
            }
          </FormDataConsumer>
        </div>
        <RoleRadioSelect internal={true} />
      </div>
    </CreateInDialogButton>
  );
};
