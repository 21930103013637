import { SimpleTable } from '@/modules/ra-ui/components/simple-table';
import { Badge } from '@/modules/ui/components/badge';
import { Card } from '@/modules/ui/components/card';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import {
  RecordContextProvider,
  useGetIdentity,
  useGetList,
  useStore,
} from 'ra-core';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { useNavigate } from 'react-router-dom';

type OpenTasksTableColumns = {
  title: string;
  description: string;
  due_on: string;
  patient_id: number;
  priority: string;
};
const columnHelper = createColumnHelper<OpenTasksTableColumns>();
const columns = [
  columnHelper.accessor('title', {
    header: () => 'Title',
    cell: (info) => info.getValue(),
    enableSorting: false,
  }),
  columnHelper.accessor('description', {
    header: () => 'Description',
    cell: (info) => info.getValue(),
    enableSorting: false,
  }),
  columnHelper.accessor('patient_id', {
    header: () => 'Patient',
    cell: (info) => (
      <RecordContextProvider
        value={{
          patient_id: info.getValue(),
        }}
      >
        <ReferenceField
          reference="patients"
          source="patient_id"
          label="Patient"
          link={false}
        >
          <TextField source="user.full_name" />
        </ReferenceField>
      </RecordContextProvider>
    ),
    enableSorting: true,
  }),
  columnHelper.accessor('priority', {
    header: () => 'Priority',
    cell: (info) => {
      const priority = info.getValue();
      const priorityMap = {
        1: { label: 'High', color: 'red' },
        2: { label: 'Medium', color: 'yellow' },
        3: { label: 'Low', color: 'green' },
      };
      const { label, color } = priorityMap[priority] || {
        label: priority,
        color: 'gray',
      };
      return (
        <Badge
          variant="outline"
          className={`bg-${color}-100 text-${color}-800 border-${color}-300`}
        >
          {label}
        </Badge>
      );
    },
    enableSorting: true,
  }),

  columnHelper.accessor('due_on', {
    header: () => 'Due on',
    cell: (info) =>
      DateTime.fromISO(info.getValue(), { zone: 'UTC' }).toLocaleString(),
    enableSorting: true,
  }),
];
export const OpenTasksTable = () => {
  const { identity } = useGetIdentity();
  const navigate = useNavigate();
  const [sorting, setSorting] = useStore<{ field: string; order: string }>(
    'home.openTasksTableSorting',
    {
      field: 'due_on',
      order: 'ASC',
    },
  );

  const { data, isLoading, total } = useGetList('tasks', {
    pagination: { page: 1, perPage: 10 },
    filter: {
      'complete[eq]': false,
      'assigned_to_id[eq]': identity?.id,
    },
    sort: sorting as any,
  });

  if (isLoading) {
    return (
      <div className="w-full h-32 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (total === 0) {
    return null;
  }

  const onRowClick = (row: any) => {
    navigate(`/patients/${row.getValue('patient_id')}/show`);
  };

  const onSort = (newSorting: any) => {
    setSorting({
      field: newSorting[0].id,
      order: newSorting[0].desc ? 'DESC' : 'ASC',
    });
  };

  return (
    <Card className="my-2 w-fit">
      <p className="text-md font-semibold px-3 py-1   ">Open Tasks</p>
      <SimpleTable
        data={data}
        columns={columns}
        rowClick={onRowClick}
        onSort={onSort}
        sorting={[{ id: sorting.field, desc: sorting.order === 'DESC' }]}
      />
    </Card>
  );
};
