import React, { createElement, useState } from 'react';
import { Button } from './button';
import { Dialog, DialogContent, DialogFooter } from './dialog';
import { Loader2 } from 'lucide-react';
import { cn } from '@/modules/ui/utils/cn';

interface ConfirmActionButtonProps {
  action: () => void;
  actionText?: string;
  confirmText: string;
  cancelText: string;
  dialogTitle: string;
  dialogDescription: string;
  isLoading: boolean;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  buttonClassName?: string;
  iconClassName?: string;
  buttonType?: 'button' | 'submit' | 'reset';
  buttonVariant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost';
}

const ConfirmActionButton: React.FC<ConfirmActionButtonProps> = ({
  action,
  actionText,
  confirmText,
  cancelText,
  dialogTitle,
  dialogDescription,
  isLoading,
  Icon = null,
  iconClassName = null,
  buttonClassName = null,
  buttonType = null,
  buttonVariant = 'default',
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirm = () => {
    action();
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className={cn('h-8', buttonClassName)}
        disabled={isLoading}
        type={buttonType}
        variant={buttonVariant}
      >
        {isLoading ? (
          <Loader2 className={cn('mr-2 h-4 w-4 animate-spin', iconClassName)} />
        ) : Icon ? (
          createElement(Icon, {
            className: cn('h-4 w-4 ', iconClassName, actionText && 'mr-2'),
          })
        ) : null}
        {isLoading ? 'Please wait' : actionText ?? ''}
      </Button>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          {dialogTitle}
          <p className="text-sm text-gray-500">{dialogDescription}</p>
          <DialogFooter>
            <Button onClick={handleClose} variant="secondary">
              {cancelText}
            </Button>
            <Button onClick={handleConfirm}>{confirmText}</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmActionButton;
