import { titleize } from 'inflection';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { StatCard } from '../../ui/components/stat-card';

export function StatCardField({
  label,
  source,
  sourceSecondary = null,
  icon = null,
  emptyValue = null,
}) {
  const record = useRecordContext();

  const statName = label ? label : titleize(source);
  const statValue = get(record, source, emptyValue);
  const statSecondary = sourceSecondary ? get(record, sourceSecondary) : null;

  if (!record && statValue !== null) {
    return null;
  }

  return (
    <StatCard
      statName={statName}
      statValue={statValue}
      statSecondary={statSecondary}
      icon={icon}
    />
  );
}
