import { DateTime } from 'luxon';

export function formatPastDay(timestamp: string) {
  const dateToday = DateTime.local().startOf('day');
  const date = DateTime.fromISO(timestamp, {
    zone: 'utc',
  })
    .setZone('local')
    .startOf('day');

  const diff = dateToday.diff(date, 'days').days;
  if (diff > 7) {
    return date.toLocaleString(DateTime.DATE_MED);
  } else if (diff === 0) {
    return 'Today';
  } else if (diff === 1) {
    return 'Yesterday';
  } else {
    return `${diff} days ago`;
  }
}
