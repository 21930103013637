import React, { useEffect, useState } from 'react';
import { useGetIdentity, useGetOne } from 'react-admin';
import { get } from 'lodash';

const OrganizationContext = React.createContext({
  organization: null,
  isLoading: true,
});

export function useOrganization() {
  const organization = React.useContext(OrganizationContext);

  return organization;
}

export function OrganizationProvider({ children }) {
  const { isLoading: identityLoading, identity } = useGetIdentity();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (identity) {
      if (!identityLoading && get(identity, 'tenantId')) {
        setEnabled(true);
      }
    }
  }, [identity, identityLoading]);

  const { data: organization, isLoading } = useGetOne(
    'providers',
    { id: get(identity, 'tenantId') },
    {
      enabled: enabled,
      staleTime: Infinity,
    },
  );

  return (
    <OrganizationContext.Provider
      value={{ organization: organization, isLoading: isLoading && enabled }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}
