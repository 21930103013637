import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { cn } from '@/modules/ui/utils/cn';
import React from 'react';

export function QuestionnaireDialogConfirmExit({
  open,
  handleClose,
  handleConfirm,
}) {
  return (
    <Dialog open={open}>
      <DialogContent
        onClose={handleClose}
        className={cn('min-w-[400px] overflow-y-auto max-h-[90vh]')}
      >
        <DialogHeader>
          <DialogTitle>Are you sure you want to exit?</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          This will discard all your answers and return to the patient's page.
        </DialogDescription>
        <DialogFooter>
          <Button
            onClick={handleConfirm}
            className="bg-red-500 hover:bg-red-600"
          >
            Exit
          </Button>
          <Button
            onClick={handleClose}
            className="bg-gray-500 hover:bg-gray-600"
          >
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
