import React from 'react';
import { SelectInput, TextInput, required } from 'react-admin';
import stateCodes from '@/modules/ra-ui/constants/states.json';
import { cn } from '@/modules/ui/utils/cn';

type AddressInputProps = {
  source: string;
  isRequired?: boolean;
  label?: string | false;
  className?: string;
};

export const AddressInput = ({
  source,
  isRequired = false,
  label = 'Address',
  className,
}: AddressInputProps) => {
  return (
    <div className={cn('flex flex-col', className)}>
      {label && (
        <div>
          <p className="text-sm">{label}</p>
        </div>
      )}
      <TextInput
        source={`${source}.address_line1`}
        label="Street Address"
        fullWidth
        helperText={false}
        validate={isRequired ? [required()] : null}
      />
      <TextInput
        source={`${source}.address_line2`}
        label="Apt, Suite, etc."
        fullWidth
        helperText={false}
      />
      <div className="flex flex-row gap-1">
        <TextInput
          source={`${source}.city`}
          label="City"
          fullWidth
          helperText={false}
          validate={isRequired ? [required()] : null}
        />
        <SelectInput
          choices={stateCodes}
          source={`${source}.state`}
          label="State"
          fullWidth
          helperText={false}
          validate={isRequired ? [required()] : null}
        />
        <TextInput
          source={`${source}.zip_code`}
          label="Zip Code"
          fullWidth
          helperText={false}
          validate={isRequired ? [required()] : null}
        />
      </div>
      <SelectInput
        source={`${source}.country`}
        label="Country"
        fullWidth
        helperText={false}
        choices={[{ id: 'United States', name: 'United States' }]}
        defaultValue="United States"
        validate={isRequired ? [required()] : null}
      />
    </div>
  );
};
