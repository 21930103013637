import React from 'react';
import { useSidebarState } from '../hooks/useSidebarState';
import { SidebarItem } from './SidebarItem';

export function SidebarSection({ links, title = '' }) {
  const [sidebarOpen] = useSidebarState();

  return (
    <div
      data-collapsed={!sidebarOpen}
      className="group flex flex-col py-2 data-[collapsed=true]:py-2 "
    >
      {sidebarOpen && title && (
        <p className="px-4 text-xs text-gray-500">{title}</p>
      )}
      <nav className="grid gap-1 px-2">
        {links.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
      </nav>
    </div>
  );
}
