import React, { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  CreateBase,
  NumberInput,
  SaveButton,
  SelectInput,
  TextInput,
  email,
  maxLength,
  minLength,
  required,
} from 'react-admin';

import { getTimeZones } from '@vvo/tzdb';

import { Plus } from 'lucide-react';
import { BaseForm } from '../../modules/ra-ui/components/BaseForm';
import { FormCard } from '../../modules/ra-ui/components/FormCard';

import { AddressInput } from '@/modules/ra-ui/components/address-input';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { Separator } from '@/modules/ui/components/separator';
const timeZones = getTimeZones();

export const OrganizationCreate: FC = (props) => {
  // TODO Select tenant if super admin as option, otherwise send the current user's tenant as the tenant
  // TODO Create aside menu for what happens next
  // TODO Password setting field if no invite email is to be sent

  return (
    <>
      {/* <AppTitle title="Add provider" /> */}
      <CreateBase {...props} resource="providers">
        <BaseForm title="Add organization" saveButton={<></>}>
          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Add provider</CardTitle>
              <CardDescription>
                Add a new provider onto the platform. This form will not email
                or create any user accounts for the new provider.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <FormCard>
                <div className="max-w-xl">
                  <div className="flex flex-row gap-2">
                    <TextInput
                      source="name"
                      label="Organization name"
                      validate={required()}
                      fullWidth
                    />
                    <TextInput
                      source="email"
                      type="email"
                      label="Primary email"
                      validate={[required(), email()]}
                      fullWidth
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <TextInput
                      source="npi_number"
                      label="Organization NPI Number"
                      validate={[
                        required(),
                        minLength(10, 'NPI number must be 10 digits'),
                        maxLength(10, 'NPI number must be 10 digits'),
                      ]}
                    />
                    <NumberInput
                      source="customer_size"
                      label="Size (Estimated patient count)"
                      helperText={false}
                      validate={required()}
                    />
                    <AutocompleteInput
                      source="timezone"
                      label="Timezone"
                      helperText={false}
                      validate={required()}
                      choices={timeZones}
                      optionValue="name"
                      optionText="name"
                    />
                  </div>
                  <Separator className="my-2" />
                  <AddressInput source="address" label={false} isRequired />
                  <Separator className="my-2" />
                  <div className="py-2">
                    <BooleanInput
                      source="stripe_billing_enabled"
                      defaultValue={true}
                      helperText={false}
                    />
                    <SelectInput
                      source="billing_scheme"
                      label="Billing scheme"
                      choices={[
                        { id: 'subscription', name: 'Subscription' },
                        { id: 'fee_for_service', name: 'Fee for Service' },
                      ]}
                      helperText={false}
                      validate={required()}
                    />
                  </div>
                </div>
              </FormCard>
              <CardFooter className="flex flex-row justify-end">
                <SaveButton
                  variant="contained"
                  size="medium"
                  label="Add"
                  icon={<Plus />}
                />
              </CardFooter>
            </CardContent>
          </Card>
        </BaseForm>
      </CreateBase>
    </>
  );
};
