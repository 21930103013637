import { Badge } from '@/modules/ui/components/badge';
import { cn } from '@/modules/ui/utils/cn';
import { formatDistanceToNow } from 'date-fns';
import { titleize } from 'inflection';
import { get } from 'lodash';
import {
  Building,
  Radio,
  Shield,
  UserCheck2,
  UserX2,
  Users,
  Battery,
  Signal,
} from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const eventIcons = {
  device_ping: Radio,
  update_shared_device: Users,
  update_tenant: Building,
  assign_to_patient: UserCheck2,
  unassign_patient: UserX2,
};

const DeviceEventItem = ({ item }) => {
  return (
    <div
      key={item.id}
      className={cn(
        'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
      )}
    >
      <div className="flex w-full flex-col gap-1">
        <div className="flex items-center">
          <div className="flex items-center gap-2">
            <div className="font-semibold">
              {item?.activity_type &&
                React.createElement(
                  get(eventIcons, item.activity_type, Shield),
                  {
                    className: 'h-4',
                  },
                )}
            </div>
            <div className="flex flex-col gap-1 ">
              <div className="flex flex-row gap-1">
                <Badge variant="outline">
                  {item?.activity_type && titleize(item.activity_type)}
                </Badge>
              </div>
              {item?.value?.message && (
                <div className="text-xs font-medium">{item.value.message}</div>
              )}
              {item.value?.issue_type && (
                <div>
                  <div className="text-xs font-medium">
                    <span className="font-semibold">Issue Type:</span>{' '}
                    {titleize(item.value.issue_type)}
                  </div>
                  <div className="text-xs font-medium">
                    <span className="font-semibold">Description:</span>{' '}
                    {item.value.description}
                  </div>
                  <div className="text-xs font-medium">
                    <span className="font-semibold">Reported by patient:</span>{' '}
                    {item.value.reported_by_patient ? 'Yes' : 'No'}
                  </div>
                  <div className="text-xs font-medium">
                    <span className="font-semibold">Resolved:</span>{' '}
                    {item.value.is_resolved ? 'Yes' : 'No'}
                  </div>
                </div>
              )}
              {item.activity_type === 'device_ping' &&
                item.value &&
                item.value[0] && (
                  <div className="text-sm">
                    {item.value[0].battery !== undefined && (
                      <p className="flex items-center">
                        <Battery className="w-4 h-4 mr-2" />
                        <span>{item.value[0].battery}%</span>
                      </p>
                    )}
                    {item.value[0].signal !== undefined &&
                      item.value[0].network !== undefined && (
                        <p className="flex items-center">
                          <Signal className="w-4 h-4 mr-2" />
                          <span>
                            {item.value[0].signal} - {item.value[0].network}
                          </span>
                        </p>
                      )}
                  </div>
                )}
            </div>
          </div>
          <div className="ml-auto flex flex-col items-end ">
            <p className="text-xs font-semibold">{item?.actor_name}</p>
            {item.patient_id && (
              <div className="text-xs text-muted-foreground">
                Patient:{' '}
                <ReferenceField
                  record={item}
                  source="patient_id"
                  reference="patients"
                  link="show"
                >
                  <TextField source="user.full_name" />
                </ReferenceField>
              </div>
            )}
            <div className={cn('ml-auto text-xs', 'text-foreground')}>
              {formatDistanceToNow(
                DateTime.fromISO(item.created_on, {
                  zone: 'utc',
                })
                  .setZone('local')
                  .toJSDate(),
                {
                  addSuffix: true,
                },
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceEventItem;
