export const triageText = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  TEST: 'Test',
  DOC: 'Contact Physician',
  DOC_LOW: 'Contact Physician',
  DOC_HIGH: 'Contact Physician',
  ER: 'ER',
  ER_override: 'ER',
  DOC_override: 'Contact Physician',
};
