import { Button } from '@/modules/ui/components/button';
import { Input } from '@/modules/ui/components/input';
import { get } from 'lodash';
import { XCircle } from 'lucide-react';
import React, {
  ReactElement,
  ReactNode,
  cloneElement,
  useMemo,
  useState,
} from 'react';
import { useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { DataTableViewOptions } from './data-table-view-button';
import { cn } from '@/modules/ui/utils/cn';

const DefaultSearch = ({
  source = 'user.full_name;id',
  placeholder = 'Search patients...',
}) => {
  const { filterValues, setFilters } = useListContext();
  const [notification, setNotification] = useState('');

  const updateFilters = (searchValue) => {
    if (searchValue.length === 0) {
      const { [source]: _, ...filters } = filterValues;
      setFilters(filters, null, false);
    } else {
      setFilters(
        {
          ...filterValues,
          q: {
            field: source,
            value: searchValue,
          },
        },
        null,
      );
    }
  };

  const initialValues = useMemo(
    () => ({
      q:
        get(filterValues, 'q.field') === source
          ? get(filterValues, 'q.value', '')
          : '',
    }),
    [filterValues, source],
  );

  const form = useForm({ defaultValues: initialValues });

  const onSubmit = (data) => {
    const searchValue = get(data, 'q') ?? '';
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setNotification('');
      updateFilters(searchValue);
    } else {
      setNotification('Please enter at least 3 characters to search');
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Input
          placeholder={placeholder}
          className="h-8 w-[150px] lg:w-[250px]"
          {...form.register('q')}
        />
        {notification && (
          <div className="text-red-500 text-xs">{notification}</div>
        )}
      </form>
    </FormProvider>
  );
};

interface DataTableToolbarProps {
  search?: ReactElement | false;
  searchProps?: object;
  showViewOptions?: boolean;
  createButton?: ReactElement | false;
  actionButtons?: ReactElement[];
  children?: ReactNode;
  className?: string;
  preferenceKey?: string;
}

export function DataTableToolbar({
  search = <DefaultSearch />,
  searchProps = {},
  showViewOptions = false,
  createButton = false,
  actionButtons = null,
  preferenceKey,
  children,
  className,
}: DataTableToolbarProps) {
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const isFiltered = Object.keys(filterValues).length > 0;

  return (
    <div
      className={cn(
        'flex items-center justify-between my-2 min-w-full',
        className,
      )}
    >
      <div className="flex flex-row flex-1 flex-wrap items-center gap-2 ">
        {search ? cloneElement(search, searchProps) : null}
        {children ?? null}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => setFilters({}, displayedFilters)}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <XCircle className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex items-center space-x-2">
        {createButton ? cloneElement(createButton, { className: 'h-8' }) : null}
        {actionButtons
          ? actionButtons.map((Button, index) =>
              cloneElement(Button, { key: index }),
            )
          : null}
        {showViewOptions ? (
          <DataTableViewOptions preferenceKey={preferenceKey} />
        ) : null}
      </div>
    </div>
  );
}
