export function formatDuration(
  durationSeconds: number,
  abbreviated: boolean = false,
  shortMode: boolean = false,
): string {
  const days = Math.floor(durationSeconds / 86400);
  const hours = Math.floor((durationSeconds % 86400) / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = durationSeconds % 60;

  if (shortMode) {
    if (days > 0) {
      const totalDays =
        days + (hours > 0 || minutes > 0 || seconds > 0 ? 1 : 0);
      return abbreviated
        ? `${totalDays}d`
        : `${totalDays} day${totalDays > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      const totalHours = hours + (minutes > 0 || seconds > 0 ? 1 : 0);
      return abbreviated
        ? `${totalHours}h`
        : `${totalHours} hour${totalHours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      const totalMinutes = minutes + (seconds > 0 ? 1 : 0);
      return abbreviated
        ? `${totalMinutes}min`
        : `${totalMinutes} minute${totalMinutes > 1 ? 's' : ''}`;
    } else if (seconds > 0) {
      return abbreviated ? '1sec' : '1 second';
    } else {
      return '0 seconds';
    }
  }

  const parts = [];
  if (days > 0) {
    parts.push(abbreviated ? `${days}d` : `${days} day${days > 1 ? 's' : ''}`);
  }
  if (hours > 0) {
    parts.push(
      abbreviated ? `${hours}h` : `${hours} hour${hours > 1 ? 's' : ''}`,
    );
  }
  if (minutes > 0) {
    parts.push(
      abbreviated
        ? `${minutes}min`
        : `${minutes} minute${minutes > 1 ? 's' : ''}`,
    );
  }
  if (seconds > 0) {
    parts.push(
      abbreviated
        ? `${seconds}sec`
        : `${seconds} second${seconds > 1 ? 's' : ''}`,
    );
  }

  if (parts.length === 0) {
    return '0 seconds';
  } else if (parts.length === 1) {
    return parts[0];
  } else if (parts.length === 2) {
    return `${parts[0]} and ${parts[1]}`;
  } else {
    const lastPart = parts.pop();
    return `${parts.join(', ')} and ${lastPart}`;
  }
}
