import { Button } from '@/modules/ui/components/button';
import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { useNotify, useStore } from 'react-admin';

import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { FormProvider, useForm } from 'react-hook-form';

export function UserSettings() {
  const notify = useNotify();
  const [dailyTaskSummaryEmail, setDailyTaskSummaryEmail] = useStore(
    'notificationSettings.daily_task_summary_email',
  );
  const [monitoringProgressSummaryEmail, setMonitoringProgressSummaryEmail] =
    useStore('notificationSettings.monitoring_progress_summary_email');
  const [collapseSidebarOnClick, setCollapseSidebarOnClick] = useStore(
    'preferences.collapseSidebarOnClick',
  );

  const form = useForm({
    defaultValues: {
      daily_task_summary_email: dailyTaskSummaryEmail,
      monitoring_progress_summary_email: monitoringProgressSummaryEmail,
      collapseSidebarOnClick: collapseSidebarOnClick,
    },
  });

  const updateSettings = (data) => {
    setDailyTaskSummaryEmail(data.daily_task_summary_email);
    setMonitoringProgressSummaryEmail(data.monitoring_progress_summary_email);
    setCollapseSidebarOnClick(data.collapseSidebarOnClick);
    form.reset(data);
    notify('Settings saved.', { type: 'success' });
  };

  return (
    <Card className=" w-full">
      <CardHeader>
        <CardTitle className="text-2xl">Preferences</CardTitle>
      </CardHeader>
      <CardContent>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(updateSettings)}>
            <div className="flex flex-col gap-2">
              <p className="text-sm text-gray-500 font-semibold">
                Email Notifications
              </p>
              <div className="flex flex-col gap-2">
                <SwitchInput
                  source="daily_task_summary_email"
                  label="Daily Task Summary"
                  helperText="Receive a daily summary of your tasks and their statuses."
                  size="lg"
                />
                <SwitchInput
                  source="monitoring_progress_summary_email"
                  label="Monitoring Progress Summary"
                  helperText="Receive a daily summary of your monitoring progress of your patients."
                  size="lg"
                />
                <p className="text-sm text-gray-500 font-semibold">
                  UI Preferences
                </p>
                <SwitchInput
                  source="collapseSidebarOnClick"
                  label="Collapse Sidebar on Click"
                  helperText="Automatically collapse the sidebar when clicking on a menu item."
                  size="lg"
                />

                <Button
                  type="submit"
                  disabled={!form.formState.isDirty}
                  className="w-min self-end mt-2"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
}
