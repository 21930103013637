import React from 'react';
import { AppStorePreview } from './components/AppStorePreview';
import { GooglePLayPreview } from './components/GooglePlayPreview';
import { isAndroid } from 'react-device-detect';

export const Invitation = () => {
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      {isAndroid ? <GooglePLayPreview /> : <AppStorePreview />}
    </div>
  );
};
