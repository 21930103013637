import { Badge } from '@/modules/ui/components/badge';
import { useGetList, useRecordContext } from 'react-admin';
import React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { formatICD10Code } from '@/modules/icd10/utils/formatICD10Code';

const DiagnosisBadge = () => {
  const record = useRecordContext();

  const { data, total } = useGetList(
    `patients/${record?.id}/conditions`,
    {
      pagination: { page: 1, perPage: 20 },
      filter: {
        'source[eq]': 'vironix',
        'category[eq]': 'diagnosis',
      },
      sort: { field: 'created_at', order: 'DESC' },
    },
    {
      enabled: !!record,
    },
  );

  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-row gap-1 flex-wrap">
      {data && total > 0 ? (
        data.map((condition: any) => (
          <TooltipProvider key={condition.id}>
            <Tooltip>
              <TooltipTrigger>
                <Badge variant="outline" className="border-2 ">
                  {formatICD10Code(condition.additional_info.icd10_id)}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-base">{condition.name}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))
      ) : (
        <p className="text-base font-medium">Not set</p>
      )}
    </div>
  );
};

export default DiagnosisBadge;
