import React from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';

export default function AppTitle({ title, onlyHeader = false }) {
  const [domReady, setDomReady] = React.useState(false);

  React.useEffect(() => {
    setDomReady(true);
  }, []);

  if (!domReady) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{title} - Vironix</title>
      </Helmet>
      {!onlyHeader &&
        createPortal(
          <h1
            id="vironix-app-title"
            className="scroll-m-20 pt-2 pb-2 text-3xl font-semibold tracking-tight first:mt-0"
          >
            {title}
          </h1>,
          document.getElementById('vironix-app-title'),
        )}
    </>
  );
}
