import React from 'react';
import { Label } from '@/modules/ui/components/label';
import { EnrollmentDatePicker } from './EnrollmentDatePicker';
import { useEnrollmentQueue } from './EnrollmentQueueProvider';
import { Switch } from '@/modules/ui/components/switch';

export const EnrollmentListConfig = () => {
  const { config, setConfig } = useEnrollmentQueue();

  // Add UI components to modify the config here
  return (
    <div>
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2 mb-4"></div>
        <Label htmlFor="enrollment-date-picker">Select Range</Label>
        <EnrollmentDatePicker />
        <Label htmlFor="show-enrolled-patients">Show Enrolled Patients</Label>
        <Switch
          id="show-enrolled-patients"
          checked={config.showEnrolledPatients}
          onCheckedChange={(checked) =>
            setConfig((prevConfig) => ({
              ...prevConfig,
              showEnrolledPatients: checked,
            }))
          }
        />
      </div>
    </div>
  );
};
