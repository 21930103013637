import { patientStatus } from '../constants/patientStatus';
import React from 'react';
import {
  required,
  useCreate,
  useGetIdentity,
  useRecordContext,
  FormDataConsumer,
} from 'react-admin';

import inflection from 'inflection';
import { DateTime } from 'luxon';
import CreateDialog from '@/modules/ra-ui/components/create-dialog';
import { declinedReasons } from '@/modules/enrollment/constants/declinedReasons';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { unseenReasons } from '../constants/unseenReasons';
export function PatientStatusDialog({ open, setOpen }) {
  const record = useRecordContext();
  const [create] = useCreate();
  const { identity } = useGetIdentity();

  const transform = (data) => {
    return { patient_status: data.status };
  };

  if (!record) return null;

  const logMonitoringNote = (data) => {
    const patient_id = record?.id;

    create(`monitoring-activities`, {
      data: {
        patient_id: patient_id,
        practitioner_id: identity?.id,
        reviewed_on: DateTime.utc().toISO({ includeOffset: false }),
        type: 'activity',
        description:
          'Patient status updated to ' +
          inflection.humanize(data.patient_status, true),
      },
    });
  };
  return (
    <CreateDialog
      title="Update patient status"
      resource={`patients/${record.id}/status`}
      transform={transform}
      notifyMessage="Patient status updated"
      open={open}
      setOpen={setOpen}
      refreshOnSuccess={true}
      onSuccess={(data) => {
        logMonitoringNote(data);
      }}
      dialogVariant="shadcn"
    >
      <div className="flex flex-col gap1">
        <SelectInput
          source="status"
          choices={patientStatus.filter((status) => !status.disabled)}
          optionText="name"
          helperText={false}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.status === 'declined' ? (
              <SelectInput
                source="declined_reason"
                choices={declinedReasons}
                validate={required()}
              />
            ) : formData.status === 'unseen' ? (
              <SelectInput
                source="unseen_reason"
                choices={unseenReasons}
                validate={required()}
              />
            ) : null
          }
        </FormDataConsumer>
      </div>
    </CreateDialog>
  );
}
