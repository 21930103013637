const presetNotes = {
  rpm_99457_99458: [
    {
      name: 'Reviewed Patient Vitals - General Check',
      body: 'Reviewed patient vitals. [VITALS_DETAILS]. Will continue to monitor. [ADDITIONAL_NOTES].',
    },
    {
      name: 'Reviewed Patient Vitals - No Data Received',
      body: 'Reviewed patient vitals, no vitals in [NUMBER_OF_DAYS] days. Attempted to contact patient for missing data. Will continue to monitor. [TEAM_MEMBER/CLINICIAN] is aware.',
    },
    {
      name: 'Reviewed Patient Vitals - Blood Pressure Monitoring',
      body: 'Reviewed patient vitals, patients BP [WENT_UP/WENT_DOWN] by [AMOUNT]. [ADDITIONAL_BP_NOTES]. Will continue to monitor BP. [TEAM_MEMBER/CLINICIAN] is aware.',
    },
    {
      name: 'Patient Communication - Compliance and Health Check',
      body: 'Contacted patient regarding [ISSUE]. Patient [RESPONSE]. Advised patient to [ACTION]. Will continue to monitor and follow up on [DATE/TIME]. [TEAM_MEMBER/CLINICIAN] is aware.',
    },
    {
      name: 'Reviewed Patient Vitals - Specific Observation',
      body: 'Reviewed patient vitals, noted [SPECIFIC_OBSERVATION] (e.g., significant drop in BP, high glucose reading). Contacted patient/family to ensure they are doing alright and not experiencing any pain-related symptoms. [ADDITIONAL_ACTIONS]. [TEAM_MEMBER/CLINICIAN] is aware.',
    },
    {
      name: 'Followed-up with Noncompliant Patient',
      body: 'No vitals from patient in [NUMBER_OF_DAYS] days. Attempted to contact via [CALL/TEXT], [RESPONSE_RECEIVED/NO_RESPONSE]. Reminded patient of the importance of daily vitals monitoring. Will continue to attempt contact. [TEAM_MEMBER/CLINICIAN] is aware.',
    },
    {
      name: 'Reminded Patient about Onboarding',
      body: 'Contacted patient to complete onboarding process. [LEFT_VOICEMAIL/SENT_TEXT_MESSAGE/SPOKE_DIRECTLY] to remind them to [SPECIFIC_ACTION_NEEDED]. [PATIENT_RESPONSE].',
    },
    {
      name: 'Addressed Device Issue',
      body: 'Reviewed patient vitals, Pts reported issues with [DEVICE_NAME]. Advised to [TROUBLESHOOTING_STEP]. [OUTCOME_OF_TROUBLESHOOTING]. Will follow up for [REPLACEMENT/CONFIRMATION_OF_RESOLUTION]. [TEAM_MEMBER/CLINICIAN] is aware.',
    },
    {
      name: 'Monitored Weight',
      body: 'Reviewed patient vitals, Pts weight recorded at [INSERT_WEIGHT]. [NOTED_ANY_SIGNIFICANT_CHANGE_FROM_PREVIOUS_MEASUREMENT]. [ANY_ACTION_TAKEN_OR_ADVICE_GIVEN]. Will continue to monitor weight closely. [TEAM_MEMBER/CLINICIAN] is aware.',
    },
    {
      name: 'Coordinated Care with Clinician',
      body: "Discussed [PATIENT_NAME]'s recent vitals and health status with [OTHER_CLINICIAN/HEALTHCARE_PROVIDER'S_NAME]. Agreed on [ANY_CHANGES_TO_CARE_PLAN/MONITORING_STRATEGY]. [PATIENT_NAME] informed and agrees with the plan. Will implement changes and monitor impact. [TEAM_MEMBER/CLINICIAN] is aware.",
    },
    {
      name: 'Discussed Care Plan with Patient',
      body: 'Discussed recent vitals and health status with patient. Agreed on [ANY_CHANGES_TO_CARE_PLAN/MONITORING_STRATEGY]. [PATIENT_NAME] informed and agrees with the plan. Will implement changes and monitor impact.',
    },
  ],
  ccm_99490_99439: [
    {
      name: 'Reviewed Health Data',
      body: "Pt.'s health data reviewed. [Additional Notes]",
    },
    {
      name: 'Discussed Case with Clinician',
      body: 'Case discussed with [Name], [Role]. [Details]',
    },
    {
      name: 'Attempted Call to Patient',
      body: 'Attempted to contact pt. [Outcome]. [Next Steps]',
    },
    {
      name: 'Completed Virtual Assessment',
      body: 'Virtual Physical Assessment completed. [Details]',
    },
    {
      name: 'Reviewed Medication and Care Plan',
      body: "Reviewed pt.'s medications and care plan. [Modifications/Recommendations]",
    },
    {
      name: 'Addressed Physical Symptoms',
      body: 'Addressed physical symptoms reported by pt. [Symptoms]. [Actions Taken]',
    },
    {
      name: 'Updated Monitoring Plan',
      body: 'Monitoring plan for pt. updated. [Details]',
    },
    {
      name: 'Provided Health Education',
      body: 'Provided health education to pt. on [Topic]. [Key Points]',
    },
    {
      name: 'Troubleshot Device Problems',
      body: 'Assisted pt. with device troubleshooting. [Issue & Resolution]',
    },
  ],

  rpm_99453: [
    {
      name: 'Initiated RPM',
      body: "1. Have the patients' devices been set up and successfully tested?\n2. Have the Patient Demographics been entered correctly in the chart with the most recent updated Information?\n3. Did Patient ICD codes get entered correctly into the chart along with the correct Billing type?\n4. Have the patient Medications been entered into the chart with the most recent medication list?\n5. Have the patient's medical Conditions been entered into the chart correctly?\n6. Does the patient have the correct care team?\n7. Did the patient get set up with Vironix App on either an iOS device or Android Device?\n8. Has the patient verbally given an understanding of the importance of taking vitals at least one time a day, every day.",
    },
  ],
};

export default presetNotes;
