export const cptCodeInfo = {
  '99453': {
    description: 'Initial Setup of Device',
    claim_min: 1,
    frequency: 'patient_setup',
    monthly_instance_cap: '1',
    type: 'instance',
  },
  '99454': {
    description: 'Device Supply With Daily Recordings and Programmed Alerts',
    claim_min: 16,
    frequency: 'monthly',
    monthly_instance_cap: '1',
    units: 'days',
    type: 'days',
  },
  '99457': {
    description: '20 Minutes of Monitoring and Treatment Management',
    claim_min: 20,
    frequency: 'monthly',
    monthly_instance_cap: '1',
    units: 'minutes',
    type: 'time',
  },
  '99458': {
    description: 'Additional 20 Minutes of Monitoring and Treatment Management',
    claim_min: 20,
    frequency: 'monthly',
    monthly_instance_cap: '2',
    units: 'minutes',
    type: 'time',
  },
  '99091': {
    description: 'Collection and interpretation of physiological data',
    claim_min: 30,
    frequency: 'monthly',
    monthly_instance_cap: '2',
    units: 'minutes',
    type: 'time',
  },
  '99490': {
    description: '20 Minutes of CCM Management Services - Clinical Staff',
    claim_min: 20,
    frequency: 'monthly',
    monthly_instance_cap: '1',
    units: 'minutes',
    type: 'time',
  },
  '99439': {
    description:
      'Additional 20 Minutes of CCM Management Services - Clinical Staff',
    claim_min: 20,
    frequency: 'monthly',
    monthly_instance_cap: '*',
    units: 'minutes',
    type: 'time',
  },
  '99491': {
    description: '30 Minutes of CCM Management Services - QHP Staff',
    claim_min: 30,
    frequency: 'monthly',
    monthly_instance_cap: '1',
    units: 'minutes',
    type: 'time',
  },
  '99437': {
    description: 'Additional 30 Minutes of CCM Management Services - QHP Staff',
    claim_min: 30,
    frequency: 'monthly',
    monthly_instance_cap: '*',
    units: 'minutes',
    type: 'time',
  },
  '99487': {
    description: '60 Minutes of CCCM Management Services - Clinical Staff',
    claim_min: 60,
    frequency: 'monthly',
    monthly_instance_cap: '1',
    units: 'minutes',
    type: 'time',
  },
  '99489': {
    description:
      'Additional 30 Minutes of CCCM Management Services - Clinical Staff',
    claim_min: 30,
    frequency: 'monthly',
    monthly_instance_cap: '*',
    units: 'minutes',
    type: 'time',
  },
  '99424': {
    description: '30 Minutes of PCM Management Services - QHP',
    claim_min: 30,
    frequency: 'monthly',
    monthly_instance_cap: '1',
    units: 'minutes',
    type: 'time',
  },
  '99425': {
    description: 'Additional 30 Minutes of PCM Management Services - QHP',
    claim_min: 30,
    frequency: 'monthly',
    monthly_instance_cap: '*',
    units: 'minutes',
    type: 'time',
  },
  '99426': {
    description: '30 Minutes of PCM Management Services - Clinical Staff',
    claim_min: 30,
    frequency: 'monthly',
    monthly_instance_cap: '1',
    units: 'minutes',
    type: 'time',
  },
  '99427': {
    description:
      'Additional 30 Minutes of PCM Management Services - Clinical Staff',
    claim_min: 30,
    frequency: 'monthly',
    monthly_instance_cap: '*',
    units: 'minutes',
    type: 'time',
  },
};
