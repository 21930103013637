export const encounterStatus = [
  {
    id: 'planned',
    name: 'Planned',
  },
  {
    id: 'in_progress',
    name: 'In Progress',
  },
  {
    id: 'on_hold',
    name: 'On Hold',
  },
  {
    id: 'discharged',
    name: 'Discharged',
  },
  {
    id: 'completed',
    name: 'Completed',
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
  },
  {
    id: 'discontinued',
    name: 'Discontinued',
  },
  {
    id: 'entered_in_error',
    name: 'Entered In Error',
  },
  {
    id: 'unknown',
    name: 'Unknown',
  },
];
