import { get } from 'lodash';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { cropText } from '@/modules/patients/utils/cropText';
import { Avatar, AvatarFallback } from '@/modules/ui/components/avatar';
import { cn } from '@/modules/ui/utils/cn';
import { getInitials } from '@/utils/getInitials';

export const NameAvatarField = (props) => {
  const { className } = props;
  const record = useRecordContext();
  const name = get(record, 'full_name');
  const email = get(record, 'email');

  return (
    <div className="flex flex-row gap-2 items-center">
      <Avatar className="h-8 w-8">
        <AvatarFallback>{getInitials(name)}</AvatarFallback>
      </Avatar>
      <div>
        <span className={cn('font-semibold', className)}>
          {name}{' '}
          <span className="font-normal inline-flex">
            ({cropText(email, 30)})
          </span>
        </span>
      </div>
    </div>
  );
};
