import { Button } from '@/modules/ui/components/button';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '@/modules/ui/components/input-otp';
import { Separator } from '@/modules/ui/components/separator';
import { LoaderIcon } from 'lucide-react';
import { useLogin, useNotify } from 'ra-core';
import React, { useState, useRef, useEffect } from 'react';
import { useMFAToken } from './MFATokenProvider';

export function TOTPLogin({ setView }) {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const { token } = useMFAToken();
  const login = useLogin();
  const [otpCode, setOtpCode] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login({ mfaToken: token, mfaType: 'totp', totp: otpCode });
    } catch (error) {
      console.error('TOTP verification failed:', error);
      if (error.status === 401 && error.message === 'Token has expired') {
        notify(
          "Oops! Your session timed out. No worries, let's try that again!",
          { type: 'warning' },
        );
        setTimeout(() => setView('login'), 3000);
      } else {
        notify('TOTP verification failed. Please try again.', {
          type: 'error',
        });
      }
      setOtpCode(''); // Clear the OTP input on error
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CardHeader className="text-center">
        <CardTitle className="text-2xl text-center">
          Verify Your Identity
        </CardTitle>
        <CardDescription>
          Enter the 6-digit code from your authenticator app.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 items-center"
        >
          <InputOTP
            maxLength={6}
            value={otpCode}
            onChange={setOtpCode}
            ref={inputRef}
          >
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
            </InputOTPGroup>
            <InputOTPSeparator />
            <InputOTPGroup>
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>

          <Button
            variant="default"
            className="w-full"
            type="submit"
            disabled={loading || otpCode.length !== 6}
          >
            {loading ? <LoaderIcon className="animate-spin mr-2" /> : null}
            Verify
          </Button>
          <Separator className="w-full" />
          <Button
            variant="ghost"
            className="w-full"
            onClick={() => setView('login')}
            type="button"
          >
            Back
          </Button>
        </form>
      </CardContent>
    </>
  );
}
