import { monitoringActivityTypes } from '@/modules/monitoring-activity/constants/monitoringActivityTypes';
import { serviceInstanceTypesAbbreviated } from '@/modules/patients/constants/serviceInstanceTypesAbbreviated';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { Stack } from '@mui/material';
import { get, map } from 'lodash';
import React from 'react';
import {
  BooleanInput,
  DeleteWithUndoButton,
  ReferenceInput,
  SelectInput,
  required,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import IncompatibleBillingWarning from './IncompatibleBillingWarning';
import { ManualTimer, NotesFieldLegacy } from './MonitoringActivityCreate';

import DateTimeProviderTZInput from '@/modules/ra-ui/components/DateTimeProviderTZInput';
import { filter } from 'lodash';

const MonitoringActivitySelectType = () => {
  const record = useRecordContext();
  const serviceInstanceId = useWatch({ name: 'service_instance_id' });
  const { data, isLoading } = useGetOne(
    'service-instances',
    { id: serviceInstanceId },
    { enabled: !!serviceInstanceId },
  );

  const monitoringActivityTypeChoices = map(
    monitoringActivityTypes,
    (v, k) => ({ name: v, id: k }),
  );

  let choices = monitoringActivityTypeChoices;
  if (data) {
    choices = filter(monitoringActivityTypeChoices, (v) =>
      v.id.startsWith(data.type),
    );
    choices.push({
      name: 'Note',
      id: 'note',
    });
  } else {
    choices = [
      {
        name: 'Note',
        id: 'note',
      },
    ];
  }

  return (
    <div className="flex flex-col gap-1 pb-2">
      <SelectInput
        source="type"
        choices={choices}
        fullWidth
        helperText="Specify which service code this work belongs to."
        disabled={isLoading}
        validate={required()}
      />
      <IncompatibleBillingWarning
        patientId={record?.patient_id}
        hasDevice={null}
        lastDeviceMeasurement={null}
      />
    </div>
  );
};

export function MonitoringActivityEditDialog(props) {
  const record = useRecordContext();
  const { tenant_id } = props;

  if (record.type === 'activity') {
    return null;
  }

  return (
    <EditInDialogButton
      title={`Edit monitoring activity (#${record?.id})`}
      deleteButton={<DeleteWithUndoButton redirect={false} />}
      resource="monitoring-activities"
      closeOnClickOutside={false}
    >
      <Stack>
        <div className="flex flex-row gap-1 items-center ">
          <ManualTimer variant="simple" />
          <DateTimeProviderTZInput
            source="reviewed_on"
            label="Date"
            tenant_id={tenant_id}
          />
        </div>
        <Stack gap={1} direction="row">
          <ReferenceInput
            source="service_instance_id"
            reference="service-instances"
            filter={{
              'is_active[eq]': true,
              'patient_id[eq]': record.patient_id,
            }}
          >
            <SelectInput
              label="Care Program"
              optionText={(record) =>
                get(serviceInstanceTypesAbbreviated, record.type, record.type)
              }
              sx={{
                maxWidth: '300px',
              }}
            />
          </ReferenceInput>
          <MonitoringActivitySelectType />
        </Stack>
        <Stack gap={1} direction="row">
          <BooleanInput
            source="data_reviewed"
            defaultValue={false}
            helperText={false}
          />
          <BooleanInput
            source="patient_contacted"
            defaultValue={false}
            helperText={false}
          />
        </Stack>
        <NotesFieldLegacy
          label="Notes"
          source="description"
          validate={required('Notes are required.')}
        />
      </Stack>
    </EditInDialogButton>
  );
}
