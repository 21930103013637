export const patientPriorityLevel = [
  {
    id: 'normal',
    name: 'Normal',
    className: 'border-green-300 bg-green-100',
  },
  {
    id: 'medium',
    name: 'Medium',
    className: 'border-yellow-300 bg-yellow-100',
  },
  {
    id: 'high',
    name: 'High',
    className: 'border-orange-300 bg-orange-100',
  },
  {
    id: 'acute',
    name: 'Acute',
    className: 'border-red-300 bg-red-100',
  },
  {
    id: 'blank',
    name: 'N/A',
  },
];
