export const encounterType = [
  {
    id: 'physician_contact_noncompliance',
    name: 'Physician Contact - Noncompliance',
  },
  {
    id: 'physician_contact_health_escalation',
    name: 'Physician Contact - Health Escalation',
  },
  {
    id: 'routine_checkup',
    name: 'Routine Checkup',
  },
  {
    id: 'specialty_consultation',
    name: 'Specialty Consultation',
  },
  {
    id: 'follow_up_visit',
    name: 'Follow-Up Visit',
  },
  {
    id: 'preventive_screening',
    name: 'Preventive Screening',
  },
  {
    id: 'immunization_visit',
    name: 'Immunization Visit',
  },
  {
    id: 'prenatal_visit',
    name: 'Prenatal Visit ',
  },
  {
    id: 'obgyn_visit',
    name: 'OB/GYN Visit',
  },
  {
    id: 'mental_health_session',
    name: 'Mental Health Session',
  },
  {
    id: 'surgical_procedure',
    name: 'Surgical Procedure',
  },
  {
    id: 'diagnostic_testing',
    name: 'Diagnostic Testing',
  },
  {
    id: 'hospital_admission',
    name: 'Hospital Admission',
  },
  {
    id: 'home_health_visit',
    name: 'Home Health Visit',
  },
  {
    id: 'hospice_care_facility',
    name: 'Hospice Care Facility',
  },
  {
    id: 'assisted_living_home_admission',
    name: 'Assisted Living Home Admission',
  },
  {
    id: 'virtual_telehealth_checkup',
    name: 'Virtual/Telehealth Check-Up',
  },
  {
    id: 'nursing_skilled_facility',
    name: 'Nursing Skilled Facility',
  },
  {
    id: 'emergency_icu',
    name: 'Emergency - ICU',
  },
  {
    id: 'emergency_er',
    name: 'Emergency - ER',
  },
];
