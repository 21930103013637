export const deviceManufacturers = [
  {
    id: 'tenovi',
    name: 'Tenovi',
  },
  {
    id: 'tellihealth',
    name: 'Tellihealth',
  },
  {
    id: 'bodytrace',
    name: 'BodyTrace',
  },
  {
    id: 'smartmeter',
    name: 'Smart Meter',
  },
  {
    id: 'freestylelibre',
    name: 'FreeStyle Libre',
  },
  {
    id: 'transtek',
    name: 'Transtek',
  },
  {
    id: 'other',
    name: 'Other',
  },
];
