import DateTimeProviderTZInput from '@/modules/ra-ui/components/DateTimeProviderTZInput';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { get } from 'lodash';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useRecordContext,
} from 'react-admin';

const filterUserName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

export const EnrollmentEditButton = ({ label = 'Edit' }) => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  let filters: any = {
    'role[ne]': `patient`,
    'tenant_id[in]': [get(record, 'user.tenant_id'), null],
  };

  return (
    <EditInDialogButton
      title="Edit Enrollment Information"
      resource="patients"
      variant="outline"
      refreshOnSuccess={true}
      stopPropagation={true}
    >
      <div className="flex flex-col gap-2">
        <div className="mb-2 text-lg">{record.user?.full_name}</div>
        <TextInput
          source="eligible_devices"
          label="Eligible Devices"
          helperText={false}
        />
        <TextInput source="insurances" label="Insurances" helperText={false} />
        <SelectInput
          source="insurance_coverage_type"
          label="Insurance Coverage Type"
          choices={[
            { id: 'partial', name: 'Partial' },
            { id: 'full', name: 'Full' },
            { id: 'other', name: 'Other' },
          ]}
          helperText={false}
        />
        <DateTimeProviderTZInput source="onboarding_appointment_datetime" />
        <ReferenceInput
          source="onboarding_appointment_location_id"
          reference="locations"
          label="Appointment Location"
          filter={{
            'tenant_id[eq]': record?.user?.tenant_id,
          }}
        >
          <AutocompleteInput
            label="Appointment Location"
            optionText="name"
            parse={(v) => (v === '' ? null : v)}
            helperText={false}
          />
        </ReferenceInput>
        <TextInput
          source="expected_care_program"
          label="Expected Care Program"
          helperText={false}
        />

        <ReferenceInput
          source="expected_onboarding_user_id"
          reference="users"
          label="Enrollment Clinician"
          filter={filters}
        >
          <AutocompleteInput
            label="Enrollment Clinician"
            optionText={(choice) => {
              if (choice.is_internal) {
                return `${choice.first_name} ${choice.last_name} (Vironix) ${
                  choice.credentials ? `(${choice.credentials})` : ``
                }`;
              }
              return `${choice.full_name} ${
                choice.credentials ? `(${choice.credentials})` : ``
              }`;
            }}
            parse={(v) => (v === '' ? null : v)}
            helperText={false}
            filterToQuery={filterUserName}
          />
        </ReferenceInput>
        <BooleanInput
          source="virtual_enrollment"
          label="Virtual Enrollment"
          helperText={false}
        />
      </div>
    </EditInDialogButton>
  );
};
