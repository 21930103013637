import { Box, Grid } from '@mui/material';
import React from 'react';
import { Form, SaveButton, Title } from 'react-admin';

export const BaseForm = (props) => {
  const {
    title,
    saveButton = <SaveButton />,
    padding = 4,
    children,
    ...rest
  } = props;
  return (
    <Box sx={{ p: padding }}>
      <Title title={title} />
      <Form {...rest}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end">
              {saveButton && <Grid item>{saveButton}</Grid>}
            </Grid>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Form>
    </Box>
  );
};
