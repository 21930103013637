import { DataTableFilterSingle } from '@/modules/data-table/components/data-table-filter-single';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import AppTitle from '@/modules/layout/components/app-title';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { TaskEditButton } from '@/modules/tasks/components/TaskEditButton';
import React, { FC, useEffect, useState } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useGetIdentity,
} from 'react-admin';
import { get } from 'lodash';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import { AssignTasksButton } from '@/modules/tasks/components/AssignTasksButton';
import { Badge } from '@/modules/ui/components/badge';
import { cropText } from '@/modules/patients/utils/cropText';
import DataTableReferenceFilter from '@/modules/data-table/components/data-table-reference-filter';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';

const Toolbar = ({ setSelectedTab, selectedTab }) => {
  const { identity } = useGetIdentity();

  if (!identity) return null;

  return (
    <div className="w-full">
      <TabbedWrapper
        options={[
          { value: 'false', title: 'Not Completed' },
          { value: 'true', title: 'Completed' },
          { value: '', title: 'All' },
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      >
        <DataTableToolbar
          showViewOptions={false}
          search={false}
          className="px-2"
        >
          <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
          <DataTableFilterSingle
            column="assigned_to_id[eq]"
            title="Assigned To Me"
            options={[
              { label: 'Yes', value: get(identity, 'id', '').toString() },
            ]}
          />
          <DataTableFilterSingle
            column="added_by_id[eq]"
            title="Added By Me"
            options={[
              { label: 'Yes', value: get(identity, 'id', '').toString() },
            ]}
          />
          <DataTableReferenceFilter
            resource="users"
            column="assigned_to_id[eq]"
            title="Assigned To"
            optionText="full_name"
            customFilter={(name) => ({
              ...(name &&
                name.length > 1 && {
                  q: { field: 'full_name', value: name },
                }),
              'role[ne]': `patient`,
            })}
          />
          <DataTableReferenceFilter
            resource="users"
            column="added_by_id[eq]"
            title="Added By"
            optionText="full_name"
            customFilter={(name) => ({
              ...(name &&
                name.length > 1 && {
                  q: { field: 'full_name', value: name },
                }),
              'role[ne]': `patient`,
            })}
          />
          <DataTableFilter
            column="task_type[in]"
            title="Task Type"
            options={[
              { label: 'One off', value: 'one_off' },
              { label: 'Onboarding', value: 'onboarding' },
            ]}
          />
        </DataTableToolbar>
      </TabbedWrapper>
    </div>
  );
};

export const TaskList: FC = (props) => {
  const [selectedTab, setSelectedTab] = useState('false');

  const [cropSize, setCropSize] = useState(50);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 640) {
        // sm
        setCropSize(30);
      } else if (width < 768) {
        // md
        setCropSize(40);
      } else if (width < 1024) {
        // lg
        setCropSize(50);
      } else if (width < 1280) {
        // xl
        setCropSize(60);
      } else {
        // 2xl and above
        setCropSize(100);
      }
    };

    handleResize(); // Set initial size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <AppTitle title="Tasks" />
      <List
        {...props}
        exporter={false}
        actions={
          <Toolbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        }
        filter={{
          'complete[eq]': selectedTab !== '' ? selectedTab : undefined,
        }}
      >
        <Datagrid
          rowClick={false}
          bulkActionButtons={
            <>
              <AssignTasksButton />
            </>
          }
        >
          <TextField source="title" />
          <FunctionField
            render={(record) => cropText(record.description, cropSize)}
            label="Description"
            sortable={false}
          />
          <BadgeField source="task_type" />
          <FunctionField
            source="priority"
            render={(record) => {
              const priority = record.priority;
              const priorityMap = {
                1: { label: 'High', color: 'red' },
                2: { label: 'Medium', color: 'yellow' },
                3: { label: 'Low', color: 'green' },
              };
              const { label, color } = priorityMap[priority] || {
                label: priority,
                color: 'gray',
              };
              if (!label) return null;
              return (
                <Badge
                  variant="outline"
                  className={`bg-${color}-100 text-${color}-800 border-${color}-300`}
                >
                  {label}
                </Badge>
              );
            }}
          />
          <ReferenceField
            label="Patient"
            source="patient_id"
            reference="patients"
            link="show"
            sortBy="user.last_name"
          >
            <TextField source="user.full_name" />
          </ReferenceField>
          <TextField source="assigned_to_name" label="Assigned To" />
          <BooleanField source="complete" />
          <DateField source="created_on" />
          <ReferenceField
            label="Added By"
            source="added_by_id"
            reference="users"
            link="show"
            sortBy="last_name"
          >
            <TextField source="full_name" />
          </ReferenceField>
          {selectedTab === 'true' ? (
            <DateField source="completed_at" />
          ) : (
            <DateField source="due_on" />
          )}
          <TaskEditButton />
        </Datagrid>
      </List>
    </>
  );
};
