import React from 'react';

function Copyright() {
  return (
    <div className="flex flex-row gap-4 items-center mx-auto self-end place-self-end	">
      <p className="text-sm text-gray-500">
        {'Copyright © '}
        <a className="text-gray-500" href="https://vironix.ai">
          Vironix
        </a>{' '}
        {new Date().getFullYear()}
      </p>
    </div>
  );
}

export default Copyright;
