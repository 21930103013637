import { Button } from '@/modules/ui/components/button';
import { Pencil } from 'lucide-react';

import React, { cloneElement } from 'react';
import EditDialog from './edit-dialog';
import { cn } from '@/modules/ui/utils/cn';

const EditInDialogDefaultButton = (props) => {
  const { label = null, icon = <Pencil />, editButtonVariant, ...rest } = props;

  return (
    <Button
      size="sm"
      className="h-8  hover:bg-gray-300"
      variant={editButtonVariant}
      {...rest}
    >
      {cloneElement(icon, {
        className: cn('h-4 w-4 fill-primary', { label: 'mr-2' }),
      })}
      {label ? label : null}
    </Button>
  );
};

export default function EditInDialogButton(props) {
  const {
    label = null,
    icon = <Pencil />,
    editButton = null,
    editButtonVariant = 'link',
    ...rest
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  return (
    <>
      {editButton ? (
        cloneElement(editButton, { onClick: handleClickOpen })
      ) : (
        <EditInDialogDefaultButton
          label={label}
          icon={icon}
          onClick={handleClickOpen}
          variant={editButtonVariant}
        />
      )}
      <EditDialog open={open} setOpen={setOpen} {...rest} />
    </>
  );
}
