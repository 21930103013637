import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { Input } from '@/modules/ui/components/input';
import inMemoryJWT from '@/utils/inMemoryJWT';
import {
  startRegistration,
  browserSupportsWebAuthn,
} from '@simplewebauthn/browser';
import { Loader2, LockKeyhole, Trash } from 'lucide-react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import {
  useDelete,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRefresh,
} from 'react-admin';

const PassKeyDialog = () => {
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { identity } = useGetIdentity();
  const notify = useNotify();

  const [name, setName] = useState('');

  const setKeyName = (e) => {
    setName(e.target.value);
  };

  const checkBrowserSupport = () => {
    if (!browserSupportsWebAuthn()) {
      notify(
        'Your browser does not support WebAuthn. Please use a modern browser that supports this feature.',
        { type: 'error' },
      );
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (open) {
      checkBrowserSupport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getRegistrationOptions = async () => {
    try {
      const token = await inMemoryJWT.getToken('passkey');
      const response = await fetch(
        `${window.config.apiUrl}/auth/webauthn/register?authenticator_type=platform`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
        },
      );
      if (!response.ok) {
        throw new Error('Failed to fetch registration options');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching registration options:', error);
      notify('Failed to fetch registration options. Please try again.', {
        type: 'error',
      });
      throw error;
    }
  };

  const createPasskey = async (attResp) => {
    const token = await inMemoryJWT.getToken('passkey');
    const response = await fetch(
      `${window.config.apiUrl}/auth/webauthn/register`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...attResp,
          name: name,
        }),
        credentials: 'include',
      },
    );
    if (!response.ok) {
      throw new Error('Failed to create passkey');
    }
    return await response.json();
  };

  const handleSetupPasskey = async () => {
    setIsLoading(true);
    try {
      const data = await getRegistrationOptions();
      const attResp = await startRegistration(data);

      await createPasskey(attResp);
      refresh();
      notify('Passkey created successfully', { type: 'success' });
      setOpen(false);
    } catch (error) {
      console.error('Error setting up passkey:', error);
      if (
        error instanceof Error &&
        error.name === 'InvalidStateError' &&
        error.message.includes('The authenticator was previously registered')
      ) {
        notify(
          'This authenticator was previously registered. Please try a different device or browser.',
          {
            type: 'error',
          },
        );
      } else {
        notify('Failed to create passkey. Please try again.', {
          type: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const { data: userData } = useGetOne(
    'users',
    { id: identity?.id },
    { enabled: !!identity?.id },
  );

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="default"
        size="sm"
        className="w-fit mx-auto"
        type="button"
      >
        Register new key
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          className="min-w-[400px] overflow-y-auto max-h-[90vh]"
          onClose={handleClose}
        >
          <DialogHeader>
            <DialogTitle>Register a new security key</DialogTitle>
          </DialogHeader>
          {!userData?.has_passkey && (
            <p className="mb-4">
              Passkeys are WebAuthn credentials that validate your identity
              using touch, facial recognition, a device password, or a PIN. They
              can be used as a password replacement or as a 2FA method. Passkeys
              provide a simple and secure alternative to your password and
              two-factor credentials for sign-in.
            </p>
          )}
          <div className="flex flex-row gap-2 items-center justify-start w-fit">
            <Input
              value={name}
              onChange={setKeyName}
              placeholder="Enter a nickname for this key"
              className="w-fit"
            />
            <Button
              onClick={handleSetupPasskey}
              className="mx-auto w-fit"
              disabled={isLoading || !name}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Creating...
                </>
              ) : (
                'Create Passkey'
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const SecurityKey = () => {
  const { identity } = useGetIdentity();

  const { data: keys } = useGetOne(
    'auth/webauthn',
    { id: 'keys' },
    { enabled: !!identity?.id },
  );

  const [edit, setEdit] = useState(false);
  const keyCount = keys?.credentials?.length || 0;

  const notify = useNotify();
  const refresh = useRefresh();
  const [deleteKey] = useDelete();

  const handleDeleteSecurityKey = async (keyId: string) => {
    try {
      await deleteKey(
        'auth/webauthn/keys',
        { id: keyId },
        {
          onSuccess: () => {
            notify('Security key deleted successfully', { type: 'success' });
            refresh();
          },
          onError: (error) => {
            console.error('Error deleting security key:', error);
            notify('Failed to delete security key. Please try again.', {
              type: 'error',
            });
          },
        },
      );
    } catch (error) {
      console.error('Error deleting security key:', error);
      notify('Failed to delete security key. Please try again.', {
        type: 'error',
      });
    }
  };

  return (
    <div className="flex flex-col gap-4 p-4 border rounded-lg shadow-sm">
      <div className="flex items-center gap-4 justify-between">
        <div className="flex items-center gap-2">
          <LockKeyhole className="w-6 h-6 text-gray-500" />
          <div>
            <strong>Security Keys:</strong>{' '}
            <span>{keyCount ? `${keyCount} Enabled` : 'None'}</span>
          </div>
        </div>
        <Button
          onClick={() => setEdit(!edit)}
          variant="outline"
          size="sm"
          className="text-black hover:bg-gray-100"
          type="button"
        >
          {edit ? 'Hide' : 'Edit'}
        </Button>
      </div>
      {edit && (
        <div className="flex flex-col gap-2">
          {keys?.credentials?.map((key, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-2 bg-gray-50 rounded-md"
            >
              <div>
                <div className="font-semibold">{key.name}</div>
                <div className="text-sm text-gray-500">
                  Created on:{' '}
                  {DateTime.fromISO(key.created_on).toLocaleString(
                    DateTime.DATE_MED,
                  )}
                </div>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleDeleteSecurityKey(key.id)}
              >
                <Trash className="h-4 w-4" />
              </Button>
            </div>
          ))}
          <PassKeyDialog />
        </div>
      )}
    </div>
  );
};
