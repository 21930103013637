import { Chip, Tooltip } from '@mui/material';
import { cptCodeInfo } from '@/modules/patients/constants/cptCodeInfo';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';
import React from 'react';

export const CPTCodeField = (props) => {
  const record = useRecordContext();
  const codeInfo = get(cptCodeInfo, record.cpt_code);

  return (
    <Tooltip title={get(codeInfo, 'description')} arrow={true}>
      <Chip label={get(record, 'cpt_code', '')} />
    </Tooltip>
  );
};
