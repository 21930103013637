import { Button } from '@/modules/ui/components/button';
import { PlusIcon } from 'lucide-react';
import React, { useState } from 'react';
import { MonitoringActivitiesListAside } from './MonitoringActivitiesListAside';
import { MonitoringActivityCreateAside } from './MonitoringActivityCreateAside';

export const MonitoringActivityAside = () => {
  const [mode, setMode] = useState<'list' | 'create' | 'edit'>('list');

  const handleCreateClick = () => {
    setMode('create');
  };

  const handleCreateClose = () => {
    setMode('list');
  };

  return (
    <div className="flex flex-col gap-1 h-[90vh] overflow-y-auto">
      <div className="flex justify-end px-2 mb-4">
        {mode === 'list' && (
          <Button onClick={handleCreateClick}>
            <PlusIcon className="h-4 w-4 mr-2" />
            Monitoring
          </Button>
        )}
      </div>
      {mode === 'list' && <MonitoringActivitiesListAside />}
      {mode === 'create' && (
        <MonitoringActivityCreateAside handleCancelCreate={handleCreateClose} />
      )}
    </div>
  );
};
