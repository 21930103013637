import { Typography } from '@mui/material';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, memo } from 'react';
import {
  sanitizeFieldRestProps,
  useRecordContext,
  useTranslate,
} from 'react-admin';

export interface DateFieldProps {
  className?: string;
  emptyText?: string;
  source: string;
  label?: string;
  locales?: string | string[];
  options?: object;
  showTime?: boolean;
  showDate?: boolean;
  zone?: string;
  sortBy?: string;
}

export const LuxonDateField: FC<DateFieldProps> = memo((props) => {
  const {
    className,
    emptyText,
    locales,
    options,
    showTime = false,
    showDate = true,
    source,
    zone = 'local',
    ...rest
  } = props;
  const translate = useTranslate();

  if (!showTime && !showDate) {
    throw new Error(
      '<DateField> cannot have showTime and showDate false at the same time',
    );
  }

  const record = useRecordContext(props);
  if (!record) {
    return null;
  }

  const value = get(record, source);
  if (value === null || value === '') {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText && translate(emptyText, { _: emptyText })}
      </Typography>
    ) : null;
  }

  const date =
    value instanceof Date
      ? value
      : DateTime.fromISO(value, {
          zone: 'utc',
        }).setZone(zone);

  let dateString = '';
  if (showTime && showDate) {
    dateString = date.toLocaleString(DateTime.DATETIME_MED);
  } else if (showDate) {
    dateString = date.toLocaleString(DateTime.DATE_MED);
  } else if (showTime) {
    dateString = date.toLocaleString(DateTime.TIME_SIMPLE);
  }

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {dateString}
    </Typography>
  );
});
