const apiUrl = window.config?.apiUrl;
// @ts-ignore

const inMemoryJWTManager = () => {
  let isLoggedOut = false;
  let inMemoryJWT = null;
  let tokenExpiry = null;
  let isRefreshing: Promise<boolean> | null = null;
  let logoutEventName = 'ra-logout';
  let refreshEndpoint = apiUrl + '/auth/refresh';
  let refreshTimeOutId;
  const TOKEN_EXPIRY_BUFFER = 60_000 * 0.5; // 0.5 minute buffer in milliseconds

  const setLogoutEventName = (name) => (logoutEventName = name);
  const setRefreshTokenEndpoint = (endpoint) => (refreshEndpoint = endpoint);

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const getRefreshedToken = () => {

    if (isLoggedOut) {
      return Promise.resolve(false);
    }

    if (isRefreshing) {
      return isRefreshing;
    }

    const request = new Request(refreshEndpoint, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include',
    });
  
    isRefreshing = fetch(request)
      .then(async (response) => {
        if (response.status !== 200) {
          const errorData = await response.json();
          console.log('Refresh token error', errorData, response.status);
          throw new Error(errorData.message || 'Token refresh failed');
        }
        return response.json();
      })
      .then(({ access_token, token_expiry }) => {
        if (access_token) {
          setToken(access_token, token_expiry);
          return true;
        }
        throw new Error('Failed to refresh token. Empty access token.');
      })
      .catch((error) => {
        console.error('Failed to refresh token:', error);
        eraseToken();
        window.dispatchEvent(new CustomEvent('logout'));
        return false;
      })
      .finally(() => {
        isRefreshing = null;
      });
  
    return isRefreshing;
  };

  const getToken = async (who) => {
    // If a refresh is already in progress, wait for it to complete
    if (isRefreshing) {
      await isRefreshing;
    }

  
    // Check if the token needs refreshing
    if (!inMemoryJWT || new Date().getTime() > tokenExpiry - TOKEN_EXPIRY_BUFFER) {
      // Start the refresh process
      const refreshResult = await getRefreshedToken();
      if (!refreshResult) {
        throw new Error('Token refresh failed');
      }
    }
  
    // Final check to ensure the token is valid
    if (!inMemoryJWT || new Date().getTime() > tokenExpiry) {
      throw new Error('Token is invalid or expired');
    }
  
    return inMemoryJWT;
  };
  const setToken = (token, expiry) => {

    isLoggedOut = false;
    inMemoryJWT = token;
    tokenExpiry = expiry * 1000;

    return true;
  };

  const eraseToken = () => {
    inMemoryJWT = null;
    tokenExpiry = null;
    abortRefreshToken();
    isLoggedOut = true;
    window.localStorage.setItem(logoutEventName, Date.now().toString());
    return true;
  };

  window.addEventListener('storage', (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
      tokenExpiry = null;
    }
  });

  return {
    eraseToken,
    getRefreshedToken,
    getToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
  };
};

export default inMemoryJWTManager();