import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { get } from 'lodash';
import React, { FC } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';

import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { SymptomsEmpty } from './SymptomsEmpty';
import { Card, CardContent } from '@/modules/ui/components/card';
import {
  ToggleGroup,
  ToggleGroupItem,
} from '@/modules/ui/components/toggle-group';
import { Skeleton } from '@/modules/ui/components/skeleton';

const colorRange = [
  { backgroundColor: '#7ED6A1', borderColor: '#0C6D33', max: 0 },
  { backgroundColor: '#CCBAA5', borderColor: '#928576', max: 1 },
  { backgroundColor: '#E9BA83', borderColor: '#907452', max: 4 },
  { backgroundColor: '#DE8383', borderColor: '#690707', max: 10 },
];

const SeverityIndicator = ({ value, wide = false }) => {
  if (value === null) return null;
  const determineColor = () => {
    if (value === true) return colorRange[3];
    if (value === 'Y') return colorRange[3];
    if (value === 'N') return colorRange[0];
    for (let i = 0; i < colorRange.length; i++) {
      if (value <= colorRange[i].max) {
        return colorRange[i];
      }
    }
  };
  const color = determineColor();

  const formatted =
    value === true ? 'Y' : Number(value) ? Math.round(value) : value;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: wide ? '16px' : '50%',
        width: '14px',
        height: '14px',
        padding: '10px',
        background: get(color, 'backgroundColor'),
        border: '1px solid ' + get(color, 'borderColor'),
        color: '#000',
        margin: 'auto',
        paddingLeft: wide ? '15px' : '10px',
        paddingRight: wide ? '15px' : '10px',
      }}
    >
      <p className="text-sm">{formatted}</p>
    </div>
  );
};

const formatWeekRange = (date) => {
  const dt = DateTime.fromISO(date);
  return `${dt.startOf('week').toLocaleString(DateTime.DATE_SHORT)} - ${dt
    .endOf('week')
    .toLocaleString(DateTime.DATE_SHORT)} `;
};

const Calendar = ({ x, symptoms, timeframe }) => {
  return (
    <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {x.map((day, index) =>
              timeframe === 'week' ? (
                <TableCell align="center" key={index}>
                  {DateTime.fromISO(day).weekdayShort}
                </TableCell>
              ) : (
                <TableCell key={index} align="center">
                  {formatWeekRange(day)}
                </TableCell>
              ),
            )}
            {timeframe === 'week' ? (
              <TableCell align="center">Ongoing</TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {symptoms.length
            ? symptoms.map((row) => (
                <TableRow
                  key={row.symptom}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.symptom}
                  </TableCell>
                  {row.severity.map((day, index) => (
                    <TableCell align="center" key={index}>
                      <SeverityIndicator value={day} />
                    </TableCell>
                  ))}
                  {timeframe === 'week' ? (
                    <TableCell align="center">
                      {row.ongoing === null ? null : row.ongoing ? (
                        <SeverityIndicator value="Y" wide />
                      ) : (
                        <SeverityIndicator value="N" wide />
                      )}
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            : null}
          <TableRow>
            {symptoms.length ? (
              <TableCell
                colSpan={9}
                sx={{ textAlign: 'center', borderBottom: 'none' }}
              >
                <p className="text-sm text-gray-500">
                  Self-reported severity (1-10)
                </p>
              </TableCell>
            ) : (
              <TableCell
                colSpan={9}
                sx={{ textAlign: 'center', borderBottom: 'none' }}
              >
                <p className="text-sm text-gray-500 mt-2">None reported</p>
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SymptomSeverityUI = ({
  data,
  isLoading,
  timeframe,
  onChangeTimeframe,
}) => {
  const Frame = ({ children }) => (
    <div className="flex flex-col space-y-2">
      <div>
        <h3 className="text-xl font-semibold">Symptoms</h3>
        <p className="text-sm text-gray-500">
          Patient symptoms and their severity.
        </p>
      </div>
      <Card>
        <CardContent className="mt-2">{children}</CardContent>
      </Card>
    </div>
  );

  if (isLoading)
    return (
      <Frame>
        <Skeleton className="h-[100px]" />
      </Frame>
    );
  if (!data || !get(data, 'x', null))
    return (
      <Frame>
        <SymptomsEmpty />
      </Frame>
    );

  return (
    <Frame>
      <div className="flex flex-col gap-2">
        <div className="self-end">
          <ToggleGroup
            type="single"
            value={timeframe}
            onValueChange={onChangeTimeframe}
          >
            <ToggleGroupItem value="month" aria-label="Month">
              Month
            </ToggleGroupItem>
            <ToggleGroupItem value="week" aria-label="Week">
              Week
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
        <div>
          <Calendar
            x={get(data, 'x')}
            symptoms={get(data, 'data')}
            timeframe={timeframe}
          />
        </div>
      </div>
    </Frame>
  );
};

export const SymptomSeverity: FC = (props) => {
  const patientRecord = useRecordContext();
  const [timeframe, setTimeFrame] = React.useState<string | null>('month');

  const queryOptions = {
    refetchOnWindowFocus: false,
  };
  const dataProvider = useDataProvider();
  const { data, isLoading } = useQuery(
    [
      `patients/${patientRecord?.id}/conditions/summary`,
      timeframe,
      'getCustom',
    ],
    () =>
      dataProvider.getCustom(
        `patients/${patientRecord?.id}/conditions/summary`,
        { meta: { timeframe: timeframe } },
      ),
    { ...queryOptions, enabled: !!patientRecord },
  );

  if (!patientRecord) return null;

  return (
    <SymptomSeverityUI
      data={data?.data}
      isLoading={isLoading}
      timeframe={timeframe}
      onChangeTimeframe={setTimeFrame}
    />
  );
};
