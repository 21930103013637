import CreateDialog from '@/modules/ra-ui/components/create-dialog';
import { Separator } from '@/modules/ui/components/separator';
import { CheckBoxInput } from '@/modules/ui/inputs/checkbox-input';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';
import React from 'react';
import {
  FormDataConsumer,
  RecordContextProvider,
  required,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { unenrollReasons } from '../constants/unenrollReasons';
import { DevicesDataGrid } from './DevicesDataGrid';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { TextInput } from '@/modules/ui/inputs/text-input';

export const UnenrollPatientDialog = ({ open, setOpen }) => {
  const record = useRecordContext();

  const CommonFormFields = () => {
    return (
      <>
        <SelectInput
          source="reason"
          choices={unenrollReasons}
          optionText="value"
          helperText={false}
          validate={required()}
          label="Reason for unenrollment"
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.reason === 'other' && (
              <TextInput
                source="reason_other"
                label="Explain the reason for unenrollment"
                validate={required()}
              />
            )
          }
        </FormDataConsumer>
        <CheckBoxInput
          source="team_notified"
          label="Has the rest of the team been informed about this patient's unenrollment?"
          className="mt-4"
          validate={(value) =>
            value === true
              ? undefined
              : 'Please confirm that the team has been informed'
          }
        />
        <CheckBoxInput
          source="provider_notified"
          label="Has the billing provider been informed about this patient's unenrollment?"
          className="mt-2"
          validate={(value) =>
            value === true
              ? undefined
              : 'Please confirm that the billing provider has been informed'
          }
        />
      </>
    );
  };

  const { total, isLoading } = useGetList('devices', {
    filter: { 'patient_id[eq]': record?.id },
  });

  if (!record) return null;

  if (record.patient_status === 'disabled') return null;

  return (
    <CreateDialog
      title="Unenroll patient"
      description="This action will unenroll the patient and deactivate any active care programs. Please be certain before proceeding."
      resource={`patients/${record?.id}/unenroll`}
      notifyMessage="Patient has been successfully unenrolled."
      open={open}
      setOpen={setOpen}
      refreshOnSuccess
      logActionLabel="Patient was unenrolled"
      enableLogAction
      logActionPatientIdField="id"
      dialogVariant="shadcn"
      dialogContentClassName="max-w-4xl w-fit overflow-y-auto"
      closeOnClickOutside={false}
    >
      <div className="flex flex-col gap1">
        {!isLoading && total > 0 ? (
          <>
            <p className="mt-1">
              Please have the patient return and then unassign the following
              devices prior to unenrolling patient
            </p>
            <div className="w-fit">
              <RecordContextProvider value={record}>
                <DevicesDataGrid />
              </RecordContextProvider>
            </div>
            <SwitchInput
              source="skip"
              label="Skip device unassignment"
              className="mt-4"
            />
            <FormDataConsumer>
              {({ formData }) => {
                return formData.skip ? (
                  <>
                    <p className="mt-4  text-red-500">
                      Please have a valid reason for skipping device
                      unassignment
                    </p>
                    <TextAreaInput
                      source="device_skip_reason"
                      label="Reason for skipping device unassignment"
                      className="mt-4"
                      validate={required()}
                    />
                    <Separator className="mt-4" />
                    <CommonFormFields />
                  </>
                ) : null;
              }}
            </FormDataConsumer>
          </>
        ) : (
          <CommonFormFields />
        )}
      </div>
    </CreateDialog>
  );
};
