import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/modules/ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { Separator } from '@/modules/ui/components/separator';
import { cn } from '@/modules/ui/utils/cn';
import { get, debounce } from 'lodash';
import { CheckIcon, PlusCircle, Search } from 'lucide-react';
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  useGetList,
  useListContext,
  usePermissions,
  useGetMany,
} from 'react-admin';

export default function DataTableProviderFilter(props) {
  const { permissions } = usePermissions();

  if (permissions && !!!permissions.is_internal) return null;

  return <DataTableProviderFilterMain {...props} />;
}

function DataTableProviderFilterMain({ column, title }) {
  const perPage = 10;
  const [page, setPage] = useState(1);
  const { filterValues, setFilters } = useListContext();

  const [providerNameSearch, setProviderNameSearch] = useState(null);

  const delayedUpdate = useRef(
    debounce((q) => setProviderNameSearch(q), 500),
  ).current;

  let selectedValue = get(filterValues, column);

  const [params, setParams] = useState({
    pagination: { page, perPage },
    sort: { field: 'name', order: 'DESC' } as any,
    filter: {},
  });

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      filter: {
        ...(providerNameSearch && providerNameSearch.length > 1
          ? { q: { field: 'name', value: providerNameSearch } }
          : {}),
      },
    }));
  }, [providerNameSearch]);

  const { data: possibleValuesData = [], total } = useGetList(
    'providers',
    params,
  );

  const { data: selectedProviderData } = useGetMany(
    'providers',
    { ids: selectedValue ? [selectedValue] : [] },
    { enabled: !!selectedValue },
  );

  const options = useMemo(() => {
    const possibleOptions = possibleValuesData.map((provider) => ({
      label: provider.name,
      value: provider.id,
    }));

    if (
      selectedValue &&
      selectedProviderData &&
      selectedProviderData.length > 0
    ) {
      const selectedProvider = selectedProviderData[0];
      if (
        !possibleOptions.some((option) => option.value === selectedProvider.id)
      ) {
        possibleOptions.unshift({
          label: selectedProvider.name,
          value: selectedProvider.id,
        });
      }
    }

    return possibleOptions;
  }, [possibleValuesData, selectedProviderData, selectedValue]);

  const handleSearch = useCallback(
    (searchValue) => {
      delayedUpdate(searchValue);
      setPage(1);
    },
    [delayedUpdate],
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircle className="mr-2 h-4 w-4" />
          {title}
          {selectedValue && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <div className="hidden space-x-1 lg:flex">
                {options
                  .filter((option) => selectedValue === option.value)
                  .map((option) => (
                    <Badge
                      variant="secondary"
                      key={option.value}
                      className="rounded-sm px-1 font-normal"
                    >
                      {option.label}
                    </Badge>
                  ))}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <div className="flex items-center border-b px-3">
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
              className={cn(
                'flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
              )}
              placeholder={title}
              onChange={(e) => handleSearch(e.target.value)}
              defaultValue={providerNameSearch}
            />
          </div>
          <CommandList>
            {total === 0 ? (
              <CommandEmpty>No results found.</CommandEmpty>
            ) : null}
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValue === option.value;
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      setFilters(
                        {
                          ...filterValues,
                          [column]: isSelected ? undefined : option.value,
                        },
                        null,
                      );
                    }}
                    value={option.value}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-lg border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    <span>{option.label}</span>
                    <span className="invisible">{option.value}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValue && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() =>
                      setFilters({ ...filterValues, [column]: undefined }, null)
                    }
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
