import React from 'react';
import { usePermissions, useRecordContext } from 'react-admin';
import DeviceAssignTenant from './DeviceAssignTenant';
import DeviceAssignToPatient from './DeviceAssignToPatient';
import DeviceChangeExternalID from './DeviceChangeExternalId';
import DeviceReportIssueButton from './DeviceReportIssueButton';
import DeviceToggleSharedStatus from './DeviceToggleSharedStatus';
import DeviceUnassignFromPatient from './DeviceUnassignFromPatient';
import DeviceMarkReceived from './DeviceMarkReceived';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/modules/ui/components/dropdown-menu';
import { CircleDollarSign, Cog, MoreVertical } from 'lucide-react';
import DeviceDecommissionDialog from './DeviceDecommisionDialog';
import DeviceSetLocation from './DeviceSetLocation';
import DeviceSetCustomerInvoice from './DeviceSetCustomerInvoice'; // Added import statement
import { get } from 'lodash';

const DeviceActionsDropDown = () => {
  const record = useRecordContext();
  const [openDecommissionDialog, setOpenDecommissionDialog] =
    React.useState(false);
  const [openSharedStatusDialog, setOpenSharedStatusDialog] =
    React.useState(false);
  const [openChangeExternalID, setOpenChangeExternalID] = React.useState(false);
  const [openSetCustomerInvoice, setOpenSetCustomerInvoice] =
    React.useState(false); // Added state for DeviceSetCustomerInvoice
  const { permissions } = usePermissions();

  if (!record) {
    return null;
  }

  return (
    <>
      <DeviceDecommissionDialog
        open={openDecommissionDialog}
        setOpen={setOpenDecommissionDialog}
      />
      <DeviceToggleSharedStatus
        open={openSharedStatusDialog}
        setOpen={setOpenSharedStatusDialog}
      />
      <DeviceChangeExternalID
        open={openChangeExternalID}
        setOpen={setOpenChangeExternalID}
      />
      <DeviceSetCustomerInvoice
        open={openSetCustomerInvoice}
        setOpen={setOpenSetCustomerInvoice}
      />
      <DropdownMenu>
        <DropdownMenuTrigger>
          <MoreVertical />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex flex-col">
          <DropdownMenuLabel>Device Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => setOpenChangeExternalID(true)}
            disabled={record.patient_id}
          >
            <Cog className="w-4 h-4 mr-2" />
            Change External ID
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setOpenSharedStatusDialog(true)}
            disabled={record.patient_id}
          >
            <Cog className="w-4 h-4 mr-2" />
            Toggle Shared Status
          </DropdownMenuItem>

          {get(permissions, 'is_internal') && (
            <DropdownMenuItem
              onClick={() => setOpenSetCustomerInvoice(true)}
              disabled={!!!record.tenant_id}
            >
              <CircleDollarSign className="w-4 h-4 mr-2" />
              Set Device Paid Status
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => setOpenDecommissionDialog(true)}
            className="bg-destructive text-destructive-foreground focus:bg-destructive/90 focus:text-destructive-foreground"
            disabled={record.patient_id}
          >
            <Cog className="w-4 h-4 mr-2" />
            Decommission
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const DeviceActionsToolbar = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  if (record.status === 'decommissioned') {
    return (
      <div
        className="
          bg-destructive/50
          rounded-md
          m-2
          p-1
          px-4
          text-center
          w-fit
          mx-auto
          text-md
          "
      >
        Device has been decommissioned and can no longer be managed.
      </div>
    );
  }

  let buttons = [<DeviceReportIssueButton />];

  if (record.patient_id) {
    buttons.unshift(<DeviceUnassignFromPatient />);
  } else {
    buttons.unshift(<DeviceAssignTenant />);
    buttons.unshift(<DeviceSetLocation />);
  }

  if (!record.patient_id && !record.is_shared_device && record.tenant_id) {
    buttons.unshift(<DeviceAssignToPatient />);
  }

  if (record.status === 'pending') {
    buttons.unshift(<DeviceMarkReceived />);
  }

  return (
    <div className="flex flex-row flex-wrap justify-end gap-2 items-center mb-2">
      {buttons}
      <DeviceActionsDropDown />
    </div>
  );
};

export default DeviceActionsToolbar;
