import * as React from 'react';
import {
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { AutocompleteProps, Chip, TextFieldProps } from '@mui/material';
import {
  ChoicesProps,
  FieldTitle,
  RaRecord,
  useChoicesContext,
  useInput,
  useSuggestions,
  UseSuggestionsOptions,
  useTranslate,
  warning,
  useGetRecordRepresentation,
  useEvent,
} from 'ra-core';
import {
  CommonInputProps,
  SupportCreateSuggestionOptions,
  useSupportCreateSuggestion,
} from 'react-admin';

import { Check, ChevronsUpDown } from 'lucide-react';
import { Button } from '@/modules/ui/components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/modules/ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { InputMessage } from './input-message';
import { cn } from '../utils/cn';
import { FormLabel } from '../components/form';

export const AutocompleteInput = <
  OptionType extends RaRecord = RaRecord,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  SupportCreate extends boolean | undefined = false,
>(
  props: AutocompleteInputProps<
    OptionType,
    Multiple,
    DisableClearable,
    SupportCreate
  >,
) => {
  const {
    choices: choicesProp,
    className,
    create,
    createLabel,
    createItemLabel,
    createValue,
    debounce: debounceDelay = 250,
    defaultValue,
    emptyText,
    emptyValue = '',
    field: fieldOverride,
    format,
    helperText,
    id: idOverride,
    inputText,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    isRequired: isRequiredOverride,
    label,
    limitChoicesToValue,
    matchSuggestion,
    margin,
    fieldState: fieldStateOverride,
    filterToQuery: filterToQueryProp = DefaultFilterToQuery,
    formState: formStateOverride,
    multiple = false,
    noOptionsText,
    onBlur,
    onChange,
    onCreate,
    optionText,
    optionValue,
    parse,
    resource: resourceProp,
    shouldRenderSuggestions,
    setFilter,
    size,
    source: sourceProp,
    suggestionLimit = Infinity,
    TextFieldProps,
    translateChoice,
    validate,
    variant,
    onInputChange,
    disabled,
    readOnly,
    ...rest
  } = props;

  const filterToQuery = useEvent(filterToQueryProp);

  const {
    allChoices,
    error: fetchError,
    resource,
    source,
    setFilters,
    isFromReference,
  } = useChoicesContext({
    choices: choicesProp,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    resource: resourceProp,
    source: sourceProp,
  });

  const translate = useTranslate();

  const {
    field,
    isRequired,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    defaultValue,
    id: idOverride,
    field: fieldOverride,
    fieldState: fieldStateOverride,
    formState: formStateOverride,
    isRequired: isRequiredOverride,
    onBlur,
    onChange,
    parse,
    format,
    resource,
    source,
    validate,
    disabled,
    readOnly,
    ...rest,
  });

  const finalChoices = useMemo(
    () =>
      // eslint-disable-next-line eqeqeq
      emptyText == undefined || isRequired || multiple
        ? allChoices
        : [
            {
              [optionValue || 'id']: emptyValue,
              [typeof optionText === 'string' ? optionText : 'name']: translate(
                emptyText,
                {
                  _: emptyText,
                },
              ),
            },
          ].concat(allChoices),
    [
      allChoices,
      emptyValue,
      emptyText,
      isRequired,
      multiple,
      optionText,
      optionValue,
      translate,
    ],
  );

  const selectedChoice = useSelectedChoice<
    OptionType,
    Multiple,
    DisableClearable,
    SupportCreate
  >(field.value, {
    choices: finalChoices,
    // @ts-ignore
    multiple,
    optionValue,
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (emptyValue == null) {
      throw new Error(
        `emptyValue being set to null or undefined is not supported. Use parse to turn the empty string into null.`,
      );
    }
  }, [emptyValue]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (isValidElement(optionText) && emptyText != undefined) {
      throw new Error(
        `optionText of type React element is not supported when setting emptyText`,
      );
    }
    // eslint-disable-next-line eqeqeq
    if (isValidElement(optionText) && inputText == undefined) {
      throw new Error(`
If you provided a React element for the optionText prop, you must also provide the inputText prop (used for the text input)`);
    }
    if (
      isValidElement(optionText) &&
      !isFromReference &&
      // eslint-disable-next-line eqeqeq
      matchSuggestion == undefined
    ) {
      throw new Error(`
If you provided a React element for the optionText prop, you must also provide the matchSuggestion prop (used to match the user input with a choice)`);
    }
  }, [optionText, inputText, matchSuggestion, emptyText, isFromReference]);

  useEffect(() => {
    warning(
      /* eslint-disable eqeqeq */
      shouldRenderSuggestions != undefined && noOptionsText == undefined,
      `When providing a shouldRenderSuggestions function, we recommend you also provide the noOptionsText prop and set it to a text explaining users why no options are displayed. It supports translation keys.`,
    );
    /* eslint-enable eqeqeq */
  }, [shouldRenderSuggestions, noOptionsText]);

  const getRecordRepresentation = useGetRecordRepresentation(resource);

  const { getChoiceText, getChoiceValue, getSuggestions } = useSuggestions({
    choices: finalChoices,
    limitChoicesToValue,
    matchSuggestion,
    optionText:
      optionText ?? (isFromReference ? getRecordRepresentation : undefined),
    optionValue,
    selectedItem: selectedChoice,
    suggestionLimit,
    translateChoice: translateChoice ?? !isFromReference,
  });

  const [filterValue, setFilterValue] = useState('');

  const handleChange = (newValue: any) => {
    if (multiple) {
      if (Array.isArray(newValue)) {
        field.onChange(newValue.map(getChoiceValue), newValue);
      } else {
        field.onChange(
          [...(field.value ?? []), getChoiceValue(newValue)],
          newValue,
        );
      }
    } else {
      field.onChange(getChoiceValue(newValue) ?? emptyValue, newValue);
    }
  };

  // eslint-disable-next-line
  const debouncedSetFilter = useCallback(
    debounce((filter) => {
      if (setFilter) {
        return setFilter(filter);
      }

      if (choicesProp) {
        return;
      }

      setFilters(filterToQuery(filter), undefined, true);
    }, debounceDelay),
    [debounceDelay, setFilters, setFilter],
  );

  // We must reset the filter every time the value changes to ensure we
  // display at least some choices even if the input has a value.
  // Otherwise, it would only display the currently selected one and the user
  // would have to first clear the input before seeing any other choices
  const currentValue = useRef(field.value);
  useEffect(() => {
    if (!isEqual(currentValue.current, field.value)) {
      currentValue.current = field.value;
      debouncedSetFilter('');
    }
  }, [field.value]); // eslint-disable-line

  const {
    handleChange: handleChangeWithCreateSupport,
    createElement,
    createId,
  } = useSupportCreateSuggestion({
    create,
    createLabel,
    createItemLabel,
    createValue,
    handleChange,
    filter: filterValue,
    onCreate,
    optionText,
  });

  const getOptionLabel = useCallback(
    (option: any, isListItem: boolean = false) => {
      // eslint-disable-next-line eqeqeq
      if (option == undefined) {
        return '';
      }

      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }

      if (option?.id === createId) {
        return get(
          option,
          typeof optionText === 'string' ? optionText : 'name',
        );
      }

      if (!isListItem && option[optionValue || 'id'] === emptyValue) {
        return get(
          option,
          typeof optionText === 'string' ? optionText : 'name',
        );
      }

      if (!isListItem && inputText !== undefined) {
        return inputText(option);
      }

      return getChoiceText(option);
    },
    [getChoiceText, inputText, createId, optionText, optionValue, emptyValue],
  );

  useEffect(() => {
    if (!multiple) {
      const optionLabel = getOptionLabel(selectedChoice);
      if (typeof optionLabel === 'string') {
        setFilterValue(optionLabel);
      } else {
        throw new Error(
          'When optionText returns a React element, you must also provide the inputText prop',
        );
      }
    }
  }, [getOptionLabel, multiple, selectedChoice]);

  const handleInputChange: AutocompleteProps<
    OptionType,
    Multiple,
    DisableClearable,
    SupportCreate
  >['onInputChange'] = (event, newInputValue, reason) => {
    if (event?.type === 'change' || !doesQueryMatchSelection(newInputValue)) {
      setFilterValue(newInputValue);
      debouncedSetFilter(newInputValue);
    }
    if (reason === 'clear') {
      setFilterValue('');
      debouncedSetFilter('');
    }
    onInputChange?.(event, newInputValue, reason);
  };

  const doesQueryMatchSelection = useCallback(
    (filter: string) => {
      let selectedItemTexts;

      if (multiple) {
        selectedItemTexts = selectedChoice.map((item) => getOptionLabel(item));
      } else {
        selectedItemTexts = [getOptionLabel(selectedChoice)];
      }

      return selectedItemTexts.includes(filter);
    },
    [getOptionLabel, multiple, selectedChoice],
  );

  const handleAutocompleteChange = useCallback(
    (event: any, newValue: any, _reason: string) => {
      handleChangeWithCreateSupport(newValue != null ? newValue : emptyValue);
    },
    [emptyValue, handleChangeWithCreateSupport],
  );

  const suggestions = useMemo(() => {
    if (!isFromReference && (matchSuggestion || limitChoicesToValue)) {
      return getSuggestions(filterValue);
    }
    return finalChoices?.slice(0, suggestionLimit) || [];
  }, [
    finalChoices,
    filterValue,
    getSuggestions,
    limitChoicesToValue,
    matchSuggestion,
    suggestionLimit,
    isFromReference,
  ]);

  const isOptionEqualToValue = (option, value) => {
    return String(getChoiceValue(option)) === String(getChoiceValue(value));
  };
  const renderHelperText =
    !!fetchError ||
    helperText !== false ||
    ((isTouched || isSubmitted) && invalid);

  return (
    <>
      <div className={cn('space-y-2', className)}>
        <FormLabel>
          <FieldTitle
            label={label}
            source={source}
            resource={resourceProp}
            isRequired={isRequired}
          />
        </FormLabel>

        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              className={cn(
                'w-full justify-between',
                !field.value && 'text-muted-foreground',
                disabled && 'opacity-50 cursor-not-allowed',
              )}
              disabled={disabled || readOnly}
            >
              {field.value ? (
                multiple ? (
                  <div className="flex gap-1 flex-wrap">
                    {field.value.map((value, index) => (
                      <Chip
                        key={index}
                        label={
                          isValidElement(optionText)
                            ? inputText(value)
                            : getChoiceText(value)
                        }
                        size="small"
                      />
                    ))}
                  </div>
                ) : (
                  getOptionLabel(selectedChoice)
                )
              ) : (
                'Select...'
              )}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-full p-0">
            <Command>
              <CommandInput
                placeholder="Search..."
                value={filterValue}
                onValueChange={(value) => {
                  setFilterValue(value);
                  handleInputChange(null, value, 'input');
                }}
                disabled={disabled || readOnly}
              />
              <CommandList>
                <CommandEmpty>
                  {typeof noOptionsText === 'string'
                    ? translate(noOptionsText, { _: noOptionsText })
                    : noOptionsText}
                </CommandEmpty>
                <CommandGroup>
                  {suggestions.map((option) => {
                    const value = getChoiceValue(option);
                    const label = getOptionLabel(option, true);

                    return (
                      <CommandItem
                        key={value}
                        value={value}
                        onSelect={() => {
                          handleAutocompleteChange(
                            null,
                            option,
                            'select-option',
                          );
                          setOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            isOptionEqualToValue(option, selectedChoice)
                              ? 'opacity-100'
                              : 'opacity-0',
                          )}
                        />
                        {label}
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>

        {renderHelperText && (
          <InputMessage
            touched={isTouched || isSubmitted || !!fetchError}
            error={error?.message || fetchError?.message}
            helperText={helperText}
          />
        )}
      </div>
      {createElement}
    </>
  );
};

const PREFIX = 'RaAutocompleteInput';

export const AutocompleteInputClasses = {
  textField: `${PREFIX}-textField`,
};

// @ts-ignore
export interface AutocompleteInputProps<
  OptionType extends any = RaRecord,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  SupportCreate extends boolean | undefined = false,
> extends Omit<CommonInputProps, 'source' | 'onChange'>,
    ChoicesProps,
    UseSuggestionsOptions,
    Omit<SupportCreateSuggestionOptions, 'handleChange' | 'optionText'>,
    Omit<
      AutocompleteProps<OptionType, Multiple, DisableClearable, SupportCreate>,
      'onChange' | 'options' | 'renderInput'
    > {
  children?: ReactNode;
  debounce?: number;
  emptyText?: string;
  emptyValue?: any;
  filterToQuery?: (searchText: string) => any;
  inputText?: (option: any) => string;
  onChange?: (
    // We can't know upfront what the value type will be
    value: Multiple extends true ? any[] : any,
    // We return an empty string when the input is cleared in single mode
    record: Multiple extends true ? OptionType[] : OptionType | '',
  ) => void;
  setFilter?: (value: string) => void;
  shouldRenderSuggestions?: any;
  // Source is optional as AutocompleteInput can be used inside a ReferenceInput that already defines the source
  source?: string;
  TextFieldProps?: TextFieldProps;
}

/**
 * Returns the selected choice (or choices if multiple) by matching the input value with the choices.
 */
const useSelectedChoice = <
  OptionType extends any = RaRecord,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  SupportCreate extends boolean | undefined = false,
>(
  value: any,
  {
    choices,
    multiple,
    optionValue,
  }: AutocompleteInputProps<
    OptionType,
    Multiple,
    DisableClearable,
    SupportCreate
  >,
) => {
  const selectedChoiceRef = useRef(
    getSelectedItems(choices, value, optionValue, multiple),
  );
  const [selectedChoice, setSelectedChoice] = useState<RaRecord | RaRecord[]>(
    () => getSelectedItems(choices, value, optionValue, multiple),
  );

  // As the selected choices are objects, we want to ensure we pass the same
  // reference to the Autocomplete as it would reset its filter value otherwise.
  useEffect(() => {
    const newSelectedItems = getSelectedItems(
      choices,
      value,
      optionValue,
      multiple,
    );

    if (
      !areSelectedItemsEqual(
        selectedChoiceRef.current,
        newSelectedItems,
        optionValue,
        multiple,
      )
    ) {
      selectedChoiceRef.current = newSelectedItems;
      setSelectedChoice(newSelectedItems);
    }
  }, [choices, value, multiple, optionValue]);
  return selectedChoice || null;
};

const getSelectedItems = (
  choices = [],
  value,
  optionValue = 'id',
  multiple,
) => {
  if (multiple) {
    return (Array.isArray(value ?? []) ? value : [value])
      .map((item) =>
        choices.find(
          (choice) => String(item) === String(get(choice, optionValue)),
        ),
      )
      .filter((item) => !!item);
  }
  return (
    choices.find(
      (choice) => String(get(choice, optionValue)) === String(value),
    ) || ''
  );
};

const areSelectedItemsEqual = (
  selectedChoice: RaRecord | RaRecord[],
  newSelectedChoice: RaRecord | RaRecord[],
  optionValue = 'id',
  multiple: boolean,
) => {
  if (multiple) {
    const selectedChoiceArray = (selectedChoice as RaRecord[]) ?? [];
    const newSelectedChoiceArray = (newSelectedChoice as RaRecord[]) ?? [];
    if (selectedChoiceArray.length !== newSelectedChoiceArray.length) {
      return false;
    }
    const equalityArray = selectedChoiceArray.map((choice) =>
      newSelectedChoiceArray.some(
        (newChoice) => get(newChoice, optionValue) === get(choice, optionValue),
      ),
    );
    return !equalityArray.some((item) => item === false);
  }
  return (
    get(selectedChoice, optionValue) === get(newSelectedChoice, optionValue)
  );
};

const DefaultFilterToQuery = (searchText) => ({ q: searchText });
