import React, { useContext, useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';

import { get } from 'lodash';
import { DateTime } from 'luxon';

import { roundTo } from '@/modules/metrics/utils/roundTo';
import { MetricsScatterChart } from './MetricsScatterChart';
import { MetricTableRecent } from './MetricTableRecent';
import { chartProperties } from './chartProperties';
import { MetricsDialogContext } from './MetricsDialog';
import { MetricsSummarySidebar } from './MetricsSummarySidebar';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';

export function MetricsPrimaryView({ open }) {
  const { measureName } = useContext(MetricsDialogContext);

  const patientRecord = useRecordContext();
  const [range, setRange] = useState([
    DateTime.now().minus({ months: 3 }).toJSDate(),
    null,
  ]);
  const [showTrend, setShowTrend] = useState(true);
  const [timescale, setTimescale] = useState('all');
  const dataProvider = useDataProvider();

  const processChartData = (data, dataKey, key = 'y') => {
    if (!data) return [];

    const dataIn = get(data, dataKey);
    return dataIn.map((element) => ({
      x: DateTime.fromISO(element.x, { zone: 'utc' }).toSeconds(),
      y: roundTo(
        get(element, key),
        get(chartProperties, [get(data, 'chart_name'), 'round'], 0),
      ),
      is_y2: key === 'y2' ? true : false,
    }));
  };

  const { data, isLoading, refetch } = useQuery(
    ['patients', 'charts', measureName, { id: patientRecord?.id }],
    () =>
      dataProvider
        .getPatientResource(`charts`, {
          patient_id: patientRecord?.id,
          id: measureName,
          params: {
            timescale: timescale === 'all' ? null : timescale,
            start_date: DateTime.fromJSDate(get(range, 0))
              .startOf('day')
              .toISO({
                includeOffset: false,
              }),
            end_date: DateTime.fromJSDate(get(range, 1)).endOf('day').toISO({
              includeOffset: false,
            }),
            order: 'desc',
            limit: 5000,
          },
        })
        .then(({ data }) => ({
          ...data,
          dataPoints: processChartData(data, 'points'),
          dataPointsY2:
            get(data, 'chart_name') === 'blood_pressure'
              ? processChartData(data, 'points', 'y2')
              : null,
          dataPointsTrend: processChartData(data, 'trend_points'),
          dataPointsTrendY2:
            get(data, 'chart_name') === 'blood_pressure'
              ? processChartData(data, 'trend_points', 'y2')
              : null,
        })),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (open === true) {
      refetch();
    }
  }, [timescale, range, open, refetch]);

  if (!data || isLoading)
    return (
      <div className="flex items-center justify-center w-full h-96">
        <LoadingSpinner />
      </div>
    );

  const chartName = get(data, 'chart_name');

  return (
    <div className="flex flex-col md:flex-row gap-2">
      <div className="w-full md:w-2/12">
        <MetricsSummarySidebar
          data={data}
          showTrend={showTrend}
          setShowTrend={setShowTrend}
          range={range}
          setRange={setRange}
          timescale={timescale}
          setTimescale={setTimescale}
        />
      </div>
      <div className="w-full md:w-8/12" style={{ height: '500px' }}>
        <MetricsScatterChart
          data={data}
          chartName={chartName}
          unitText={get(data, 'unit.text')}
          showTrend={showTrend}
          timescale={timescale}
        />
      </div>
      <div className="w-full md:w-2/12">
        <MetricTableRecent data={data} />
      </div>
    </div>
  );
}
