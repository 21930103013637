'use client';

import { SlidersHorizontal } from 'lucide-react';

import { Button } from '@/modules/ui/components/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/modules/ui/components/dropdown-menu';
import inflection from 'inflection';
import React from 'react';
import {
  ConfigurableDatagridColumn,
  useResourceContext,
  useStore,
} from 'react-admin';

export function DataTableViewOptions(props) {
  const { preferenceKey } = props;

  const resource = useResourceContext(props);
  const finalPreferenceKey = preferenceKey || `${resource}.datagrid`;

  const [availableColumns] = useStore<ConfigurableDatagridColumn[]>(
    `preferences.${finalPreferenceKey}.availableColumns`,
    [],
  );
  const [omit] = useStore<string[]>(
    `preferences.${finalPreferenceKey}.omit`,
    [],
  );
  const [columns, setColumns] = useStore<string[]>(
    `preferences.${finalPreferenceKey}.columns`,
    availableColumns
      .filter((column) => !omit?.includes(column.source))
      .map((column) => column.index),
  );

  const handleToggle = (name, checked) => {
    if (checked) {
      // add the column at the right position
      setColumns(
        availableColumns
          .filter(
            (column) => column.index === name || columns.includes(column.index),
          )
          .map((column) => column.index),
      );
    } else {
      setColumns(columns.filter((index) => index !== name));
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="ml-auto hidden h-8 lg:flex"
        >
          <SlidersHorizontal className="mr-2 h-4 w-4" />
          View
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="w-[150px] overflow-y-auto max-h-[300px]"
      >
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {availableColumns.map((column) => {
          return (
            <DropdownMenuCheckboxItem
              key={column.index}
              className="capitalize"
              checked={columns.includes(column.index)}
              onCheckedChange={(value) => handleToggle(column.index, value)}
            >
              {column.label ?? inflection.titleize(column.source)}
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
