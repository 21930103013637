import Rollbar from 'rollbar';

export const rollbarConfig = {
    accessToken: '3f83c1e9923c493582d9d60d3520762b',
    environment: window.config.env,
    enabled: window.config.env !== 'dev',
    payload: {
      client: {
        javascript: {
          source_map_enabled: true, // false by default
          // -- Add this into your configuration ---
          code_version: import.meta.env.VITE_GIT_SHA,
          guess_uncaught_frames: true,
        },
      },
    },
  };

export const rollbar = new Rollbar(rollbarConfig);

export default rollbar;