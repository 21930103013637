export const patientRiskFactors = [
  {
    id: 'Lives alone',
    name: 'Lives alone',
  },
  {
    id: 'Long term oxygen user',
    name: 'Long term oxygen user',
  },
  {
    id: 'Needs help performing daily activities',
    name: 'Needs help performing daily activities',
  },
];
