export const primaryConditions = [
  {
    id: 'HeartFailure',
    name: 'Heart Failure',
    className: 'border-red-300 bg-red-100',
  },
  {
    id: 'COPD',
    name: 'COPD',
    className: 'border-blue-300 bg-blue-100',
  },
  {
    id: 'Asthma',
    name: 'Asthma',
    className: 'border-green-300 bg-green-100',
  },
  {
    id: 'general',
    name: 'General',
    className: 'border-purple-300 bg-purple-100',
  },
  {
    id: 'Diabetes',
    name: 'Diabetes',
    className: 'border-yellow-300 bg-yellow-100',
  },
  {
    id: 'chronic_kidney_disease',
    name: 'Chronic Kidney Disease',
    className: 'border-indigo-300 bg-indigo-100',
  },
];
