export const declinedReasons = [
    { "id": "no_time", "name": "No time to participate" },
    { "id": "unnecessary", "name": "Feels program unnecessary" },
    { "id": "already_monitored_by_provider", "name": "Already monitored/managed by provider " },
    { "id": "uncomfortable_data", "name": "Uncomfortable sharing data or being monitored" },
    { "id": "own_device", "name": "Owns and uses medical device" },
    { "id": "cost_concerns", "name": "Concerned about costs" },
    { "id": "incomplete_enrollment", "name": "Didn’t complete enrollment" },
    { "id": "md_ineligible", "name": "MD deemed ineligible" },
    { "id": "thinking_about_it", "name": "Wants to think on it" }
];
