import { Box, Button, Container, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import inMemoryJWT from '../../utils/inMemoryJWT';
import decodeJwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const apiUrl = window.config.apiUrl;

export const ConfirmOTP = (props) => {
  const [, setLoading] = useState(false);
  const [otp, setOTP] = useState('');
  const [error, setError] = useState(null);
  const [tokenType, setTokenType] = useState(null);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const navigate = useNavigate();

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'otp') {
      setOTP(event.target.value);
    } else if (event.target.id === 'password1') {
      setPassword1(event.target.value);
    } else if (event.target.id === 'password2') {
      setPassword2(event.target.value);
    }
  };

  const handleReset = () => {
    setPassword1('');
    setPassword2('');
    setTokenType('');
    setOTP('');
    setError(null);
  };
  const handleClickOTP = () => {
    setLoading(true);

    if (!tokenType) {
      const requestData = { token: otp };

      const request = new Request(apiUrl + '/auth/otp', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      fetch(request)
        .then((data) => {
          if (data.status === 400) {
            setError('bad_token');
          } else if (data.status === 200) {
            return data.json();
          }
        })
        .then((data) => {
          setTokenType(data.token_type);
          setError('');
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    } else if (tokenType === 'email_user') {
      if (!password1 || !password2 || password1 !== password2) {
        return;
      }
      const requestData = {
        token: otp,
        new_password: password1,
        new_password_2: password2,
      };
      const request = new Request(apiUrl + '/auth/otp-set-password', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include',
      });

      fetch(request)
        .then((data) => {
          if (data.status === 400) {
            setError('bad_token');
            setTokenType(null);
          } else if (data.status === 200) {
            return data.json();
          }
        })
        .then(({ access_token, token_expiry }) => {
          const decodedToken: any = decodeJwt(access_token);
          if (['patient'].includes(decodedToken.role)) {
            throw new Error('Forbidden');
          }

          inMemoryJWT.setToken(access_token, token_expiry);
        })
        .then(() => {
          navigate('/');
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <Box sx={{ m: 4, marginBottom: 2 }}>
        <form>
          <Stack spacing={1}>
            <TextField
              required
              id="otp"
              label="Code"
              value={otp}
              onChange={handleChangeText}
              error={error && error === 'bad_token'}
            />
            {tokenType ? (
              <TextField
                required
                id="password1"
                label="Password"
                value={password1}
                onChange={handleChangeText}
                error={error && error === 'bad_token'}
                type="password"
              />
            ) : null}
            {tokenType ? (
              <TextField
                required
                id="password2"
                label="Confirm Password"
                value={password2}
                onChange={handleChangeText}
                error={error && error === 'bad_token'}
                type="password"
              />
            ) : null}
            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                variant="contained"
                onClick={handleClickOTP}
              >
                Submit
              </Button>
              <Button type="button" variant="outlined" onClick={handleReset}>
                Reset
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};
