import { Card, CardContent, CardHeader, CardTitle } from './card';

import React, { createElement } from 'react';
import { Skeleton } from './skeleton';

export function StatCard({
  statName,
  statValue,
  statSecondary = null,
  icon = null,
  isLoading = false,
}) {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium mr-4">{statName}</CardTitle>
        <div className="text-muted-foreground ">
          {icon && createElement(icon, { className: ' h-4 w-4 ' })}
        </div>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">
          {isLoading ? <Skeleton className="w-20 h-6" /> : statValue}
        </div>
        {statSecondary && (
          <p className="text-xs text-muted-foreground">{statSecondary}</p>
        )}
      </CardContent>
    </Card>
  );
}
