import React from 'react';
import { Archive } from 'lucide-react';

export const AISummariesEmpty = () => {
  return (
    <div className="flex flex-col space-y-4 items-center justify-center">
      <h2 className="text-2xl font-semibold text-gray-900 text-center">
        No summaries available
      </h2>
      <div className="flex flex-col gap-8 items-center">
        <p>
          AI generated summaries will appear here once a summary has been
          generated.
        </p>
      </div>
      <Archive size={64} className="text-gray-400 opacity-70" strokeWidth={1} />
    </div>
  );
};
