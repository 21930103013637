import React from 'react';
import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  useGetIdentity,
  useRecordContext,
} from 'react-admin';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';

const filterUserName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

export function TaskCreateButton() {
  const record = useRecordContext();
  const { identity } = useGetIdentity();

  const transform = (data) => {
    return {
      ...data,
      patient_id: record.id,
      added_by_id: identity?.id,
      task_type: 'one_off',
      tenant_id: record?.user.tenant_id,
    };
  };

  let filters: any = {
    'role[ne]': `patient`,
    'tenant_id[in]': [record?.user.tenant_id, null],
  };

  return (
    <CreateInDialogButton
      title="Add new task"
      resource={`tasks`}
      transform={transform}
      fullWidth
      label="Task"
      notifyMessage="Task created"
      refreshOnSuccess
    >
      <div className="flex flex-col gap-2">
        <TextInput source="title" validate={required()} helperText={false} />
        <TextInput
          source="description"
          helperText={false}
          multiline
          minRows={2}
        />
        <DateInput
          source="due_on"
          parse={(v) => new Date(v).toISOString().split('T', 1)[0]}
          helperText={false}
        />
        <SelectInput
          source="priority"
          choices={[
            { id: 1, name: 'High' },
            { id: 2, name: 'Medium' },
            { id: 3, name: 'Low' },
          ]}
          helperText={false}
        />
        <ReferenceInput
          source="assigned_to_id"
          reference="users"
          filter={filters}
        >
          <AutocompleteInput
            label="Assigned to"
            optionText={(choice) => {
              if (choice.is_internal) {
                return `${choice.first_name} ${choice.last_name} (Vironix) ${
                  choice.credentials ? `(${choice.credentials})` : ``
                }`;
              }
              return `${choice.full_name} ${
                choice.credentials ? `(${choice.credentials})` : ``
              }`;
            }}
            parse={(v) => (v === '' ? null : v)}
            helperText={false}
            filterToQuery={filterUserName}
          />
        </ReferenceInput>
      </div>
    </CreateInDialogButton>
  );
}
