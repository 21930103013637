import { Chip, Tooltip } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';

const formatICD10Code = (value) => {
  // add dot after 3rd character ie. I501 -> I50.1
  const id = value.id.slice(0, 3) + '.' + value.id.slice(3);
  return `${id}`;
};

export const ICD10Chip = () => {
  const record = useRecordContext();

  return (
    <Tooltip title={record.description}>
      <Chip label={formatICD10Code(record)} sx={{ mx: 0.5 }} />
    </Tooltip>
  );
};
