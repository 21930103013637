import { Grid, MenuItem, Select, Stack, TextField } from '@mui/material';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

export const weightUnits = [
  {
    name: 'pound',
    text: 'lb',
    system: 'imperial',
    code: {
      code: 'imperial__pound',
    },
  },
  {
    name: 'kilograms',
    text: 'kg',
    system: 'metric',
    code: {
      code: 'metric__kilogram',
    },
  },
];

export const heightUnits = [
  {
    name: 'inch',
    text: 'in',
    system: 'imperial',
    code: {
      code: 'imperial__inch',
    },
  },
  {
    name: 'centimeter',
    text: 'cm',
    system: 'metric',
    code: {
      code: 'metric__centimeter',
    },
  },
];

const HeightTextInput = (props) => {
  const { margin, unitValue, required, field } = props;
  const getValueFeet = () => {
    return Math.floor(Math.round(get(field, 'value')) / 12);
  };

  const getValueInches = () => {
    return Math.round(Math.round(get(field, 'value')) % 12);
  };

  const handleChange = (event) => {
    if (event.target.name === 'feet') {
      field.onChange((getValueInches() || 0) + Number(event.target.value) * 12);
    }
    if (event.target.name === 'inches') {
      field.onChange((getValueFeet() || 0) * 12 + Number(event.target.value));
    }
  };

  if (get(unitValue, 'text') === 'in')
    return (
      <Stack direction="row" spacing={1} alignItems="baseline">
        <TextField
          name="feet"
          onChange={handleChange}
          value={getValueFeet()}
          margin={margin}
          id="feet"
          label="Feet"
          type="number"
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: '0',
            max: '8',
            step: '1',
          }}
        />
        <TextField
          name="inches"
          onChange={handleChange}
          value={getValueInches()}
          margin={margin}
          id="inches"
          label="Inches"
          required={required}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: '0',
              max: '11',
              step: 'any',
            },
          }}
        />
      </Stack>
    );
  else if (get(unitValue, 'text') === 'cm')
    return (
      <TextField
        {...field}
        margin={margin}
        id="centimeter"
        label="Height"
        required={required}
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputProps: {
            min: '1',
            step: 'any',
          },
        }}
      />
    );

  return (
    <TextField
      margin={margin}
      disabled
      label="Height"
      helperText="Select unit"
    />
  );
};

export const HeightInput = (props) => {
  const { source, margin = 'dense', required = true } = props;

  const { field: fieldValue } = useController({ name: source + '.value' });
  const { field: fieldUnit } = useController({
    name: source + '.unit',
    defaultValue: heightUnits[0],
  });

  const onChangeUnit = (value) => {
    fieldValue.onChange(0);
    fieldUnit.onChange(value);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <HeightTextInput
          unitValue={get(fieldUnit, 'value')}
          margin={margin}
          required={required}
          field={fieldValue}
        />
      </Grid>
      <Grid item>
        <UnitSelector
          required={required}
          field={fieldUnit}
          choices={heightUnits}
          onChangeUnit={onChangeUnit}
        />
      </Grid>
    </Grid>
  );
};

const UnitSelector = (props) => {
  const { field, required, choices, onChangeUnit } = props;

  const [unitText, setUnitText] = useState(
    get(field, 'value.text') === undefined ? '' : choices[0].text,
  );

  const handleChange = (event) => {
    onChangeUnit(choices?.find((u) => u.text === event.target.value));
    setUnitText(event.target.value);
  };

  return (
    <Select
      value={unitText}
      ref={get(field, 'ref')}
      onChange={handleChange}
      required={required}
    >
      {choices.map((u) => (
        <MenuItem key={u.text} value={u.text}>
          {u.text}
        </MenuItem>
      ))}
    </Select>
  );
};

export const WeightInput = (props) => {
  const { source, margin = 'dense', required = true } = props;
  const { field: fieldValue } = useController({
    name: source + '.value',
    rules: { required: required, min: 10, max: 500 },
  });
  const { field: fieldUnit } = useController({
    name: source + '.unit',
    defaultValue: weightUnits[0],
  });

  const onChangeUnit = (value) => {
    fieldValue.onChange(0);
    fieldUnit.onChange(value);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        {get(fieldUnit, 'value.text') ? (
          <TextField
            {...fieldValue}
            margin={margin}
            id="weight"
            label="Weight"
            required={required}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: '10',
                step: 'any',
              },
            }}
          />
        ) : (
          <TextField disabled label="Weight" helperText="Select unit" />
        )}
      </Grid>
      <Grid item>
        <UnitSelector
          required={required}
          field={fieldUnit}
          choices={weightUnits}
          onChangeUnit={onChangeUnit}
        />
      </Grid>
    </Grid>
  );
};
