export const race = [
  {
    id: 'american_indian_or_alaska_native',
    name: 'American Indian or Alaska Native',
  },
  {
    id: 'north_east_asian',
    name: 'North East Asian',
  },
  {
    id: 'south_east_asian',
    name: 'South East Asian',
  },
  {
    id: 'black',
    name: 'Black or African American',
  },
  {
    id: 'native_hawaiian_or_other_pacific_islander',
    name: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    id: 'other',
    name: 'Other race',
  },
  {
    id: 'white',
    name: 'White',
  },
  {
    id: 'no_disclosure',
    name: 'Choose not to disclose',
  },
];
