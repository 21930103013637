import React from 'react';
import { Card, CardContent } from '@/modules/ui/components/card';
import IExamMultipleChoice from 'healthicons-react/dist/outline/IExamMultipleChoice';

export const AssessmentsEmpty = () => {
  return (
    <Card>
      <CardContent className="mt-2">
        <div className="flex flex-col gap-4 items-center">
          <p className="text-xl">None reported</p>
          <IExamMultipleChoice className="w-16 h-16" />
        </div>
      </CardContent>
    </Card>
  );
};
