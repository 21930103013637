import clsx from 'clsx';
import inflection from 'inflection';
import { get, find } from 'lodash';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { Badge } from '../../ui/components/badge';

export default function BadgeField({
  className = '',
  source,
  emptyText = '',
  variant = 'secondary' as const,
  humanize = true,
  mapping = null,
  capitalize = false,
  label = '',
  applyMappedClassName = true,
}) {
  const record = useRecordContext();
  const value = get(record, source);

  const mappedValue = find(mapping, { id: value });

  let displayValue = mappedValue
    ? mappedValue.value || mappedValue.name || value
    : value;
  const additionalClassNames = mappedValue ? mappedValue.className : '';

  if (value == null && emptyText) {
    return (
      <span className={clsx(className, 'italic text-center')}>{emptyText}</span>
    );
  } else if (value == null) {
    return null;
  }

  if (humanize) {
    displayValue = inflection.humanize(displayValue);
  } else if (capitalize) {
    displayValue = displayValue.toUpperCase();
  }

  return (
    <Badge
      variant={variant}
      className={clsx(
        'm-0 border-slate border-2 whitespace-nowrap ',
        className,
        applyMappedClassName ? additionalClassNames : null,
      )}
    >
      {displayValue}
    </Badge>
  );
}
