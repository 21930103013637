import React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';

export const AddressField = ({ source, maxChar = 150 }) => {
  const record = useRecordContext();

  const address = get(record, source);

  const addressString = [
    get(address, 'address_line1', ''),
    get(address, 'address_line2', ''),
    get(address, 'city', ''),
    get(address, 'state', ''),
    get(address, 'zip_code', ''),
    get(address, 'country', ''),
  ]
    .filter(Boolean)
    .join(', ');

  const truncatedAddress =
    addressString.slice(0, maxChar) +
    (addressString.length > maxChar ? '...' : '');

  return record ? <span className="text-sm">{truncatedAddress}</span> : null;
};
