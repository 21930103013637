export function getInitials(name: string, numInitials: number = 2): string {
  if (!name) return '?';
  
  const names = name.trim().split(/\s+/);
  if (!names.length) return '?';

  const initials = names
    .slice(0, numInitials)
    .map(part => part.charAt(0).toUpperCase())
    .join('');

  return initials || '?';
}
