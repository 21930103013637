import { Card } from '@/modules/ui/components/card';
import React from 'react';
import { ExportReportButton } from './ExportReportButton';

const ReportHeaderTemplate = ({
  children,
  exportReportButton = <ExportReportButton />,
}) => {
  return (
    <Card className="relative">
      <div className="absolute top-2 right-2 ">{exportReportButton}</div>
      <div className="grid grid-cols-1 md:grid-cols-2">{children}</div>
    </Card>
  );
};

export default ReportHeaderTemplate;
