import React, { useEffect } from 'react';
import { useRecordContext, useStore, useNotify } from 'react-admin';
import { Input } from '@/modules/ui/components/input';
import { Button } from '@/modules/ui/components/button';
import { Copy } from 'lucide-react';
import { InfoBlock } from '../../components/InfoBlock';
export const InviteCodeInput = () => {
  const record = useRecordContext();
  const [otp, setOtp] = useStore('otp', '');
  const notify = useNotify();

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(otp);
    notify('Code copied to clipboard', {
      variant: 'success',
    });
  };

  useEffect(() => {
    return () => {
      setOtp('');
    };
  }, [setOtp]);

  if (!record || !otp) return null;

  return (
    <InfoBlock
      title="Invite code"
      component={
        <div className="flex flex-col gap-2  w-full">
          <div className="flex gap-2">
            <Input readOnly value={otp} className="w-full h-8" />
            <Button
              variant="outline"
              size="icon"
              onClick={copyToClipBoard}
              className="shrink-0 h-8"
            >
              <Copy className="h-4 w-4" />
            </Button>
          </div>
        </div>
      }
    />
  );
};
