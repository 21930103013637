import { Box, Container } from '@mui/material';
import React from 'react';

export default function QuestionnaireCard({ children }) {
  return (
    <Container maxWidth="md">
      <Box m={{ xs: 1, sm: 2, md: 4 }}>{children}</Box>
    </Container>
  );
}
