'use client';

import * as React from 'react';
import { DateTime } from 'luxon';
import { Calendar as CalendarIcon } from 'lucide-react';

import { cn } from '../utils/cn';
import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { TimePicker } from './time-picker';

export function DateTimePicker({
  value,
  onChange,
  timezone = 'utc',
  dateTimeFormat = 'fff',
}: {
  value: string;
  onChange: (date: string) => void;
  timezone?: string;
  dateTimeFormat?: string;
}) {
  const dateTime = value
    ? DateTime.fromISO(value, { zone: 'utc' }).setZone(timezone)
    : null;

  const handleSelect = (newDay: DateTime | null) => {
    if (!newDay) return;
    if (!dateTime) {
      onChange(
        newDay.toUTC().toISO({
          includeOffset: false,
        }),
      );
      return;
    }
    const newDateTime = dateTime.set({
      year: newDay.year,
      month: newDay.month,
      day: newDay.day,
    });
    onChange(newDateTime.toUTC().toISO({ includeOffset: false }));
  };

  const handleTimeChange = (newDate: DateTime) => {
    onChange(newDate.toUTC().toISO({ includeOffset: false }));
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-fit justify-start text-left font-normal h-10 ',
            !value && 'text-muted-foreground',
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {dateTime ? (
            dateTime.toFormat(dateTimeFormat)
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={dateTime ? dateTime.toJSDate() : undefined}
          onSelect={(d) =>
            handleSelect(d ? DateTime.fromJSDate(d, { zone: timezone }) : null)
          }
        />
        <div className="p-3 border-t border-border">
          <TimePicker
            setDate={handleTimeChange}
            date={dateTime ? dateTime : undefined}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
