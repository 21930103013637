import { DateTime } from 'luxon';

export function checkDeviceMeasurements(hasDevice: boolean , lastDeviceMeasurement: string | null): {
  hasWarning: boolean;
  warningType: 'no-measurements' | 'stale-measurements' | null;
  businessDays?: number;
} {
  if (!hasDevice) {
    return {
      hasWarning: false,
      warningType: null,
    };
  }

  if (!lastDeviceMeasurement) {
    return {
      hasWarning: true,
      warningType: 'no-measurements',
    };
  }

  const lastMeasurementDate = DateTime.fromISO(lastDeviceMeasurement);
  const today = DateTime.now();
  const diffInDays = today.diff(lastMeasurementDate, 'days').days;

  // Calculate business days by subtracting weekends
  const businessDays = Math.floor(diffInDays - Math.floor(diffInDays / 7) * 2);

  if (businessDays > 7) {
    return {
      hasWarning: true,
      warningType: 'stale-measurements',
      businessDays,
    };
  }

  return {
    hasWarning: false,
    warningType: null,
    businessDays,
  };
}
