import React, { useCallback } from 'react';
import { useInput, useChoices, FieldTitle } from 'ra-core';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/modules/ui/components/radio-group';
import { cn } from '@/modules/ui/utils/cn';
import { InputMessage } from './input-message';

export interface RadioGroupInputProps {
  choices?: any[];
  className?: string;
  label?: string | React.ReactElement;
  helperText?: string | React.ReactElement | false;
  source: string;
  optionText?: string | ((choice: any) => React.ReactNode);
  optionValue?: string;
  translateChoice?: boolean;
  validate?: any;
  row?: boolean;
  [key: string]: any;
}

export const RadioGroupInput = (props: RadioGroupInputProps) => {
  const {
    choices = [],
    className,
    label,
    helperText,
    source,
    optionText = 'name',
    optionValue = 'id',
    translateChoice = true,
    validate,
    row = true,
    ...rest
  } = props;

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    source,
    validate,
    ...rest,
  });

  const renderHelperText =
    helperText !== false || ((isTouched || isSubmitted) && invalid);

  const handleChange = useCallback(
    (value: string) => {
      field.onChange(value);
    },
    [field],
  );

  return (
    <div className={cn('space-y-2', className)}>
      <FieldTitle label={label} source={source} isRequired={isRequired} />
      <RadioGroup
        onValueChange={handleChange}
        defaultValue={field.value}
        className={cn(
          row ? 'flex flex-row space-x-2' : 'flex flex-col space-y-2',
        )}
      >
        {choices.map((choice: any) => (
          <div
            key={getChoiceValue(choice)}
            className="flex items-center space-x-2"
          >
            <RadioGroupItem
              value={getChoiceValue(choice)}
              id={`${source}_${getChoiceValue(choice)}`}
            />
            <label
              htmlFor={`${source}_${getChoiceValue(choice)}`}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {getChoiceText(choice)}
            </label>
          </div>
        ))}
      </RadioGroup>
      {renderHelperText && (
        <InputMessage
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      )}
    </div>
  );
};
