import React from 'react';
import { Questionnaire } from '@/modules/questionnaire/components/Questionnaire';
import { useQuestionnaireContext } from '../hooks/useQuestionnaireContext';
import { ModalVitalsToUpdateContent } from './ModalVitalsToUpdateContent';

export function ModalQuestionnaireContent({ handleCompleteAssessment }) {
  const { workFlow } = useQuestionnaireContext();

  if (!workFlow) return;

  if (!!!workFlow.available) {
    return <ModalVitalsToUpdateContent />;
  }

  return (
    <Questionnaire
      workflowName={workFlow.name}
      onComplete={handleCompleteAssessment}
    />
  );
}
