import React from 'react';
import { TextInput } from 'react-admin';

import EditDialog from '@/modules/ra-ui/components/edit-dialog';

const DeviceChangeExternalID = ({ open, setOpen }) => {
  return (
    <EditDialog
      open={open}
      setOpen={setOpen}
      title="IMEI"
      resource="devices"
      variant="default"
      notifyMessage="Device configuration updated."
      refreshOnSuccess={true}
      transform={(data) => {
        return {
          external_id: data.external_id,
          update_action: 'update_external_id',
        };
      }}
    >
      <TextInput source="external_id" helperText={false} />
    </EditDialog>
  );
};

export default DeviceChangeExternalID;
