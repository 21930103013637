import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';

import { formatDuration } from '@/utils/formatDuration';
import React, { useEffect, useState } from 'react';
import { useLogout } from 'react-admin';
import { useIdleTimer } from 'react-idle-timer';

const timeout = 60_000 * 15;
const promptBeforeIdle = 60_000;

const InactivityDialog = ({ open, onClose, onLogout, getRemainingTime }) => {
  const [remaining, setRemaining] = useState<number>(0);

  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [open, getRemainingTime]);

  return (
    <Dialog open={open} fullWidth>
      <DialogContent onClose={onClose} className="max-w-sm">
        <DialogHeader>
          <DialogTitle>Are you still here?</DialogTitle>
        </DialogHeader>
        <div className="p-4">
          <p>
            You will be logged out in{' '}
            {formatDuration(
              remaining > 60 ? Math.floor(remaining / 60) * 60 : remaining,
            )}{' '}
            due to inactivity.
          </p>
        </div>
        <DialogFooter>
          <Button onClick={onClose}>Yes</Button>
          <Button onClick={onLogout} variant="outline">
            Logout
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export function InactivityHandler() {
  const [open, setOpen] = useState<boolean>(false);
  const logout = useLogout();

  const onIdle = () => {
    setOpen(false);
    logout();
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true, // Enable cross-tab synchronization
    syncTimers: 200, // Sync interval in milliseconds
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <InactivityDialog
      open={open}
      onClose={handleStillHere}
      onLogout={logout}
      getRemainingTime={getRemainingTime}
    />
  );
}
