import { get, isEqual } from 'lodash';
import React from 'react';

import { useEnrollmentQueue } from '@/modules/enrollment/components/EnrollmentQueueProvider';
import { DateRangePicker } from '@/modules/ui/components/date-picker-range';
import { FormProvider, useForm } from 'react-hook-form';

export const EnrollmentDatePicker = () => {
  const { config, setConfig } = useEnrollmentQueue();
  const column = 'appointment_date';

  const getDefaultValues = React.useCallback(() => {
    if (config.appointmentDate && 'from' in config.appointmentDate) {
      return {
        from: config.appointmentDate.from,
        to: config.appointmentDate.to,
      };
    } else {
      return null;
    }
  }, [config.appointmentDate]);

  const form = useForm({
    defaultValues: {
      [column]: getDefaultValues(),
    },
  });

  React.useEffect(() => {
    const newValue = getDefaultValues();
    const previousValue = get(form.getValues(), column);
    if (!isEqual(newValue, previousValue)) {
      form.reset({
        [column]: newValue,
      });
    }
  }, [form, column, getDefaultValues]);

  React.useEffect(() => {
    const subscription = form.watch(async (values, { name }) => {
      const isFormValid = await form.trigger();
      if (name && isFormValid) {
        const range = get(values, column);

        if (range) {
          let fromDate = new Date(range.from);
          fromDate.setHours(0, 0, 0, 0);
          let toDate = range.to ? new Date(range.to) : new Date(range.from);
          toDate.setHours(23, 59, 59, 999);

          setConfig((prevConfig) => ({
            ...prevConfig,
            appointmentDate: {
              from: fromDate,
              to: toDate,
            },
          }));
        } else {
          setConfig((prevConfig) => ({
            ...prevConfig,
            appointmentDate: null,
          }));
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [form, setConfig, column]);

  return (
    <FormProvider {...form}>
      <DateRangePicker
        column={'appointment_date'}
        align="start"
        locale="en-GB"
        placeHolderText="Appointment Date"
        presets={[
          {
            label: 'Today',
            name: 'today',
            getRange: () => ({
              from: new Date(),
              to: new Date(),
            }),
          },
          {
            label: 'Tomorrow',
            name: 'tomorrow',
            getRange: () => {
              const tomorrow = new Date(
                new Date().setDate(new Date().getDate() + 1),
              );
              return {
                from: tomorrow,
                to: tomorrow,
              };
            },
          },
          {
            label: 'This Week',
            name: 'thisWeek',
            getRange: () => {
              const today = new Date();
              const from = new Date(today);
              from.setDate(today.getDate() - today.getDay());
              from.setHours(0, 0, 0, 0);
              const to = new Date(from);
              to.setDate(from.getDate() + 6);
              to.setHours(23, 59, 59, 999);
              return { from, to };
            },
          },
        ]}
      />
    </FormProvider>
  );
};
