import React from 'react';
import { useRecordContext, SelectInput } from 'react-admin';
import { DateTime } from 'luxon';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';

export const AddAISummaryButton = () => {
  const record = useRecordContext();

  const transformData = (values) => {
    const { period } = values;
    const endDate = DateTime.now().toLocal().toISODate();
    let startDate;
    switch (period) {
      case 'past_week':
        startDate = DateTime.now().toLocal().minus({ weeks: 1 }).toISODate();
        break;
      case 'past_month':
        startDate = DateTime.now().toLocal().minus({ months: 1 }).toISODate();
        break;
      case 'past_year':
        startDate = DateTime.now().toLocal().minus({ years: 1 }).toISODate();
        break;
      default:
        startDate = DateTime.now().toLocal().toISODate();
    }
    return {
      start_date: startDate,
      end_date: endDate,
      rpm_patient_id: record.user.id,
    };
  };

  if (!record) return null;

  return (
    <CreateInDialogButton
      title="Generate AI Summary"
      resource="foundation-model-responses/patient-summary"
      transform={transformData}
      variant="default"
      fullWidth
      label="AI Summary"
      notifyMessage="AI summary generated"
      refreshOnSuccess
    >
      <p>
        Choose the period for which to generate the summary.
        <br /> This summary will be generated by an AI language model.
      </p>
      <SelectInput
        choices={[
          { id: 'past_week', name: 'Past week' },
          { id: 'past_month', name: 'Past month' },
          { id: 'past_year', name: 'Past year' },
        ]}
        className="mt-1 block w-full"
        source="period"
        required
      />
    </CreateInDialogButton>
  );
};
