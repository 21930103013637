import { get } from 'lodash';
import { LoaderIcon } from 'lucide-react';
import {
  Form,
  required,
  useLogin,
  useNotify,
  useSafeSetState,
  useTranslate,
} from 'ra-core';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@/modules/ui/components/button';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { TextInput } from '@/modules/ui/inputs/text-input';
import { useMFAToken } from '@/modules/login/components/MFATokenProvider';

export function LoginForm({ setView }) {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setToken, setAvailableMethods } = useMFAToken();

  const submit = (values) => {
    setLoading(true);
    login(values, searchParams.get('redirectTo') || '')
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (get(error, 'error.error') === 'mfa_challenge') {
          const mfaToken = get(error, 'error.body.mfa_token');
          const availableMethods = get(
            error,
            'error.body.available_mfa_methods',
            [],
          );
          const preferredMFAMethod = get(
            error,
            'error.body.preferred_mfa_method',
          );

          setToken(mfaToken);
          setAvailableMethods(availableMethods);

          if (
            preferredMFAMethod &&
            availableMethods.includes(preferredMFAMethod)
          ) {
            if (preferredMFAMethod === 'webauthn') {
              setView('webauthn');
            } else if (preferredMFAMethod === 'totp') {
              setView('totp');
            }
          } else {
            // If preferred method is not available, default to the first available method
            setView(availableMethods[0] === 'webauthn' ? 'webauthn' : 'totp');
          }
          return;
        }

        if (get(error, 'body.message') === 'User account belongs to patient') {
          setView('patient-success');
          setSearchParams({ page: 'patient-success' });
          return;
        }
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          {
            type: 'warning',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                    ? error.message
                    : undefined,
            },
          },
        );
      });
  };

  return (
    <>
      <CardHeader className="text-center">
        <CardTitle className="text-2xl ">Login</CardTitle>
        <CardDescription>
          Enter your email below to login to your account
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form onSubmit={submit} mode="onChange" noValidate>
          <div className="flex flex-col gap-1">
            <TextInput
              autoFocus
              source="username"
              label="Email"
              validate={required()}
              helperText={false}
              autoComplete="username"
            />
            <TextInput
              source="password"
              label={translate('ra.auth.password')}
              type="password"
              autoComplete="current-password"
              validate={required()}
              helperText={false}
            />
            <Button
              variant="ghost"
              className="w-full mt-2"
              onClick={() => {
                setView('reset-password');
              }}
              type="button"
            >
              Forgot your password?
            </Button>

            <Button
              variant="default"
              className="w-full"
              type="submit"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <LoaderIcon className="animate-spin" />
              ) : (
                translate('ra.auth.sign_in')
              )}
            </Button>
          </div>
        </Form>
      </CardContent>
    </>
  );
}
