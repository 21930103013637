import { Button } from '@/modules/ui/components/button';
import { Loader2 } from 'lucide-react';
import React, { useCallback } from 'react';
import {
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useDropzone } from 'react-dropzone';

export function ConsentForm() {
  const record = useRecordContext();
  const [isLoading, setIsLoading] = React.useState(false);

  const dataProvider = useDataProvider();

  const isUploaded = record?.consent_form_document_id ? true : false;
  const [showDropZone, setShowDropZone] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const refresh = useRefresh();
  const notify = useNotify();

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: {
      'application/pdf': [],
    },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024,
  });

  const cancelUpload = () => {
    setShowDropZone(false);
    setFile(null);
  };

  const uploadFile = () => {
    setIsLoading(true);
    dataProvider
      .uploadFile('patient-documents/upload', {
        file: file,
        meta: {
          patient_id: record.id,
          document_type: 'consent_form',
        },
      })
      .then((response) => {
        setShowDropZone(false);
        setFile(null);
        notify('Consent form uploaded successfully', { type: 'success' });
        refresh();
        setIsLoading(false);
      })
      .catch((error) => {
        notify('Error uploading consent form: ' + error.message, {
          type: 'error',
        });
        setIsLoading(false);
      });
  };

  const viewFile = () => {
    dataProvider
      .getOne('patient-documents', {
        id: record.consent_form_document_id,
      })
      .then((response) => {
        if (!response.data.signed_link) {
          notify('Error viewing consent form: No signed link found', {
            type: 'error',
          });
          return;
        }
        window.open(response.data.signed_link, '_blank');
      })
      .catch((error) => {
        notify('Error viewing consent form: ' + error.message, {
          type: 'error',
        });
      });
  };

  return (
    <div className="p-2">
      {showDropZone ? (
        <div className="flex flex-row items-center justify-stretch gap-2	">
          <div className="flex flex-col items-center justify-stretch gap-2  w-full">
            <div
              {...getRootProps()}
              className="border-dashed border-4 border-gray-200 rounded-lg p-10 text-center cursor-pointer  w-full"
            >
              <input {...getInputProps()} />
              <p>Drag and drop your consent form here, or click and browse</p>
            </div>
            <span className="flex flex-row items-center justify-center gap-1 mb-2">
              {file && (
                <p className="w-20">
                  <i>Uploading:</i>{' '}
                </p>
              )}
              {file && <p className="w-40 truncate">{file.name}</p>}
            </span>
          </div>
          <div className="flex flex-col items-center justify-stretch mr-2 gap-2">
            <Button
              className="h-8 flex items-center justify-center"
              onClick={uploadFile}
              disabled={!file || isLoading}
            >
              {isLoading ? (
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              ) : (
                'Confirm'
              )}
            </Button>
            <Button
              className="h-8 "
              variant="destructive"
              onClick={cancelUpload}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : isUploaded ? (
        <div className="flex flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center">
            <svg
              className="w-6 h-6 text-green-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M5 13l4 4L19 7"></path>
            </svg>
            <p>Consent form uploaded</p>
          </div>
          <div className="flex flex-row items-center justify-between gap-2">
            <Button className="h-8" onClick={viewFile}>
              View
            </Button>
            <Button
              className="h-8"
              variant="outline"
              onClick={() => setShowDropZone(true)}
            >
              Upload New
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center">
            <svg
              className="w-6 h-6 text-red-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
            <p>Consent form not uploaded</p>
          </div>
          <div className="flex flex-row items-center justify-between gap-2">
            <Button className="h-8" onClick={() => setShowDropZone(true)}>
              Upload
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
