import * as React from 'react';
import { Children, isValidElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import {
  RaRecord,
  useRecordContext,
  OptionalRecordContextProvider,
} from 'ra-core';
import { Labeled } from 'react-admin';
import { cn } from '@/modules/ui/utils/cn';

export const ShowSimpleGrid = (props: ShowSimpleGridProps) => {
  const { className, children, ...rest } = props;
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }
  return (
    <OptionalRecordContextProvider value={props.record}>
      <div
        className={cn(
          'grid grid-cols-2 xl:grid-cols-3 gap-1  w-full ',
          ShowSimpleGridClasses.root,
          className,
        )}
        {...sanitizeRestProps(rest)}
      >
        {Children.map(children, (field) =>
          field && isValidElement<any>(field) ? (
            <div
              className={cn(
                'ra-field',
                field.props.source && `ra-field-${field.props.source}`,
                ShowSimpleGridClasses.row,
                field.props.className,
              )}
            >
              <Labeled key={field.props.source}>{field}</Labeled>
            </div>
          ) : null,
        )}
      </div>
    </OptionalRecordContextProvider>
  );
};

export interface ShowSimpleGridProps {
  children: ReactNode;
  className?: string;
  record?: RaRecord;
  sx?: any; // Adjusted for custom styling props if necessary
}

ShowSimpleGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  record: PropTypes.object,
  sx: PropTypes.any,
};

const PREFIX = 'RaShowSimpleGrid';

export const ShowSimpleGridClasses = {
  root: `${PREFIX}-root`,
  row: `${PREFIX}-row`,
};

const sanitizeRestProps = ({
  record,
  resource,
  initialValues,
  translate,
  ...rest
}: any) => rest;
