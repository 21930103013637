import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';
import { CustomPallete } from './utils/color';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tableHeadTitle: React.CSSProperties;
    customBody1: React.CSSProperties;
    modalTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    tableHeadTitle?: React.CSSProperties;
    customBody1?: React.CSSProperties;
    modalTitle?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableHeadTitle: true;
    customBody1: true;
    modalTitle: true;
  }
}

export const theme = merge(defaultTheme, {
  palette: {
    // primary: indigo,
    // secondary: pink,
    // error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: 34,
      fontWeight: '600',
      color: CustomPallete.neutral10,
    },
    h2: {
      fontWeight: '600',
      fontSize: 24,
      color: CustomPallete.neutral10,
    },
    h3: {
      fontWeight: '600',
      fontSize: 20,
      color: CustomPallete.neutral10,
      marginBottom: 16,
    },
    h4: {
      fontSize: 14,
      color: CustomPallete.neutral10,
      fontWeight: '500',
    },
    tableHeadTitle: {
      fontSize: 16,
      color: CustomPallete.neutral10,
    },
    customBody1: {
      fontSize: 14,
      color: CustomPallete.neutral10,
      fontWeight: '400',
    },
    modalTitle: {
      fontSize: 18,
      color: CustomPallete.neutral10,
      fontWeight: '600',
      lineHeight: '25px',
    },
  },
  components: {
    ...defaultTheme.components,
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 8,
          '&:last-child': {
            paddingBottom: 8,
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: 'black',
          backgroundColor: '#DCE8F0',
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          height: '100%',
          boxShadow: '0px 0px 12px rgba(117, 117, 117, 0.12)',
          '& .RaSidebar-fixed': {
            height: 'calc(100% - 0.5em)',
          },
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F9F9FA',
          boxShadow: 'none',
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F9F9FA',
          '& .RaBulkActionsToolbar-toolbar': {
            backgroundColor: '#F9F9FA',
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#E0E5EB',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 24,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          borderRadius: '8px',
          borderBottom: null,
          '&:before': {
            borderBottom: 0,
          },
          '&:after': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '8px',
          '&:before, &:after': {
            borderBottom: 0,
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
          '&:hover:not(.Mui-disabled):after': {
            borderBottom: 'none',
          },
        },
      },
    },
  },
});
