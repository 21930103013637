export const chartProperties = {
  oxygen_saturation: {
    name: 'Oxygen Saturation',
    round: 0,
  },
  heart_rate: {
    name: 'Heart Rate',
    round: 0,
  },
  peak_expiratory_flow_rate: {
    name: 'Peak Flow',
    round: 0,
  },
  forced_expiratory_volume_1: {
    name: 'FEV1',
    round: 1,
  },
  body_temperature: {
    name: 'Temperature',
    round: 1,
  },
  blood_pressure: {
    name: 'Blood Pressure',
    round: 0,
  },
  body_mass: {
    name: 'Weight',
    round: 1,
  },
  blood_glucose: {
    name: 'Blood Glucose',
    round: 0,
  },
};
