import React from 'react';
import { googleIcon, appIcon, androidScreenshots } from '../../assets';
import { iphoneDescription, androidContacts } from '../../constants';

export const GooglePLayPreview = () => {
  return (
    <div
      style={{
        width: '100%',
        padding: 16,
        boxSizing: 'border-box',
      }}
    >
      <header
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 50,
        }}
      >
        <img
          style={{
            width: 35,
            marginRight: 10,
          }}
          src={googleIcon}
          alt="Google Play Icon"
        />
        <span
          style={{
            fontSize: 22,
            fontWeight: '600',
            color: '#54585D',
          }}
        >
          Google Play Preview
        </span>
      </header>

      <div
        style={{
          display: 'flex',
          marginBottom: 50,
        }}
      >
        <img
          style={{
            width: 70,
            boxShadow:
              '0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)',
            borderRadius: 10,
            marginRight: 25,
          }}
          src={appIcon}
          alt="App Icon"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              fontSize: 24,
              fontWeight: '600',
              marginBottom: 5,
            }}
          >
            Vironix RPM
          </span>
          <span
            style={{
              fontSize: 16,
              fontWeight: '600',
              color: '#007C57',
            }}
          >
            Vironix Health
          </span>
        </div>
      </div>

      <a
        href="https://play.google.com/store/apps/details?id=com.vironix&hl=en_US&gl=US"
        style={{
          display: 'block',
          backgroundColor: '#007C57',
          color: 'white',
          fontSize: 14,
          fontWeight: '600',
          textAlign: 'center',
          padding: 10,
          width: '100%',
          borderRadius: 10,
          textDecoration: 'none',
          marginBottom: 40,
          maxWidth: 500,
          boxSizing: 'border-box',
        }}
      >
        View In Google Play
      </a>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
          marginBottom: 50,
        }}
      >
        {androidScreenshots.map((screenshot, index) => (
          <img
            style={{
              width: 200,
              borderRadius: 20,
              marginRight: 10,
            }}
            key={index}
            src={screenshot}
            alt="Android Screenshot"
          />
        ))}
      </div>

      <div
        style={{
          maxWidth: 500,
          marginBottom: 50,
        }}
      >
        <span
          style={{
            display: 'block',
            fontSize: 18,
            fontWeight: '600',
            marginBottom: 20,
            boxSizing: 'border-box',
          }}
        >
          About this app
        </span>
        {iphoneDescription.map((description) => (
          <p
            style={{
              fontSize: 14,
              color: '#575B60',
              boxSizing: 'border-box',
            }}
            key={description}
          >
            {description}
          </p>
        ))}
      </div>

      <div>
        <span
          style={{
            display: 'block',
            fontSize: 18,
            fontWeight: '600',
            marginBottom: 20,
          }}
        >
          Developer contact
        </span>
        {androidContacts.map((contact) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
              }}
              key={contact.title}
            >
              <img
                style={{
                  width: 20,
                  marginRight: 10,
                }}
                src={contact.icon}
                alt="Contact Icon"
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 10,
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: '600',
                    marginBottom: 5,
                  }}
                >
                  {contact.title}
                </span>
                <a
                  href={contact.text}
                  style={{
                    fontSize: 14,
                    textDecoration: 'none',
                  }}
                >
                  {contact.text}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
