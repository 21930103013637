export const encounterClass = [
  {
    id: 'inpatient',
    name: 'Inpatient',
  },
  {
    id: 'outpatient',
    name: 'Outpatient',
  },
  {
    id: 'emergency',
    name: 'Emergency',
  },
  {
    id: 'virtual',
    name: 'Virtual',
  },
  {
    id: 'other',
    name: 'Other',
  },
];
