import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import inflection from 'inflection';
import React from 'react';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/modules/ui/components/tabs';
import { Loader2, Plus, Radio } from 'lucide-react';
import {
  AutocompleteInput,
  Form,
  ReferenceInput,
  required,
  useCreate,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { useQuery } from 'react-query';

export const AddObservationButton = () => {
  const record = useRecordContext();

  const [open, setOpen] = React.useState(false);

  const [create, { isLoading }] = useCreate();
  const refresh = useRefresh();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePressSetSharedDevice = (values) => {
    create(
      'devices/assign-shared-device',
      { data: { device_id: values.device_id, patient_id: record.id } },
      {
        onSuccess: () => {
          setOpen(false);
          refresh();
        },
      },
    );
  };

  if (!record) return null;

  return (
    <div>
      <div className="flex flex-row gap-2">
        <ActiveSharedDevice />
        <Button
          onClick={handleClickOpen}
          size="sm"
          className="h-8"
          variant="default"
        >
          <Plus className="mr-2 h-4 w-4" />
          Measurement
        </Button>
      </div>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogContent onClose={handleClose}>
          <DialogHeader>
            <DialogTitle>Add measurement</DialogTitle>
          </DialogHeader>
          <Tabs defaultValue="shared-device" className=" pt-2">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="shared-device">Shared Device</TabsTrigger>
              {/* <TabsTrigger value="manual">Manual</TabsTrigger> */}
            </TabsList>
            <TabsContent value="shared-device">
              <Form onSubmit={handlePressSetSharedDevice}>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-xl">Shared Device</CardTitle>
                    <CardDescription className="max-w-[400px]">
                      Use a shared device to add a measurement to this patient.
                      <br />
                      <ul className="list-disc pl-6">
                        <li>
                          When applied, the device will be linked to this
                          patient for the next 10 minutes.
                        </li>
                        <li>Only good for 1 measurement.</li>
                        <li>No need to "unassign" from patient.</li>
                        <li>Must use devices marked as shared.</li>
                      </ul>
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-2">
                    <ReferenceInput
                      source="device_id"
                      reference="devices"
                      filter={{
                        'is_shared_device[eq]': true,
                      }}
                    >
                      <AutocompleteInput
                        label="Device"
                        optionText={(record) =>
                          `${inflection.humanize(record.device_type)} (${
                            record.external_id
                          })`
                        }
                        parse={(v) => (v === '' ? null : v)}
                        sx={{ width: '250px' }}
                        helperText={false}
                        validate={required()}
                      />
                    </ReferenceInput>
                    {/* <SelectInput source='device_id' label='Device' choices={[]} validate={required()} /> */}
                  </CardContent>
                  <CardFooter className="float-right">
                    <SubmitButton isLoading={isLoading} text="Submit" />
                  </CardFooter>
                </Card>
              </Form>
            </TabsContent>
            <TabsContent value="manual">
              <Form onSubmit={handlePressSetSharedDevice}>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-xl">
                      Manual measurement
                    </CardTitle>
                    <CardDescription className="max-w-[400px]">
                      Log measurements taken by devices manually.
                      <ul className="list-disc pl-6">
                        <li>Measurements will not count for RPM purposes.</li>
                      </ul>
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-2"></CardContent>
                  <CardFooter className="float-right">
                    <SubmitButton isLoading={isLoading} text="Submit" />
                  </CardFooter>
                </Card>
              </Form>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ActiveSharedDevice = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const { data, isLoading, error } = useQuery(
    [`patients/${record?.id}/assigned-devices`],
    () =>
      dataProvider.getCustom(
        `patients/${record?.id}/assigned-shared-devices`,
        {},
      ),
  );

  if (isLoading) return null;
  if (!data) return null;
  if (error) return null;

  return (
    <div className="flex flex-row gap-1">
      {data?.data?.map((device) => (
        <div className="h-8 rounded-lg border bg-card text-card-foreground shadow-sm p-1 min-w-[50px] inline-flex items-center hover:hover:bg-card/50">
          <Radio className="h-4 w-4" />
          <span className="pl-1">
            {inflection.humanize(device.device_type)}
          </span>
          <span className="text-green-600 font-bold pl-1">Active</span>
        </div>
      ))}
    </div>
  );
};

const SubmitButton = ({ isLoading, text }) => {
  const { isValid, isValidating, isSubmitting } = useFormState();

  const disabled = isValidating || isSubmitting || !isValid || isLoading;
  return (
    <Button disabled={disabled} type="submit" className="mt-2">
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Please wait
        </>
      ) : (
        <>{text || 'Save'}</>
      )}
    </Button>
  );
};
