import { get } from 'lodash';

export function pickTextColorBasedOnBgColorAdvanced(
  bgColor,
  lightColor,
  darkColor,
) {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}

export const triageColors = {
  LOW: { primary: '#D6F5D6', secondary: '#29A329' },
  MEDIUM: { primary: '#FCF9CF', secondary: '#999233' },
  TEST: { primary: '#CCBAA5', secondary: '#928576' },
  DOC: { primary: '#FCEEE3', secondary: '#E56F1A' },
  DOC_LOW: { primary: '#FCEEE3', secondary: '#E56F1A' },
  DOC_HIGH: { primary: '#FCEEE3', secondary: '#E56F1A' },
  ER: { primary: '#FAE6E6', secondary: '#D22D2D' },
};

export const reviewStatusColor = {
  pending: { color: '#E9BA83' },
  reviewed: { color: '#7ED6A1' },
};

export const monitoringConditionColor = {
  asthma: {
    color: '#A1C084',
  },
  copd: {
    color: '#84BCDA',
  },
  covid19: {
    color: '#DCCCFF',
  },
  chf: {
    color: '#F37748',
  },
  heartfailure: {
    color: '#F37748',
  },
  diabetes: {
    color: '#F7DC6F',
  },
  ckd: {
    color: '#CB77FC',
  },
  viral: {
    color: '#928576',
  },
  general: {
    color: '#4d5382',
  },
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  if (!string) return null;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  if (!name) return null;
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: 32,
      width: 32,
      fontSize: '0.9rem',
    },
    children: `${get(name.split(' '), [0, 0], '')}${get(
      name.split(' '),
      [1, 0],
      '',
    )}`,
  };
}

export const CustomPallete = {
  modalBack: '#FFFFFF',
  neutral1: '#F9FAFB',
  neutral2: '#EDF0F3',
  neutral3: '#E0E5EB',
  neutral5: '#56738F',
  neutral6: '#476685',
  neutral7: '#3E5974',
  neutral8: '#364C63',
  neutral10: '#243342',
  red5: '#DB5757',
  green1: '#D6F5D6',
  green2: '#ADEBAD',
  green6: '#29A329',
  primary2: '#E2F1FD',
  primary5: '#0B7ADA',
  primary6: '#0A6CC2',
  primary8: '#085191',
  white: '#FFFFFF',
  yellow1: '#FDFCE2',
  yellow2: '#FCF9CF',
  yellow6: '#999233',
  orange1: '#FCEEE3',
  orange2: '#FCEEE3',
  orange6: '#E56F1A',
  gray: '#F4F3F3',
};

export const statusColors = {
  background: {
    active: CustomPallete.green1,
    new: CustomPallete.yellow1,
    pending: CustomPallete.orange1,
    past: CustomPallete.neutral1,
  },
  border: {
    active: CustomPallete.green2,
    new: CustomPallete.yellow2,
    pending: CustomPallete.orange2,
    past: CustomPallete.neutral2,
  },
  text: {
    active: CustomPallete.green6,
    new: CustomPallete.yellow6,
    pending: CustomPallete.orange6,
    past: CustomPallete.neutral6,
  },
};

export const patientQueueColors = {
  blank: '#9e9e9e',
  normal: '#00FF00',
  medium: '#FFD700',
  high: '#ff9800',
  acute: '#f44336',
};
