export const validCPTCodes = [
  { id: '99091', name: '99091' },

  // RPM:
  { id: '99453', name: '99453' },
  { id: '99454', name: '99454' },
  { id: '99457', name: '99457' },
  { id: '99458', name: '99458' },

  // RTM:
  { id: '98975', name: '98975' },
  { id: '98976', name: '98976' },
  { id: '98977', name: '98977' },
  { id: '98980', name: '98980' },
  { id: '98981', name: '98981' },

  // CCM:
  { id: '99490', name: '99490' },
  { id: '99491', name: '99491' },
  { id: '99439', name: '99439' },
  { id: '99437', name: '99437' },

  // CCCM:
  { id: '99487', name: '99487' },
  { id: '99489', name: '99489' },

  // PCM:
  { id: '99424', name: '99424' },
  { id: '99425', name: '99425' },
  { id: '99426', name: '99426' },
  { id: '99427', name: '99427' },
];
