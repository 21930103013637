export const deviceStatusTypes = [
  {
    id: 'inactive',
    name: 'Inactive',
  },
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'pending',
    name: 'Pending',
  },
  {
    id: 'inactive_with_patient',
    name: 'Inactive with Patient',
  },
  {
    id: 'decommissioned',
    name: 'Decommissioned',
  },
  {
    id: 'pending_return_to_manufacturer',
    name: 'Pending Return to Manufacturer',
  },
  {
    id: 'returned_to_manufacturer',
    name: 'Returned to Manufacturer',
  },
];
