import React, { useState, useEffect } from 'react';
import {
  startAuthentication,
  startRegistration,
} from '@simplewebauthn/browser';

const apiUrl = window.config.apiUrl;

const PassKeyLogin = () => {
  const [message, setMessage] = useState('');
  const [otp, setOtp] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [verificationResponse, setVerificationResponse] = useState('');

  useEffect(() => {
    // Check if WebAuthn is supported
    if (!window.PublicKeyCredential) {
      setMessage('WebAuthn is not supported in this browser.');
    }
  }, []);

  const handleLogin = async () => {
    try {
      const options = await fetchAuthenticationOptions();
      const authenticationResponse = await startAuthentication(options);
      const verificationResult = await verifyAuthentication(
        authenticationResponse,
      );

      if (verificationResult) {
        setMessage('Login successful!');
        setVerificationResponse(JSON.stringify(verificationResult, null, 2));
      } else {
        setMessage('Login failed. Please try again.');
        setVerificationResponse('');
      }
    } catch (error) {
      console.error('Authentication error:', error);
      setMessage('An error occurred during login.');
      setVerificationResponse('');
    }
  };

  const handleRegister = async () => {
    try {
      const options = await fetchRegistrationOptions(otp, birthdate);
      const registrationResponse = await startRegistration(options);
      const verificationResult = await verifyRegistration(registrationResponse);

      if (verificationResult) {
        setMessage('Registration successful!');
      } else {
        setMessage('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setMessage('An error occurred during registration.');
    }
  };

  const fetchAuthenticationOptions = async () => {
    const response = await fetch(`${apiUrl}/auth/login/passkey`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });
    return response.json();
  };

  const verifyAuthentication = async (authenticationResponse) => {
    const response = await fetch(`${apiUrl}/auth/login/passkey`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(authenticationResponse),
      credentials: 'include',
    });
    return response.json();
  };

  const fetchRegistrationOptions = async (otp, birthdate) => {
    const response = await fetch(
      `${apiUrl}/auth/webauthn/register/patient/start`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: otp, birthdate }),
        credentials: 'include',
      },
    );
    return response.json();
  };

  const verifyRegistration = async (registrationResponse) => {
    const response = await fetch(
      `${apiUrl}/auth/webauthn/register/patient/complete`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(registrationResponse),
        credentials: 'include',
      },
    );
    return response.json();
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            PassKey Login
          </h2>
        </div>
        <div className="mt-8 space-y-6">
          <button
            onClick={handleLogin}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Login with PassKey
          </button>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-gray-100 text-gray-500">
                  Or register a new PassKey
                </span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-3">
              <input
                type="text"
                placeholder="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
              <input
                type="date"
                placeholder="Birthdate"
                value={birthdate}
                onChange={(e) => setBirthdate(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <button
              onClick={handleRegister}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Register PassKey
            </button>
          </div>
        </div>

        {message && (
          <div className="mt-6 text-center text-sm text-gray-600">
            {message}
          </div>
        )}

        {verificationResponse && (
          <div className="mt-6 text-sm text-gray-600">
            <h3 className="font-bold">Verification Response:</h3>
            <pre className="mt-2 bg-gray-200 p-4 rounded-md overflow-x-auto">
              {verificationResponse}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default PassKeyLogin;
