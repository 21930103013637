export const monitoringActivityTypesExtended = {
  rpm_99091: '99091 - QHP collection and interpretation',
  rpm_99457_99458: '99457/99458 - RPM treatment management services',
  rpm_99453: '99453 - RPM Initial Setup',
  rtm_98975: '98975 - RTM Initial Setup',
  rtm_98980_98981: '98980/98981 - RTM - QHP time',
  ccm_99490_99439: '99490/99439 - CCM - Clinical staff time',
  ccm_99491_99437: '99491/99437 - CCM - QHP time',
  cccm_99487_99489: '99487/99489 - CCCM - Clinical staff time',
  pcm_99424_99425: '99424/99425 - PCM - QHP time',
  pcm_99426_99427: '99426/99427 - PCM - Clinical staff time',
  note: 'Note',
  activity: 'Activity Log',
};
