export const unseenReasons = [
  {
    id: 'already_checked_out',
    name: 'Already checked out per EHR/healthcare professionals'
  },
  {
    id: 'missed_enrollment_room',
    name: 'Patient missed enrollment room before follow-up visit'
  },
  {
    id: 'staff_unavailable', 
    name: 'Onboarding staff unavailable due to other enrollments'
  },
  {
    id: 'no_show',
    name: 'Patient was a no-show',
  },
  {
    id: 'rescheduled',
    name: 'Appointment was rescheduled',
  },
  {
    id: 'canceled',
    name: 'Appointment was canceled',
  },
];

