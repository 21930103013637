import { Button } from '@/modules/ui/components/button';

import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { determineCellValue } from '../utils/determineCellValue';
import { chartProperties } from './chartProperties';
import { MetricsDialogContext } from './MetricsDialog';
import { Datagrid, RowClickFunction, useRecordContext } from 'react-admin';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { StickyNote } from 'lucide-react';

// biome-ignore lint/correctness/noUnusedVariables: <explanation>
function AnnotatedValue({ label }) {
  const { measureName } = useContext(MetricsDialogContext);

  const record = useRecordContext();
  const value = determineCellValue({
    row: record,
    chartName: measureName,
    chartProperties,
  });

  const hasAnnotation = record.annotations?.some(
    (annotation) => annotation.annotation_type === 'note',
  );

  return (
    <span className="flex items-center">
      {value}
      {hasAnnotation && <StickyNote className="w-4 h-4 ml-1" />}
    </span>
  );
}

export function MetricTableRecent({ data }) {
  const { setSelectedView, setSelectedRow } = useContext(MetricsDialogContext);

  const onClickRow = (id) => {
    if (id) {
      setSelectedRow(id);
      setSelectedView('table');
    }

    return false;
  };

  return (
    <div className="flex flex-col">
      <p className="text-left text-md font-medium mb-2">Past 7 days</p>
      <Datagrid
        data={get(data, 'points', [])
          .filter(
            (row) =>
              DateTime.fromISO(row.x) >= DateTime.utc().minus({ days: 7 }),
          )
          .slice(0, 10)}
        sort={{ field: 'x', order: 'DESC' }}
        rowClick={onClickRow as RowClickFunction}
      >
        <LuxonDateField source="x" label="Timestamp" showTime />
        <AnnotatedValue label="Value" />
        {/* <FunctionField source="annotations" render={(record) => record.annotations?.map(annotation => <Badge variant='outline'>{inflection.humanize(annotation?.annotation_reason)}</Badge>)} /> */}
      </Datagrid>
      <Button
        variant="default"
        className="mt-4 h-8 mx-auto"
        onClick={() => {
          setSelectedView('table');
        }}
      >
        View all
      </Button>
    </div>
  );
}
