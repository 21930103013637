import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { cn } from '@/modules/ui/utils/cn';
import { AssessmentType } from '@/modules/questionnaire/types/assessmentTypes';
import React, { useState } from 'react';
import { useDataProvider, useRecordContext, useRefresh } from 'react-admin';
import { useQuery } from 'react-query';
import { QuestionnaireDialogConfirmExit } from './QuestionnarieDialogConfirmExit';
import { ModalSelectAssessmentContent } from './ModalSelectAssessmentContent';
import { QuestionnaireContext } from '../context/QuestionnaireContext';
import { ModalQuestionnaireContent } from './ModalQuestionnaireContent';
import inflection from 'inflection';

export function QuestionnaireDialog({ open, setOpen }) {
  const patientRecord = useRecordContext();
  const dataProvider = useDataProvider();
  const [workFlow, setWorkFlow] = useState<AssessmentType>();
  const [inProgress, setInProgress] = useState(false);
  const [confirmExitOpen, setConfirmExitOpen] = useState(false);
  const refresh = useRefresh();

  const {
    data: dataAssessments,
    isLoading: isLoadingAssessments,
    refetch: refetchAssessments,
  } = useQuery(
    ['getUserTasks', patientRecord?.id],
    () =>
      dataProvider.getCustom(`patients/${patientRecord?.id}/tasks`, {
        meta: {
          subject: 'provider',
        },
      }),
    {
      enabled: !!patientRecord?.id && !inProgress && open,
    },
  );

  if (!patientRecord) return null;

  const handleClose = () => {
    if (inProgress) {
      setConfirmExitOpen(true);
    } else {
      handleConfirmExit();
    }
  };

  const handleConfirmExit = () => {
    setConfirmExitOpen(false);
    setOpen(false);
    setInProgress(false);
    setWorkFlow(undefined);
  };

  const handleStay = () => {
    setConfirmExitOpen(false);
  };

  const onNextClick = (workflowIn: AssessmentType) => {
    setWorkFlow(workflowIn);
    if (workflowIn.available) {
      setInProgress(true);
    }
  };

  const handleCompleteAssessment = () => {
    refresh();
    handleConfirmExit();
  };

  const title =
    workFlow?.title ||
    (workFlow && inflection.humanize(workFlow?.name)) ||
    'Patient Assessment';
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          onClose={handleClose}
          className={cn('min-w-[70vw] overflow-y-auto max-h-[90vh]')}
        >
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <QuestionnaireContext.Provider
            value={{
              workFlow,
              inProgress,
              setInProgress,
              refetchAssessments,
              isLoadingAssessments,
              setWorkFlow,
            }}
          >
            {!!!workFlow && (
              <ModalSelectAssessmentContent
                onCloseClick={handleClose}
                onNextClick={onNextClick}
                dataAssessments={dataAssessments?.data}
                isLoading={isLoadingAssessments}
              />
            )}
            {workFlow && (
              <ModalQuestionnaireContent
                handleCompleteAssessment={handleCompleteAssessment}
              />
            )}
          </QuestionnaireContext.Provider>
        </DialogContent>
      </Dialog>
      <QuestionnaireDialogConfirmExit
        open={confirmExitOpen}
        handleClose={handleStay}
        handleConfirm={handleConfirmExit}
      />
    </div>
  );
}
