export const patientReviewStatus = [
  {
    id: 'pending',
    name: 'Pending',
    className: 'border-yellow-300 bg-yellow-100',
  },
  {
    id: 'reviewed',
    name: 'Reviewed',
    className: 'border-green-300 bg-green-100',
  },
];
