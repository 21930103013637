import { PatientLayout } from '@/modules/patients/components/PatientLayout';
import { PatientStatusProvider } from '@/modules/patients/hooks/patient-status';
import React from 'react';
import { ShowBase } from 'react-admin';
import { Route, Routes } from 'react-router-dom';
import { PatientClinicalShow } from './PatientClinicalShow';
import { PatientShowSimple } from './PatientShowSimple';

function PatientShow() {
  return (
    <ShowBase
      resource="patients"
      queryOptions={{
        meta: { expand: ['profile', 'measure_reports', 'observations'] },
      }}
    >
      <PatientStatusProvider>
        <PatientLayout>
          <Routes>
            <Route path="" element={<PatientShowSimple />} />
            <Route path="/clinical/*" element={<PatientClinicalShow />} />
          </Routes>
        </PatientLayout>
      </PatientStatusProvider>
    </ShowBase>
  );
}

export { PatientShow };
