import inflection from 'inflection';
import get from 'lodash/get';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { pickTextColorBasedOnBgColorAdvanced } from '../../../utils/color';
import { Badge } from '@/modules/ui/components/badge';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const ColoredChipField = (props) => {
  const { source, emptyText, colorScheme = null, mapping = null } = props;
  const record = useRecordContext(props);

  if (!record) {
    return null;
  }
  let value = get(record, source);
  if (!value) {
    return emptyText ? (
      <p className="text-base font-medium">{emptyText}</p>
    ) : null;
  }

  let color = stringToColor(value);
  if (colorScheme && value) {
    color = get(colorScheme, [value.toLowerCase(), 'color'], color);
    value = get(colorScheme, [value.toLowerCase(), 'value'], value);
  }
  const textColor = pickTextColorBasedOnBgColorAdvanced(
    color,
    '#FFFFFF',
    '#000000',
  );

  let label = inflection.humanize(value);
  if (mapping) {
    label = get(mapping, value.toLowerCase(), label);
  }

  return (
    <Badge
      variant="outline"
      className="capitalize"
      style={{ borderColor: color, backgroundColor: color, color: textColor }}
    >
      {label}
    </Badge>
  );
};
